import React, {Component} from 'react';
import './style.css'
import CcCheckbox from "../ccCheckbox";

class PermissionItem extends Component {
    render() {
        const {checked, onChange, text} = this.props;
        return (
            <div className="permission-item-style d-flex align-items-center">
                <CcCheckbox
                    checked={checked}
                    onChange={onChange}
                />
                {text}
            </div>
        )
    }
}

export default PermissionItem;
