import {callApi} from "../05-utils/commonUtils";

const ACTIONS = {
    GET_SECRET_KEY_PROGRESS: "GET_SECRET_KEY_PROGRESS",
    GET_SECRET_KEY_SUCCESS: "GET_SECRET_KEY_SUCCESS",
    GET_SECRET_KEY_FAILED: "GET_SECRET_KEY_FAILED",
    ADD_SECRET_KEY_PROGRESS: "ADD_SECRET_KEY_PROGRESS",
    ADD_SECRET_KEY_SUCCESS: "ADD_SECRET_KEY_SUCCESS",
    ADD_SECRET_KEY_FAILED: "ADD_SECRET_KEY_FAILED",
};

const updateStatus = (status, statusInfo) => {
    return {
        type: status,
        data: statusInfo
    }
};

const getSecretKet = () => {
    let config = {
        method: 'get',
        isAuthorization: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_SECRET_KEY_PROGRESS));
        callApi('/api/get_secret_key',
            config,
            null,
            (data) => {
                dispatch(updateStatus(ACTIONS.GET_SECRET_KEY_SUCCESS, data));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.GET_SECRET_KEY_FAILED, {error: err}));
            }
        );
    };
};

const addSecretKey = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.ADD_SECRET_KEY_PROGRESS));
        callApi('/api/add_secret_key',
            config,
            null,
            () => {
                dispatch(updateStatus(ACTIONS.ADD_SECRET_KEY_SUCCESS,));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.ADD_SECRET_KEY_FAILED, {error: err}));
            }
        );
    };
};

export {ACTIONS, getSecretKet, addSecretKey};
