import React, { Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withNamespaces } from "react-i18next";
import ReactPaginate from "react-paginate";
import { Col, Input, Row, Table, Button, Label } from "reactstrap";
import ButtonTabs from "../../../../../03-components/ButtonTabs";
import {
  COIN_TYPE,
  MMP_TOP_UP_TAB,
  POM_UPDATE_STATUS_TOP_UP,
} from "../../../../../05-utils/commonData";
import "./style.css";
import Workbook from "react-excel-workbook";
import momentTz from "moment-timezone";
import _ from "lodash";

const Search = ({
  params,
  handleChange,
  handleSearch,
  charges,
  pages,
  setParams,
  openConfirm,
  selected: requestSelected,
  setSelected,
  t,
  statisticChargeByStatus,
  setStatusConfirm,
  totalUSD,
  adminBalance,
  openRejection,
  userInfo,
  totalCCT,
  totalROY,
  mmpCurrencyBalance
}) => {
  const { startTime, endTime, query, limit, tab, page } = params;
  const handleKeyPressSearch = (e) => {
    // if (e.keyCode === 13) {
    handleSearch();
    // }
  };

  const handleChangePage = ({ selected }) => {
    const value = parseInt(selected) + 1;
    handleChange({ target: { name: "page", value } });
  };

  const handleChangeLimit = (e) => {
    setParams({ ...params, page: 1, limit: e.target.value });
  };

  const handleChangeTab = (e) => {
    setParams({ ...params, tab: e.target.value, page: 1, limit: 1000 });
    setSelected([]);
  };

  const dataExport = charges.map((item, index) => {
    return {
      number: index + 1,
      walletId: item?.walletId,
      fullName: item?.fullname,
      email: item?.email,
      TCTAmount:
        item?.coinType === COIN_TYPE.CCT ? item?.TCTAmount || "0" : "0",
      wcgFee: item?.coinType === COIN_TYPE.CCT ? item?.wcgFee || "0" : "0",
      cardFee: item?.coinType === COIN_TYPE.CCT ? item?.cardFee || "0" : "0",
      topUpAmount: item?.topUpAmount || "0",
      wcgFee: item?.wcgFee || "0",
      cardFee: item?.cardFee || "0",
      rate: `1 ${item?.currency} = ${item?.rate} ${item?.coinType ? COIN_TYPE[item?.coinType] : COIN_TYPE.AGT}`,
      createdAt: momentTz
        .tz(item?.createdAt, "Asia/Tokyo")
        .format("YYYY/MM/DD HH:mm:ss"),
      updatedAt: momentTz
        .tz(item?.updatedAt, "Asia/Tokyo")
        .format("YYYY/MM/DD HH:mm:ss"),
      approvedTime:  momentTz
        .tz(
          item?.timestampSuperAdminApproval ||
            item?.timestampAdminApproval,
          "Asia/Tokyo"
        )
        .format("YYYY/MM/DD HH:mm:ss"),
      note: item?.note
    };
  });

  return (
    <Fragment>
      <Row className="justify-content-between mb-4">
        <Col xs={12} sm={12} md="auto">
          <Row>
            <Col lg="6" md="6" sm="6" xs="12" className="mt-1">
              <DatePicker
                className="form-control brs"
                placeholderText={t("HuvStyleTopUpPage.StartDate")}
                dateFormat="yyyy/MM/dd"
                selected={startTime}
                onChange={(value) =>
                  handleChange({ target: { name: "startTime", value } })
                }
                isClearable
                maxDate={new Date()}
              />
            </Col>
            <Col lg="6" md="6" sm="6" xs="12" className="mt-1">
              <DatePicker
                className="form-control brs"
                placeholderText={t("HuvStyleTopUpPage.EndDate")}
                dateFormat="yyyy/MM/dd"
                selected={endTime}
                onChange={(value) =>
                  handleChange({ target: { name: "endTime", value } })
                }
                minDate={startTime}
                maxDate={new Date()}
                isClearable
              />
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12" className="mt-1">
              <Input
                type="text"
                onChange={(e) => handleChange(
                  {
                    target:{ name: "keyWord", value: e.target.value.trim() }
                  }
                )}
                name="keyWord"
                id="search"
                className="brs"
                placeholder={t("HuvStyleTopUpPage.KeyWord_2")}
                onBlur={handleKeyPressSearch}
                value={query}
              ></Input>
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={12} md="auto" className="mf-auto">
          <div className="w3-green">
            <Label className="label-admin-amount">
              {t("TopUpPage.RemainingUSD")}
              {`: ${adminBalance || 0}`}{" "}
            </Label>
          </div>
        </Col>
        <Col xs={12} sm={12} md="auto">
          <Table className="statistic currency-balance mt-1">
            <tbody>
              <tr>
                <td colSpan={2} className="left">
                  {t("HuvStyleTopUpPage.Statistic.CurrencyBalance")}
                </td>
              </tr>
              <tr>
                <td>USD</td>
                <td>{mmpCurrencyBalance?.usd || 0}</td>
              </tr>
              <tr>
                <td>USDT</td>
                <td>{mmpCurrencyBalance?.usdt || 0}</td>
              </tr>
              <tr>
                <td>ETH</td>
                <td>{mmpCurrencyBalance?.eth || 0}</td>
              </tr>
              <tr>
                <td>BTC</td>
                <td>{mmpCurrencyBalance?.btc || 0}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col xs={12} sm={12} md="auto">
          <Table className="statistic mt-1">
            <tbody>
              <tr>
                <td>{t("HuvStyleTopUpPage.Statistic.UncheckedNumber")}</td>
                <td>
                  {statisticChargeByStatus["not-yet-verified"]?.count || 0}
                </td>
              </tr>
              <tr>
                <td>
                  {t("HuvStyleTopUpPage.Statistic.UncheckedToRejectedNumber")}
                </td>
                <td>
                  {statisticChargeByStatus["rejected-step-2"]?.count || 0}
                </td>
              </tr>
              <tr>
                <td>{t("HuvStyleTopUpPage.Statistic.CheckingNumber")}</td>
                <td>{statisticChargeByStatus?.checking?.count || 0}</td>
              </tr>
              <tr>
                <td>{t("HuvStyleTopUpPage.Statistic.Approved")}</td>
                <td>{statisticChargeByStatus?.approved?.count || 0}</td>
              </tr>
              <tr>
                <td>{t("HuvStyleTopUpPage.Statistic.Rejecting")}</td>
                <td>{statisticChargeByStatus?.rejecting?.count || 0}</td>
              </tr>
              <tr>
                <td>{t("HuvStyleTopUpPage.Statistic.Rejected")}</td>
                <td>{statisticChargeByStatus?.rejected?.count || 0}</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <ButtonTabs
        options={Object.values(MMP_TOP_UP_TAB)}
        userInfo={userInfo}
        value={tab}
        onChange={handleChangeTab}
        name="tab"
      />
      <Row className="justify-content-between status-paginate-box align-items-end">
        <Col>
          <Row noGutters className="align-items-end">
            <Col xs="auto" className="mb-2">
              <div className="usd mr-1">{`USD: ${totalUSD || 0}`}</div>
            </Col>
            <Col xs="auto" className="mb-2">
              <div className="tct mr-1">{`ROY: ${_.round(Number(totalCCT) + Number(totalROY), 3) || 0}`}</div>
            </Col>
            <Col xs="auto" className="mb-2 mr-2">
              <Input
                className="limit-select"
                type="select"
                name="limit"
                value={limit}
                onChange={handleChangeLimit}
                bsSize="md"
              >
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000" selected>
                  1000
                </option>
              </Input>
            </Col>
            <Col xs="auto" className="mb-2 d-flex">
              {Array.isArray(charges) && charges.length > 0 && (
                <ReactPaginate
                  previousLabel="<"
                  nextLabel=">"
                  breakLabel={<a href="">...</a>}
                  breakClassName="break-me"
                  pageCount={pages}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={handleChangePage}
                  containerClassName="pagination m-0"
                  activeClassName="active"
                  forcePage={page - 1}
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col xs="auto">
          {tab === MMP_TOP_UP_TAB.NotYetVerified.value && (
            <Fragment>
              <Button
                className="rejection-step-2-btn mb-2"
                onClick={() => {
                  setStatusConfirm(POM_UPDATE_STATUS_TOP_UP.REJECTION);
                  openRejection(true);
                }}
                disabled={requestSelected.length === 0}
              >
                {t("HuvStyleTopUpPage.Rejection")}
              </Button>

              <Button
                className="checked-btn mb-2"
                onClick={() => {
                  setStatusConfirm(POM_UPDATE_STATUS_TOP_UP.CHECKED);
                  openConfirm(true);
                }}
                disabled={requestSelected.length === 0}
              >
                {t("HuvStyleTopUpPage.Confirmation")}
              </Button>
            </Fragment>
          )}
          {tab === MMP_TOP_UP_TAB.UncheckedToRejected.value && (
            <Workbook
              filename="未チェック->拒否済.xlsx"
              element={
                <Button
                  className="btn checked-btn mb-2"
                  disabled={charges.length === 0}
                >
                  {t("Button.CSVDownloadAll")}
                </Button>
              }
            >
              <Workbook.Sheet data={dataExport} name="Sheet A">
                <Workbook.Column label="No." value="number" />
                <Workbook.Column label="ウォレット ID" value="walletId" />
                <Workbook.Column label="メールアドレ" value="email" />
                <Workbook.Column label="フルネーム" value="fullName" />
                <Workbook.Column label="チャージ金額" value="topUpAmount" />
                <Workbook.Column
                  label="ユーザーが支払った金額"
                  value="TCTAmount"
                />
                <Workbook.Column label="Excel Finance ⼿数料" value="wcgFee" />
                <Workbook.Column label="AGT-Glover ⼿数料" value="cardFee" />
                <Workbook.Column label="レートログ" value="rate" />
                <Workbook.Column label="リクエスト時間" value="createdAt" />
                <Workbook.Column label="拒否時間" value="updatedAt" />
                <Workbook.Column label="メモ" value="note" />
              </Workbook.Sheet>
            </Workbook>
          )}

          {tab === MMP_TOP_UP_TAB.Approved.value && (
            <Workbook
              filename="承認済.xlsx"
              element={
                <Button
                  className="btn checked-btn mb-2"
                  disabled={charges.length === 0}
                >
                  {t("Button.CSVDownloadAll")}
                </Button>
              }
            >
              <Workbook.Sheet data={dataExport} name="Sheet A">
                <Workbook.Column label="No." value="number" />
                <Workbook.Column label="ウォレット ID" value="walletId" />
                <Workbook.Column label="メールアドレ" value="email" />
                <Workbook.Column label="フルネーム" value="fullName" />
                <Workbook.Column label="チャージ金額" value="topUpAmount" />
                <Workbook.Column
                  label="ユーザーが支払った金額"
                  value="TCTAmount"
                />
                <Workbook.Column label="Excel Finance ⼿数料" value="wcgFee" />
                <Workbook.Column label="AGT-Glover ⼿数料" value="cardFee" />
                <Workbook.Column label="レートログ" value="rate" />
                <Workbook.Column label="リクエスト時間" value="createdAt" />
                <Workbook.Column label="承認時間" value="approvedTime" />
              </Workbook.Sheet>
            </Workbook>
          )}

          {tab === MMP_TOP_UP_TAB.Checking.value && (
            <Button
              className="checked-btn mb-2"
              onClick={() => openConfirm(true)}
            >
              {t("HuvStyleTopUpPage.PushReNotification")}
            </Button>
          )}
          {tab === MMP_TOP_UP_TAB.Rejecting.value && (
            <Fragment>
              <Button
                className="reject-btn mb-2"
                onClick={() => {
                  setStatusConfirm(POM_UPDATE_STATUS_TOP_UP.REJECTED);
                  openConfirm("Rejected");
                }}
                disabled={requestSelected.length === 0}
              >
                {t("HuvStyleTopUpPage.Rejected")}
              </Button>
              <Button
                className="re-checked-btn mb-2"
                onClick={() => {
                  setStatusConfirm(POM_UPDATE_STATUS_TOP_UP.RECHECKED);
                  openConfirm("Rechecked");
                }}
                disabled={requestSelected.length === 0}
              >
                {t("HuvStyleTopUpPage.Rechecked")}
              </Button>
            </Fragment>
          )}
        </Col>
      </Row>
    </Fragment>
  );
};

export default withNamespaces()(Search);
