import { callApi } from "../05-utils/commonUtils";
import queryString from "query-string";

const ACTIONS = {
  POMO_GET_RATE_SETTING_PROGRESS: "POMO_GET_RATE_SETTING_PROGRESS",
  POMO_GET_RATE_SETTING_SUCCESS: "POMO_GET_RATE_SETTING_SUCCESS",
  POMO_GET_RATE_SETTING_FAILED: "POMO_GET_RATE_SETTING_FAILED",
  POMO_GET_RATE_LIMIT_SETTING_PROGRESS: "POMO_GET_RATE_LIMIT_SETTING_PROGRESS",
  POMO_GET_RATE_LIMIT_SETTING_SUCCESS: "POMO_GET_RATE_LIMIT_SETTING_SUCCESS",
  POMO_GET_RATE_LIMIT_SETTING_FAILED: "POMO_GET_RATE_LIMIT_SETTING_FAILED",
  POMO_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS:
    "POMO_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS",
  POMO_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS:
    "POMO_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS",
  POMO_UPDATE_EXCEL_FINANCE_SETTING_FAILED:
    "POMO_UPDATE_EXCEL_FINANCE_SETTING_FAILED",
  POMO_UPDATE_EZP_SETTING_PROGRESS: "POMO_UPDATE_EZP_SETTING_PROGRESS",
  POMO_UPDATE_EZP_SETTING_SUCCESS: "POMO_UPDATE_EZP_SETTING_SUCCESS",
  POMO_UPDATE_EZP_SETTING_FAILED: "POMO_UPDATE_EZP_SETTING_FAILED",
  POMO_UPDATE_SETTING_RATE_PROGRESS: "POMO_UPDATE_SETTING_RATE_PROGRESS",
  POMO_UPDATE_SETTING_RATE_SUCCESS: "POMO_UPDATE_SETTING_RATE_SUCCESS",
  POMO_UPDATE_SETTING_RATE_FAILED: "POMO_UPDATE_SETTING_RATE_FAILED",
  POMO_UPDATE_RATE_LIMIT_SETTING_PROGRESS:
    "POMO_UPDATE_RATE_LIMIT_SETTING_PROGRESS",
  POMO_UPDATE_RATE_LIMIT_SETTING_SUCCESS:
    "POMO_UPDATE_RATE_LIMIT_SETTING_SUCCESS",
  POMO_UPDATE_RATE_LIMIT_SETTING_FAILED:
    "POMO_UPDATE_RATE_LIMIT_SETTING_FAILED",
  POMO_REFUND_CCT_PROGRESS: "POMO_REFUND_CCT_PROGRESS",
  POMO_REFUND_CCT_SUCCESS: "POMO_REFUND_CCT_SUCCESS",
  POMO_REFUND_CCT_FAILED: "POMO_REFUND_CCT_FAILED",
  POMO_RECEIVING_CCT_PROGRESS: "POMO_RECEIVING_CCT_PROGRESS",
  POMO_RECEIVING_CCT_SUCCESS: "POMO_RECEIVING_CCT_SUCCESS",
  POMO_RECEIVING_CCT_FAILED: "POMO_RECEIVING_CCT_FAILED",
  POMO_AUTHENTICATION_PROGRESS: "POMO_AUTHENTICATION_PROGRESS",
  POMO_AUTHENTICATION_SUCCESS: "POMO_AUTHENTICATION_SUCCESS",
  POMO_AUTHENTICATION_FAILED: "POMO_AUTHENTICATION_FAILED",
};

const updateStatus = (status, statusInfo) => {
  return {
    type: status,
    data: statusInfo,
  };
};

const PomoAuthenticationSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POMO_AUTHENTICATION_PROGRESS));
    callApi(
      "/api/pomo-pomo/update_auth_pomo_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.POMO_AUTHENTICATION_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.POMO_AUTHENTICATION_FAILED, { error: err })
        );
      }
    );
  };
};

const PomoReceivingCCT = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POMO_RECEIVING_CCT_PROGRESS));
    callApi(
      "/api/pomo-pomo/update_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.POMO_RECEIVING_CCT_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.POMO_RECEIVING_CCT_FAILED, { error: err })
        );
      }
    );
  };
};

const PomoRefundCCT = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POMO_REFUND_CCT_PROGRESS));
    callApi(
      "/api/pomo-pomo/update_wallet_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.POMO_REFUND_CCT_SUCCESS));
      },
      (err) => {
        dispatch(updateStatus(ACTIONS.POMO_REFUND_CCT_FAILED, { error: err }));
      }
    );
  };
};

const PomoUpdateRateLimit = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POMO_UPDATE_RATE_LIMIT_SETTING_PROGRESS));
    callApi(
      `/api/pomo-pomo/update_limit_title`,
      config,
      null,
      (data) => {
        dispatch(updateStatus(ACTIONS.POMO_UPDATE_RATE_LIMIT_SETTING_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.POMO_UPDATE_RATE_LIMIT_SETTING_FAILED, {
            error: err,
          })
        );
      }
    );
  };
};

const PomoUpdateRateSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POMO_UPDATE_SETTING_RATE_PROGRESS));
    callApi(
      `/api/pomo-pomo/update_setting`,
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.POMO_UPDATE_SETTING_RATE_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.POMO_UPDATE_SETTING_RATE_FAILED, { error: err })
        );
      }
    );
  };
};

const PomoUpdateEzpSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POMO_UPDATE_EZP_SETTING_PROGRESS));
    callApi(
      `/api/pomo-pomo/update_setting`,
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.POMO_UPDATE_EZP_SETTING_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.POMO_UPDATE_EZP_SETTING_FAILED, { error: err })
        );
      }
    );
  };
};

const PomoUpdateExcelFinanceSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POMO_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS));
    callApi(
      `/api/pomo-pomo/update_setting`,
      config,
      null,
      () => {
        dispatch(
          updateStatus(ACTIONS.POMO_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS)
        );
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.POMO_UPDATE_EXCEL_FINANCE_SETTING_FAILED, {
            error: err,
          })
        );
      }
    );
  };
};

const PomoGetRateLimitSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POMO_GET_RATE_LIMIT_SETTING_PROGRESS));
    callApi(
      `/api/pomo-pomo/get_limit_titles`,
      config,
      null,
      (res) => {
        dispatch(
          updateStatus(ACTIONS.POMO_GET_RATE_LIMIT_SETTING_SUCCESS, {
            limitSettings: res,
          })
        );
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.POMO_GET_RATE_LIMIT_SETTING_FAILED, {
            error: err,
          })
        );
      }
    );
  };
};

const PomoGetRateSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POMO_GET_RATE_SETTING_PROGRESS));
    callApi(
      `/api/pomo-pomo/get_setting`,
      config,
      null,
      (res) => {
        dispatch(
          updateStatus(ACTIONS.POMO_GET_RATE_SETTING_SUCCESS, { settings: res })
        );
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.POMO_GET_RATE_SETTING_FAILED, { error: err })
        );
      }
    );
  };
};

export {
  ACTIONS,
  PomoGetRateSetting,
  PomoGetRateLimitSetting,
  PomoUpdateExcelFinanceSetting,
  PomoUpdateEzpSetting,
  PomoUpdateRateSetting,
  PomoUpdateRateLimit,
  PomoRefundCCT,
  PomoReceivingCCT,
  PomoAuthenticationSetting
};
