import React, {Component} from 'react';

export default class Error403Page extends Component {
	render() {
		return (
			<div className="pages-error-403">
				403 Forbidden
			</div>
		);
	}
}