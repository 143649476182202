import React, {Component} from 'react';
import './style.css';

export default class LogSetting extends Component {

    render() {
        const {keySetting, oldValue, newValue} = this.props;
        return (
            <div className="com-log-setting d-flex ">
                <p>Change&nbsp;</p>
                <p className="text-danger">{keySetting}</p>
                <p>&nbsp; from&nbsp; </p>
                <p className="text-danger">{oldValue}</p>
                <p>&nbsp; to &nbsp;</p>
                <p className="text-success">{newValue}</p>
            </div>
        );
    }
}