const ENV = {
    LCL: {
        // API_SERVER: "http://192.168.10.37:5001",
        API_SERVER: "https://dev-api.excelpayment-world.com",
        ENCRYPT_KEY: "7c8b5J341Gy8Ds4AJQR7kEFZH3WmcslR",
        IV_ENCRYPT: "aKSKKSZsB7I9sTxr",
    },

    DEV: {
        API_SERVER: "https://dev-api.excelpayment-world.com",
        ENCRYPT_KEY: "7c8b5J341Gy8Ds4AJQR7kEFZH3WmcslR",
        IV_ENCRYPT: "aKSKKSZsB7I9sTxr",
    },

    STG: {
        API_SERVER: "https://stg-api.excelpayment-world.com",
        ENCRYPT_KEY: "7c8b5J341Gy8Ds4AJQR7kEFZH3WmcslR",
        IV_ENCRYPT: "aKSKKSZsB7I9sTxr",
    },

    PRO: {
        API_SERVER: "https://api.excelpayment-world.com",
        ENCRYPT_KEY: "IpA5wQ2ZY38UgOo6eBexOnV4bqRwVq26",
        IV_ENCRYPT: "cAGmQqURXn7fwRQQ",
    },
};

const config = ENV[process.env.REACT_APP_STAGE || "LCL"];

const getEnv = (name, defaultValue) => {
    return process.env[name] || config[name] || defaultValue;
};

export {getEnv};
