import React, {Component} from 'react';

export default class Error404Page extends Component {
	render() {
		return (
			<div className="pages-error-404">
				404 Not Found
			</div>
		);
	}
}