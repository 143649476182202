import { ACTIONS } from "../01-actions/settingRateMMP";

const initialState = {
  status: null,
  settings: null,
  settingRateError: null,
  mmpStatus: null,
  mmpLimitSettings: [],
  mmpSettings: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // PROGRESS
    case ACTIONS.MMP_GET_RATE_SETTING_PROGRESS:
    case ACTIONS.MMP_GET_RATE_LIMIT_SETTING_PROGRESS:
    case ACTIONS.MMP_UPDATE_EZP_SETTING_PROGRESS:
    case ACTIONS.MMP_UPDATE_SETTING_RATE_PROGRESS:
    case ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_PROGRESS:
    case ACTIONS.MMP_REFUND_CCT_PROGRESS:
    case ACTIONS.MMP_RECEIVING_CCT_PROGRESS:
    case ACTIONS.MMP_AUTHENTICATION_PROGRESS:
    case ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS:
      return {
        ...state,
        mmpStatus: action.type,
      };
    case ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS:
    case ACTIONS.MMP_UPDATE_EZP_SETTING_SUCCESS:
    case ACTIONS.MMP_UPDATE_SETTING_RATE_SUCCESS:
    case ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_SUCCESS:
    case ACTIONS.MMP_REFUND_CCT_SUCCESS:
    case ACTIONS.MMP_RECEIVING_CCT_SUCCESS:
    case ACTIONS.MMP_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        mmpStatus: action.type,
      };
    // SUCCESS
    case ACTIONS.MMP_GET_RATE_SETTING_SUCCESS:
      return {
        ...state,
        mmpStatus: action.type,
        mmpSettings: action.data.settings,
      };
    case ACTIONS.MMP_GET_RATE_LIMIT_SETTING_SUCCESS:
      return {
        ...state,
        mmpStatus: action.type,
        mmpLimitSettings: action.data.limitSettings
          ? action.data.limitSettings
          : [],
      };

    // FAILED
    case ACTIONS.MMP_GET_RATE_SETTING_FAILED:
    case ACTIONS.MMP_GET_RATE_LIMIT_SETTING_FAILED:
    case ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_FAILED:
    case ACTIONS.MMP_UPDATE_EZP_SETTING_FAILED:
    case ACTIONS.MMP_UPDATE_SETTING_RATE_FAILED:
    case ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_FAILED:
    case ACTIONS.MMP_REFUND_CCT_FAILED:
    case ACTIONS.MMP_RECEIVING_CCT_FAILED:
    case ACTIONS.MMP_AUTHENTICATION_FAILED:
      return {
        ...state,
        mmpStatus: action.type,
        settingRateError: action.data.error,
      };
    default:
      return state;
  }
};
