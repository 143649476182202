import {createSelector} from 'reselect'

const getErrorAuth = state => state.auth.authError;
const getErrorCharge = state => state.charge.error;
const getErrorSettingRate = state => state.settingRate.settingRateError;
const getErrorMember = state => state.member.memberError;
const getErrorPermission = state => state.permission.permissionError;
const getErrorWallet = state => state.wallet.walletError;
const getErrorDeposit = state => state.deposit.error;
const getErrorMmp = state => state.mmp.error;
const getErrorPom = state => state.pom.error;
const getErrorPomo = state => state.pomo.error;
const getErrorSettingRateMmp = state => state.settingRateMMP.settingRateError;
const getErrorSettingRatePomo = state => state.settingRatePomo.settingRateError;
const getErrorManigo = state => state.userMaingo.error;

const getTokenErrors = createSelector(
    [
        getErrorAuth,
        getErrorCharge,
        getErrorSettingRate,
        getErrorMember,
        getErrorPermission,
        getErrorWallet,
        getErrorDeposit,
        getErrorMmp,
        getErrorPom,
        getErrorPomo,
        getErrorSettingRateMmp,
        getErrorSettingRatePomo,
        getErrorManigo
    ],
    (
        authErr,
        chargeErr,
        settingRateErr,
        memberErr,
        permissionErr,
        walletError,
        depositErr,
        mmpError,
        pomError,
        pomoError,
        settingRateMmpError,
        settingRatePomoError,
        manigoErr,
    ) => {
        const errorsList = [
            authErr,
            chargeErr,
            settingRateErr,
            memberErr,
            permissionErr,
            walletError,
            depositErr,
            mmpError,
            pomError,
            pomoError,
            settingRateMmpError,
            settingRatePomoError,
            manigoErr,
        ];
        return errorsList.find(error => error && error.code === 'NOT_AUTHENTICATED_ERROR');
    }
);
export {
    getTokenErrors,
}
