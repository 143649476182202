import { ACTIONS } from "../01-actions/settingRate";

const initialState = {
  status: null,
  huvStatus: null,
  settings: null,
  huvSettings: null,
  logSettings: null,
  settingRateError: null,
  limitSettings: [],
  huvLimitSettings: [],
  pomStatus: null,
  pomLimitSettings: [],
  pomSettings: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_RATE_SETTING_PROGRESS:
    case ACTIONS.HUV_GET_RATE_SETTING_PROGRESS:
    case ACTIONS.GET_RATE_LIMIT_SETTING_PROGRESS:
    case ACTIONS.HUV_GET_RATE_LIMIT_SETTING_PROGRESS:
    case ACTIONS.UPDATE_RATE_SETTING_PROGRESS:
    case ACTIONS.UPDATE_RATE_SETTING_SUCCESS:
    case ACTIONS.UPDATE_EXCEL_FINANCE_SETTING_PROGRESS:
    case ACTIONS.UPDATE_EXCEL_FINANCE_SETTING_SUCCESS:
    case ACTIONS.UPDATE_EZP_SETTING_PROGRESS:
    case ACTIONS.HUV_UPDATE_EZP_SETTING_PROGRESS:
    case ACTIONS.GET_LOG_SETTING_PROGRESS:
    case ACTIONS.UPDATE_MAX_TOP_UP_PROGRESS:
    case ACTIONS.UPDATE_MAX_TOP_UP_SUCCESS:
    case ACTIONS.UPDATE_RATE_LIMIT_SETTING_PROGRESS:
    case ACTIONS.HUV_UPDATE_RATE_LIMIT_SETTING_PROGRESS:
    case ACTIONS.REFUND_AGT_PROGRESS:
    case ACTIONS.RECEIVING_AGT_PROGRESS:
    case ACTIONS.UPDATE_RATE_LIMIT_SETTING_SUCCESS:
    case ACTIONS.UPDATE_EZP_SETTING_SUCCESS:
    case ACTIONS.HUV_UPDATE_SETTING_RATE_PROGRESS:
    case ACTIONS.POM_GET_RATE_LIMIT_SETTING_PROGRESS:
    case ACTIONS.POM_GET_RATE_SETTING_PROGRESS:
    case ACTIONS.POM_UPDATE_EZP_SETTING_PROGRESS:
    case ACTIONS.POM_UPDATE_SETTING_RATE_PROGRESS:
    case ACTIONS.POM_UPDATE_RATE_LIMIT_SETTING_PROGRESS:
    case ACTIONS.POM_RECEIVING_AGT_PROGRESS:
    case ACTIONS.POM_REFUND_AGT_PROGRESS:
    case ACTIONS.POM_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS:
      return {
        ...state,
        status: action.type,
      };
    case ACTIONS.HUV_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS:
    case ACTIONS.HUV_UPDATE_EZP_SETTING_SUCCESS:
    case ACTIONS.HUV_UPDATE_SETTING_RATE_SUCCESS:
    case ACTIONS.HUV_UPDATE_RATE_LIMIT_SETTING_SUCCESS:
      return {
        ...state,
        huvStatus: action.type,
      };
    case ACTIONS.POM_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS:
    case ACTIONS.POM_UPDATE_EZP_SETTING_SUCCESS:
    case ACTIONS.POM_UPDATE_SETTING_RATE_SUCCESS:
    case ACTIONS.POM_UPDATE_RATE_LIMIT_SETTING_SUCCESS:
    case ACTIONS.POM_RECEIVING_AGT_SUCCESS:
    case ACTIONS.POM_REFUND_AGT_SUCCESS:
      return {
        ...state,
        pomStatus: action.type,
      };
    case ACTIONS.GET_RATE_SETTING_SUCCESS:
      return {
        ...state,
        status: action.type,
        settings: action.data.settings,
      };
    case ACTIONS.HUV_GET_RATE_SETTING_SUCCESS:
      return {
        ...state,
        huvStatus: action.type,
        huvSettings: action.data.settings,
      };
    case ACTIONS.POM_GET_RATE_SETTING_SUCCESS:
      return {
        ...state,
        pomStatus: action.type,
        pomSettings: action.data.settings,
      };
    case ACTIONS.GET_RATE_LIMIT_SETTING_SUCCESS:
      return {
        ...state,
        status: action.type,
        limitSettings: action.data.limitSettings
          ? action.data.limitSettings
          : [],
      };
    case ACTIONS.HUV_GET_RATE_LIMIT_SETTING_SUCCESS:
      return {
        ...state,
        huvStatus: action.type,
        huvLimitSettings: action.data.limitSettings
          ? action.data.limitSettings
          : [],
      };
    case ACTIONS.POM_GET_RATE_LIMIT_SETTING_SUCCESS:
      return {
        ...state,
        pomStatus: action.type,
        pomLimitSettings: action.data.limitSettings
          ? action.data.limitSettings
          : [],
      };
    case ACTIONS.GET_LOG_SETTING_SUCCESS:
      return {
        ...state,
        status: action.type,
        logSettings: action.data.logSettings,
      };
    case ACTIONS.REFUND_AGT_SUCCESS:
      return {
        ...state,
        huvStatus: action.type,
      };
    case ACTIONS.RECEIVING_AGT_SUCCESS:
      return {
        ...state,
        huvStatus: action.type,
      };
    case ACTIONS.GET_RATE_SETTING_FAILED:
    case ACTIONS.GET_RATE_LIMIT_SETTING_FAILED:
    case ACTIONS.UPDATE_RATE_SETTING_FAILED:
    case ACTIONS.UPDATE_EXCEL_FINANCE_SETTING_FAILED:
    case ACTIONS.UPDATE_EZP_SETTING_FAILED:
    case ACTIONS.UPDATE_MAX_TOP_UP_FAILED:
    case ACTIONS.GET_LOG_SETTING_FAILED:
    case ACTIONS.UPDATE_RATE_LIMIT_SETTING_FAILED:
      return {
        ...state,
        status: action.type,
        settingRateError: action.data.error,
      };
    case ACTIONS.HUV_GET_RATE_LIMIT_SETTING_FAILED:
    case ACTIONS.HUV_GET_RATE_SETTING_FAILED:
    case ACTIONS.HUV_UPDATE_RATE_LIMIT_SETTING_FAILED:
    case ACTIONS.HUV_UPDATE_EZP_SETTING_FAILED:
    case ACTIONS.HUV_UPDATE_EXCEL_FINANCE_SETTING_FAILED:
    case ACTIONS.HUV_UPDATE_SETTING_RATE_FAILED:
      return {
        ...state,
        huvStatus: action.type,
        settingRateError: action.data.error,
      };
    case ACTIONS.POM_GET_RATE_LIMIT_SETTING_FAILED:
    case ACTIONS.POM_GET_RATE_SETTING_FAILED:
    case ACTIONS.POM_UPDATE_EXCEL_FINANCE_SETTING_FAILED:
    case ACTIONS.POM_UPDATE_EZP_SETTING_FAILED:
    case ACTIONS.POM_UPDATE_SETTING_RATE_FAILED:
    case ACTIONS.POM_UPDATE_RATE_LIMIT_SETTING_FAILED:
    case ACTIONS.POM_RECEIVING_AGT_FAILED:
    case ACTIONS.POM_REFUND_AGT_FAILED:
      return {
        ...state,
        pomStatus: action.type,
        settingRateError: action.data.error,
      };
    case ACTIONS.REFUND_AGT_FAILED:
      return {
        ...state,
        huvStatus: action.type,
        settingRateError: action.data.error,
      };
    case ACTIONS.RECEIVING_AGT_FAILED:
      return {
        ...state,
        huvStatus: action.type,
        settingRateError: action.data.error,
      };
    default:
      return state;
  }
};
