import React from "react";
import { connect } from "react-redux";
import { Table } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { ACTIONS, getAllLocation } from "../../../../01-actions/auth";
import Alert from "../../../../03-components/Alert";
import "./style.css";
import ReactPaginate from "react-paginate";
import { formatDateTimeFull } from "../../../../05-utils/commonUtils";

class LogIp extends React.Component {
  constructor() {
    super();
    this.state = {
      logIp: [],
      isShowModal: false,
      alertAck: true,
      activePage: 1
    };
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  componentDidMount() {
    this.alertAck();
    this.props.getAllLocation({ page: this.state.activePage });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.logIp !== this.props.logIp) {
      this.setState({ logIp: nextProps.logIp });
    }
  }

  handlePageClick = data => {
    const page = data.selected + 1;
    this.setState({ activePage: page }, () => {
      this.alertAck();
      this.props.getAllLocation({
        page: this.state.activePage
      });
    });
  };

  renderHeaderTable = () => {
    const { t } = this.props;
    return (
      <tr className="tb-header">
        <th className="custom-item">{t("HistoryLogin.Table.No")}</th>
        <th>{t("HistoryLogin.Table.AdminId")}</th>
        <th>{t("HistoryLogin.Table.IP")}</th>
        <th>{t("HistoryLogin.Table.Location")}</th>
        <th>{t("HistoryLogin.Table.Timestamps")}</th>
      </tr>
    );
  };

  renderContentTable() {
    const { t } = this.props;
    const { logIp } = this.state;
    if (Array.isArray(logIp) && logIp.length > 0) {
      return logIp.map((data, index) => {
        return (
          <tr key={index} className="tb-body">
            <td>{index + 1}</td>
            <td>{data.adminId}</td>
            <td>{data.ip}</td>
            <td>
              {data.local.city
                ? data.local.city + ", " + data.local.country
                : data.local}
            </td>
            <td>{formatDateTimeFull(data.createdAt)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={5}>
            <div className="d-flex justify-content-center">
              <span>{t("NoDataToShow")}</span>
            </div>
          </td>
        </tr>
      );
    }
  }

  renderTable() {
    const { logIp } = this.state;
    if (Array.isArray(logIp) && logIp.length > 18) {
      return (
        <div className="table-container">
          <Table bordered striped>
            <thead className="thead-light">{this.renderHeaderTable()}</thead>
            <tbody>{this.renderContentTable()}</tbody>
          </Table>
        </div>
      );
    } else {
      return (
        <Table bordered responsive striped>
          <thead className="thead-light">{this.renderHeaderTable()}</thead>
          <tbody>{this.renderContentTable()}</tbody>
        </Table>
      );
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="log-ip-container">
        <div className="title">
          <div className="title-content">{t("HistoryLogin.HistoryLogin")}</div>
        </div>
        <div className="log-ip-container-content">{this.renderTable()}</div>

        {[ACTIONS.GET_LOG_LOCATION_FAIL].indexOf(this.props.status) >= 0 &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Error")}
              content={this.props.authError.code}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.setState({ alertAck: true });
              }}
            />
          )}
        {Array.isArray(this.state.logIp) && this.state.logIp.length > 0 && (
          <div className="pagination-center pagination-content">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={<a href="">...</a>}
              breakClassName={"break-me"}
              pageCount={this.props.pages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.auth.status,
    authError: state.auth.authError,
    logIp: state.auth.logIp,
    pages: state.auth.pages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllLocation: data => {
      dispatch(getAllLocation(data));
    }
  };
};

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LogIp)
);
