import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';
import './style.css';

const ButtonTabs = ({ className, options, value, onChange, name, t, userInfo}) => {
  const permissionArr = userInfo && userInfo.roleId?.permission

  const handleChange = (item) => {
    if (item.value !== value) {
      onChange({
        target: { name, value: item.value },
      });
    }
  };

  const checkPermission = (permissions) => {
    let isPermission = false
    if(permissionArr && permissionArr.findIndex(item => item === permissions) !== -1){
      isPermission = true
    }
    return isPermission;
  }

  return (
    <Row className={'button-tabs ' + (className || '')}>
      {options.map((item) => checkPermission(item.permission) ? (
        <Col xs="auto" key={item.value} className="mt-1">
          <Button
            color={value === item.value ? 'primary' : 'secondary'}
            onClick={() => handleChange(item)}
          >
            {t(item.name)}
          </Button>
        </Col>
      ) : null )}
    </Row>
  );
};

export default withNamespaces()(ButtonTabs);
