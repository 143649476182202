import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { getChargeAdminAmount } from "../../../../../01-actions/charge";
import {
  ACTIONS as ACTION_USER,
  getInfoUser,
} from "../../../../../01-actions/member";
import {
  PERMISSION,
  POM_UPDATE_STATUS_TOP_UP,
} from "../../../../../05-utils/commonData";
import { useModal } from "../../../../../05-utils/hook/use-modal";
import Alert from "./Alert";
import AlertComponent from "../../../../../03-components/Alert";
import Search from "./Search";
import "./style.css";
import ChargeRequestListTabled from "./Table";
import moment from "moment";
import _ from "lodash";
import {
  ACTIONS,
  MmpChangeNote,
  MmpChangeStatus,
  MmpChargeSearch,
  MmpClearStatus,
  MmpCurrencyBalance,
  MmpGetChargeRequest,
  MmpGetStatisticChargeByStatus,
  MmpPushNotification,
} from "../../../../../01-actions/mmp";
import LoadingSpinner from "../../../../../03-components/LoadingSpinner/LoadingSpinner";

const MMPChargeRequestList = ({
  charges,
  handleGetChargeRequest,
  chargeSearch,
  status,
  pages,
  t,
  statisticOfChargesByStatus,
  currencyBalance,
  statisticChargeByStatus,
  mmpChangeStatus,
  onChangeNote,
  pushNotification,
  totalUSD,
  totalTCT,
  adminBalance,
  getAdminAmount,
  mmpStatus,
  clearSts,
  getInfoUser,
  userInfo,
  statusGetUserInfo,
  error,
  totalCCT,
  totalROY,
  mmpCurrencyBalance,
}) => {
  const [init, setInit] = useState(false);
  const [selected, setSelected] = useState([]);
  const [alertAck, closeAlert, openAlert] = useModal(false);
  const [itemEditMemo, closeEditMemo, openEditMemo] = useModal(null);
  const [isConfirm, closeConfirm, openConfirm] = useModal(false);
  const [isRejection, closeRejection, openRejection] = useModal(null);
  const [params, setParams] = useState({
    limit: 1000,
    page: 1,
    startTime: null,
    endTime: null,
    filterBy: "",
    keyWord: "",
    tab: "",
  });
  const [statusConfirm, setStatusConfirm] = useState(null);
  const [rejectingSuccess, setRejectingSuccess] = useState(false);
  const [rejectingFailed, setRejectingFailed] = useState(false);

  const [isPushNotificationSuccess, setIsPushNotificationSuccess] =
    useState(false);
  const [statusTopUp, setStatusTopUp] = useState(false);
  const [alertPermission, setAlertPermission] = useState(false);
  const [balanceNotEnough, setBalanceNotEnough] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (
      mmpStatus === "MMP_GET_CHARGE_FAIL" ||
      mmpStatus === "MMP_SEARCH_CHARGE_FAIL"
    ) {
      setAlertPermission(false);
    }
  }, [mmpStatus]);

  useEffect(() => {
    if (params && params?.startTime > params?.endTime) {
      setParams({ ...params, endTime: null });
    }
  }, [params?.startTime, params?.endTime]);

  const handleConfirm = async (value) => {
    try {
      setIsLoading(true);
      let payload = {};
      switch (statusConfirm) {
        case POM_UPDATE_STATUS_TOP_UP.CHECKED:
        case POM_UPDATE_STATUS_TOP_UP.RECHECKED:
          payload = {
            rejectIds: [],
            approveIds: selected,
            status: params.tab,
            systemId: 14,
            note: value?.note,
          };
          break;
        case POM_UPDATE_STATUS_TOP_UP.REJECTED:
          payload = {
            approveIds: [],
            rejectIds: selected,
            status: params.tab,
            systemId: 14,
            note: value?.note,
          };
          break;
      }

      await mmpChangeStatus(payload, (action, result) => {
        if (action === ACTIONS.MMP_CHANGE_STATUS_TOP_UP_SUCCESS) {
          setIsLoading(false);
          setSelected([]);
          closeConfirm();
          handleSearch();

          if (result?.rejectSuccess.length < 0 ){
            setRejectingFailed(true)
          }else{
            statusConfirm === POM_UPDATE_STATUS_TOP_UP.REJECTED
            ? setRejectingSuccess(true)
            : setStatusTopUp(true);
          }
        
          const timeout = setTimeout(() => {
            handleAdminAmount();
            statisticOfChargesByStatus();
            currencyBalance();
            clearTimeout(timeout);
          }, 2000);
        }
        if (action === ACTIONS.MMP_CHANGE_STATUS_TOP_UP_FAIL) {
          setIsLoading(false);
          setSelected([]);
          closeConfirm();
          statusConfirm === POM_UPDATE_STATUS_TOP_UP.REJECTED
          ?  setRejectingFailed(true)
          :  setBalanceNotEnough(true);
        }
      });
    } catch (error) {}
  };
  const handlePushNotification = (values) => {
    pushNotification({
      title: values.title,
      content: values.content,
    });
    closeConfirm();
    setIsPushNotificationSuccess(true);
  };
  const handleConfirmEditMemo = async (values) => {
    try {
      setIsLoading(true);
      onChangeNote(
        {
          note: values?.note,
          id: itemEditMemo?._id,
        },
        (action) => {
          if (action === ACTIONS.MMP_CHANGE_NOTE_SUCCESS) {
            setIsLoading(false);
            closeEditMemo();
            handleSearch();
          }
        }
      );
    } catch (error) {}
  };

  const handleSubmitNote = async (value) => {
    try {
      setIsLoading(true);
      let payload = {};
      payload = {
        approveIds: [],
        rejectIds: selected,
        status: params.tab,
        note: value?.note,
      };

      mmpChangeStatus(payload, (action, result) => {
        if (action === ACTIONS.MMP_CHANGE_STATUS_TOP_UP_SUCCESS) {
          setIsLoading(false);
          setSelected([]);
          closeRejection();
          handleSearch();
          if (result?.rejectSuccess.length <= 0 ){
            setRejectingFailed(true)
          }else{
            setRejectingSuccess(true)
          }
        }
        if (action === ACTIONS.MMP_CHANGE_STATUS_TOP_UP_FAIL) {
          setIsLoading(false);
          setSelected([]);
          closeRejection();
          setRejectingFailed(true)
        }
      });
    } catch (error) {}
  };

  const handleGetList = (payload) => {
    openAlert();

    handleGetChargeRequest(
      payload || {
        limit: params.limit,
        page: params.page,
        status: params.tab,
        search: params.keyWord,
        startTime: params.startTime,
        endTime: params.endTime,
      }
    );
    setInit(true);
  };

  const handleSearch = () => {
    let startDate = params.startTime ? params.startTime : "";
    let endDate = params.endTime ? moment(params.endTime).endOf("date") : "";
    if (status === ACTIONS.MMP_SEARCH_CHARGE_PROGRESS || !init) {
      return;
    }
    openAlert();
    chargeSearch({
      startTime: startDate,
      endTime: endDate,
      search: params.keyWord,
      page: params.page,
      limit: params.limit,
      status: params.tab,
    });
  };

  const checkPermission = () => {
    const permissionArr = userInfo && userInfo.roleId?.permission;

    const routes = _.chain(permissionArr)
      .reduce((rs, item) => {
        if (_.startsWith(item, "mmp-") && item !== "mmp-setting") {
          rs = _.concat(rs, item);
        }
        return rs;
      }, [])
      .value();

    if (routes.includes(PERMISSION.MMP_NOT_YET_VERIFIED)) {
      return PERMISSION.MMP_NOT_YET_VERIFIED;
    } else if (routes.includes(PERMISSION.MMP_ADMIN_REJECTED)) {
      return PERMISSION.MMP_ADMIN_REJECTED;
    } else if (routes.includes(PERMISSION.MMP_CHECKING)) {
      return PERMISSION.MMP_CHECKING;
    } else if (routes.includes(PERMISSION.MMP_APPROVED)) {
      return PERMISSION.MMP_APPROVED;
    } else if (routes.includes(PERMISSION.MMP_REJECTING)) {
      return PERMISSION.MMP_REJECTING;
    } else if (routes.includes(PERMISSION.MMP_REJECTED)) {
      return PERMISSION.MMP_REJECTED;
    }
    return;
  };

  useEffect(() => {
    getInfoUser();
    statisticOfChargesByStatus();
    currencyBalance();
    handleAdminAmount();
  }, []);

  useEffect(() => {
    if (statusGetUserInfo === ACTION_USER.GET_INFO_USER_SUCCESS) {
      const startedRoute = checkPermission()
        ? checkPermission().replace("mmp-", "")
        : "";
      if (startedRoute) {
        setParams({
          limit: 1000,
          page: 1,
          startTime: null,
          endTime: null,
          filterBy: "",
          keyWord: "",
          tab: startedRoute,
        });
        handleGetList({
          limit: 1000,
          page: 1,
          startTime: null,
          endTime: null,
          filterBy: "",
          keyWord: "",
          status: startedRoute,
        });
      }
    }
  }, [statusGetUserInfo]);

  useEffect(() => {
    handleSearch();
  }, [
    params?.startTime,
    params?.endTime,
    params?.tab,
    params?.page,
    params?.limit,
  ]);

  const handleAdminAmount = () => {
    getAdminAmount({
      systemId: 14,
    });
  };

  const handleChange = (e) => {
    setParams({ ...params, [e.target.name]: e.target.value });
  };

  const handleClearStatus = () => {
    clearSts();
  };

  const messageError = (error) => {
    switch (error) {
      case "Source wallet balance is not enough token!":
        return t("not_enough_token");
      case "System error":
        return t("not_enough_token");
      case "REFUND_FAILED":
        return t("REFUND_FAILED");
      case "MISSING_REQUIRED_VALUE":
        return t("Code.MISSING_REQUIRED_VALUE");
      case "CHANGE_STATUS_TOPUP_FAIL":
        return t("Code.CHANGE_STATUS_TOPUP_FAIL");
      default:
        return t("DepositToSystem.MaxBalances");
    }
  };

  return (
    <div className="huv-styled-charge-request-list">
      {isLoading && <LoadingSpinner />}
      <div className="title">
        <div className="title-content">
          {t("TopUpPage.ChargeApplicationList")}
        </div>
      </div>
      <div className="charge-request-list-content">
        {params && (
          <Search
            setStatusConfirm={setStatusConfirm}
            statisticChargeByStatus={statisticChargeByStatus}
            mmpCurrencyBalance={mmpCurrencyBalance}
            params={params}
            handleChange={handleChange}
            handleSearch={handleSearch}
            pages={pages}
            charges={charges}
            setParams={setParams}
            openConfirm={openConfirm}
            selected={selected}
            setSelected={setSelected}
            totalUSD={totalUSD}
            totalTCT={totalTCT}
            adminBalance={adminBalance}
            openRejection={openRejection}
            userInfo={userInfo}
            totalCCT={totalCCT}
            totalROY={totalROY}
          />
        )}
        <ChargeRequestListTabled
          charges={charges}
          selected={selected}
          setSelected={setSelected}
          tab={params?.tab}
          openEditMemo={openEditMemo}
        />
        <Alert
          selected={selected}
          charges={charges}
          isConfirm={isConfirm}
          closeConfirm={() => {
            setStatusConfirm(null);
            closeConfirm();
          }}
          handleConfirm={handleConfirm}
          tab={params?.tab}
          alertAck={alertAck}
          closeAlert={closeAlert}
          itemEditMemo={itemEditMemo}
          closeEditMemo={closeEditMemo}
          handleConfirmEditMemo={handleConfirmEditMemo}
          handlePushNotification={handlePushNotification}
          isRejection={isRejection}
          closeRejection={() => {
            setStatusConfirm(null);
            closeRejection();
          }}
          handleSubmitNote={handleSubmitNote}
          isLoading={isLoading}
        />
        {isPushNotificationSuccess && (
          <AlertComponent
            title={t("Alert.Success")}
            content={t("pushNotificationSuccess")}
            okTitle={t("Alert.OK")}
            onOK={() => setIsPushNotificationSuccess(false)}
          />
        )}
        {statusTopUp && (
          <AlertComponent
            title={t("Alert.Success")}
            content={t("changeStatusTopUpSuccess")}
            okTitle={t("Alert.OK")}
            onOK={() => setStatusTopUp(false)}
          />
        )}
        {rejectingSuccess && (
          <AlertComponent
            title={t("Alert.Success")}
            content={t("rejectingSuccess")}
            okTitle={t("Alert.OK")}
            onOK={() => setRejectingSuccess(false)}
          />
        )}
        {rejectingFailed && (
          <AlertComponent
            title={t("Alert.failed")}
            content={t("rejectingFail")}
            okTitle={t("Alert.OK")}
            onOK={() => setRejectingFailed(false)}
          />
        )}
        {balanceNotEnough && (
          <AlertComponent
            title={t("Alert.Error")}
            content={messageError(error?.code)}
            okTitle={t("Alert.OK")}
            onOK={() => setBalanceNotEnough(false)}
          />
        )}
        {[ACTIONS.MMP_GET_CHARGE_FAIL, ACTIONS.MMP_SEARCH_CHARGE_FAIL].indexOf(
          status
        ) >= 0 &&
          !alertPermission && (
            <AlertComponent
              title={t("Alert.Error")}
              content={t("Code.PERMISSION_DENIED")}
              okTitle={t("Alert.OK")}
              onOK={() => {
                handleClearStatus();
                setAlertPermission(true);
              }}
            />
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    status: state.mmp.mmpStatus,
    error: state.mmp.error,
    charges: state.mmp.mmpCharges,
    pages: state.mmp.pages,
    statisticChargeByStatus: state.mmp.mmpStatisticChargeByStatus,
    totalTCT: state.mmp.totalTCT,
    totalUSD: state.mmp.totalUSD,
    mmpStatus: state.mmp.mmpStatus,
    adminBalance: state.charge.adminBalance,
    userInfo: state.member.infoUser,
    statusGetUserInfo: state.member.status,
    totalCCT: state.mmp.totalCCT,
    totalROY: state.mmp.totalROY,
    mmpCurrencyBalance: state.mmp.mmpCurrencyBalance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetChargeRequest: (data) => {
      dispatch(MmpGetChargeRequest(data));
    },
    chargeSearch: (data) => {
      dispatch(MmpChargeSearch(data));
    },
    statisticOfChargesByStatus: () => {
      dispatch(MmpGetStatisticChargeByStatus());
    },
    mmpChangeStatus: (data, callback) => {
      dispatch(MmpChangeStatus(data, callback));
    },
    onChangeNote: (data, callback) => {
      dispatch(MmpChangeNote(data, callback));
    },
    pushNotification: (data) => {
      dispatch(MmpPushNotification(data));
    },
    getAdminAmount: (data) => {
      dispatch(getChargeAdminAmount(data));
    },
    clearSts: () => {
      dispatch(MmpClearStatus());
    },
    getInfoUser: () => {
      dispatch(getInfoUser());
    },
    currencyBalance: () => {
      dispatch(MmpCurrencyBalance());
    },
  };
};

export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(MMPChargeRequestList)
);
