import { callApi } from "../05-utils/commonUtils";
import queryString from "query-string";

const ACTIONS = {
  POM_CLEAR_STATUS: "POM_CLEAR_STATUS",
  POM_GET_CHARGE_PROGRESS: "POM_GET_CHARGE_PROGRESS",
  POM_GET_CHARGE_SUCCESS: "POM_GET_CHARGE_SUCCESS",
  POM_GET_CHARGE_FAIL: "POM_GET_CHARGE_FAIL",
  POM_SEARCH_CHARGE_PROGRESS: "POM_SEARCH_CHARGE_PROGRESS",
  POM_SEARCH_CHARGE_SUCCESS: "POM_SEARCH_CHARGE_SUCCESS",
  POM_SEARCH_CHARGE_FAIL: "POM_SEARCH_CHARGE_FAIL",
  POM_GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS:
    "POM_GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS",
  POM_GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS:
    "POM_GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS",
  POM_GET_STATISTIC_CHARGE_BY_STATUS_FAIL:
    "POM_GET_STATISTIC_CHARGE_BY_STATUS_FAIL",
  POM_CHANGE_STATUS_TOP_UP_PROCESS: "POM_CHANGE_STATUS_TOP_UP_PROCESS",
  POM_CHANGE_STATUS_TOP_UP_SUCCESS: "POM_CHANGE_STATUS_TOP_UP_SUCCESS",
  POM_CHANGE_STATUS_TOP_UP_FAIL: "POM_CHANGE_STATUS_TOP_UP_FAIL",
  POM_CHANGE_NOTE_PROCESS: "POM_CHANGE_NOTE_PROCESS",
  POM_CHANGE_NOTE_SUCCESS: "POM_CHANGE_NOTE_SUCCESS",
  POM_CHANGE_NOTE_FAIL: "POM_CHANGE_NOTE_FAIL",
  POM_PUSH_NOTIFICATION_PROCESS: "POM_PUSH_NOTIFICATION_PROCESS",
  POM_PUSH_NOTIFICATION_SUCCESS: "POM_PUSH_NOTIFICATION_SUCCESS",
  POM_PUSH_NOTIFICATION_FAIL: "POM_PUSH_NOTIFICATION_FAIL",
};

const PomUpdateStatus = (status, statusInfo) => {
  return {
    type: status,
    data: statusInfo,
  };
};

const pomPushNotification = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(PomUpdateStatus(ACTIONS.POM_PUSH_NOTIFICATION_PROCESS));
    return callApi(
      "/api/pom/push_notification",
      config,
      null,
      (result) => {
        dispatch(
          PomUpdateStatus(ACTIONS.POM_PUSH_NOTIFICATION_SUCCESS, {
            note: result.data,
          })
        );
      },
      (err) => {
        dispatch(
          PomUpdateStatus(ACTIONS.POM_PUSH_NOTIFICATION_FAIL, { error: err })
        );
      }
    );
  };
};

const pomChangeNote = (data, callback) => {
  let config = {
    method: "put",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(PomUpdateStatus(ACTIONS.POM_CHANGE_NOTE_PROCESS));
    return callApi(
      "/api/pom/note_charge",
      config,
      null,
      (result) => {
        dispatch(
          PomUpdateStatus(ACTIONS.POM_CHANGE_NOTE_SUCCESS, {
            note: result.data,
          })
        );
        callback(ACTIONS.POM_CHANGE_NOTE_SUCCESS, result);
      },
      (err) => {
        dispatch(PomUpdateStatus(ACTIONS.POM_CHANGE_NOTE_FAIL, { error: err }));
        callback(ACTIONS.POM_CHANGE_NOTE_FAIL, err);
      }
    );
  };
};

const pomChangeStatus = (data, callback) => {
  let config = {
    method: "put",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(PomUpdateStatus(ACTIONS.POM_CHANGE_STATUS_TOP_UP_PROCESS));
    return callApi(
      "/api/pom/change_status_top_up",
      config,
      null,
      (result) => {
        dispatch(
          PomUpdateStatus(ACTIONS.POM_CHANGE_STATUS_TOP_UP_SUCCESS, {
            note: result.data,
          })
        );
        callback(ACTIONS.POM_CHANGE_STATUS_TOP_UP_SUCCESS, result);
      },
      (err) => {
        dispatch(
          PomUpdateStatus(ACTIONS.POM_CHANGE_STATUS_TOP_UP_FAIL, { error: err })
        );
        callback(ACTIONS.POM_CHANGE_STATUS_TOP_UP_FAIL, err);
      }
    );
  };
};

const PomGetStatisticChargeByStatus = () => {
  let config = {
    method: "get",
    isAuthorization: true,
  };
  return (dispatch) => {
    dispatch(
      PomUpdateStatus(ACTIONS.POM_GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS)
    );
    callApi(
      "/api/pom/statistic_charge_status",
      config,
      null,
      (result) => {
        dispatch(
          PomUpdateStatus(ACTIONS.POM_GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS, {
            statisticChargeByStatus: result.data,
          })
        );
      },
      (err) => {
        dispatch(
          PomUpdateStatus(ACTIONS.POM_GET_STATISTIC_CHARGE_BY_STATUS_FAIL, {
            error: err,
          })
        );
      }
    );
  };
};

const PomChargeSearch = (data, limit = 100) => {
  let config = {
    method: "get",
    isAuthorization: true,
  };

  const params = queryString.stringify({
    page: data.page,
    limit: data.limit || limit,
    startTime: data.startTime,
    endTime: data.endTime,
    search: data.search,
    status: data.status,
  });

  return (dispatch) => {
    dispatch(PomUpdateStatus(ACTIONS.POM_SEARCH_CHARGE_PROGRESS));
    callApi(
      `/api/pom/charges?${params}`,
      config,
      null,
      (result) => {
        dispatch(
          PomUpdateStatus(ACTIONS.POM_SEARCH_CHARGE_SUCCESS, {
            charges: result.charges,
            pages: result.pages,
            totalTCT: result.totalTCT,
            totalUSD: result.totalUSD,
            totalGCCT: result.totalGCCT,
            totalCCT: result.totalCCT,
            totalROY: result.totalROY,
            totalGROY: result.totalGROY,
          })
        );
      },
      (err) => {
        dispatch(
          PomUpdateStatus(ACTIONS.POM_SEARCH_CHARGE_FAIL, { error: err })
        );
      }
    );
  };
};

const PomGetChargeRequest = (data) => {
  let config = {
    method: "get",
    isAuthorization: true,
  };

  return (dispatch) => {
    dispatch(PomUpdateStatus(ACTIONS.POM_GET_CHARGE_PROGRESS));
    callApi(
      `/api/pom/charges?${queryString.stringify(data)}`,
      config,
      null,
      (result) => {
        dispatch(
          PomUpdateStatus(ACTIONS.POM_GET_CHARGE_SUCCESS, {
            charges: result.charges,
            pages: result.pages,
            totalTCT: result.totalTCT,
            totalUSD: result.totalUSD,
            totalGCCT: result.totalGCCT,
            totalCCT: result.totalCCT,
            totalROY: result.totalROY,
            totalGROY: result.totalGROY,
          })
        );
      },
      (err) => {
        dispatch(PomUpdateStatus(ACTIONS.POM_GET_CHARGE_FAIL, { error: err }));
      }
    );
  };
};

const PomClearStatus = () => {
  return (dispatch) => {
    dispatch(PomUpdateStatus(ACTIONS.POM_CLEAR_STATUS));
  };
};

export {
  ACTIONS,
  PomClearStatus,
  PomGetChargeRequest,
  PomChargeSearch,
  PomGetStatisticChargeByStatus,
  pomChangeStatus,
  pomChangeNote,
  pomPushNotification,
};
