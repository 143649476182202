import React from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router";

import { Input } from "reactstrap";
import { IconButton, Button, MenuItem, Menu } from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined";
import { logout } from "../../../../01-actions/auth";
import enLogo from "../../../../06-assets/images/en.png";
import jaLogo from "../../../../06-assets/images/ja.png";
import { SYSTEMS } from "../../../../05-utils/commonData";
import {
  huvStyleRoutes,
  pomoRoutes,
  pomRoutes,
  mmpRoutes,
} from "../../../../routes";

import "./style.css";

const useStyles = makeStyles((theme) => ({
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const getStatusPage = (pathname) => {
  if (
    Object.values(huvStyleRoutes).some((item) => pathname.includes(item.link))
  )
    return "HUVSTYLE";
  else if (
    Object.values(pomRoutes).some((item) => pathname.includes(item.link))
  )
    return "POM";
  else if (
    Object.values(pomoRoutes).some((item) => pathname.includes(item.link))
  )
    return "POMO";
  else if (
    Object.values(mmpRoutes).some((item) => pathname.includes(item.link))
  )
    return "MMP";
  return "GREENBOX_TRIPLE";
};

export const checkPathNamePage = (route, pathname) => {
  Object.values(route).some((item) => pathname.includes(item.link));
};

function Header(props, logout) {
  const {
    t,
    i18n,
    history: {
      location: { pathname },
    },
  } = props;
  function changeLanguage(lang) {
    i18n.changeLanguage(lang);
  }
  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const statusPage = getStatusPage(pathname);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleChangeSystem = (event) => {
    const { value } = event.target;
    let nextPage = "";
    switch (value) {
      case SYSTEMS.HUVSTYLE.value:
        nextPage = huvStyleRoutes.topUpPage.link;
        break;
      case SYSTEMS.POM.value:
        nextPage = pomRoutes.topUpPage.link;
        break;
      case SYSTEMS.POMO.value:
        nextPage = pomoRoutes.topUpPage.link;
        break;
      case SYSTEMS.MMP.value:
        nextPage = mmpRoutes.topUpPage.link;
        break;
      case SYSTEMS.GREENBOX_TRIPLE.value:
        nextPage = "/";
        break;
      default:
        nextPage = "/";
        break;
    }
    props.history.push(nextPage);
  };

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <span onClick={() => changeLanguage("en")}>
          <img src={enLogo} alt="English logo" className="resize-image" />
          <span className="ml-2">{t("Language.English")}</span>
        </span>
      </MenuItem>

      <MenuItem>
        <span onClick={() => changeLanguage("jp")}>
          <img src={jaLogo} alt="Japanese logo" className="resize-image" />
          <span className="ml-2">{t("Language.Japanese")}</span>
        </span>
      </MenuItem>

      <MenuItem color="inherit">
        <AccountBoxOutlinedIcon color="primary" />
        <span className="ml-2">{localStorage.getItem("ID") || ""}</span>
      </MenuItem>

      <MenuItem color="inherit">
        <ExitToAppOutlinedIcon color="primary" />
        <span
          color="inherit"
          onClick={() => props.logout()}
          className="ml-2 header-logout"
        >
          {t("Header.Logout")}
        </span>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <div className={classes.sectionDesktop}>
        <Input
          type="select"
          name="system"
          className="system-select"
          value={SYSTEMS?.[statusPage].value}
          onChange={handleChangeSystem}
        >
          {Object.values(SYSTEMS).map((item) => (
            <option value={item.value} key={item.value}>
              {t(item.name)}
            </option>
          ))}
        </Input>

        <Button color="inherit">
          <span onClick={() => changeLanguage("en")}>
            <img src={enLogo} alt="English logo" className="resize-image" />
          </span>
        </Button>

        <Button color="inherit">
          <span onClick={() => changeLanguage("jp")}>
            <img src={jaLogo} alt="Japanese logo" className="resize-image" />
          </span>
        </Button>

        <span className="text-infor">{localStorage.getItem("ID") || ""}</span>

        <Button
          color="inherit"
          onClick={() => props.logout()}
          className="header-logout"
        >
          {t("Header.Logout")}
        </Button>
      </div>

      <div className={classes.sectionMobile}>
        <IconButton
          aria-label="show more"
          aria-controls={mobileMenuId}
          aria-haspopup="true"
          onClick={handleMobileMenuOpen}
          color="inherit"
        >
          <MoreIcon />
        </IconButton>
      </div>

      {renderMobileMenu}
    </div>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
}

export default withNamespaces()(
  withRouter(connect(null, mapDispatchToProps)(Header))
);
