import { ACTIONS } from '../01-actions/deposit';

const initialState = {
    status: null,
    error: null,
    depositData: [],
    staticBalance : [],
    depositAllData: [],
}

export default (state = initialState, action) => {
    switch (action.type) {

        case ACTIONS.GET_TRANSFER_PROGRESS:
            return {
                ...state,
                status: action.type
            }
        case ACTIONS.GET_TRANSFER_SUCCESS:
            return {
                ...state,
                status: action.type
            }
        case ACTIONS.GET_TRANSFER_FAIL:
            return {
                ...state,
                error: action.data.error,
                status: action.type
            }
        case ACTIONS.GET_SYSTEM_TOPUP_PROGRESS:
        case ACTIONS.GET_DEPOSIT_TABLE_PROGRESS:
        case ACTIONS.GET_ALL_DEPOSIT_DATA_PROGRESS:
            return {
                ...state,
                status: action.type
            };
        case ACTIONS.GET_SYSTEM_TOPUP_SUCCESS:
            return{
                ...state,
                staticBalance: action.data,
                status: action.type
            }
        case ACTIONS.GET_DEPOSIT_TABLE_SUCCESS:
            return {
                ...state,
                depositData: action.data,
                status: action.type
            };
        case ACTIONS.GET_ALL_DEPOSIT_DATA_SUCCESS:
            return {
                ...state,
                depositAllData: action.data,
                status: action.type
            };
        case ACTIONS.GET_SYSTEM_TOPUP_FAIL:
        case ACTIONS.GET_DEPOSIT_TABLE_FAIL:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                depositData: []
            };
        case ACTIONS.GET_ALL_DEPOSIT_DATA_FAILD:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                depositAllData: []
            };
        default:
            return state;
    }
}