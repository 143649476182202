import {ACTIONS} from '../01-actions/wallet';

const initialState = {
    status: null,
    walletError: null,
    secretKey: null,
};
export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_SECRET_KEY_PROGRESS:
        case ACTIONS.ADD_SECRET_KEY_PROGRESS:
        case ACTIONS.ADD_SECRET_KEY_SUCCESS:
            return {
                ...state,
                status: action.type
            };
        case ACTIONS.GET_SECRET_KEY_SUCCESS:
            return {
                ...state,
                status: action.type,
                secretKey: action.data.secretKey
            };
        case ACTIONS.GET_SECRET_KEY_FAILED:
        case ACTIONS.ADD_SECRET_KEY_FAILED:
            return {
                ...state,
                status: action.type,
                walletError: action.data.error
            };
        default:
            return state;
    }
}


