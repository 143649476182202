import { ACTIONS } from "../01-actions/pomo";

const initialState = {
  status: null,
  pomoStatus: null,
  pomoCharges: [],
  pages: null,
  totalTCT: 0,
  totalUSD: 0,
  error: null,
  pomoStatisticChargeByStatus: {},
  note: null,
  totalCCT: 0,
  totalROY: 0,
  pomoCurrencyBalance: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    // PROGRESS
    case ACTIONS.POMO_GET_CHARGE_PROGRESS:
    case ACTIONS.POMO_SEARCH_CHARGE_PROGRESS:
    case ACTIONS.POMO_GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS:
    case ACTIONS.POMO_CHANGE_STATUS_TOP_UP_PROCESS:
    case ACTIONS.POMO_GET_CURRENCY_BALANCE_PROGRESS:
      return {
        ...state,
        status: action.type,
      };
    // SUCCESS
    case ACTIONS.POMO_GET_CHARGE_SUCCESS:
    case ACTIONS.POMO_SEARCH_CHARGE_SUCCESS:
      return {
        ...state,
        pomoStatus: action.type,
        pomoCharges: action.data.charges,
        pages: action.data.pages,
        totalTCT: action.data.totalTCT || 0,
        totalUSD: action.data.totalUSD || 0,
        totalCCT: action.data.totalCCT || 0,
        totalROY: action.data.totalROY || 0
      };
    case ACTIONS.POMO_GET_CURRENCY_BALANCE_SUCCESS:
      return{
        ...state,
        status: action.type,
        pomoCurrencyBalance: action.data.currencyBalance,
      }
    case ACTIONS.POMO_GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS:
      return {
        ...state,
        status: action.type,
        pomoStatisticChargeByStatus: action.data.statisticChargeByStatus,
      };
    // FAILED
    case ACTIONS.POMO_GET_CHARGE_FAIL:
    case ACTIONS.POMO_SEARCH_CHARGE_FAIL:
      return {
        ...state,
        pomoStatus: action.type,
        error: action.data.error,
        pomoCharges: [],
      };
    case ACTIONS.POMO_GET_STATISTIC_CHARGE_BY_STATUS_FAIL:
    case ACTIONS.POMO_GET_CURRENCY_BALANCE_FAIL:
    case ACTIONS.POMO_CHANGE_STATUS_TOP_UP_FAIL:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
      };
    case ACTIONS.POMO_CLEAR_STATUS:
      return {
        ...state,
        status: null,
        pomoStatus: null,
      };
    default:
      return state;
  }
};
