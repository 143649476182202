import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Collapse } from "reactstrap";
import { ListItem, ListItemText, Divider } from "@material-ui/core";
import { withNamespaces } from "react-i18next";
import "./style.css";

class ChildMenu extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
  }

  getChildMenu() {
    return (
      <Collapse isOpen={this.state.isOpen}>
        <ul onClick={e => e.stopPropagation()} className="nav">
          {this.props.route.children.map((child, index) => {
            return (
              <li key={index} className="menu-item">
                <NavLink to={child.link} activeClassName="active">
                  <div className="menu-item-child">{child.name}</div>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </Collapse>
    );
  }

  render() {
    const { route, t } = this.props;
    return (
      <span
        onClick={() => {
          this.setState({ isOpen: !this.state.isOpen });
        }}
        className={"menu-item"}
      >
        {route.link ? (
          <span>
            <NavLink to={route.link} activeClassName="active">
              <ListItem button>
                  <ListItemText primary={t(route.name)} />
              </ListItem>
              <Divider />
            </NavLink>
          </span>
        ) : (
          <div className="d-flex justify-content-between align-items-center menu-item-header">
            {route.name}
            <i
              className={`fa fa-chevron-down icon ${
                this.state.isOpen ? "rotate-180" : ""
              }`}
            />
          </div>
        )}
        {route.children && route.children.length > 0
          ? this.getChildMenu()
          : null}
      </span>
    );
  }
}

export default withNamespaces()(ChildMenu);
