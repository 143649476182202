import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { PureComponent } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import {
  ACTIONS,
  //   huvGetRateLimitSetting,
  //   huvGetRateSetting,
  //   huvUpdateExcelFinanceSetting,
  //   huvUpdateEzpSetting,
  updateMaxGbt,
  //   huvUpdateRateLimit,
  //   huvUpdateRateSetting,
  pomGetRateLimitSetting,
  pomGetRateSetting,
  pomUpdateExcelFinanceSetting,
  pomUpdateEzpSetting,
  pomUpdateRateSetting,
  pomUpdateRateLimit,
} from "../../../../../01-actions/settingRate";
import Alert from "../../../../../03-components/Alert";
import {
  TYPE_SETTING,
  ENCRYPT_KEY,
  IV_ENCRYPT,
  COIN_TYPE,
} from "../../../../../05-utils/commonData";
import ModalSettingRate from "../RateSetting/ModalSettingRate";
import { getInfoUser } from "../../../../../01-actions/member";
import crypto from "crypto";
import "./style.css";

class PomRateSetting extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      settingRate: "",
      settingExcelFinance: "",
      settingEzp: "",
      alertAck: true,
      setRate: false,
      setEzpFee: false,
      setMaxTopUp: false,
      setExcelFee: false,
      setRateLimit: false,
      maxGbt: 0,
      isLoop: true,
      timeStart: "",
      timeEnd: "",
      idRateLimit: null,
      titleRateLimit: "",
      changeOneTimeLimit: "",
      changeDailyLimit: "",
      changeMonthlyLimit: "",
      changeYearlyLimit: "",
      limitSettings: [],
      checkData: false,
      businessId: "10",
      valueRefundAGT: "",
      valueReceivingAGT: "",
      isOpenModal: false,
      isRefundAGT: false,
      isReceivingAGT: false,
      isRefundFIL: false,
      isReceivingFIL: false,
      isRefundCCT: false,
      isReceivingCCT: false,
      valueRefundFIL: "",
      valueReceivingFIL: "",
      settingRateFIL: "",
      valueRefundCCT: "",
      valueReceivingCCT: "",
      settingRateCCT: "",
      settingTFA: false,
      isUpdateSetting: false,
      isUpdateSettingFIL: false,
      isUpdateSettingCCT: false,
      valueRateSetting: "",
      valueRateSettingFIL: "",
      valueRateSettingCCT: "",
      isFeeExcel: false,
      isFeeGlover: false,
      isSettingRateLimit: false,
      templeSecretKey: "",
      templeSecretKeyFIL: "",
      templeSecretKeyCCT: "",
      secretValue: "",
      secretValueFIL: "",
      secretValueCCT: "",

      isRefundROY: false,
      isReceivingROY: false,
      valueRefundROY: "",
      valueReceivingROY: "",
      settingRateROY: "",
      isUpdateSettingROY: false,
      valueRateSettingROY: "",
      templeSecretKeyROY: "",
      secretValueROY: "",
      isRefundGROY: false,
      isReceivingGROY: false,
      valueRefundGROY: "",
      valueReceivingGROY: "",
      settingRateGROY: "",
      isUpdateSettingGROY: false,
      valueRateSettingGROY: "",
      templeSecretKeyGROY: "",
      secretValueGROY: "",
    };
    this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
    this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  clearState = () => {
    this.setState({
      setRateLimit: false,
      idRateLimit: null,
      titleRateLimit: "",
      changeOneTimeLimit: "",
      changeDailyLimit: "",
      changeMonthlyLimit: "",
      changeYearlyLimit: "",
      valueRefundAGT: "",
      secretValue: "",
      valueReceivingAGT: "",
      valueReceivingFIL: "",
      valueReceivingCCT: "",
      valueRefundFIL: "",
      valueRefundCCT: "",
      valueRateSetting: "",
      valueRateSettingFIL: "",
      valueRateSettingCCT: "",

      valueReceivingROY: "",
      valueRefundROY: "",
      valueRateSettingROY: "",
      valueReceivingGROY: "",
      valueRefundGROY: "",
      valueRateSettingGROY: "",
    });
  };

  validateDate() {
    const checkData =
      moment(this.state.timeStart).format("YYYY-MM-DD") >
      moment(this.state.timeEnd).format("YYYY-MM-DD");
    this.setState({ checkDate: checkData });
  }

  componentDidMount() {
    this.alertAck();
    this.props.getRateSetting({
      businessId: this.state.businessId,
    });
    this.props.getRateLimitSetting({ businessId: this.state.businessId });
    this.props.getInfoUser();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.settings !== this.props.settings) {
      this.setState({
        settingRate: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.POM_RATE
        )?.value,
        settingExcelFinance: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.POM_EXCEL_FINANCE_FEE
        )?.value,
        settingEzp: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.POM_GLOVER_CARD_FEE
        )?.value,
        maxGbt: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.MAX_GBT_CHARGE
        )?.value,
        timeStart: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.TIME_START
        )?.value,
        timeEnd: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.TIME_END
        )?.value,
        // valueRefundAGT: nextProps.settings?.find(
        //   (val) => val.key === TYPE_SETTING.WALLET_ADDRESS_AGT
        // )?.value,
        // secretValue: nextProps.settings?.find(
        //   (val) => val.key === TYPE_SETTING.SECRET_KEY_AGT
        // )?.value,
        // valueReceivingAGT: nextProps.settings?.find(
        //   (val) => val.key === TYPE_SETTING.ADDRESS_USER_AGT
        // )?.value,
        // valueReceivingFIL: nextProps.settings?.find(
        //   (val) => val.key === TYPE_SETTING.ADDRESS_USER_GCCT
        // )?.value,
        // valueRefundFIL: nextProps.settings?.find(
        //   (val) => val.key === TYPE_SETTING.WALLET_ADDRESS_GCCT
        // )?.value,
        // secretValueFIL: nextProps.settings?.find(
        //   (val) => val.key === TYPE_SETTING.SECRET_KEY_GCCT
        // )?.value,
        // settingRateFIL: nextProps.settings?.find(
        //   (val) => val.key === TYPE_SETTING.FIL_RATE
        // )?.value,
        // valueRefundCCT: nextProps.settings?.find(
        //   (val) => val.key === TYPE_SETTING.WALLET_ADDRESS_CCT
        // )?.value,
        // valueReceivingCCT: nextProps.settings?.find(
        //   (val) => val.key === TYPE_SETTING.ADDRESS_USER_CCT
        // )?.value,
        // settingRateCCT: nextProps.settings?.find(
        //   (val) => val.key === TYPE_SETTING.CCT_RATE
        // )?.value,
        // secretValueCCT: nextProps.settings?.find(
        //   (val) => val.key === TYPE_SETTING.SECRET_KEY_CCT
        // )?.value,
        // ROY
        valueRefundROY: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.WALLET_ADDRESS_ROY
        )?.value,
        valueReceivingROY: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.ADDRESS_USER_ROY
        )?.value,
        settingRateROY: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.ROY_RATE
        )?.value,
        secretValueROY: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.SECRET_KEY_ROY
        )?.value,
        // GROY
        valueRefundGROY: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.WALLET_ADDRESS_GROY
        )?.value,
        valueReceivingGROY: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.ADDRESS_USER_GROY
        )?.value,
        settingRateGROY: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.GROY_RATE
        )?.value,
        secretValueGROY: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.SECRET_KEY_GROY
        )?.value,
        isLoop:
          nextProps.settings?.find(
            (val) => val.key === TYPE_SETTING.TIME_SET_LOOP
          )?.value !== "0",
      });
    }

    if (nextProps.limitSettings !== this.props.limitSettings) {
      this.setState({
        limitSettings: nextProps.limitSettings,
      });
    }

    if (
      nextProps.settingRate === ACTIONS.POM_RECEIVING_AGT_SUCCESS ||
      nextProps.settingRate === ACTIONS.POM_RECEIVING_AGT_FAILED ||
      nextProps.settingRate === ACTIONS.POM_REFUND_AGT_SUCCESS ||
      nextProps.settingRate === ACTIONS.POM_REFUND_AGT_FAILED ||
      nextProps.settingRate === ACTIONS.POM_UPDATE_EZP_SETTING_SUCCESS ||
      nextProps.settingRate === ACTIONS.POM_UPDATE_EZP_SETTING_FAILED ||
      nextProps.settingRate ===
        ACTIONS.POM_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS ||
      nextProps.settingRate ===
        ACTIONS.POM_UPDATE_EXCEL_FINANCE_SETTING_FAILED ||
      nextProps.settingRate === ACTIONS.POM_UPDATE_RATE_LIMIT_SETTING_SUCCESS ||
      nextProps.settingRate === ACTIONS.POM_UPDATE_RATE_LIMIT_SETTING_FAILED ||
      nextProps.settingRate === ACTIONS.POM_UPDATE_SETTING_RATE_SUCCESS ||
      nextProps.settingRate === ACTIONS.POM_UPDATE_SETTING_RATE_FAILED ||
      nextProps.settingRate === ACTIONS.POM_GET_RATE_SETTING_FAILED ||
      nextProps.settingRate === ACTIONS.POM_GET_RATE_LIMIT_SETTING_FAILED
    ) {
      if (
        nextProps.settingRate === ACTIONS.POM_RECEIVING_AGT_SUCCESS ||
        nextProps.settingRate === ACTIONS.POM_REFUND_AGT_SUCCESS ||
        nextProps.settingRate === ACTIONS.POM_UPDATE_EZP_SETTING_SUCCESS ||
        nextProps.settingRate ===
          ACTIONS.POM_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS ||
        nextProps.settingRate ===
          ACTIONS.POM_UPDATE_RATE_LIMIT_SETTING_SUCCESS ||
        nextProps.settingRate === ACTIONS.POM_UPDATE_SETTING_RATE_SUCCESS
      ) {
        this.setState({
          isOpenModal: false,
          isRefundAGT: false,
          isReceivingAGT: false,
          isUpdateSetting: false,
          isFeeExcel: false,
          isFeeGlover: false,
          isSettingRateLimit: false,
        });
      }
      this.setState({
        alertAck: false,
      });
    }
  }

  handleChangeStartTime(date) {
    this.setState(
      {
        timeStart: date,
      },
      () => {
        this.validateDate();
      }
    );
  }

  handleChangeEndTime(date) {
    this.setState(
      {
        timeEnd: date,
      },
      () => {
        this.validateDate();
      }
    );
  }

  onRefundROY = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        isRefundROY: true,
        isReceivingROY: false,
        alertAck: true,
        isUpdateSetting: false,
        isFeeExcel: false,
        isFeeGlover: false,
        isSettingRateLimit: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  onRefundGROY = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        isRefundGROY: true,
        isReceivingGROY: false,
        alertAck: true,
        isUpdateSetting: false,
        isFeeExcel: false,
        isFeeGlover: false,
        isSettingRateLimit: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  // onRefundAGT = () => {
  //   if (this.props.infoUser.tfaEnable) {
  //     this.setState({
  //       isOpenModal: true,
  //       isRefundAGT: true,
  //       isReceivingAGT: false,
  //       alertAck: true,
  //       isUpdateSetting: false,
  //       isFeeExcel: false,
  //       isFeeGlover: false,
  //       isSettingRateLimit: false,
  //     });
  //   } else {
  //     this.setState({
  //       settingTFA: true,
  //     });
  //   }
  // };

  // onRefundFIL = () => {
  //   if (this.props.infoUser.tfaEnable) {
  //     this.setState({
  //       isOpenModal: true,
  //       isRefundFIL: true,
  //       isReceivingFIL: false,
  //       alertAck: true,
  //       isUpdateSetting: false,
  //       isFeeExcel: false,
  //       isFeeGlover: false,
  //       isSettingRateLimit: false,
  //     });
  //   } else {
  //     this.setState({
  //       settingTFA: true,
  //     });
  //   }
  // };

  // onRefundCCT = () => {
  //   if (this.props.infoUser.tfaEnable) {
  //     this.setState({
  //       isOpenModal: true,
  //       isRefundCCT: true,
  //       isReceivingCCT: false,
  //       alertAck: true,
  //       isUpdateSetting: false,
  //       isFeeExcel: false,
  //       isFeeGlover: false,
  //       isSettingRateLimit: false,
  //     });
  //   } else {
  //     this.setState({
  //       settingTFA: true,
  //     });
  //   }
  // };

  onReceivingROY = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        isRefundROY: false,
        isReceivingROY: true,
        alertAck: true,
        isUpdateSetting: false,
        isFeeExcel: false,
        isFeeGlover: false,
        isSettingRateLimit: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  onReceivingGROY = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        isRefundGROY: false,
        isReceivingGROY: true,
        alertAck: true,
        isUpdateSetting: false,
        isFeeExcel: false,
        isFeeGlover: false,
        isSettingRateLimit: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  // onReceivingAGT = () => {
  //   if (this.props.infoUser.tfaEnable) {
  //     this.setState({
  //       isOpenModal: true,
  //       isRefundAGT: false,
  //       isReceivingAGT: true,
  //       alertAck: true,
  //       isUpdateSetting: false,
  //       isFeeExcel: false,
  //       isFeeGlover: false,
  //       isSettingRateLimit: false,
  //     });
  //   } else {
  //     this.setState({
  //       settingTFA: true,
  //     });
  //   }
  // };

  // onReceivingFIL = () => {
  //   if (this.props.infoUser.tfaEnable) {
  //     this.setState({
  //       isOpenModal: true,
  //       isRefundFIL: false,
  //       isReceivingFIL: true,
  //       alertAck: true,
  //       isUpdateSetting: false,
  //       isFeeExcel: false,
  //       isFeeGlover: false,
  //       isSettingRateLimit: false,
  //     });
  //   } else {
  //     this.setState({
  //       settingTFA: true,
  //     });
  //   }
  // };

  // onReceivingCCT = () => {
  //   if (this.props.infoUser.tfaEnable) {
  //     this.setState({
  //       isOpenModal: true,
  //       isRefundCCT: false,
  //       isReceivingCCT: true,
  //       alertAck: true,
  //       isUpdateSetting: false,
  //       isFeeExcel: false,
  //       isFeeGlover: false,
  //       isSettingRateLimit: false,
  //     });
  //   } else {
  //     this.setState({
  //       settingTFA: true,
  //     });
  //   }
  // };

  onHuvRateSetting = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        alertAck: true,
        isUpdateSetting: true,
        isRefundAGT: false,
        isReceivingAGT: false,
        isFeeExcel: false,
        isFeeGlover: false,
        isSettingRateLimit: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  // onFilRateSetting = () => {
  //   if (this.props.infoUser.tfaEnable) {
  //     this.setState({
  //       isOpenModal: true,
  //       alertAck: true,
  //       isUpdateSettingFIL: true,
  //       isRefundFIL: false,
  //       isReceivingFIL: false,
  //       isFeeExcel: false,
  //       isFeeGlover: false,
  //       isSettingRateLimit: false,
  //     });
  //   } else {
  //     this.setState({
  //       settingTFA: true,
  //     });
  //   }
  // };

  // onCCTRateSetting = () => {
  //   if (this.props.infoUser.tfaEnable) {
  //     this.setState({
  //       isOpenModal: true,
  //       alertAck: true,
  //       isUpdateSettingCCT: true,
  //       isRefundCCT: false,
  //       isReceivingCCT: false,
  //       isFeeExcel: false,
  //       isFeeGlover: false,
  //       isSettingRateLimit: false,
  //     });
  //   } else {
  //     this.setState({
  //       settingTFA: true,
  //     });
  //   }
  // };

  onROYRateSetting = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        alertAck: true,
        isUpdateSettingROY: true,
        isRefundROY: false,
        isReceivingROY: false,
        isFeeExcel: false,
        isFeeGlover: false,
        isSettingRateLimit: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  onGROYRateSetting = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        alertAck: true,
        isUpdateSettingGROY: true,
        isRefundGROY: false,
        isReceivingGROY: false,
        isFeeExcel: false,
        isFeeGlover: false,
        isSettingRateLimit: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  onHuvFeeExcel = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        alertAck: true,
        isUpdateSetting: false,
        isRefundAGT: false,
        isReceivingAGT: false,
        isFeeExcel: true,
        isFeeGlover: false,
        isSettingRateLimit: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  onHuvFeeGlover = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        alertAck: true,
        isUpdateSetting: false,
        isRefundAGT: false,
        isReceivingAGT: false,
        isFeeExcel: false,
        isFeeGlover: true,
        isSettingRateLimit: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  messageError = (errorCode) => {
    const { t } = this.props;
    switch (errorCode) {
      case "USER_TFA_CODE_INVALID":
        return t("Code.USER_TFA_CODE_INVALID");
      case "MISSING_REQUIRED_VALUE":
        return t("Code.MISSING_REQUIRED_VALUE");
      case "VALUE_EQUAL":
        return t("Code.VALUE_EQUAL");
      case "PERMISSION_DENIED":
        return t("Code.PERMISSION_DENIED");
      default:
        return errorCode;
    }
  };

  closeModal = () => {
    this.setState({
      isOpenModal: false,
      isRefundAGT: false,
      isReceivingAGT: false,
      isRefundFIL: false,
      isReceivingFIL: false,
      isRefundCCT: false,
      isReceivingCCT: false,
      alertAck: true,
      isUpdateSetting: false,
      isUpdateSettingFIL: false,
      isUpdateSettingCCT: false,
      isFeeExcel: false,
      isFeeGlover: false,
      isSettingRateLimit: false,

      isRefundROY: false,
      isReceivingROY: false,
      isUpdateSettingROY: false,
      isRefundGROY: false,
      isReceivingGROY: false,
      isUpdateSettingGROY: false,
    });
  };

  decryptWithIv = (data, key) => {
    const inputEncoding = "base64" || "hex";

    let decipher = crypto.createCipheriv(
      "aes-256-ctr",
      ENCRYPT_KEY,
      IV_ENCRYPT
    );

    let decrypted = decipher.update(data, inputEncoding, "utf8");
    decrypted += decipher.final("utf8");
    return decrypted;
  };

  onChangeSecretValue = (e) => {
    const value = e.target.value;

    this.setState({
      templeSecretKey: value,
      secretValue: value,
    });
  };

  onChangeSecretValueROY = (e) => {
    const value = e?.target?.value;

    this.setState({
      templeSecretKeyROY: value,
      secretValueROY: value,
    });
  };

  onChangeSecretValueGROY = (e) => {
    const value = e?.target?.value;

    this.setState({
      templeSecretKeyGROY: value,
      secretValueGROY: value,
    });
  };

  // onChangeSecretValueFIL = (e) => {
  //   const value = e.target.value;

  //   this.setState({
  //     templeSecretKeyFIL: value,
  //     secretValueFIL: value,
  //   });
  // };

  // onChangeSecretValueCCT = (e) => {
  //   const value = e?.target?.value;

  //   this.setState({
  //     templeSecretKeyCCT: value,
  //     secretValueCCT: value,
  //   });
  // };

  formatSecretKey = (key) => {
    return key
      ? key.substring(0, 4) +
          "********" +
          key?.substring(key?.length - 4, key?.length)
      : "";
  };

  // RENDER UI
  renderSettingROYRate(settingRateROY) {
    const { t } = this.props;

    return (
      <>
        <Row className="mt-5">
          <Label className="label-title">
            {t("RateSetting.SettingRate")}
            {` (1 EUR ~ ${
              (+this.props.settings?.find(
                (val) => val.key === TYPE_SETTING.ROY_RATE
              )?.value || 0) / 100
            } ${COIN_TYPE.ROY})`}
            {` ${t("adil_network")}`}
          </Label>
        </Row>
        <Row>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <Input
              className="input-label-style"
              disabled={true}
              value={t("RateSetting.SettingRate")}
            />
          </Col>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <InputGroup>
              <Input
                className="input-style"
                type="number"
                value={settingRateROY}
                disabled={
                  this.props.settingRate ===
                  ACTIONS.UPDATE_RATE_SETTING_PROGRESS
                }
                onChange={(e) => {
                  this.setState({ settingRateROY: parseInt(e.target.value) });
                }}
                min="1"
                // max="120"
              />
              <InputGroupAddon addonType="append">
                <InputGroupText className="brs">%</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col lg="2" md="2" sm="2" xs="12" className="mt-1">
            <Button
              variant="contained"
              disabled={
                this.props.settingRate === ACTIONS.UPDATE_RATE_SETTING_PROGRESS
              }
              className="submit-btn brs"
              // onClick={() => {
              //     this.setState({ setRate: true });
              // }}
              onClick={this.onROYRateSetting}
            >
              {this.props.settingRate ===
                ACTIONS.UPDATE_RATE_SETTING_PROGRESS && (
                <CircularProgress className="mr-2" size={20} />
              )}
              <span>{t("Button.Save")}</span>
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  renderSettingGROYRate(settingRateGROY) {
    const { t } = this.props;

    return (
      <>
        <Row className="mt-5">
          <Label className="label-title">
            {t("RateSetting.SettingRate")}
            {` (1 EUR ~ ${
              (+this.props.settings?.find(
                (val) => val.key === TYPE_SETTING.GROY_RATE
              )?.value || 0) / 100
            } ${COIN_TYPE.GROY})`}
            {` ${t("adil_network")}`}
          </Label>
        </Row>
        <Row>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <Input
              className="input-label-style"
              disabled={true}
              value={t("RateSetting.SettingRate")}
            />
          </Col>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <InputGroup>
              <Input
                className="input-style"
                type="number"
                value={settingRateGROY}
                disabled={
                  this.props.settingRate ===
                  ACTIONS.UPDATE_RATE_SETTING_PROGRESS
                }
                onChange={(e) => {
                  this.setState({ settingRateGROY: parseInt(e.target.value) });
                }}
                min="1"
                // max="120"
              />
              <InputGroupAddon addonType="append">
                <InputGroupText className="brs">%</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col lg="2" md="2" sm="2" xs="12" className="mt-1">
            <Button
              variant="contained"
              disabled={
                this.props.settingRate === ACTIONS.UPDATE_RATE_SETTING_PROGRESS
              }
              className="submit-btn brs"
              // onClick={() => {
              //     this.setState({ setRate: true });
              // }}
              onClick={this.onGROYRateSetting}
            >
              {this.props.settingRate ===
                ACTIONS.UPDATE_RATE_SETTING_PROGRESS && (
                <CircularProgress className="mr-2" size={20} />
              )}
              <span>{t("Button.Save")}</span>
            </Button>
          </Col>
        </Row>
      </>
    );
  }
  // renderSettingAGTRate(settingRate) {
  //   const { t } = this.props;

  //   return (
  //     <>
  //       <Row className="mt-5">
  //         <Label className="label-title">
  //           {t("RateSetting.SettingRate")}
  //           {` (1 EUR ~ ${
  //             (+this.props.settings?.find(
  //               (val) => val.key === TYPE_SETTING.POM_RATE
  //             )?.value || 0) / 100
  //           } AGT)`}
  //         </Label>
  //       </Row>
  //       <Row>
  //         <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
  //           <Input
  //             className="input-label-style"
  //             disabled={true}
  //             value={t("RateSetting.SettingRate")}
  //           />
  //         </Col>
  //         <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
  //           <InputGroup>
  //             <Input
  //               className="input-style"
  //               type="number"
  //               value={settingRate}
  //               disabled={
  //                 this.props.settingRate ===
  //                 ACTIONS.UPDATE_RATE_SETTING_PROGRESS
  //               }
  //               onChange={(e) => {
  //                 this.setState({ settingRate: parseInt(e.target.value) });
  //               }}
  //               min="1"
  //               // max="120"
  //             />
  //             <InputGroupAddon addonType="append">
  //               <InputGroupText className="brs">%</InputGroupText>
  //             </InputGroupAddon>
  //           </InputGroup>
  //         </Col>
  //         <Col lg="2" md="2" sm="2" xs="12" className="mt-1">
  //           <Button
  //             variant="contained"
  //             disabled={
  //               this.props.settingRate === ACTIONS.UPDATE_RATE_SETTING_PROGRESS
  //             }
  //             className="submit-btn brs"
  //             // onClick={() => {
  //             //     this.setState({ setRate: true });
  //             // }}
  //             onClick={this.onHuvRateSetting}
  //           >
  //             {this.props.settingRate ===
  //               ACTIONS.UPDATE_RATE_SETTING_PROGRESS && (
  //               <CircularProgress className="mr-2" size={20} />
  //             )}
  //             <span>{t("Button.Save")}</span>
  //           </Button>
  //         </Col>
  //       </Row>
  //     </>
  //   );
  // }

  // renderSettingGCCTRate(settingRateFIL) {
  //   const { t } = this.props;

  //   return (
  //     <>
  //       <Row className="mt-5">
  //         <Label className="label-title">
  //           {t("RateSetting.SettingRate")}
  //           {` (1 EUR ~ ${
  //             (+this.props.settings?.find(
  //               (val) => val.key === TYPE_SETTING.FIL_RATE
  //             )?.value || 0) / 100
  //           } ${COIN_TYPE.GCCT})`}
  //         </Label>
  //       </Row>
  //       <Row>
  //         <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
  //           <Input
  //             className="input-label-style"
  //             disabled={true}
  //             value={t("RateSetting.SettingRate")}
  //           />
  //         </Col>
  //         <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
  //           <InputGroup>
  //             <Input
  //               className="input-style"
  //               type="number"
  //               value={settingRateFIL}
  //               disabled={
  //                 this.props.settingRate ===
  //                 ACTIONS.UPDATE_RATE_SETTING_PROGRESS
  //               }
  //               onChange={(e) => {
  //                 this.setState({ settingRateFIL: parseInt(e.target.value) });
  //               }}
  //               min="1"
  //               // max="120"
  //             />
  //             <InputGroupAddon addonType="append">
  //               <InputGroupText className="brs">%</InputGroupText>
  //             </InputGroupAddon>
  //           </InputGroup>
  //         </Col>
  //         <Col lg="2" md="2" sm="2" xs="12" className="mt-1">
  //           <Button
  //             variant="contained"
  //             disabled={
  //               this.props.settingRate === ACTIONS.UPDATE_RATE_SETTING_PROGRESS
  //             }
  //             className="submit-btn brs"
  //             // onClick={() => {
  //             //     this.setState({ setRate: true });
  //             // }}
  //             onClick={this.onFilRateSetting}
  //           >
  //             {this.props.settingRate ===
  //               ACTIONS.UPDATE_RATE_SETTING_PROGRESS && (
  //               <CircularProgress className="mr-2" size={20} />
  //             )}
  //             <span>{t("Button.Save")}</span>
  //           </Button>
  //         </Col>
  //       </Row>
  //     </>
  //   );
  // }

  // renderSettingCCTRate(settingRateCCT) {
  //   const { t } = this.props;

  //   return (
  //     <>
  //       <Row className="mt-5">
  //         <Label className="label-title">
  //           {t("RateSetting.SettingRate")}
  //           {` (1 EUR ~ ${
  //             (+this.props.settings?.find(
  //               (val) => val.key === TYPE_SETTING.CCT_RATE
  //             )?.value || 0) / 100
  //           } ${COIN_TYPE.CCT})`}
  //         </Label>
  //       </Row>
  //       <Row>
  //         <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
  //           <Input
  //             className="input-label-style"
  //             disabled={true}
  //             value={t("RateSetting.SettingRate")}
  //           />
  //         </Col>
  //         <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
  //           <InputGroup>
  //             <Input
  //               className="input-style"
  //               type="number"
  //               value={settingRateCCT}
  //               disabled={
  //                 this.props.settingRate ===
  //                 ACTIONS.UPDATE_RATE_SETTING_PROGRESS
  //               }
  //               onChange={(e) => {
  //                 this.setState({ settingRateCCT: parseInt(e.target.value) });
  //               }}
  //               min="1"
  //               // max="120"
  //             />
  //             <InputGroupAddon addonType="append">
  //               <InputGroupText className="brs">%</InputGroupText>
  //             </InputGroupAddon>
  //           </InputGroup>
  //         </Col>
  //         <Col lg="2" md="2" sm="2" xs="12" className="mt-1">
  //           <Button
  //             variant="contained"
  //             disabled={
  //               this.props.settingRate === ACTIONS.UPDATE_RATE_SETTING_PROGRESS
  //             }
  //             className="submit-btn brs"
  //             // onClick={() => {
  //             //     this.setState({ setRate: true });
  //             // }}
  //             onClick={this.onCCTRateSetting}
  //           >
  //             {this.props.settingRate ===
  //               ACTIONS.UPDATE_RATE_SETTING_PROGRESS && (
  //               <CircularProgress className="mr-2" size={20} />
  //             )}
  //             <span>{t("Button.Save")}</span>
  //           </Button>
  //         </Col>
  //       </Row>
  //     </>
  //   );
  // }

  renderSettingFee(settingExcelFinance, settingEzp) {
    const { t } = this.props;
    return (
      <>
        <Row className="mt-5">
          <Label className="label-title">
            {t("RateSetting.SettingFee")}
            {` (~ ${
              ((+this.props.settings?.find(
                (val) => val.key === TYPE_SETTING.POM_EXCEL_FINANCE_FEE
              )?.value || 0) +
                (+this.props.settings?.find(
                  (val) => val.key === TYPE_SETTING.POM_GLOVER_CARD_FEE
                )?.value || 0)) /
              100
            } EUR)`}
          </Label>
        </Row>
        <Row>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <Input
              className="input-label-style"
              disabled={true}
              value={"Setting Fee Excel Finance"}
            />
          </Col>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <InputGroup>
              <Input
                className="input-style"
                type="number"
                value={settingExcelFinance}
                disabled={
                  this.props.settingRate ===
                  ACTIONS.UPDATE_EXCEL_FINANCE_SETTING_PROGRESS
                }
                onChange={(e) => {
                  this.setState({ settingExcelFinance: e.target.value });
                }}
                min="0"
                // max="100"
              />
              <InputGroupAddon addonType="append">
                <InputGroupText className="brs">%</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col lg="2" md="2" sm="2" xs="12" className="mt-1">
            <Button
              variant="contained"
              disabled={
                this.props.settingRate ===
                ACTIONS.UPDATE_EXCEL_FINANCE_SETTING_PROGRESS
              }
              className="submit-btn brs"
              onClick={this.onHuvFeeExcel}
              // onClick={() => {
              //     this.setState({ setExcelFee: true });
              // }}
            >
              {this.props.settingRate ===
                ACTIONS.UPDATE_EXCEL_FINANCE_SETTING_PROGRESS && (
                <CircularProgress className="mr-2" size={20} />
              )}
              <span>{t("Button.Save")}</span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <Input
              className="input-label-style"
              disabled={true}
              value={t("HuvStyleTopUpPage.setting_glover_fee")}
            />
          </Col>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <InputGroup>
              <Input
                className="input-style"
                type="number"
                value={settingEzp}
                disabled={
                  this.props.settingRate === ACTIONS.UPDATE_EZP_SETTING_PROGRESS
                }
                onChange={(e) => {
                  this.setState({ settingEzp: e.target.value });
                }}
                min="0"
                // max="100"
              />
              <InputGroupAddon addonType="append">
                <InputGroupText className="brs">%</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col lg="2" md="2" sm="2" xs="12" className="mt-1">
            <Button
              variant="contained"
              disabled={
                this.props.settingRate === ACTIONS.UPDATE_EZP_SETTING_PROGRESS
              }
              className="submit-btn brs"
              onClick={this.onHuvFeeGlover}
              // onClick={() => {
              //     this.setState({ setEzpFee: true });
              // }}
            >
              {this.props.settingRate ===
                ACTIONS.UPDATE_EZP_SETTING_PROGRESS && (
                <CircularProgress className="mr-2" size={20} />
              )}
              <span>{t("Button.Save")}</span>
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  renderHeaderSettingLimit() {
    const { t } = this.props;
    return (
      <>
        <Row className="mt-5">
          <Label className="label-title">
            {t("RateSetting.SettingRateLimit")}
          </Label>
        </Row>
        <Row>
          <Col lg="2" md="2" sm="12" xs="12" className="mt-1">
            <Input className="input-label-style" disabled={true} value="Type" />
          </Col>
          <Col lg="2" md="2" sm="12" xs="12" className="mt-1 pd-right-left-10">
            <Input
              className="input-label-style"
              disabled={true}
              value="OneTime"
            />
          </Col>
          <Col lg="2" md="2" sm="12" xs="12" className="mt-1 pd-right-left-10">
            <Input
              className="input-label-style"
              disabled={true}
              value="Daily"
            />
          </Col>
          <Col lg="2" md="2" sm="12" xs="12" className="mt-1 pd-right-left-10">
            <Input
              className="input-label-style"
              disabled={true}
              value="Month"
            />
          </Col>
          <Col lg="2" md="2" sm="12" xs="12" className="mt-1 pd-right-left-10">
            <Input className="input-label-style" disabled={true} value="Year" />
          </Col>
          <Col lg="1" md="1" sm="12" xs="12" className="mt-1"></Col>
        </Row>
      </>
    );
  }

  renderSettingRateLimit() {
    const {
      businessId,
      idRateLimit,
      changeOneTimeLimit,
      changeDailyLimit,
      changeMonthlyLimit,
      changeYearlyLimit,
      titleRateLimit,
      limitSettings,
    } = this.state;
    const { t } = this.props;

    return (
      limitSettings &&
      limitSettings
        // .filter((s) => !["0", "1", "2", "3"].includes(s.title))
        .map((s, index) => {
          return (
            <Row key={index}>
              <Col lg="2" md="2" sm="12" xs="12" className="mt-1">
                <Input
                  className="input-label-style"
                  disabled={true}
                  value={s.name}
                />
              </Col>
              <Col
                lg="2"
                md="2"
                sm="12"
                xs="12"
                className="mt-1 pd-right-left-10"
              >
                <InputGroup>
                  <Input
                    className="input-style"
                    type="number"
                    value={
                      changeOneTimeLimit && s._id === idRateLimit
                        ? changeOneTimeLimit
                        : s.oneTimeLimit
                    }
                    disabled={
                      this.props.settingRate ===
                        ACTIONS.UPDATE_RATE_LIMIT_SETTING_PROGRESS ||
                      businessId === "02"
                    }
                    onChange={(e) => {
                      if (s._id === idRateLimit) {
                        this.setState({
                          idRateLimit: s._id,
                          titleRateLimit: s.title,
                          changeOneTimeLimit: parseInt(e.target.value),
                        });
                      } else {
                        this.setState({
                          idRateLimit: s._id,
                          titleRateLimit: s.title,
                          changeOneTimeLimit: parseInt(e.target.value),
                          changeDailyLimit: "",
                          changeMonthlyLimit: "",
                          changeYearlyLimit: "",
                        });
                      }
                    }}
                    min="0"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="brs">EUR</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col
                lg="2"
                md="2"
                sm="12"
                xs="12"
                className="mt-1 pd-right-left-10"
              >
                <InputGroup>
                  <Input
                    className="input-style"
                    type="number"
                    value={
                      changeDailyLimit && s._id === idRateLimit
                        ? changeDailyLimit
                        : s.dailyLimit
                    }
                    disabled={
                      this.props.settingRate ===
                        ACTIONS.UPDATE_RATE_LIMIT_SETTING_PROGRESS ||
                      businessId === "02"
                    }
                    onChange={(e) => {
                      if (s._id === idRateLimit) {
                        this.setState({
                          idRateLimit: s._id,
                          titleRateLimit: s.title,
                          changeDailyLimit: parseInt(e.target.value),
                        });
                      } else {
                        this.setState({
                          idRateLimit: s._id,
                          titleRateLimit: s.title,
                          changeOneTimeLimit: "",
                          changeDailyLimit: parseInt(e.target.value),
                          changeMonthlyLimit: "",
                          changeYearlyLimit: "",
                        });
                      }
                    }}
                    min="0"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="brs">EUR</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col
                lg="2"
                md="2"
                sm="12"
                xs="12"
                className="mt-1 pd-right-left-10"
              >
                <InputGroup>
                  <Input
                    className="input-style"
                    type="number"
                    value={
                      changeMonthlyLimit && s._id === idRateLimit
                        ? changeMonthlyLimit
                        : s.monthlyLimit
                    }
                    disabled={
                      this.props.settingRate ===
                        ACTIONS.UPDATE_RATE_LIMIT_SETTING_PROGRESS ||
                      businessId === "02"
                    }
                    onChange={(e) => {
                      if (s._id === idRateLimit) {
                        this.setState({
                          idRateLimit: s._id,
                          titleRateLimit: s.title,
                          changeMonthlyLimit: parseInt(e.target.value),
                        });
                      } else {
                        this.setState({
                          idRateLimit: s._id,
                          titleRateLimit: s.title,
                          changeOneTimeLimit: "",
                          changeDailyLimit: "",
                          changeMonthlyLimit: parseInt(e.target.value),
                          changeYearlyLimit: "",
                        });
                      }
                    }}
                    min="0"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="brs">EUR</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col
                lg="2"
                md="2"
                sm="12"
                xs="12"
                className="mt-1 pd-right-left-10"
              >
                <InputGroup>
                  <Input
                    className="input-style"
                    type="number"
                    value={
                      changeYearlyLimit && s._id === idRateLimit
                        ? changeYearlyLimit
                        : s.yearlyLimit
                    }
                    disabled={
                      this.props.settingRate ===
                        ACTIONS.UPDATE_RATE_LIMIT_SETTING_PROGRESS ||
                      businessId === "02"
                    }
                    onChange={(e) => {
                      if (s._id === idRateLimit) {
                        this.setState({
                          idRateLimit: s._id,
                          titleRateLimit: s.title,
                          changeYearlyLimit: parseInt(e.target.value),
                        });
                      } else {
                        this.setState({
                          idRateLimit: s._id,
                          titleRateLimit: s.title,
                          changeOneTimeLimit: "",
                          changeDailyLimit: "",
                          changeMonthlyLimit: "",
                          changeYearlyLimit: parseInt(e.target.value),
                        });
                      }
                    }}
                    min="0"
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText className="brs">EUR</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
              <Col lg="1" md="1" sm="12" xs="12" className="mt-1">
                <Button
                  variant="contained"
                  disabled={
                    this.props.settingRate ===
                      ACTIONS.UPDATE_RATE_LIMIT_SETTING_PROGRESS ||
                    businessId === "02"
                  }
                  className="submit-btn brs"
                  onClick={() => {
                    !changeOneTimeLimit &&
                      this.setState({ changeOneTimeLimit: s.oneTimeLimit });
                    !changeDailyLimit &&
                      this.setState({ changeDailyLimit: s.dailyLimit });
                    !changeMonthlyLimit &&
                      this.setState({ changeMonthlyLimit: s.monthlyLimit });
                    !changeYearlyLimit &&
                      this.setState({ changeYearlyLimit: s.yearlyLimit });
                    !titleRateLimit &&
                      this.setState({ titleRateLimit: s.title });
                    // this.setState({ setRateLimit: true });
                    if (this.props.infoUser.tfaEnable) {
                      this.setState({
                        isOpenModal: true,
                        alertAck: true,
                        isUpdateSetting: false,
                        isRefundAGT: false,
                        isReceivingAGT: false,
                        sUpdateSettingFIL: false,
                        isRefundFIL: false,
                        isReceivingFIL: false,
                        isFeeExcel: false,
                        isFeeGlover: false,
                        isSettingRateLimit: true,
                      });
                    } else {
                      this.setState({
                        settingTFA: true,
                      });
                    }
                  }}
                >
                  {this.props.settingRate ===
                    ACTIONS.UPDATE_RATE_LIMIT_SETTING_PROGRESS && (
                    <CircularProgress className="mr-2" size={20} />
                  )}
                  <span>{t("Button.Save")}</span>
                </Button>
              </Col>
            </Row>
          );
        })
    );
  }

  // renderWalletAGT(
  //   valueRefundAGT,
  //   templeSecretKey,
  //   decryptSecretKey,
  //   secretValue,
  //   valueReceivingAGT
  // ) {
  //   const { t } = this.props;
  //   return (
  //     <div className="border-div col-11 mt-5">
  //       <div className="border-div-overlay-text">
  //         <span className="font-weight-bold">AGT</span>
  //       </div>
  //       <div className="content-setting-address">
  //         <Label className="label-title">{t("RateSetting.RefundAGT")}</Label>
  //         <Row>
  //           <Col lg="12" md="12" sm="12" xs="12">
  //             <Input
  //               value={valueRefundAGT}
  //               onChange={(e) => {
  //                 this.setState({ valueRefundAGT: e.target.value });
  //               }}
  //             />
  //           </Col>
  //         </Row>
  //         <Label className="label-title  mt-3">
  //           {t("RateSetting.SecretKey")}
  //         </Label>
  //         <Row>
  //           <Col lg="12" md="12" sm="12" xs="12">
  //             <Input
  //               //   value={secretValue}
  //               // onChange={(e) => {this.setState({secretValue: e.target.value})}}
  //               value={templeSecretKey ? templeSecretKey : decryptSecretKey}
  //               onChange={this.onChangeSecretValue}
  //             />
  //           </Col>
  //         </Row>
  //         <Row className="margin-top-10px">
  //           <Col lg="12" md="12" sm="12" xs="12">
  //             <Button
  //               className="btn-confirm"
  //               onClick={this.onRefundAGT}
  //               disabled={!secretValue || !valueRefundAGT}
  //             >
  //               {t("Button.Confirm")}
  //             </Button>
  //           </Col>
  //         </Row>
  //         <Label className="label-title">{t("RateSetting.ReceivingAGT")}</Label>
  //         <Row>
  //           <Col lg="10" md="10" sm="10" xs="10">
  //             <Input
  //               value={valueReceivingAGT}
  //               onChange={(e) => {
  //                 this.setState({ valueReceivingAGT: e.target.value });
  //               }}
  //             />
  //           </Col>
  //           <Col lg="2" md="2" sm="2" xs="2">
  //             <Button
  //               className="btn-confirm"
  //               onClick={this.onReceivingAGT}
  //               disabled={!valueReceivingAGT}
  //             >
  //               {t("Button.Confirm")}
  //             </Button>
  //           </Col>
  //         </Row>
  //       </div>
  //     </div>
  //   );
  // }

  // renderWalletGCCT(
  //   valueRefundFIL,
  //   templeSecretKeyFIL,
  //   decryptSecretKeyFIL,
  //   secretValueFIL,
  //   valueReceivingFIL
  // ) {
  //   const { t } = this.props;
  //   return (
  //     <div className="border-div col-11 mt-5">
  //       <div className="border-div-overlay-text">
  //         <span className="font-weight-bold">{COIN_TYPE.GCCT}</span>
  //       </div>
  //       <div className="content-setting-address">
  //         <Label className="label-title">{t("RateSetting.RefundFIL")}</Label>
  //         <Row>
  //           <Col lg="12" md="12" sm="12" xs="12">
  //             <Input
  //               value={valueRefundFIL || ""}
  //               onChange={(e) => {
  //                 this.setState({ valueRefundFIL: e.target.value });
  //               }}
  //             />
  //           </Col>
  //         </Row>
  //         <Label className="label-title mt-3">
  //           {t("RateSetting.SecretKey")}
  //         </Label>
  //         <Row>
  //           <Col lg="12" md="12" sm="12" xs="12">
  //             <Input
  //               //   value={secretValue}
  //               // onChange={(e) => {this.setState({secretValue: e.target.value})}}
  //               value={
  //                 templeSecretKeyFIL ? templeSecretKeyFIL : decryptSecretKeyFIL
  //               }
  //               onChange={this.onChangeSecretValueFIL}
  //             />
  //           </Col>
  //         </Row>
  //         <Row className="margin-top-10px">
  //           <Col lg="12" md="12" sm="12" xs="12">
  //             <Button
  //               className="btn-confirm"
  //               onClick={this.onRefundFIL}
  //               disabled={!secretValueFIL || !valueRefundFIL}
  //             >
  //               {t("Button.Confirm")}
  //             </Button>
  //           </Col>
  //         </Row>
  //         <Label className="label-title">{t("RateSetting.ReceivingFIL")}</Label>
  //         <Row>
  //           <Col lg="10" md="10" sm="10" xs="10">
  //             <Input
  //               value={valueReceivingFIL || ""}
  //               onChange={(e) => {
  //                 this.setState({ valueReceivingFIL: e.target.value });
  //               }}
  //             />
  //           </Col>
  //           <Col lg="2" md="2" sm="2" xs="2">
  //             <Button
  //               className="btn-confirm"
  //               onClick={this.onReceivingFIL}
  //               disabled={!valueReceivingFIL}
  //             >
  //               {t("Button.Confirm")}
  //             </Button>
  //           </Col>
  //         </Row>
  //       </div>
  //     </div>
  //   );
  // }

  // renderWalletCCT(
  //   valueRefundCCT,
  //   templeSecretKeyCCT,
  //   decryptSecretKeyCCT,
  //   secretValueCCT,
  //   valueReceivingCCT
  // ) {
  //   const { t } = this.props;
  //   return (
  //     <div className="border-div col-11 mt-5">
  //       <div className="border-div-overlay-text">
  //         <span className="font-weight-bold">{COIN_TYPE.CCT}</span>
  //       </div>
  //       <div className="content-setting-address">
  //         <Label className="label-title">{t("RateSetting.RefundFIL")}</Label>
  //         <Row>
  //           <Col lg="12" md="12" sm="12" xs="12">
  //             <Input
  //               value={valueRefundCCT || ""}
  //               onChange={(e) => {
  //                 this.setState({ valueRefundCCT: e.target.value });
  //               }}
  //             />
  //           </Col>
  //         </Row>
  //         <Label className="label-title mt-3">
  //           {t("RateSetting.SecretKey")}
  //         </Label>
  //         <Row>
  //           <Col lg="12" md="12" sm="12" xs="12">
  //             <Input
  //               value={
  //                 templeSecretKeyCCT ? templeSecretKeyCCT : decryptSecretKeyCCT
  //               }
  //               onChange={this.onChangeSecretValueCCT}
  //             />
  //           </Col>
  //         </Row>
  //         <Row className="margin-top-10px">
  //           <Col lg="12" md="12" sm="12" xs="12">
  //             <Button
  //               className="btn-confirm"
  //               onClick={this.onRefundCCT}
  //               disabled={!secretValueCCT || !valueRefundCCT}
  //             >
  //               {t("Button.Confirm")}
  //             </Button>
  //           </Col>
  //         </Row>
  //         <Label className="label-title">{t("RateSetting.ReceivingFIL")}</Label>
  //         <Row>
  //           <Col lg="10" md="10" sm="10" xs="10">
  //             <Input
  //               value={valueReceivingCCT || ""}
  //               onChange={(e) => {
  //                 this.setState({ valueReceivingCCT: e.target.value });
  //               }}
  //             />
  //           </Col>
  //           <Col lg="2" md="2" sm="2" xs="2">
  //             <Button
  //               className="btn-confirm"
  //               onClick={this.onReceivingCCT}
  //               disabled={!valueReceivingCCT}
  //             >
  //               {t("Button.Confirm")}
  //             </Button>
  //           </Col>
  //         </Row>
  //       </div>
  //     </div>
  //   );
  // }

  renderWalletROY(
    valueRefundROY,
    templeSecretKeyROY,
    decryptSecretKeyROY,
    secretValueROY,
    valueReceivingROY
  ) {
    const { t } = this.props;
    return (
      <div className="border-div col-11 mt-5">
        <div className="border-div-overlay-text">
          <span className="font-weight-bold">
            {COIN_TYPE.ROY} {` ${t("adil_network")}`}
          </span>
        </div>
        <div className="content-setting-address">
          <Label className="label-title">{t("RateSetting.RefundFIL")}</Label>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Input
                value={valueRefundROY}
                onChange={(e) => {
                  this.setState({ valueRefundROY: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Label className="label-title mt-3">
            {t("RateSetting.SecretKey")}
          </Label>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Input
                value={
                  templeSecretKeyROY ? templeSecretKeyROY : decryptSecretKeyROY
                }
                onChange={this.onChangeSecretValueROY}
              />
            </Col>
          </Row>
          <Row className="margin-top-10px">
            <Col lg="12" md="12" sm="12" xs="12">
              <Button
                className="btn-confirm"
                onClick={this.onRefundROY}
                disabled={!secretValueROY || !valueRefundROY}
              >
                {t("Button.Confirm")}
              </Button>
            </Col>
          </Row>
          <Label className="label-title">{t("RateSetting.ReceivingFIL")}</Label>
          <Row>
            <Col lg="10" md="10" sm="10" xs="10">
              <Input
                value={valueReceivingROY}
                onChange={(e) => {
                  this.setState({ valueReceivingROY: e.target.value });
                }}
              />
            </Col>
            <Col lg="2" md="2" sm="2" xs="2">
              <Button
                className="btn-confirm"
                onClick={this.onReceivingROY}
                disabled={!valueReceivingROY}
              >
                {t("Button.Confirm")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderWalletGROY(
    valueRefundGROY,
    templeSecretKeyGROY,
    decryptSecretKeyGROY,
    secretValueGROY,
    valueReceivingGROY
  ) {
    const { t } = this.props;
    return (
      <div className="border-div col-11 mt-5">
        <div className="border-div-overlay-text">
          <span className="font-weight-bold">
            {COIN_TYPE.GROY} {` ${t("adil_network")}`}
          </span>
        </div>
        <div className="content-setting-address">
          <Label className="label-title">{t("RateSetting.RefundFIL")}</Label>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Input
                value={valueRefundGROY}
                onChange={(e) => {
                  this.setState({ valueRefundGROY: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Label className="label-title mt-3">
            {t("RateSetting.SecretKey")}
          </Label>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Input
                value={
                  templeSecretKeyGROY
                    ? templeSecretKeyGROY
                    : decryptSecretKeyGROY
                }
                onChange={this.onChangeSecretValueGROY}
              />
            </Col>
          </Row>
          <Row className="margin-top-10px">
            <Col lg="12" md="12" sm="12" xs="12">
              <Button
                className="btn-confirm"
                onClick={this.onRefundGROY}
                disabled={!secretValueGROY || !valueRefundGROY}
              >
                {t("Button.Confirm")}
              </Button>
            </Col>
          </Row>
          <Label className="label-title">{t("RateSetting.ReceivingFIL")}</Label>
          <Row>
            <Col lg="10" md="10" sm="10" xs="10">
              <Input
                value={valueReceivingGROY}
                onChange={(e) => {
                  this.setState({ valueReceivingGROY: e.target.value });
                }}
              />
            </Col>
            <Col lg="2" md="2" sm="2" xs="2">
              <Button
                className="btn-confirm"
                onClick={this.onReceivingGROY}
                disabled={!valueReceivingGROY}
              >
                {t("Button.Confirm")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props;

    const {
      isLoop,
      settingRate,
      settingExcelFinance,
      settingEzp,
      timeStart,
      timeEnd,
      maxGbt,
      setEzpFee,
      setExcelFee,
      setRate,
      setMaxTopUp,
      businessId,
      alertAck,
      titleRateLimit,
      changeOneTimeLimit,
      changeDailyLimit,
      changeMonthlyLimit,
      setRateLimit,
      changeYearlyLimit,
      valueRefundAGT,
      valueReceivingAGT,
      secretValue,
      isOpenModal,
      isRefundAGT,
      isReceivingAGT,
      settingTFA,
      isRefundFIL,
      isReceivingFIL,
      isRefundCCT,
      isReceivingCCT,
      valueRefundFIL,
      valueReceivingFIL,
      valueRefundCCT,
      settingRateFIL,
      templeSecretKeyFIL,
      secretValueFIL,
      isUpdateSettingFIL,
      isUpdateSettingCCT,
      valueReceivingCCT,
      templeSecretKeyCCT,
      secretValueCCT,
      settingRateCCT,
      isUpdateSetting,
      isFeeExcel,
      isFeeGlover,
      isSettingRateLimit,
      // templeSecretKey,

      isRefundROY,
      isReceivingROY,
      valueRefundROY,
      isUpdateSettingROY,
      valueReceivingROY,
      templeSecretKeyROY,
      secretValueROY,
      settingRateROY,
      isRefundGROY,
      isReceivingGROY,
      valueRefundGROY,
      isUpdateSettingGROY,
      valueReceivingGROY,
      templeSecretKeyGROY,
      secretValueGROY,
      settingRateGROY,
    } = this.state;

    // const decryptSecretKey = this.formatSecretKey(
    //   this.decryptWithIv(secretValue)
    // );
    // const decryptSecretKeyFIL = secretValueFIL
    //   ? this.formatSecretKey(this.decryptWithIv(secretValueFIL))
    //   : "";
    // const decryptSecretKeyCCT = secretValueCCT
    //   ? this.formatSecretKey(this.decryptWithIv(secretValueCCT))
    //   : "";
    const decryptSecretKeyROY = secretValueROY
      ? this.formatSecretKey(this.decryptWithIv(secretValueROY))
      : "";
    const decryptSecretKeyGROY = secretValueGROY
      ? this.formatSecretKey(this.decryptWithIv(secretValueGROY))
      : "";
    return (
      <div className="huv-style-setting-rate">
        <div className="title">
          <div className="title-content">{t("RateSetting.SettingRate")}</div>
        </div>
        <Container className="mb-5">
          {/* {this.renderSettingAGTRate(settingRate)} */}
          {/* {this.renderSettingGCCTRate(settingRateFIL)}
          {this.renderSettingCCTRate(settingRateCCT)} */}
          {this.renderSettingROYRate(settingRateROY)}
          {this.renderSettingGROYRate(settingRateGROY)}
          {this.renderSettingFee(settingExcelFinance, settingEzp)}
          {/* {this.renderHeaderSettingLimit()} */}
          {this.renderHeaderSettingLimit()}
          {this.renderSettingRateLimit()}
          {/* {this.renderWalletAGT(
            valueRefundAGT,
            templeSecretKey,
            decryptSecretKey,
            secretValue,
            valueReceivingAGT
          )} */}
          {/* {this.renderWalletGCCT(
            valueRefundFIL,
            templeSecretKeyFIL,
            decryptSecretKeyFIL,
            secretValueFIL,
            valueReceivingFIL
          )}
          {this.renderWalletCCT(
            valueRefundCCT,
            templeSecretKeyCCT,
            decryptSecretKeyCCT,
            secretValueCCT,
            valueReceivingCCT
          )} */}
          {this.renderWalletROY(
            valueRefundROY,
            templeSecretKeyROY,
            decryptSecretKeyROY,
            secretValueROY,
            valueReceivingROY
          )}
          {this.renderWalletGROY(
            valueRefundGROY,
            templeSecretKeyGROY,
            decryptSecretKeyGROY,
            secretValueGROY,
            valueReceivingGROY
          )}
        </Container>
        <ModalSettingRate
          t={t}
          isOpen={isOpenModal}
          isRefundAGT={isRefundAGT}
          isReceivingAGT={isReceivingAGT}
          isUpdateSetting={isUpdateSetting}
          isUpdateSettingFIL={isUpdateSettingFIL}
          isUpdateSettingCCT={isUpdateSettingCCT}
          valueRefundAGT={valueRefundAGT}
          valueReceivingAGT={valueReceivingAGT}
          isRefundFIL={isRefundFIL}
          isReceivingFIL={isReceivingFIL}
          isRefundCCT={isRefundCCT}
          isReceivingCCT={isReceivingCCT}
          valueRefundFIL={valueRefundFIL}
          valueRefundCCT={valueRefundCCT}
          valueReceivingFIL={valueReceivingFIL}
          valueReceivingCCT={valueReceivingCCT}
          secretValueFIL={secretValueFIL}
          secretValueCCT={secretValueCCT}
          secretValue={secretValue}
          close={this.closeModal}
          valueRateSetting={settingRate}
          valueRateSettingFIL={settingRateFIL}
          valueRateSettingCCT={settingRateCCT}
          isFeeExcel={isFeeExcel}
          settingExcelFinance={settingExcelFinance}
          isFeeGlover={isFeeGlover}
          settingEzp={settingEzp}
          businessId={businessId}
          isSettingRateLimit={isSettingRateLimit}
          title={titleRateLimit}
          oneTimeLimit={Number(changeOneTimeLimit)}
          dailyLimit={Number(changeDailyLimit)}
          monthlyLimit={Number(changeMonthlyLimit)}
          yearlyLimit={Number(changeYearlyLimit)}

          isRefundROY={isRefundROY}
          isReceivingROY={isReceivingROY}
          isUpdateSettingROY={isUpdateSettingROY}
          valueRefundROY={valueRefundROY}
          valueReceivingROY={valueReceivingROY}
          secretValueROY={secretValueROY}
          valueRateSettingROY={settingRateROY}
          isRefundGROY={isRefundGROY}
          isReceivingGROY={isReceivingGROY}
          isUpdateSettingGROY={isUpdateSettingGROY}
          valueRefundGROY={valueRefundGROY}
          valueReceivingGROY={valueReceivingGROY}
          secretValueGROY={secretValueGROY}
          valueRateSettingGROY={settingRateGROY}
        />
        {setRate && (
          <Alert
            title={t("Alert.ConfirmMessage")}
            content={t("RateSetting.SetRate")}
            okTitle={t("Alert.OK")}
            cancelTitle={t("Alert.Cancel")}
            onOK={() => {
              this.setState({ setRate: false });
              this.alertAck();
              // this.props.updateRateSetting({
              //     key: TYPE_SETTING.POM_RATE,
              //     value: this.state.settingRate,
              // });
              this.onHuvRateSetting();
            }}
            onCancel={() => {
              this.setState({ setRate: false });
            }}
          />
        )}
        {setMaxTopUp && (
          <Alert
            title={t("Alert.ConfirmMessage")}
            content={t("RateSetting.SetMaxGBTTopUp")}
            okTitle={t("Alert.OK")}
            cancelTitle={t("Alert.Cancel")}
            onOK={() => {
              this.setState({ setMaxTopUp: false });
              this.alertAck();
              this.props.updateMaxGbt({
                isLoop,
                timeStart: moment(timeStart).format("YYYY-MM-DD"),
                timeEnd: moment(timeEnd).format("YYYY-MM-DD"),
                maxGbt,
                businessId: businessId,
              });
            }}
            onCancel={() => {
              this.setState({ setMaxTopUp: false });
            }}
          />
        )}{" "}
        {setExcelFee && (
          <Alert
            title={t("Alert.ConfirmMessage")}
            content={t("RateSetting.SetExcelFinanceFee")}
            okTitle={t("Alert.OK")}
            cancelTitle={t("Alert.Cancel")}
            onOK={() => {
              this.setState({ setExcelFee: false });
              this.alertAck();
              // this.props.updateExcelFinanceSetting({
              //     key: TYPE_SETTING.POM_EXCEL_FINANCE_FEE,
              //     value: this.state.settingExcelFinance,
              // });
              this.onHuvFeeExcel();
            }}
            onCancel={() => {
              this.setState({ setExcelFee: false });
            }}
          />
        )}
        {setEzpFee && (
          <Alert
            title={t("Alert.ConfirmMessage")}
            content={t("setGloverFee")}
            okTitle={t("Alert.OK")}
            cancelTitle={t("Alert.Cancel")}
            onOK={() => {
              this.setState({ setEzpFee: false });
              this.alertAck();
              this.onHuvFeeGlover();
              // this.props.updateEzpSetting({
              //     key: TYPE_SETTING.POM_GLOVER_CARD_FEE,
              //     value: settingEzp,
              //     businessId: businessId,
              // });
            }}
            onCancel={() => {
              this.setState({ setEzpFee: false });
            }}
          />
        )}
        {[
          ACTIONS.POM_GET_RATE_SETTING_FAILED,
          ACTIONS.POM_GET_RATE_LIMIT_SETTING_FAILED,
          ACTIONS.POM_RECEIVING_AGT_FAILED,
          ACTIONS.POM_REFUND_AGT_FAILED,
          ACTIONS.POM_UPDATE_EXCEL_FINANCE_SETTING_FAILED,
          ACTIONS.POM_UPDATE_EZP_SETTING_FAILED,
          ACTIONS.POM_UPDATE_RATE_LIMIT_SETTING_FAILED,
          ACTIONS.POM_UPDATE_SETTING_RATE_FAILED,
        ].indexOf(this.props.settingRate) >= 0 &&
          !alertAck && (
            <Alert
              title={t("Alert.Error")}
              content={this.messageError(this.props.settingRateError.code)}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.props.getRateSetting({ businessId });
                this.props.getRateLimitSetting({ businessId });
                this.setState({ alertAck: true });
              }}
            />
          )}
        {/* {[
                        ACTIONS.RECEIVING_AGT_FAILED,
                        ACTIONS.REFUND_AGT_FAILED,
                    ].indexOf(this.props.settingRate) >= 0 &&
                    !alertAck && (
                        <Alert
                            title={t("Alert.Error")}
                            content={this.messageError(this.props.settingRateError.code)}
                            okTitle={t("Alert.OK")}
                            onOK={() => {
                                this.props.getRateSetting({ businessId });
                                this.props.getRateLimitSetting({ businessId });
                                this.setState({ alertAck: true });
                            }}
                        />
                    )} */}
        {[
          ACTIONS.POM_RECEIVING_AGT_SUCCESS,
          ACTIONS.POM_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS,
          ACTIONS.POM_UPDATE_EZP_SETTING_SUCCESS,
          ACTIONS.POM_REFUND_AGT_SUCCESS,
          ACTIONS.POM_UPDATE_RATE_LIMIT_SETTING_SUCCESS,
          ACTIONS.POM_UPDATE_SETTING_RATE_SUCCESS,
        ].indexOf(this.props.settingRate) >= 0 &&
          !alertAck && (
            <Alert
              title={t("Alert.Success")}
              content={t("RateSetting.SetSettingSuccess")}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.props.getRateSetting({ businessId });
                this.props.getRateLimitSetting({ businessId });
                this.setState({ alertAck: true });
              }}
            />
          )}
        {setRateLimit && (
          <Alert
            title={t("Alert.ConfirmMessage")}
            content={t("RateSetting.SettingRateLimit")}
            okTitle={t("Alert.OK")}
            cancelTitle={t("Alert.Cancel")}
            onOK={() => {
              this.alertAck();
              this.props.updateRateLimit({
                title: titleRateLimit,
                oneTimeLimit: Number(changeOneTimeLimit),
                dailyLimit: Number(changeDailyLimit),
                monthlyLimit: Number(changeMonthlyLimit),
                yearlyLimit: Number(changeYearlyLimit),
              });
              this.clearState();
            }}
            onCancel={() => {
              this.clearState();
            }}
          />
        )}
        {settingTFA && (
          <Alert
            title={t("Alert.Error")}
            content={t("Code.SETTING_TFA_PLEASE")}
            okTitle={t("Alert.OK")}
            onOK={() => {
              this.setState({ settingTFA: false });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    settingRate: store.settingRate.pomStatus,
    settings: store.settingRate.pomSettings,
    limitSettings: store.settingRate.pomLimitSettings,
    settingRateError: store.settingRate.settingRateError,
    infoUser: store.member.infoUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRateSetting: (data) => {
      dispatch(pomGetRateSetting(data));
    },
    getRateLimitSetting: (data) => {
      dispatch(pomGetRateLimitSetting(data));
    },
    updateMaxGbt: (data) => {
      dispatch(updateMaxGbt(data));
    },
    updateExcelFinanceSetting: (data) => {
      dispatch(pomUpdateExcelFinanceSetting(data));
    },
    updateEzpSetting: (data) => {
      dispatch(pomUpdateEzpSetting(data));
    },
    updateRateSetting: (data) => {
      dispatch(pomUpdateRateSetting(data));
    },
    updateRateLimit: (data) => {
      dispatch(pomUpdateRateLimit(data));
    },
    getInfoUser: () => {
      dispatch(getInfoUser());
    },
  };
};
export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(PomRateSetting)
);
