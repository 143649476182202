import { ACTIONS } from '../01-actions/user-manigo';

const initialState = {
    histories: [],
    pageHistories: null,
    users: [],
    error: null,
    pages: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.HUV_GET_HISTORIES_BALANCE_SUCCESS:
            return {
                ...state,
                status: action.type,
                histories: action.data.histories,
                pageHistories: action.data.pageHistories,
            }
            case ACTIONS.HUV_GET_SEARCH_USER_MANIGO_SUCCESS:
            case ACTIONS.HUV_GET_USER_MANIGO_SUCCESS:
                return {
                    ...state,
                    status: action.type,
                    users: action.data.users,
                    pages: action.data.pages,
                }
        case ACTIONS.HUV_GET_HISTORIES_BALANCE_FAIL:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
            };
        default:
            return state;
    }
}
