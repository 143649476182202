import React, {Component} from 'react';
import {Route, Switch, Redirect, withRouter} from 'react-router-dom';
import {renderToStaticMarkup} from 'react-dom/server';
import {withLocalize} from 'react-localize-redux';

import './App.css';

import HomePage from '../_pages/Home';
import AuthPage from '../_pages/Auth';
// import AdminPage from '../_pages/Admin';
import ErrorPage from '../_pages/Error';
import {compose} from "redux";
import {logout} from "../../01-actions/auth";
import connect from "react-redux/es/connect/connect";
import {getTokenErrors} from "../../07-selectors/getTokenErr";


class App extends Component {
    constructor(props) {
        super(props);

        this.props.initialize({
            languages: [
                {code: "en"},
                {code: "vi"},
            ],
            options: {renderToStaticMarkup}
        });
    }

    handleLogout() {
        this.props.logout();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.tokenErrors !== this.props.tokenErrors) {
            this.handleLogout();
        }

    }
   

    render() {
        return (
            <div className="app">
                <Switch>
                    <Route path="/auth" component={AuthPage}/>
                    {/*<Route path="/admin" component={AdminPage}/>*/}
                    <Route path="/error" component={ErrorPage}/>
                    <Route path="/" component={HomePage}/>
                    <Redirect to="/error/404"/>
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = (store, props) => {
    return {
        tokenErrors: getTokenErrors(store, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logout());
        }
    }
};
export default compose(withLocalize, withRouter, connect(mapStateToProps, mapDispatchToProps))(App);

