import {ACTIONS} from '../01-actions/permission';

const initialState = {
    status: null,
    role: [],
    permissionError: null,
};
export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_ROLE_PROGRESS:
        case ACTIONS.DELETE_ROLE_PROGRESS:
        case ACTIONS.CREATE_ROLE_PROGRESS:
        case ACTIONS.UPDATE_ROLE_PROGRESS:
            return {
                ...state,
                status: action.type
            };
        case ACTIONS.GET_ROLE_SUCCESS:
            return {
                ...state,
                status: action.type,
                role: action.data.role
            };
        case ACTIONS.CREATE_ROLE_SUCCESS:
            return {
                ...state,
                status: action.type,
                role: [...state.role, action.data.role]
            };
        case ACTIONS.DELETE_ROLE_SUCCESS:
            let roleIndex = [...state.role].findIndex(item => item._id === action.data.data._id);
            return {
                ...state,
                status: action.type,
                role: [
                    ...state.role.slice(0, roleIndex),
                    ...state.role.slice(roleIndex + 1)
                ]
            };
        case ACTIONS.UPDATE_ROLE_SUCCESS:
            let role = [...state.role].findIndex(item => item._id === action.data.data._id);
            return {
                ...state,
                status: action.type,
                role: [
                    ...state.role.slice(0, role),
                    Object.assign({}, state.role[role], action.data.data),
                    ...state.role.slice(role + 1)
                ]
            };

        case ACTIONS.GET_ROLE_FAILED:
            return {
                ...state,
                status: action.type,
                permissionError: action.data.error,
                role: []
            };
        case ACTIONS.DELETE_ROLE_FAILED:
        case ACTIONS.CREATE_ROLE_FAILED:
        case ACTIONS.UPDATE_ROLE_FAILED:
            return {
                ...state,
                status: action.type,
                permissionError: action.data.error
            };
        default:
            return state;
    }
}


