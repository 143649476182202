import {ACTIONS} from '../01-actions/member';

const initialState = {
    status: null,
    users: [],
    memberError: null,
    info: null,
    infoUser: null,
    secretInfo: null,
    isSettingGa: false
};
export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_USER_PROGRESS:
        case ACTIONS.DELETE_USER_PROGRESS:
        case ACTIONS.CREATE_USER_PROGRESS:
        case ACTIONS.GET_INFO_USER_PROGRESS:
        case ACTIONS.SETTING_GA_PROGRESS:
        case ACTIONS.RESET_GA_PROGRESS:
        case ACTIONS.VERIFY_CODE_QR_PROGRESS:
        case ACTIONS.CHANGE_PASSWORD_PROGRESS:
        case ACTIONS.CHANGE_TFA_LOGIN_PROGRESS:
        case ACTIONS.GET_INFO_PROGRESS:
            return {
                ...state,
                status: action.type
            };
        case ACTIONS.GET_USER_SUCCESS:
            return {
                ...state,
                status: action.type,
                users: action.data.users
            };
        case ACTIONS.GET_INFO_SUCCESS:
            return {
                ...state,
                status: action.type,
                info: action.data.info
            };
        case ACTIONS.CREATE_USER_SUCCESS:
            return {
                ...state,
                status: action.type,
                users: [...state.users, action.data.user]
            };
        case ACTIONS.DELETE_USER_SUCCESS:
            let userIndex = [...state.users].findIndex(item => item._id === action.data.data._id);
            return {
                ...state,
                status: action.type,
                users: [
                    ...state.users.slice(0, userIndex),
                    ...state.users.slice(userIndex + 1)
                ]
            };
        case ACTIONS.UPDATE_USER_SUCCESS:
            let user = [...state.users].findIndex(item => item._id === action.data.data._id);
            return {
                ...state,
                status: action.type,
                users: [
                    ...state.users.slice(0, user),
                    Object.assign({}, state.users[user], action.data.data),
                    ...state.users.slice(user + 1)
                ]
            };

        case ACTIONS.GET_USER_FAILED:
            return {
                ...state,
                status: action.type,
                memberError: action.data.error,
                users: []
            };
        case ACTIONS.DELETE_USER_FAILED:
        case ACTIONS.CREATE_USER_FAILED:
        case ACTIONS.UPDATE_USER_FAILED:
        case ACTIONS.GET_INFO_FAILED:
            return {
                ...state,
                status: action.type,
                memberError: action.data.error
            };
        case ACTIONS.GET_INFO_USER_SUCCESS:
            return {
                ...state,
                status: action.type,
                infoUser: action.data.infoUser
            };
        case ACTIONS.GET_INFO_USER_FAILED:
        case ACTIONS.SETTING_GA_SUCCESS:
            return {
                ...state,
                status: action.type,
                isSettingGa: true,
                secretInfo: action.data.data
            };
        case ACTIONS.SETTING_GA_FAILED:
            return {
                ...state,
                status: action.type,
                isSettingGa: false,
                memberError: action.data.error,
                secretInfo: []
            }
        case ACTIONS.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                status: action.type
            };
        case ACTIONS.CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                status: action.type,
                memberError: action.data.error,
            };
        case ACTIONS.VERIFY_CODE_QR_SUCCESS:
            return {
                ...state,
                status: action.type
            };
        case ACTIONS.VERIFY_CODE_QR_FAILED:
            return {
                ...state,
                status: action.type,
                memberError: action.data.error,
            };
        case ACTIONS.RESET_GA_SUCCESS:
            return {
                ...state,
                status: action.type
            };
        case ACTIONS.RESET_GA_FAILED:
            return {
                ...state,
                status: action.type,
                memberError: action.data.error,
            };
        case ACTIONS.CHANGE_TFA_LOGIN_SUCCESS:
            return {
                ...state,
                status: action.type
            };
        case ACTIONS.CHANGE_TFA_LOGIN_FAILED:
            return {
                ...state,
                status: action.type,
                memberError: action.data.error,
            };
        default:
            return state;
    }
}


