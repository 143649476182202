import React from "react";
import { connect } from "react-redux";
import { Input, Table, Row, Col } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { ACTIONS, getAllLog } from "../../../../01-actions/charge";
import Alert from "../../../../03-components/Alert";
import "./style.css";
import ReactPaginate from "react-paginate";
import { formatDateTimeFull } from "../../../../05-utils/commonUtils";
import ReactJson from "react-json-view";

class LogAction extends React.Component {
  constructor() {
    super();
    this.state = {
      logs: [],
      isShowModal: false,
      alertAck: true,
      action: "",
      activePage: 1
    };
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  componentDidMount() {
    this.alertAck();
    this.props.getAllLog({
      limit: 100,
      page: 1
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.logs !== this.props.logs) {
      this.setState({ logs: nextProps.logs });
    }
  }

  handlePageClick = data => {
    const page = data.selected + 1;
    this.setState({ activePage: page }, () => {
      this.alertAck();
      this.props.getAllLog({
        limit: 100,
        page: this.state.activePage,
        action: this.state.action
      });
    });
  };

  handleChange() {
    return e => {
      if (e.target) {
        this.setState({ action: e.target.value }, () => {
          this.props.getAllLog({
            action: this.state.action,
            limit: 100,
            page: 1
          });
        });
      }
    };
  }

  renderHeaderTable = () => {
    const { t } = this.props;
    return (
      <tr className="tb-header">
        <th className="custom-item">{t("ActionLog.Table.No")}</th>
        <th>{t("ActionLog.Table.AdminId")}</th>
        <th>{t("ActionLog.Table.Action")}</th>
        <th>{t("ActionLog.Table.Content")}</th>
        <th>{t("ActionLog.Table.Timestamps")}</th>
      </tr>
    );
  };

  renderContentTable() {
    const { t } = this.props;
    const { logs } = this.state;
    if (Array.isArray(logs) && logs.length > 0) {
      return logs.map((data, index) => {
        return (
          <tr key={index} className="tr-body-history">
            <td className="td-body">{index + 1}</td>
            <td className="td-body">{data.id}</td>
            <td className="td-body">{data.action}</td>
            <td>
              <ReactJson name={"params"} collapsed={true} src={data.param} />
            </td>
            <td className="td-body">{formatDateTimeFull(data.createdAt)}</td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={5}>
            <div className="d-flex justify-content-center">
              <span>{t("NoDataToShow")}</span>
            </div>
          </td>
        </tr>
      );
    }
  }

  renderTable() {
    const { logs } = this.state;
    if (Array.isArray(logs) && logs.length > 15) {
      return (
        <div className="table-container">
          <Table bordered striped>
          <thead className="thead-light">{this.renderHeaderTable()}</thead>
          <tbody>{this.renderContentTable()}</tbody>
          </Table>
        </div>
      );
    } else {
      return (
        <Table bordered responsive striped>
          <thead className="thead-light">{this.renderHeaderTable()}</thead>
          <tbody>{this.renderContentTable()}</tbody>
        </Table>
      );
    }
  }

  render() {
    const { actions, t } = this.props;
    return (
      <div className="log-ip-container">
        <div className="title">
          <div className="title-content">{t("ActionLog.HistoryAction")}</div>
        </div>
        <div className="log-ip-content">
          <Row className="mt-3 mb-3">
            <Col lg={{ size: 'auto' }} md={{ size: 'auto' }} sm={{ size: 'auto' }} xs="12" className="mt-1">
              <Input
                type="select"
                onChange={this.handleChange()}
                name="select"
                id="exampleSelect"
                className="selectGroup"
              >
                <option key={0} value="">
                  {t("ActionLog.All")}
                </option>
                {actions.length &&
                  actions.map((action, index) => (
                    <option key={index} value={action}>
                      {action}
                    </option>
                  ))}
              </Input>
            </Col>
          </Row>
          {this.renderTable()}
        </div>

        {[ACTIONS.GET_LOG_FAIL].indexOf(this.props.status) >= 0 &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Error")}
              content={this.props.error.code}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.setState({ alertAck: true });
              }}
            />
          )}
        {Array.isArray(this.state.logs) && this.state.logs.length > 0 && (
          <div className="pagination-center log-ip-content">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              // breakLabel={<a href="">...</a>}
              breakClassName={"break-me"}
              pageCount={this.props.pages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.charge.status,
    error: state.charge.error,
    logs: state.charge.logs,
    actions: state.charge.actions,
    pages: state.charge.pages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllLog: data => {
      dispatch(getAllLog(data));
    }
  };
};

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LogAction)
);
