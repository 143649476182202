import React, { Component } from "react";
import {
  // FormGroup,
  Input,
  Button,
  Table,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  ACTIONS,
  chargeSearch,
  getChargeRequest
} from "../../../../01-actions/charge";
import "./style.css";
import { STATUS_CHARGE } from "../../../../05-utils/commonData";
import { formatDateTimeFull } from "../../../../05-utils/commonUtils";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import Workbook from "react-excel-workbook";
import Alert from "../../../../03-components/Alert";
import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ApprovalHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 100,
      activePage: 1,
      charges: props.charges,
      tickCharges: [],
      startTime: null,
      endTime: null,
      filterBy: "",
      businessId: null,
      keyWord: "",
      checkDate: false,
      groupId: props.location.search.split("=")[
        props.location.search.split("=").length - 1
      ]
    };
    this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
    this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  componentDidMount() {
    this.alertAck();
    const { groupId } = this.state;
    this.props.handleGetChargeRequest({
      limit: this.state.limit,
      page: this.state.activePage,
      status: STATUS_CHARGE.APPROVE,
      businessId: groupId ? groupId : null
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.charges !== this.state.charges) {
      this.setState({
        charges: _.orderBy(
          nextProps.charges,
          ["changeStatusTimestamps"],
          ["asc"]
        )
      });
    }
  }

  handleChange = name => e => {
    this.setState(
      {
        [name]: e.target.value
      },
      () => {
        this.validateDate();
      }
    );
  };

  handleChangeStartTime(date) {
    this.setState({
      startTime: date
    });
  }

  handleChangeEndTime(date) {
    this.setState({
      endTime: date
    });
  }

  validateDate() {
    let checkData = moment(this.state.startTime) > moment(this.state.endTime);
    this.setState({ checkDate: checkData });
  }

  handleSearchData = () => {
    this.alertAck();
    const { filterBy } = this.state;
    let query = null;
    if (filterBy) {
      switch (filterBy) {
        case "Business Member Id":
          query = { businessMemberId: { $regex: this.state.keyWord } };
          break;
        case "GBT greater":
          query = { GBTAmount: { $gt: this.state.keyWord } };
          break;
        case "GBT greater or equal":
          query = { GBTAmount: { $gte: this.state.keyWord } };
          break;
        case "GBT smaller":
          query = { GBTAmount: { $lt: this.state.keyWord } };
          break;
        case "GBT smaller or equal":
          query = { GBTAmount: { $lte: this.state.keyWord } };
          break;
        case "Full Name":
          query = { fullname: { $regex: this.state.keyWord } };
          break;
        default:
          break;
      }
    } else query = "";
    this.props.chargeSearch({
      startTime: this.state.startTime,
      endTime: this.state.endTime,
      query: query,
      page: 1,
      status: STATUS_CHARGE.APPROVE,
      businessId: this.state.businessId
    });
  };
  handlePageClick = data => {
    const page = data.selected + 1;
    this.setState({ activePage: page }, () => {
      this.props.handleGetChargeRequest({
        limit: this.state.limit,
        page: this.state.activePage,
        status: STATUS_CHARGE.APPROVE
      });
    });
  };

  handleSelectGroupChange = value => {
    if (value !== "00") {
      this.props.history.push({
        pathname: "/approval-history",
        search: `?group=${value}`
      });
      this.setState({
        businessId: value
      });
      this.props.handleGetChargeRequest({
        limit: this.state.limit,
        page: this.state.activePage,
        status: STATUS_CHARGE.APPROVE,
        businessId: value
      });
    } else {
      this.props.history.push(`/approval-history`);
      this.setState({
        businessId: null
      });
      this.props.handleGetChargeRequest({
        limit: this.state.limit,
        page: this.state.activePage,
        status: STATUS_CHARGE.APPROVE
      });
    }
  };

  renderHeaderTable = () => {
    const { t } = this.props;
    return (
      <tr className="tb-header">
        <th className="custom-item">{t("HistoryPage.Table.No")}</th>
        <th>{t("HistoryPage.Table.BusinessMemberID")}</th>
        <th>{t("HistoryPage.Table.PassportNumber")}</th>
        <th>{t("HistoryPage.Table.FullName")}</th>
        <th>{t("HistoryPage.Table.GBTAmount")}</th>
        <th>{t("HistoryPage.Table.TopUpAmount")}</th>
        <th>{t("HistoryPage.Table.GBTExcelFinanceFee")}</th>
        <th>{t("HistoryPage.Table.GBTEzpFee")}</th>
        <th>{t("HistoryPage.Table.CreatedAt")}</th>
        <th>{t("HistoryPage.Table.TimesRequestInDay")}</th>
        <th>{t("HistoryPage.Table.TimesRequestInMonth")}</th>
        <th>{t("HistoryPage.Table.TimestampsApproval")}</th>
      </tr>
    );
  };

  renContentTable() {
    const { t } = this.props;
    if (Array.isArray(this.state.charges) && this.state.charges.length > 0) {
      return (
        <tbody>
          {this.state.charges.map((item, index) => {
            return (
              <tr key={index} className="tb-body">
                <td>{index + 1}</td>
                <td>{item.businessMemberId}</td>
                <td>{item.passportNumber}</td>
                <td>{item.fullname}</td>
                <td>{item.GBTAmount}</td>
                <td>{item.topUpAmount}</td>
                <td>{item.excelFeeGBT}</td>
                <td>{item.ezpFeeGBT}</td>
                <td>{formatDateTimeFull(item.timestamps)}</td>
                <td>{item.dayCharge}</td>
                <td>{item.monthCharge}</td>
                <td>{formatDateTimeFull(item.changeStatusTimestamps)}</td>
              </tr>
            );
          })}
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={12}>
              <div className="d-flex justify-content-center">
                <span>{t("NoDataToShow")}</span>
              </div>
            </td>
          </tr>
        </tbody>
      );
    }
  }

  renderTable() {
    if (Array.isArray(this.state.charges) && this.state.charges.length > 9) {
      return (
        <div className="table-container">
          <Table bordered striped>
            <thead className="thead-light">{this.renderHeaderTable()}</thead>
            {this.renContentTable()}
          </Table>
        </div>
      );
    } else {
      return (
        <Table bordered responsive striped>
          <thead className="thead-light">{this.renderHeaderTable()}</thead>
          {this.renContentTable()}
        </Table>
      );
    }
  }

  render() {
    const { t } = this.props;
    const { groupId } = this.state;
    return (
      <div className="approval-history-list">
        <div className="title">
          <div className="title-content">
            {t("HistoryPage.ListApproveCharge")}
          </div>
        </div>
        <div className="approval-history-list-content">
          <Row className="mt-3">
            <Col lg="3" md="3" sm="3" xs="12" className="mt-1">
              <Input
                className="selectGroup"
                type="select"
                name="selectGroup"
                id="group-select"
                defaultValue={groupId}
                onChange={event => {
                  this.handleSelectGroupChange(event.target.value);
                }}
              >
                <option value="00">{t("HistoryPage.GroupSelection")}</option>
                <option value="01">Greenbox</option>
                <option value="02">Triple</option>
                <option value="03">Huv Style</option>
              </Input>
            </Col>
          </Row>
          <Row>
            <Col lg="3" md="3" sm="3" xs="12" className="mt-1">
              <DatePicker
                className="form-control brs"
                placeholderText="YYYY-MM-DD"
                dateFormat="yyyy/MM/dd"
                selected={this.state.startTime}
                onChange={this.handleChangeStartTime}
              />
            </Col>
            <Col lg="3" md="3" sm="3" xs="12" className="mt-1">
              <DatePicker
                className="form-control brs"
                placeholderText="YYYY-MM-DD"
                dateFormat="yyyy/MM/dd"
                selected={this.state.endTime}
                onChange={this.handleChangeEndTime}
              />
            </Col>
          </Row>
          <Row>
            <Col lg="3" md="3" sm="3" xs="12" className="mt-1">
              <Input
                type="select"
                onChange={this.handleChange("filterBy")}
                name="select"
                id="exampleSelect"
                className="mr-2 selectGroup"
              >
                <option>Filter list</option>
                <option>Business Member Id</option>
                <option>GBT greater</option>
                <option>GBT greater or equal</option>
                <option>GBT smaller</option>
                <option>GBT smaller or equal</option>
                <option>Full Name</option>
              </Input>
            </Col>
            <Col lg="3" md="3" sm="3" xs="12" className="mt-1">
              <Input
                type="text"
                onChange={this.handleChange("keyWord")}
                name="text"
                id="search"
                className="brs"
                placeholder={t("HistoryPage.KeyWord")}
              ></Input>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg="3" md="3" sm="3" xs="12" className="mt-1">
              <Button
                outline
                color="secondary"
                className="custom-btn mr-4 mt-1"
                disabled={
                  this.props.status === ACTIONS.SEARCH_CHARGE_PROGRESS ||
                  !this.state.startTime ||
                  !this.state.endTime ||
                  this.state.checkDate
                }
                onClick={this.handleSearchData}
              >
                {this.props.status === ACTIONS.SEARCH_CHARGE_PROGRESS && (
                  <CircularProgress className="mr-2" size={20} />
                )}
                {t("Button.Search")}
              </Button>
              <Workbook
                filename="HistoryPage.xlsx"
                element={
                  <Button
                    outline
                    color="secondary"
                    className="custom-btn mt-1"
                    disabled={
                      this.props.status !== ACTIONS.SEARCH_CHARGE_SUCCESS ||
                      !Array.isArray(this.state.charges) ||
                      this.state.charges.length === 0
                    }
                  >
                    {t("Button.CSVExport")}
                  </Button>
                }
              >
                <Workbook.Sheet data={this.state.charges} name="Sheet A">
                  <Workbook.Column label="No." value="no" />
                  <Workbook.Column
                    label="Business Member Id"
                    value="businessMemberId"
                  />
                  <Workbook.Column
                    label="Passport Number"
                    value="passportNumber"
                  />
                  <Workbook.Column label="Full Name" value="fullname" />
                  <Workbook.Column label="GBT Amount" value="GBTAmount" />
                  <Workbook.Column label="Top Up Amount" value="topUpAmount" />
                  <Workbook.Column label="GBT Ezp Fee" value="ezpFeeGBT" />
                  <Workbook.Column
                    label="GBT Excel Finance Fee"
                    value="excelFeeGBT"
                  />
                  <Workbook.Column
                    label="Times request in Day"
                    value="dayCharge"
                  />
                  <Workbook.Column
                    label="Times request in Month"
                    value="monthCharge"
                  />
                  <Workbook.Column label="Created At" value="timestamps" />
                  <Workbook.Column
                    label="Change status charge at"
                    value="changeStatusTimestamps"
                  />
                </Workbook.Sheet>
              </Workbook>
            </Col>
          </Row>
          {this.renderTable()}
        </div>

        {Array.isArray(this.state.charges) && this.state.charges.length > 0 && (
          <div className="d-flex justify-content-between align-content-center align-items-center approval-history-list-content">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={<a href="">...</a>}
              breakClassName={"break-me"}
              pageCount={this.props.pages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        )}
        {[ACTIONS.GET_CHARGE_FAIL, ACTIONS.SEARCH_CHARGE_FAIL].indexOf(
          this.props.status
        ) >= 0 &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Error")}
              content={this.props.error.code}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.setState({ alertAck: true });
              }}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.charge.status,
    error: state.charge.error,
    charges: state.charge.charges,
    pages: state.charge.pages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleGetChargeRequest: data => {
      dispatch(getChargeRequest(data));
    },
    chargeSearch: data => {
      dispatch(chargeSearch(data, 1000));
    }
  };
};

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ApprovalHistory)
);
