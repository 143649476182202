import { PERMISSION } from "../05-utils/commonData";

export const routes = [
    {
        name: "Menu.Permissionsetting",
        link: "/permission-setting"
    },
    {
        name: "Menu.AdministratorSetting",
        link: "/administrator-setting"
    },
    {
        name: "Menu.TopUpPage",
        link: "/charged-request-list"
    },
    {
        name: "Menu.ReBalancePage",
        link: "/re-balance"
    },
    {
        name: "Menu.HistoryPage",
        link: "/approval-history"
    },
    {
        name: "Menu.UnconfirmedPage",
        link: "/list-reject"
    },
    {
        name: "Menu.RateSetting",
        link: "/rate-setting"
    },
    {
        name: "Menu.Statistic",
        link: "/statistic"
    },
    {
        name: "Menu.SecretKey",
        link: "/secret-key"
    },
    {
        name: "Menu.HistoryLogin",
        link: "/login-history"
    },
    {
        name: "Menu.ActionLog",
        link: "/log-history"
    },
    {
        name: "Menu.TFASetting",
        link: "/tfa-setting"
    },
    {
        name: "Menu.DepositToSystem",
        link: "/deposit-to-system"
    },
];

export const huvStyleRoutes = {
    topUpPage: {
        name: "Menu.TopUpPage",
        link: "/huv-style/charged-request-list",
        permissions: [PERMISSION.HUV_NOT_YET_VERIFIED, PERMISSION.HUV_CHECKING, PERMISSION.HUV_APPROVED, PERMISSION.HUV_REJECTING, PERMISSION.HUV_REJECTED, PERMISSION.HUV_ADMIN_REJECTED]
    },
    rateSetting: {
        name: "Menu.RateSetting",
        link: "/huv-style/rate-setting",
        permissions: [PERMISSION.HUV_SETTING]
    },
    // userManagement: {
    //     name: "Menu.UserManagement",
    //     link: "/huv-style/user-management",
    //     permissions: [PERMISSION.VIEW_LIST_USER]
    // },
    // depositToSystem:  {
    //     name: "Menu.DepositToSystem",
    //     link: "/huv-style/deposit-to-system",
    //     permissions: [PERMISSION.DEPOSIT_TO_HUV, PERMISSION.DEPOSIT_TO_TRIPLE]
    // }
};


export const pomRoutes = {
    topUpPage:{
        name: "Menu.TopUpPage",
        link: "/pom/charged-request-list",
        permissions: [PERMISSION.POM_NOT_YET_VERIFIED, PERMISSION.POM_CHECKING, PERMISSION.POM_APPROVED, PERMISSION.POM_REJECTING, PERMISSION.POM_REJECTED, PERMISSION.POM_ADMIN_REJECTED]
    },
    rateSetting: {
        name:"Menu.RateSetting",
        link:"/pom/rate-setting",
        permissions: [PERMISSION.POM_SETTING]
    }
}

export const pomoRoutes = {
    topUpPage:{
        name: "Menu.TopUpPage",
        link: "/pomo/charged-request-list",
        permissions: [PERMISSION.POMO_NOT_YET_VERIFIED, PERMISSION.POMO_CHECKING, PERMISSION.POMO_APPROVED, PERMISSION.POMO_REJECTING, PERMISSION.POMO_REJECTED, PERMISSION.POMO_ADMIN_REJECTED]
    },
    rateSetting: {
        name:"Menu.RateSetting",
        link:"/pomo/rate-setting",
        permissions: [PERMISSION.POMO_SETTING]
    }
}

export const mmpRoutes = {
    topUpPage:{
        name: "Menu.TopUpPage",
        link: "/mmp/charged-request-list",
        permissions: [PERMISSION.MMP_NOT_YET_VERIFIED, PERMISSION.MMP_CHECKING, PERMISSION.MMP_APPROVED, PERMISSION.MMP_REJECTING, PERMISSION.MMP_REJECTED, PERMISSION.MMP_ADMIN_REJECTED]
    },
    rateSetting: {
        name:"Menu.RateSetting",
        link:"/mmp/rate-setting",
        permissions: [PERMISSION.MMP_SETTING]
    }
}