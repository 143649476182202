import React, { Fragment } from "react";
import { withNamespaces } from "react-i18next";
import { Table, Button } from "reactstrap";
import CcCheckbox from "../../../../../03-components/ccCheckbox";
import {
  COIN_TYPE,
  MMP_TOP_UP_TAB,
  MANIGO_STATUS
} from "../../../../../05-utils/commonData";
import editIcon from "../../../../../06-assets/images/edit.png";
import momentTz from 'moment-timezone'
import ReactJson from "react-json-view";

const ChargeRequestListTabled = ({
  tab,
  charges,
  selected,
  setSelected,
  openEditMemo,
  t
}) => {
  const isLarge = Array.isArray(charges) && charges.length > 9;
  const isApproved = tab === MMP_TOP_UP_TAB.Approved.value;
  const isNotVerified = tab === MMP_TOP_UP_TAB.NotYetVerified.value;
  const isChecking = tab === MMP_TOP_UP_TAB.Checking.value;
  const isRejecting = tab === MMP_TOP_UP_TAB.Rejecting.value;
  const isRejected = tab === MMP_TOP_UP_TAB.Rejected.value;
  const isRejectedStep2 = tab === MMP_TOP_UP_TAB.UncheckedToRejected.value;

  const handleCheckboxAllChange = (e) => {
    let newSelected = [];
    if (e.target.checked) {
      newSelected = charges.map((item) => item._id);
    }
    setSelected(newSelected);
  };

  const handleCheckboxChange = (selectItem) => {
    const value = selectItem._id;
    let newSelected = selected;
    if (selected.includes(value)) {
      newSelected = newSelected.filter((item) => item !== value);
    } else {
      newSelected = [...newSelected, value];
    }
    setSelected(newSelected);
  };

  return (
    <div className="table-container">
      <Table responsive={!isLarge} striped size="sm">
        <thead className="thead-light">
          <tr className="tb-header">
            {(isNotVerified || isRejecting) && (
              <th className="check-box">
                <CcCheckbox
                  onChange={handleCheckboxAllChange}
                  checked={
                    selected.length === charges.length && selected.length !== 0
                  }
                  value={
                    selected.length === charges.length && selected.length !== 0
                  }
                />
              </th>
            )}
            <th className="custom-item">{t("HuvStyleTopUpPage.Table.No")}</th>
            <th>{t("HuvStyleTopUpPage.Table.WalletId")}</th>
            <th style={{ minWidth: 140 }}>{t("HuvStyleTopUpPage.Table.EmailAddress")}</th>
            <th>{t("HuvStyleTopUpPage.Table.FullName")}</th>
            <th className="fee">
              {t("HuvStyleTopUpPage.Table.ChargeAmount")}
            </th>
            <th className="fee">
              {t("HuvStyleTopUpPage.Table.UserPayedAmount")}
            </th>
            <th className="fee">
              {t("HuvStyleTopUpPage.Table.ExcelFinanceFee")}
            </th>
            <th className="fee">{t("HuvStyleTopUpPage.Table.ManagementFee")}</th>
            <th style={{ minWidth: 120 }}>{t("HuvStyleTopUpPage.Table.RateLog")}</th>
            <th style={{ minWidth: 150 }}>{t("HuvStyleTopUpPage.Table.RequestTime")}</th>
            {isChecking && (
              <th style={{ minWidth: 300 }}>
                {t("HuvStyleTopUpPage.Table.FailureReason")}
              </th>
            )}
            {isApproved && (
              <Fragment>
                <th style={{ minWidth: 150 }}>{t("HuvStyleTopUpPage.Table.AdmitTime")}</th>
                <th style={{ minWidth: 300 }}>{t("HuvStyleTopUpPage.Table.APILog")}</th>
              </Fragment>
            )}
            {isRejecting && <th>{t("HuvStyleTopUpPage.Table.Memo")}</th>}
            {isRejected && (
              <Fragment>
                <th style={{ minWidth: 150 }}>{t("HuvStyleTopUpPage.Table.RejectionTime")}</th>
                <th>{t("HuvStyleTopUpPage.Table.Memo")}</th>
              </Fragment>
            )}
            {isRejectedStep2 && (
              <>
               <th style={{ minWidth: 150 }}>{t("HuvStyleTopUpPage.Table.RejectionTime")}</th>
                <th>{t("HuvStyleTopUpPage.Table.Memo")}</th>
              </>
            )}
          </tr>
        </thead>
        {Array.isArray(charges) && charges.length > 0 ? (
          <tbody>
            {charges.map((item, index) => {
              return (
                <tr key={index} className="tb-body">
                  {(isNotVerified || isRejecting) && (
                    <td className="check-box">
                      <CcCheckbox
                        checked={selected.includes(item?._id)}
                        value={selected.includes(item?._id)}
                        className="check-box-item"
                        onChange={() => handleCheckboxChange(item)}
                      />
                    </td>
                  )}
                  <td>{index + 1}</td>
                  <td>{item?.walletId}</td>
                  <td>{item?.email}</td>
                  <td>{item?.fullname}</td>
                  <td>
                    {item?.topUpAmount} {item?.currency}
                  </td>
                  <td>
                    {item?.TCTAmount} <br />{" "}
                    {`(${
                      item?.coinType ? COIN_TYPE[item?.coinType] : COIN_TYPE.AGT
                    })`}
                  </td>
                  <td>
                    {item?.wcgFee} <br />{" "}
                    {`(${
                      item?.coinType ? COIN_TYPE[item?.coinType] : COIN_TYPE.AGT
                    })`}
                  </td>
                  <td>
                    {item?.cardFee} <br />{" "}
                    {`(${
                      item?.coinType ? COIN_TYPE[item?.coinType] : COIN_TYPE.AGT
                    })`}
                  </td>
                  <td>{`1 ${item?.currency} = ${item?.rate} ${item?.coinType ? COIN_TYPE[item?.coinType] : COIN_TYPE.AGT} `}</td>
                  <td>
                    {momentTz
                      .tz(item?.createdAt, "Asia/Tokyo")
                      .format("YYYY/MM/DD HH:mm:ss")}
                  </td>
                  {isChecking && (
                    <td className="left">
                      {item?.errorPomo &&
                        item?.pomo.status === MANIGO_STATUS.FAIL && (
                          <ReactJson
                            name={t("HuvStyleTopUpPage.Table.FailureReason")}
                            collapsed={true}
                            src={JSON.parse(item?.errorPomo)}
                          />
                        )}
                    </td>
                  )}
                  {isApproved && (
                    <Fragment>
                      <td>
                        {momentTz
                          .tz(
                            item?.timestampSuperAdminApproval ||
                              item?.timestampAdminApproval,
                            "Asia/Tokyo"
                          )
                          .format("YYYY/MM/DD HH:mm:ss")}
                      </td>
                      <td className="left" style={{ minWidth: 300 }}>
                        {<ReactJson
                             name={t("HuvStyleTopUpPage.Table.APILog")}
                              collapsed={true}
                              src={item?.pomo}
                            />}
                      </td>
                    </Fragment>
                  )}
                  {isRejecting && (
                    <td>
                      <div className="memo">
                        <div className="text">{item?.note}</div>
                        <Button
                          size="sm"
                          color="link"
                          onClick={() => openEditMemo(item)}
                        >
                          <img src={editIcon} alt="Edit" />
                        </Button>
                      </div>
                    </td>
                  )}
                  {isRejected && (
                    <Fragment>
                      <td>
                        {momentTz
                          .tz(item?.updatedAt, "Asia/Tokyo")
                          .format("YYYY/MM/DD HH:mm:ss")}
                      </td>
                      <td>
                        <div className="memo">
                          <div className="text">{item?.note}</div>
                          <Button
                            size="sm"
                            color="link"
                            onClick={() => openEditMemo(item)}
                          >
                            <img src={editIcon} alt="Edit" />
                          </Button>
                        </div>
                      </td>
                      {/* <td>{item?.isSentStatus}</td> */}
                    </Fragment>
                  )}
                  {isRejectedStep2 && (
                    <>
                      <td>
                        {momentTz
                          .tz(item?.updatedAt, "Asia/Tokyo")
                          .format("YYYY/MM/DD HH:mm:ss")}
                      </td>
                      <td>
                        <div className="memo">
                          <div className="text">{item?.note}</div>
                          <Button
                            size="sm"
                            color="link"
                            onClick={() => openEditMemo(item)}
                          >
                            <img src={editIcon} alt="Edit" />
                          </Button>
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              {/* <td className="custom-checkbox-no-border"></td> */}
              <td colSpan={14}>
                <div className="d-flex justify-content-center">
                  <span>{t("NoDataToShow")}</span>
                </div>
              </td>
            </tr>
          </tbody>
        )}
      </Table>
    </div>
  );
};

export default withNamespaces()(ChargeRequestListTabled);
