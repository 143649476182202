import React, { Component } from "react";
import Confirm from "../../../../../03-components/Confirm";
import { Button, Input, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import ggAuthentication from "../../../../../06-assets/images/ga-guide.png";
import { TYPE_SETTING } from "../../../../../05-utils/commonData";
import {
  ACTIONS,
  // huvUpdateExcelFinanceSetting,
  // huvUpdateEzpSetting,
  // huvUpdateRateLimit,
  // huvUpdateRateSetting,
  // refundAGT,
  //   receivingAGT,
  pomUpdateExcelFinanceSetting,
  pomUpdateEzpSetting,
  pomUpdateRateLimit,
  pomUpdateRateSetting,
  pomReceivingAGT,
  pomRefundAGT,
} from "../../../../../01-actions/settingRate";

class ModalSettingRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.status === ACTIONS.POM_RECEIVING_AGT_SUCCESS ||
      nextProps.status === ACTIONS.POM_REFUND_AGT_SUCCESS ||
      nextProps.status === ACTIONS.POM_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS ||
      nextProps.status === ACTIONS.POM_GET_RATE_SETTING_SUCCESS ||
      nextProps.status === ACTIONS.POM_UPDATE_EZP_SETTING_SUCCESS ||
      nextProps.status === ACTIONS.POM_UPDATE_RATE_LIMIT_SETTING_SUCCESS ||
      nextProps.status === ACTIONS.POM_UPDATE_SETTING_RATE_SUCCESS
    ) {
      this.setState({
        code: "",
      });
    }
  }

  onSubmit = () => {
    if (!this.state.code) return;
    const {
      isRefundAGT,
      isReceivingAGT,
      valueRefundAGT,
      valueReceivingAGT,
      isRefundFIL,
      isReceivingFIL,
      valueRefundFIL,
      valueReceivingFIL,
      secretValueFIL,
      isUpdateSettingFIL,
      valueRateSettingFIL,
      isRefundCCT,
      isReceivingCCT,
      valueRefundCCT,
      valueReceivingCCT,
      secretValueCCT,
      isUpdateSettingCCT,
      valueRateSettingCCT,
      secretValue,
      isUpdateSetting,
      valueRateSetting,
      isFeeExcel,
      settingExcelFinance,
      isFeeGlover,
      settingEzp,
      businessId,
      isSettingRateLimit,
      title,
      oneTimeLimit,
      dailyLimit,
      monthlyLimit,
      yearlyLimit,
      isRefundROY,
      isReceivingROY,
      isUpdateSettingROY,
      valueRefundROY,
      valueReceivingROY,
      secretValueROY,
      valueRateSettingROY,
      isRefundGROY,
      isReceivingGROY,
      isUpdateSettingGROY,
      valueRefundGROY,
      valueReceivingGROY,
      secretValueGROY,
      valueRateSettingGROY,
    } = this.props;

    if (isRefundROY) {
      this.props.refundAGT({
          walletAdress: {
              key: TYPE_SETTING.WALLET_ADDRESS_ROY,
              value: valueRefundROY
          },
          secretKey: {
              key: TYPE_SETTING.SECRET_KEY_ROY,
              value: secretValueROY
          },
          code: this.state.code
      });
    } 

    if (isRefundGROY) {
      this.props.refundAGT({
          walletAdress: {
              key: TYPE_SETTING.WALLET_ADDRESS_GROY,
              value: valueRefundGROY
          },
          secretKey: {
              key: TYPE_SETTING.SECRET_KEY_GROY,
              value: secretValueGROY
          },
          code: this.state.code
      });
    } 

    if (isReceivingROY) {
      this.props.receivingAGT({
          key: TYPE_SETTING.ADDRESS_USER_ROY,
          value: valueReceivingROY,
          code: this.state.code
      });
    }

    if (isReceivingGROY) {
      this.props.receivingAGT({
          key: TYPE_SETTING.ADDRESS_USER_GROY,
          value: valueReceivingGROY,
          code: this.state.code
      });
    }

    if (isUpdateSettingROY) {
      this.props.updateRateSetting({
          key: TYPE_SETTING.ROY_RATE,
          value: valueRateSettingROY,
          code: this.state.code
      });
    }

    if (isUpdateSettingGROY) {
      this.props.updateRateSetting({
          key: TYPE_SETTING.GROY_RATE,
          value: valueRateSettingGROY,
          code: this.state.code
      });
    }

    if (isRefundAGT) {
      this.props.refundAGT({
        walletAdress: {
          key: TYPE_SETTING.WALLET_ADDRESS_AGT,
          value: valueRefundAGT,
        },
        secretKey: {
          key: TYPE_SETTING.SECRET_KEY_AGT,
          value: secretValue,
        },
        code: this.state.code,
      });
    }

    if (isReceivingAGT) {
      this.props.receivingAGT({
        key: TYPE_SETTING.ADDRESS_USER_AGT,
        value: valueReceivingAGT,
        code: this.state.code,
      });
    }

    if (isRefundFIL) {
      this.props.refundAGT({
          walletAdress: {
              key: TYPE_SETTING.WALLET_ADDRESS_GCCT,
              value: valueRefundFIL
          },
          secretKey: {
              key: TYPE_SETTING.SECRET_KEY_GCCT,
              value: secretValueFIL
          },
          code: this.state.code
      });
    }

    if (isReceivingFIL) {
      this.props.receivingAGT({
          key: TYPE_SETTING.ADDRESS_USER_GCCT,
          value: valueReceivingFIL,
          code: this.state.code
      });
    }

    if (isRefundCCT) {
      this.props.refundAGT({
        walletAdress: {
          key: TYPE_SETTING.WALLET_ADDRESS_CCT,
          value: valueRefundCCT,
        },
        secretKey: {
          key: TYPE_SETTING.SECRET_KEY_CCT,
          value: secretValueCCT,
        },
        code: this.state.code,
      });
    }

    if (isReceivingCCT) {
      this.props.receivingAGT({
        key: TYPE_SETTING.ADDRESS_USER_CCT,
        value: valueReceivingCCT,
        code: this.state.code,
      });
    }

    if (isUpdateSetting) {
      this.props.updateRateSetting({
        key: TYPE_SETTING.POM_RATE,
        value: valueRateSetting,
        code: this.state.code,
      });
    }

    if (isUpdateSettingFIL) {
      this.props.updateRateSetting({
        key: TYPE_SETTING.FIL_RATE,
        value: valueRateSettingFIL,
        code: this.state.code,
      });
    }

    if (isUpdateSettingCCT) {
      this.props.updateRateSetting({
        key: TYPE_SETTING.CCT_RATE,
        value: valueRateSettingCCT,
        code: this.state.code,
      });
    }

    if (isFeeExcel) {
      this.props.updateExcelFinanceSetting({
        key: TYPE_SETTING.POM_EXCEL_FINANCE_FEE,
        value: settingExcelFinance,
        code: this.state.code,
      });
    }

    if (isFeeGlover) {
      this.props.updateEzpSetting({
        key: TYPE_SETTING.POM_GLOVER_CARD_FEE,
        value: settingEzp,
        businessId: businessId,
        code: this.state.code,
      });
    }

    if (isSettingRateLimit) {
      this.props.updateRateLimit({
        title: title,
        oneTimeLimit: oneTimeLimit,
        dailyLimit: dailyLimit,
        monthlyLimit: monthlyLimit,
        yearlyLimit: yearlyLimit,
        code: this.state.code,
      });
    }
  };

  onchangeTfaCode = (filed) => {
    return (e) => {
      this.setState({
        [filed]: e.target.value,
      });
    };
  };

  onClose = () => {
    const { close } = this.props;
    this.setState({
      code: "",
    });
    close();
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Confirm
          isOpen={this.props.isOpen}
          toggle={this.onClose}
          destroyOnClose
          centered
          maskClosable={false}
          title={t("Two-step_verification")}
          className="modal-confirm-tfa-login"
        >
          <FormGroup>
            <div className="text-center">
              <img className="w-100" src={ggAuthentication} alt="" />
            </div>
          </FormGroup>
          <FormGroup className="text-center" name="code">
            <div>
              <Input
                type="text"
                className="text-center tfa-code"
                value={this.state.code}
                // disabled={submitting}
                placeholder={t("Two-step_verification")}
                onChange={this.onchangeTfaCode("code")}
              />
            </div>
          </FormGroup>
          <FormGroup className="mb-0 text-center box-action">
            <Button
              className="btn-tfa-login"
              type="primary"
              onClick={this.onSubmit}
              disabled={!this.state.code}
            >
              {t("TFASetting.Submit")}
            </Button>
          </FormGroup>
        </Confirm>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    status: store.settingRate.pomStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    refundAGT: (data) => {
      dispatch(pomRefundAGT(data));
    },
    receivingAGT: (data) => {
      dispatch(pomReceivingAGT(data));
    },
    updateRateSetting: (data) => {
      dispatch(pomUpdateRateSetting(data));
    },
    updateExcelFinanceSetting: (data) => {
      dispatch(pomUpdateExcelFinanceSetting(data));
    },
    updateEzpSetting: (data) => {
      dispatch(pomUpdateEzpSetting(data));
    },
    updateRateLimit: (data) => {
      dispatch(pomUpdateRateLimit(data));
    },
  };
};

export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(ModalSettingRate)
);
