import { useState } from 'react'

const useModal = (init = null) => {
  const [item, setItem] = useState(init)

  const onClose = () => setItem(null)
  const onOpen = (value) => setItem(value)
  const onToggle = () => setItem(!item)

  return [item, onClose, onOpen, onToggle]
}

const useModalToggle = (init = false) => {
  const [item, setItem] = useState(init)

  const onToggle = () => setItem(!item)

  return [item, onToggle]
}

export {
  useModal,
  useModalToggle
}
