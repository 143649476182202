import { callApi } from "../05-utils/commonUtils";
import queryString from "query-string";

const ACTIONS = {
  POMO_CLEAR_STATUS: "POMO_CLEAR_STATUS",
  POMO_GET_CHARGE_PROGRESS: "POMO_GET_CHARGE_PROGRESS",
  POMO_GET_CHARGE_SUCCESS: "POMO_GET_CHARGE_SUCCESS",
  POMO_GET_CHARGE_FAIL: "POMO_GET_CHARGE_FAIL",
  POMO_SEARCH_CHARGE_PROGRESS: "POMO_SEARCH_CHARGE_PROGRESS",
  POMO_SEARCH_CHARGE_SUCCESS: "POMO_SEARCH_CHARGE_SUCCESS",
  POMO_SEARCH_CHARGE_FAIL: "POMO_SEARCH_CHARGE_FAIL",
  POMO_GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS:
    "POMO_GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS",
  POMO_GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS:
    "POMO_GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS",
  POMO_GET_STATISTIC_CHARGE_BY_STATUS_FAIL:
    "POMO_GET_STATISTIC_CHARGE_BY_STATUS_FAIL",
  POMO_CHANGE_STATUS_TOP_UP_PROCESS: "POMO_CHANGE_STATUS_TOP_UP_PROCESS",
  POMO_CHANGE_STATUS_TOP_UP_SUCCESS: "POMO_CHANGE_STATUS_TOP_UP_SUCCESS",
  POMO_CHANGE_STATUS_TOP_UP_FAIL: "POMO_CHANGE_STATUS_TOP_UP_FAIL",
  POMO_CHANGE_NOTE_PROCESS: "POMO_CHANGE_NOTE_PROCESS",
  POMO_CHANGE_NOTE_SUCCESS: "POMO_CHANGE_NOTE_SUCCESS",
  POMO_CHANGE_NOTE_FAIL: "POMO_CHANGE_NOTE_FAIL",
  POMO_PUSH_NOTIFICATION_PROCESS: "POMO_PUSH_NOTIFICATION_PROCESS",
  POMO_PUSH_NOTIFICATION_SUCCESS: "POMO_PUSH_NOTIFICATION_SUCCESS",
  POMO_PUSH_NOTIFICATION_FAIL: "POMO_PUSH_NOTIFICATION_FAIL",
  POMO_GET_CURRENCY_BALANCE_PROGRESS: "POMO_GET_CURRENCY_BALANCE_PROGRESS",
  POMO_GET_CURRENCY_BALANCE_SUCCESS: "POMO_GET_CURRENCY_BALANCE_SUCCESS",
  POMO_GET_CURRENCY_BALANCE_FAIL: "POMO_GET_CURRENCY_BALANCE_FAIL",
};

const PomoUpdateStatus = (status, statusInfo) => {
  return {
    type: status,
    data: statusInfo,
  };
};

const PomoCurrencyBalance = () => {
  let config = {
    method: "get",
    isAuthorization: true,
  };
  return (dispatch) => {
    dispatch(PomoUpdateStatus(ACTIONS.POMO_GET_CURRENCY_BALANCE_PROGRESS));
    return callApi(
      `/api/pomo-pomo/balance_admin`,
      config,
      null,
      (result) => {
        dispatch(
          PomoUpdateStatus(ACTIONS.POMO_GET_CURRENCY_BALANCE_SUCCESS, {
            currencyBalance: result,
          })
        );
      },
      (err) => {
        dispatch(
          PomoUpdateStatus(ACTIONS.POMO_GET_CURRENCY_BALANCE_FAIL, {
            error: err,
          })
        );
      }
    );
  };
};

const PomoPushNotification = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(PomoUpdateStatus(ACTIONS.POMO_PUSH_NOTIFICATION_PROCESS));
    return callApi(
      `/api/pomo-pomo/push_notification`,
      config,
      null,
      (result) => {
        dispatch(
          PomoUpdateStatus(ACTIONS.POMO_PUSH_NOTIFICATION_SUCCESS, {
            note: result.data,
          })
        );
      },
      (err) => {
        dispatch(
          PomoUpdateStatus(ACTIONS.POMO_PUSH_NOTIFICATION_FAIL, { error: err })
        );
      }
    );
  };
};

const PomoChangeNote = (data, callback) => {
  let config = {
    method: "put",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(PomoUpdateStatus(ACTIONS.POMO_CHANGE_NOTE_PROCESS));
    return callApi(
      `/api/pomo-pomo/note_charge`,
      config,
      null,
      (result) => {
        dispatch(
          PomoUpdateStatus(ACTIONS.POMO_CHANGE_NOTE_SUCCESS, {
            note: result.data,
          })
        );
        callback(ACTIONS.POMO_CHANGE_NOTE_SUCCESS, result);
      },
      (err) => {
        dispatch(
          PomoUpdateStatus(ACTIONS.POMO_CHANGE_NOTE_FAIL, { error: err })
        );
        callback(ACTIONS.POMO_CHANGE_NOTE_FAIL, err);
      }
    );
  };
};

const PomoChangeStatus = (data, callback) => {
  let config = {
    method: "put",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(PomoUpdateStatus(ACTIONS.POMO_CHANGE_STATUS_TOP_UP_PROCESS));
    return callApi(
      `/api/pomo-pomo/change_status_top_up`,
      config,
      null,
      (result) => {
        dispatch(
          PomoUpdateStatus(ACTIONS.POMO_CHANGE_STATUS_TOP_UP_SUCCESS, {
            note: result.data,
          })
        );
        callback(ACTIONS.POMO_CHANGE_STATUS_TOP_UP_SUCCESS, result);
      },
      (err) => {
        dispatch(
          PomoUpdateStatus(ACTIONS.POMO_CHANGE_STATUS_TOP_UP_FAIL, {
            error: err,
          })
        );
        callback(ACTIONS.POMO_CHANGE_STATUS_TOP_UP_FAIL, err);
      }
    );
  };
};

const PomoGetStatisticChargeByStatus = (data) => {
  let config = {
    method: "get",
    isAuthorization: true,
  };
  return (dispatch) => {
    dispatch(
      PomoUpdateStatus(ACTIONS.POMO_GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS)
    );
    return callApi(
      `/api/pomo-pomo/statistic_charge_status`,
      config,
      null,
      (result) => {
        dispatch(
          PomoUpdateStatus(
            ACTIONS.POMO_GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS,
            {
              statisticChargeByStatus: result.data,
            }
          )
        );
      },
      (err) => {
        dispatch(
          PomoUpdateStatus(ACTIONS.POMO_GET_STATISTIC_CHARGE_BY_STATUS_FAIL, {
            error: err,
          })
        );
      }
    );
  };
};

const PomoChargeSearch = (data, limit = 100) => {
  let config = {
    method: "get",
    isAuthorization: true,
  };

  const params = queryString.stringify({
    page: data.page,
    limit: data.limit || limit,
    startTime: data.startTime,
    endTime: data.endTime,
    search: data.search,
    status: data.status,
  });

  return (dispatch) => {
    dispatch(PomoUpdateStatus(ACTIONS.POMO_SEARCH_CHARGE_PROGRESS));
    callApi(
      `/api/pomo-pomo/charges?${params}`,
      config,
      null,
      (result) => {
        dispatch(
          PomoUpdateStatus(ACTIONS.POMO_SEARCH_CHARGE_SUCCESS, {
            charges: result.charges,
            pages: result.pages,
            totalTCT: result.totalTCT,
            totalUSD: result.totalUSD,
            totalCCT: result.totalCCT,
            totalROY: result.totalROY,
          })
        );
      },
      (err) => {
        dispatch(
          PomoUpdateStatus(ACTIONS.POMO_SEARCH_CHARGE_FAIL, { error: err })
        );
      }
    );
  };
};

const PomoGetChargeRequest = (data) => {
  let config = {
    method: "get",
    isAuthorization: true,
  };

  return (dispatch) => {
    dispatch(PomoUpdateStatus(ACTIONS.POMO_GET_CHARGE_PROGRESS));
    callApi(
      `/api/pomo-pomo/charges?${queryString.stringify(data)}`,
      config,
      null,
      (result) => {
        dispatch(
          PomoUpdateStatus(ACTIONS.POMO_GET_CHARGE_SUCCESS, {
            charges: result.charges,
            pages: result.pages,
            totalTCT: result.totalTCT,
            totalUSD: result.totalUSD,
            totalCCT: result.totalCCT,
            totalROY: result.totalROY,
          })
        );
      },
      (err) => {
        dispatch(
          PomoUpdateStatus(ACTIONS.POMO_GET_CHARGE_FAIL, { error: err })
        );
      }
    );
  };
};

const PomoClearStatus = () => {
  return (dispatch) => {
    dispatch(PomoUpdateStatus(ACTIONS.POMO_CLEAR_STATUS));
  };
};

export {
  ACTIONS,
  PomoPushNotification,
  PomoChangeNote,
  PomoChangeStatus,
  PomoGetStatisticChargeByStatus,
  PomoChargeSearch,
  PomoGetChargeRequest,
  PomoClearStatus,
  PomoCurrencyBalance
};
