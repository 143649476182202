import {callApi} from "../05-utils/commonUtils";

const ACTIONS = {
    GET_ROLE_PROGRESS: "GET_ROLE_PROGRESS",
    GET_ROLE_SUCCESS: "GET_ROLE_SUCCESS",
    GET_ROLE_FAILED: "GET_ROLE_FAILED",
    DELETE_ROLE_PROGRESS: "DELETE_ROLE_PROGRESS",
    DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
    DELETE_ROLE_FAILED: "DELETE_ROLE_FAILED",
    CREATE_ROLE_PROGRESS: "CREATE_ROLE_PROGRESS",
    CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
    CREATE_ROLE_FAILED: "CREATE_ROLE_FAILED",
    UPDATE_ROLE_PROGRESS: "UPDATE_ROLE_PROGRESS",
    UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
    UPDATE_ROLE_FAILED: "UPDATE_ROLE_FAILED",
};

const updateStatus = (status, statusInfo) => {
    return {
        type: status,
        data: statusInfo
    }
};
const getRoles = () => {
    let config = {
        method: 'get',
        isAuthorization: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_ROLE_PROGRESS));
        callApi('/api/get_all_role',
            config,
            null,
            (data) => {
                dispatch(updateStatus(ACTIONS.GET_ROLE_SUCCESS, {role: data}));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.GET_ROLE_FAILED, {error: err}));
            }
        );
    };
};

const deleteRole = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.DELETE_ROLE_PROGRESS));
        callApi('/api/delete_role',
            config,
            null,
            () => {
                dispatch(updateStatus(ACTIONS.DELETE_ROLE_SUCCESS, {data}));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.DELETE_ROLE_FAILED, {error: err}));
            }
        );
    };
};

const createRole = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.CREATE_ROLE_PROGRESS));
        callApi('/api/create_role',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.CREATE_ROLE_SUCCESS, {role: result}));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.CREATE_ROLE_FAILED, {error: err}));
            }
        );
    };
};

const updatePermission = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.UPDATE_ROLE_PROGRESS));
        callApi('/api/edit_role',
            config,
            null,
            () => {
                dispatch(updateStatus(ACTIONS.UPDATE_ROLE_SUCCESS, {data}));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.UPDATE_ROLE_FAILED, {error: err}));
            }
        );
    };
};

export {ACTIONS, getRoles, deleteRole, createRole, updatePermission};
