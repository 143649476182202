import React, { PureComponent } from "react";
import {
  Label,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Container,
  Row,
  Col
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import {
  ACTIONS,
  getRateSetting,
  getRateLimitSetting,
  updateMaxGbt,
  updateExcelFinanceSetting,
  updateEzpSetting,
  updateRateSetting,
  updateRateLimit
} from "../../../../01-actions/settingRate";
import { TYPE_SETTING } from "../../../../05-utils/commonData";
import { connect } from "react-redux";
import "./style.css";
import Alert from "../../../../03-components/Alert";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

class RateSetting extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      settingRate: "",
      settingExcelFinance: "",
      settingEzp: "",
      alertAck: true,
      setRate: false,
      setEzpFee: false,
      setMaxTopUp: false,
      setExcelFee: false,
      setRateLimit: false,
      maxGbt: 0,
      isLoop: true,
      timeStart: "",
      timeEnd: "",
      idRateLimit: null,
      titleRateLimit: "",
      changeOneTimeLimit: "",
      changeDailyLimit: "",
      changeMonthlyLimit: "",
      limitSettings: [],
      checkData: false,
      businessId: "01",
      groupId: props.location.search.split("=")[props.location.search.split("=").length - 1]
    };
    this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
    this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
    this.renderSettingRateLimit = this.renderSettingRateLimit.bind(this);
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  clearState = () => {
    this.setState({
      setRateLimit: false,
      idRateLimit: null,
      titleRateLimit: "",
      changeOneTimeLimit: "",
      changeDailyLimit: "",
      changeMonthlyLimit: ""
    });
  };

  validateDate() {
    const checkData =
      moment(this.state.timeStart).format("YYYY-MM-DD") >
      moment(this.state.timeEnd).format("YYYY-MM-DD");
    this.setState({ checkDate: checkData });
  }

  componentDidMount() {
    this.alertAck();
    this.props.getRateSetting({
      businessId: this.state.businessId
    });
    this.props.getRateLimitSetting({ businessId: this.state.businessId });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.settings !== this.props.settings) {
      this.setState({
        settingRate: nextProps.settings.find(
          val => val.key === TYPE_SETTING.RATE
        )?.value,
        settingExcelFinance: nextProps.settings.find(
          val => val.key === TYPE_SETTING.EXCEL_FEE
        )?.value,
        settingEzp: nextProps.settings.find(
          val => val.key === TYPE_SETTING.EZP_FEE
        )?.value,
        maxGbt: nextProps.settings.find(
          val => val.key === TYPE_SETTING.MAX_GBT_CHARGE
        )?.value,
        timeStart: nextProps.settings.find(
          val => val.key === TYPE_SETTING.TIME_START
        )?.value,
        timeEnd: nextProps.settings.find(
          val => val.key === TYPE_SETTING.TIME_END
        )?.value,
        isLoop:
          nextProps.settings.find(val => val.key === TYPE_SETTING.TIME_SET_LOOP)
            ?.value !== "0"
      });
    }
    if (nextProps.limitSettings !== this.props.limitSettings){
      this.setState({
        limitSettings: nextProps.limitSettings
      })
    }
  }

  handleChangeStartTime(date) {
    this.setState(
      {
        timeStart: date
      },
      () => {
        this.validateDate();
      }
    );
  }

  handleChangeEndTime(date) {
    this.setState(
      {
        timeEnd: date
      },
      () => {
        this.validateDate();
      }
    );
  }

  // handleUpdateRateSetting = () => {
  //     this.alertAck();
  //     const {settingRate} = this.state;
  //     this.props.updateRateSetting({
  //         key: TYPE_SETTING.RATE,
  //         value: settingRate
  //     });
  // };

  handleSelectGroupChange = value => {
    if (value === "01" || value === "02") {
      this.props.history.push({
        pathname: "/rate-setting",
        search: `?group=${value}`
      });
      this.setState({
        businessId: value,
        groupId: value
      });
      this.props.getRateSetting({
        businessId: value
      });
      if (value === "01") {
        this.props.getRateLimitSetting({ businessId: value });
      }
    }
    else {
      this.props.history.push(`/rate-setting`);
      this.setState({
        businessId: "01",
        groupId: null
      });
      this.props.getRateSetting({
        businessId: "01"
      });
      this.props.getRateLimitSetting({ businessId: "01" });
    }
  };

  renderSettingRateLimit(){
    const {
      businessId,
      idRateLimit,
      changeOneTimeLimit,
      changeDailyLimit,
      changeMonthlyLimit,
      titleRateLimit,
      limitSettings
    } = this.state;
    const { t } = this.props;
    return(
        limitSettings && limitSettings.map((s,index)=>{
        return  <Row key={index}>
                  <Col lg="2" md="2" sm="12" xs="12" className="mt-1">
                    <Input
                        className="input-label-style"
                        disabled={true}
                        value={s.name}
                    />
                  </Col>
                  <Col lg="3" md="3" sm="12" xs="12" className="mt-1">
                    <InputGroup>
                      <Input
                          className="input-style"
                          type="number"
                          value={changeOneTimeLimit && s._id === idRateLimit ? changeOneTimeLimit :s.oneTimeLimit}
                          disabled={
                            this.props.settingRate === ACTIONS.UPDATE_RATE_LIMIT_SETTING_PROGRESS ||
                            businessId === "02"
                          }
                          onChange={e => {
                            if (s._id === idRateLimit){
                              this.setState({
                                idRateLimit: s._id,
                                titleRateLimit: s.title,
                                changeOneTimeLimit: e.target.value
                              });
                            }else {
                              this.setState({
                                idRateLimit: s._id,
                                titleRateLimit: s.title,
                                changeOneTimeLimit: e.target.value,
                                changeDailyLimit: "",
                                changeMonthlyLimit: ""
                              });
                            }
                          }}
                          min="0"
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="brs">CCT</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col lg="3" md="3" sm="12" xs="12" className="mt-1">
                    <InputGroup>
                      <Input
                          className="input-style"
                          type="number"
                          value={changeDailyLimit && s._id === idRateLimit ? changeDailyLimit :s.dailyLimit}
                          disabled={
                            this.props.settingRate === ACTIONS.UPDATE_RATE_LIMIT_SETTING_PROGRESS ||
                            businessId === "02"
                          }
                          onChange={e => {
                            if (s._id === idRateLimit){
                              this.setState({
                                idRateLimit: s._id,
                                titleRateLimit: s.title,
                                changeDailyLimit: e.target.value
                              });
                            }else {
                              this.setState({
                                idRateLimit: s._id,
                                titleRateLimit: s.title,
                                changeOneTimeLimit: "",
                                changeDailyLimit: e.target.value,
                                changeMonthlyLimit: ""
                              });
                            }
                          }}
                          min="0"
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="brs">CCT</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col lg="3" md="3" sm="12" xs="12" className="mt-1">
                    <InputGroup>
                      <Input
                          className="input-style"
                          type="number"
                          value={changeMonthlyLimit && s._id === idRateLimit ? changeMonthlyLimit : s.monthlyLimit}
                          disabled={
                            this.props.settingRate === ACTIONS.UPDATE_RATE_LIMIT_SETTING_PROGRESS ||
                            businessId === "02"
                          }
                          onChange={e => {
                            if (s._id === idRateLimit){
                              this.setState({
                                idRateLimit: s._id,
                                titleRateLimit: s.title,
                                changeMonthlyLimit: e.target.value
                              });
                            }else {
                              this.setState({
                                idRateLimit: s._id,
                                titleRateLimit: s.title,
                                changeOneTimeLimit:"",
                                changeDailyLimit:"",
                                changeMonthlyLimit: e.target.value
                              });
                            }

                          }}
                          min="0"
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="brs">CCT</InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                  <Col lg="1" md="1" sm="12" xs="12" className="mt-1">
                    <Button
                        variant="contained"
                        disabled={
                          this.props.settingRate === ACTIONS.UPDATE_RATE_LIMIT_SETTING_PROGRESS ||
                          businessId === "02"
                        }
                        className="submit-btn brs"
                        onClick={() => {
                          !changeOneTimeLimit && this.setState({ changeOneTimeLimit: s.oneTimeLimit });
                          !changeDailyLimit && this.setState({ changeDailyLimit: s.dailyLimit });
                          !changeMonthlyLimit && this.setState({ changeMonthlyLimit: s.monthlyLimit });
                          !titleRateLimit && this.setState({ titleRateLimit: s.title });
                          this.setState({ setRateLimit: true });
                        }}
                    >
                      {this.props.settingRate ===
                      ACTIONS.UPDATE_RATE_LIMIT_SETTING_PROGRESS && (
                          <CircularProgress className="mr-2" size={20} />
                      )}
                      <span>{t("Button.Save")}</span>
                    </Button>
                  </Col>
                </Row>
      })
    )
  }

  render() {
    const { t } = this.props;
    const {
      isLoop,
      settingRate,
      settingExcelFinance,
      settingEzp,
      timeStart,
      timeEnd,
      maxGbt,
      setEzpFee,
      setExcelFee,
      setRate,
      setMaxTopUp,
      groupId,
      businessId,
      alertAck,
      titleRateLimit,
      changeOneTimeLimit,
      changeDailyLimit,
      changeMonthlyLimit,
      setRateLimit
    } = this.state;
    return (
      <div className="setting-rate">
        <div className="title">
          <div className="title-content">{t("RateSetting.SettingRate")}</div>
        </div>
        <Container className="mb-5">

          <Row className="mt-5">
            <Label className="label-title"> {t("RateSetting.GroupSelection")}</Label>
          </Row>
          <Row>
            <Col lg="3" md="3" sm="3" xs="12" className="mt-1">
              <Input
                className="selectGroup brs"
                type="select"
                name="selectGroup"
                id="selectGroup"
                value={groupId || ""}
                onChange={event => {
                  this.handleSelectGroupChange(event.target.value);
                }}
              >
                {/* <option value="00">{t("RateSetting.GroupSelection")}</option> */}
                <option value="01">Greenbox</option>
                <option value="02">Triple</option>
              </Input>
            </Col>
          </Row>
          <Row className="mt-5">
            <Label className="label-title"> {t("RateSetting.SettingRate")}</Label>
          </Row>
          <Row>
            <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
              <Input
                className="input-label-style"
                disabled={true}
                value={t('RateSetting.SettingRate')}
              />
            </Col>
            <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
              <InputGroup>
                <Input
                    className="input-style"
                    type="number"
                    value={settingRate}
                    disabled={
                      this.props.settingRate ===
                        ACTIONS.UPDATE_RATE_SETTING_PROGRESS
                    }
                    onChange={e => {
                      this.setState({ settingRate: e.target.value });
                    }}
                    min="1"
                    max="100"
                  />
                <InputGroupAddon addonType="append">
                  <InputGroupText className="brs">%</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg="2" md="2" sm="2" xs="12" className="mt-1">
              <Button
                variant="contained"
                disabled={
                  this.props.settingRate ===
                  ACTIONS.UPDATE_RATE_SETTING_PROGRESS
                }
                className="submit-btn brs"
                onClick={() => {
                  this.setState({ setRate: true });
                }}
              >
                {this.props.settingRate ===
                  ACTIONS.UPDATE_RATE_SETTING_PROGRESS && (
                  <CircularProgress className="mr-2" size={20} />
                )}
                <span>{t("Button.Save")}</span>
              </Button>
            </Col>
          </Row>
          <Row className="mt-5">
            <Label className="label-title">{t("RateSetting.SettingFee")}</Label>
          </Row>
          <Row>
            <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
              <Input
                className="input-label-style"
                disabled={true}
                value={"Setting Fee Excel Finance"}
              />
            </Col>
            <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
              <InputGroup>
                <Input
                  className="input-style"
                  type="number"
                  value={+settingExcelFinance ?? 0}
                  disabled={
                    this.props.settingRate ===
                    ACTIONS.UPDATE_EXCEL_FINANCE_SETTING_PROGRESS
                  }
                  onChange={e => {
                    this.setState({ settingExcelFinance: e.target.value });
                  }}
                  min="0"
                  max="100"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText className="brs">%</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg="2" md="2" sm="2" xs="12" className="mt-1">
              <Button
                  variant="contained"
                  disabled={
                    this.props.settingRate ===
                    ACTIONS.UPDATE_EXCEL_FINANCE_SETTING_PROGRESS
                  }
                  className="submit-btn brs"
                  onClick={() => {
                    this.setState({ setExcelFee: true });
                  }}
                >
                {this.props.settingRate ===
                  ACTIONS.UPDATE_EXCEL_FINANCE_SETTING_PROGRESS && (
                  <CircularProgress className="mr-2" size={20} />
                )}
                <span>{t("Button.Save")}</span>
              </Button>
            </Col>
          </Row>

          <Row>
            <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
              <Input
                className="input-label-style"
                disabled={true}
                value={"Setting Fee Ezp"}
              />
            </Col>
            <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
              <InputGroup>
                <Input
                  className="input-style"
                  type="number"
                  value={settingEzp}
                  disabled={
                    this.props.settingRate === ACTIONS.UPDATE_EZP_SETTING_PROGRESS
                  }
                  onChange={e => {
                    this.setState({ settingEzp: e.target.value });
                  }}
                  min="0"
                  max="100"
              />
                <InputGroupAddon addonType="append">
                  <InputGroupText className="brs">%</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg="2" md="2" sm="2" xs="12" className="mt-1">
              <Button
                variant="contained"
                disabled={
                  this.props.settingRate === ACTIONS.UPDATE_EZP_SETTING_PROGRESS
                }
                className="submit-btn brs"
                onClick={() => {
                  this.setState({ setEzpFee: true });
                }}
              >
                {this.props.settingRate ===
                  ACTIONS.UPDATE_EZP_SETTING_PROGRESS && (
                  <CircularProgress className="mr-2" size={20} />
                )}
                <span>{t("Button.Save")}</span>
              </Button>
            </Col>
          </Row>
          <Row className="mt-5">
            <Label className="label-title">
              {t("RateSetting.SettingRateLimit")}
            </Label>
          </Row>
          <Row className="mt-5">
            <Col lg="2" md="2" sm="12" xs="12" className="mt-1">
              <Input
                className="input-label-style"
                disabled={true}
                value="Type"
              />
            </Col>
            <Col lg="3" md="3" sm="12" xs="12" className="mt-1">
              <Input
                className="input-label-style"
                disabled={true}
                value="OneTime"
              />
            </Col>
            <Col lg="3" md="3" sm="12" xs="12" className="mt-1">
              <Input
                className="input-label-style"
                disabled={true}
                value="Daily"
              />
            </Col>
            <Col lg="3" md="3" sm="12" xs="12" className="mt-1">
              <Input
                className="input-label-style"
                disabled={true}
                value="Month"
              />
            </Col>
            <Col lg="1" md="1" sm="12" xs="12" className="mt-1"></Col>
          </Row>
          {this.renderSettingRateLimit()}
        </Container>

        {setRate && (
          <Alert
            title={t("Alert.ConfirmMessage")}
            content={t("RateSetting.SetRate")}
            okTitle={t("Alert.OK")}
            cancelTitle={t("Alert.Cancel")}
            onOK={() => {
              this.setState({ setRate: false });
              this.alertAck();
              this.props.updateRateSetting({
                key: TYPE_SETTING.RATE,
                value: this.state.settingRate,
                businessId: businessId
              });
            }}
            onCancel={() => {
              this.setState({ setRate: false });
            }}
          />
        )}
        {setMaxTopUp && (
          <Alert
            title={t("Alert.ConfirmMessage")}
            content={t("RateSetting.SetMaxGBTTopUp")}
            okTitle={t("Alert.OK")}
            cancelTitle={t("Alert.Cancel")}
            onOK={() => {
              this.setState({ setMaxTopUp: false });
              this.alertAck();
              this.props.updateMaxGbt({
                isLoop,
                timeStart: moment(timeStart).format("YYYY-MM-DD"),
                timeEnd: moment(timeEnd).format("YYYY-MM-DD"),
                maxGbt,
                businessId: businessId
              });
            }}
            onCancel={() => {
              this.setState({ setMaxTopUp: false });
            }}
          />
        )}{" "}
        {setExcelFee && (
          <Alert
            title={t("Alert.ConfirmMessage")}
            content={t("RateSetting.SetExcelFinanceFee")}
            okTitle={t("Alert.OK")}
            cancelTitle={t("Alert.Cancel")}
            onOK={() => {
              this.setState({ setExcelFee: false });
              this.alertAck();
              this.props.updateExcelFinanceSetting({
                key: TYPE_SETTING.EXCEL_FEE,
                value: this.state.settingExcelFinance,
                businessId: businessId
              });
            }}
            onCancel={() => {
              this.setState({ setExcelFee: false });
            }}
          />
        )}
        {setEzpFee && (
          <Alert
            title={t("Alert.ConfirmMessage")}
            content={t("RateSetting.SetEzpFee？")}
            okTitle={t("Alert.OK")}
            cancelTitle={t("Alert.Cancel")}
            onOK={() => {
              this.setState({ setEzpFee: false });
              this.alertAck();
              this.props.updateEzpSetting({
                key: TYPE_SETTING.EZP_FEE,
                value: settingEzp,
                businessId: businessId
              });
            }}
            onCancel={() => {
              this.setState({ setEzpFee: false });
            }}
          />
        )}
        {[
          ACTIONS.GET_RATE_SETTING_FAILED,
          ACTIONS.GET_RATE_LIMIT_SETTING_FAILED,
          ACTIONS.UPDATE_MAX_TOP_UP_FAILED,
          ACTIONS.UPDATE_RATE_SETTING_FAILED,
          ACTIONS.UPDATE_EXCEL_FINANCE_SETTING_FAILED,
          ACTIONS.UPDATE_EZP_SETTING_FAILED,
          ACTIONS.UPDATE_RATE_LIMIT_SETTING_FAILED
        ].indexOf(this.props.settingRate) >= 0 &&
          !alertAck && (
            <Alert
              title={t("Alert.Error")}
              content={this.props.settingRateError.code}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.props.getRateSetting({ businessId });
                this.props.getRateLimitSetting({ businessId });
                this.setState({ alertAck: true });
              }}
            />
          )}
        {[
          ACTIONS.UPDATE_RATE_SETTING_SUCCESS,
          ACTIONS.UPDATE_EXCEL_FINANCE_SETTING_SUCCESS,
          ACTIONS.UPDATE_EZP_SETTING_SUCCESS,
          ACTIONS.UPDATE_MAX_TOP_UP_SUCCESS,
          ACTIONS.UPDATE_RATE_LIMIT_SETTING_SUCCESS
        ].indexOf(this.props.settingRate) >= 0 &&
          !alertAck && (
            <Alert
              title={t("Alert.Success")}
              content={t("RateSetting.SetSettingSuccess")}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.props.getRateSetting({ businessId });
                this.props.getRateLimitSetting({ businessId });
                this.setState({ alertAck: true });
              }}
            />
          )}

        {setRateLimit && (
            <Alert
                title={t("Alert.ConfirmMessage")}
                content={t("RateSetting.SettingRateLimit")}
                okTitle={t("Alert.OK")}
                cancelTitle={t("Alert.Cancel")}
                onOK={() => {
                  this.alertAck();
                  this.props.updateRateLimit({
                    businessId: businessId,
                    title: titleRateLimit,
                    oneTimeLimit: Number(changeOneTimeLimit),
                    dailyLimit: Number(changeDailyLimit),
                    monthlyLimit: Number(changeMonthlyLimit)
                  });
                  this.clearState();
                }}
                onCancel={() => {
                  this.clearState();
                }}
            />
        )}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    settingRate: store.settingRate.status,
    settings: store.settingRate.settings,
    limitSettings: store.settingRate.limitSettings,
    settingRateError: store.settingRate.settingRateError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRateSetting: data => {
      dispatch(getRateSetting(data));
    },
    getRateLimitSetting: data => {
      dispatch(getRateLimitSetting(data));
    },
    updateMaxGbt: data => {
      dispatch(updateMaxGbt(data));
    },
    updateExcelFinanceSetting: data => {
      dispatch(updateExcelFinanceSetting(data));
    },
    updateEzpSetting: data => {
      dispatch(updateEzpSetting(data));
    },
    updateRateSetting: data => {
      dispatch(updateRateSetting(data));
    },
    updateRateLimit: data => {
      dispatch(updateRateLimit(data));
    },
  };
};
export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RateSetting)
);
