import React, { Component } from "react";
import {
  Table,
  Modal,
  Form,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Button,
  Input,
  Container,
  Row
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import {
  ACTIONS,
  getUsers,
  deleteMember,
  createUser,
  getInfo,
  editMember
} from "../../../../01-actions/member";
import { connect } from "react-redux";
import Alert from "../../../../03-components/Alert";
import {
  ROLE_SUPPER_ADMIN,
  STATUS_USER
} from "../../../../05-utils/commonData";
import "./style.css";
import { Validation } from "../../../../05-utils/commonUtils";

class AdministratorSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      info: null,
      selectedUser: {},
      alertAck: true,
      isShowModal: false,
      fullyChecked: false,
      deleteUser: false
    };
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  componentDidMount() {
    this.alertAck();
    this.props.getUsers();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.users !== this.props.users) {
      this.setState({
        users: nextProps.users
      });
    }
  }

  renderHeaderTable = () => {
    const { t } = this.props;
    return (
      <tr>
        <th>{t("AdministratorSetting.Table.Id")}</th>
        <th>{t("AdministratorSetting.Table.Name")}</th>
        <th>{t("AdministratorSetting.Table.Email")}</th>
        <th>{t("AdministratorSetting.Table.Role")}</th>
        <th>{t("AdministratorSetting.Table.Status")}</th>
        <th className="no-border" />
        <th className="no-border" />
      </tr>
    );
  };

  renderContentTable() {
    const { users, t } = this.props;
    if (Array.isArray(users) && users.length > 0) {
      return users.map((data, index) => {
        return (
          <tr key={index}>
            <td>{data.id}</td>
            <td>{data.name}</td>
            <td>{data.email}</td>
            <td>{data.role}</td>
            <td>{data.status}</td>
            <td className="no-border">
              {data.role !== ROLE_SUPPER_ADMIN && (
                <Button
                  color="primary"
                  className="button-style"
                  onClick={() =>
                    this.setState(
                      {
                        isShowModal: true,
                        deleteUser: false,
                        selectedUser: { ...data, password: "123123" }
                      },
                      () => {
                        this.props.getInfo();
                      }
                    )
                  }
                >
                  {t("Button.Edit")}
                </Button>
              )}
            </td>
            <td className="no-border">
              {data.role !== ROLE_SUPPER_ADMIN && (
                <Button
                  color="danger"
                  className="button-style"
                  onClick={() =>
                    this.setState({
                      isShowModal: true,
                      deleteUser: true,
                      selectedUser: { ...data }
                    })
                  }
                >
                  {t("Button.Delete")}
                </Button>
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={5}>
            <div className="d-flex justify-content-center">
              <span>{t("NoDataToShow")}</span>
            </div>
          </td>
        </tr>
      );
    }
  }

  handleSubmitBtn = (e, actionName) => {
    e.preventDefault();
    this.alertAck();
    this.setState({ isShowModal: false });
    const { selectedUser } = this.state;
    switch (actionName) {
      case "Update":
        this.props.handleUpdateMember(selectedUser);
        break;
      case "Create":
        this.props.handleCreateMember(selectedUser);
        break;
      case "Delete":
        this.props.handleDeleteMember(selectedUser);
        break;
      default:
        break;
    }
  };

  checkData() {
    let { selectedUser } = this.state;
    let item = {
      id: selectedUser.id || "",
      name: (selectedUser.name && selectedUser.name.trim()) || "",
      email: selectedUser.email || "",
      password: selectedUser.password || "",
      role: selectedUser.role || "",
      status: selectedUser.status || ""
    };
    let fullyChecked = !Object.keys(item).some(key => {
      return item[key] === "" || item[key] === null || item[key] === "";
    });

    fullyChecked = fullyChecked && Validation.validateEmail(item.email);

    this.setState({ fullyChecked: fullyChecked });
    return fullyChecked;
  }

  handleChange(data) {
    let id =
      (this.state.selectedUser && this.state.selectedUser.id) ||
      (this.props.info && this.props.info.id);
    return e => {
      if (e.target) {
        let selectedUser = { ...this.state.selectedUser };
        selectedUser[data] = e.target.value;
        selectedUser["id"] = id;
        this.setState({ selectedUser: selectedUser }, () => {
          this.checkData();
        });
      }
    };
  }

  renderTable() {
    return (
      <Table bordered>
        <thead>{this.renderHeaderTable()}</thead>
        <tbody>{this.renderContentTable()}</tbody>
      </Table>
    );
  }

  toggleDialog = () => {
    this.setState({ isShowModal: false, selectedUser: {} });
  };

  renderForm() {
    const { selectedUser, isShowModal, deleteUser } = this.state;
    const { info, t } = this.props;
    return (
      <Modal
        isOpen={isShowModal}
        toggle={this.toggleDialog}
        className={this.props.className}
      >
        <ModalHeader toggle={this.toggleDialog}>
          {deleteUser
            ? t("AdministratorSetting.DeleteMember")
            : selectedUser && selectedUser._id
            ? t("AdministratorSetting.UpdateMember")
            : t("AdministratorSetting.CreateMember")}
        </ModalHeader>
        <ModalBody className="member-setting">
          <Form>
            <FormGroup
              row
              className="d-flex justify-content-between align-items-center"
            >
              <div className="ml-4" sm={4}>
                {t("AdministratorSetting.Table.Id")}
              </div>
              <Col sm={8}>
                <Input
                  type="input"
                  name="id"
                  id="id"
                  value={
                    (selectedUser && selectedUser.id) || (info && info.id) || ""
                  }
                  placeholder="Id"
                  disabled={true}
                />
              </Col>
            </FormGroup>
            {deleteUser ? null : (
              <React.Fragment>
                <FormGroup
                  row
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="ml-4" sm={4}>
                    {t("AdministratorSetting.Table.Name")}
                  </div>
                  <Col sm={8}>
                    <Input
                      type="input"
                      name="name"
                      id="name"
                      value={(selectedUser && selectedUser.name) || ""}
                      onChange={this.handleChange("name")}
                      placeholder="Name"
                    />
                  </Col>
                </FormGroup>
                <FormGroup
                  row
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="ml-4" sm={4}>
                    {t("AdministratorSetting.Table.Email")}
                  </div>
                  <Col sm={8}>
                    <Input
                      type="input"
                      name="email"
                      id="email"
                      value={(selectedUser && selectedUser.email) || ""}
                      onChange={this.handleChange("email")}
                      placeholder="Email"
                    />
                  </Col>
                </FormGroup>
                <FormGroup
                  row
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="ml-4" sm={4}>
                    {t("AdministratorSetting.Table.Password")}
                  </div>
                  <Col sm={8}>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      defaultValue={selectedUser ? selectedUser.password : ""}
                      onChange={this.handleChange('password')}
                      placeholder="Password"
                      disabled={selectedUser._id}
                    />
                  </Col>
                </FormGroup>
                <FormGroup
                  row
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="ml-4" sm={4}>
                    {t("AdministratorSetting.Table.Role")}
                  </div>
                  <Col sm={8}>
                    <Input
                      type="select"
                      name="role"
                      id="role"
                      value={(selectedUser && selectedUser.role) || ""}
                      onChange={this.handleChange("role")}
                      placeholder="Role"
                    >
                      <option value="">
                        {t("AdministratorSetting.Table.SelectPermission")}
                      </option>
                      {info &&
                        info.allRole.map((option, index) => (
                          <option key={index} value={option}>
                            {(option.length > 38 &&
                              option.slice(0, 28) + "...") ||
                              option}
                          </option>
                        ))}
                    </Input>
                  </Col>
                </FormGroup>
                <FormGroup
                  row
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className="ml-4" sm={4}>
                    {t("AdministratorSetting.Table.Status")}
                  </div>
                  <Col sm={8}>
                    <Input
                      type="select"
                      name="status"
                      id="status"
                      value={(selectedUser && selectedUser.status) || ""}
                      onChange={this.handleChange("status")}
                      placeholder="Status"
                    >
                      <option value="">
                        {t("AdministratorSetting.Table.SelectStatus")}
                      </option>
                      {Object.values(STATUS_USER).map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </Input>
                  </Col>
                </FormGroup>
              </React.Fragment>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.toggleDialog} className="brs">
            {t("Button.Close")}
          </Button>{" "}
          {deleteUser ? (
            <Button
              color="primary"
              onClick={e => this.handleSubmitBtn(e, "Delete")}
            >
              {t("Button.Delete")}
            </Button>
          ) : selectedUser && selectedUser._id ? (
            <Button
              color="primary"
              disabled={!this.state.fullyChecked}
              className="brs"
              onClick={e => this.handleSubmitBtn(e, "Update")}
            >
              {t("Button.Update")}
            </Button>
          ) : (
            <Button
              color="primary"
              disabled={!this.state.fullyChecked}
              className="brs"
              onClick={e => this.handleSubmitBtn(e, "Create")}
            >
              {t("Button.Create")}
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div className="member-setting">
        <div className="title">
          <div className="title-content">
            {t("AdministratorSetting.MemberSetting")}
          </div>
        </div>
        <Container>
          <Row className="mt-3">
            <Col
              lg={{ size: "auto", offset: 1 }}
              md={{ size: "auto", offset: 1 }}
              sm={{ size: "auto", offset: 1 }}
              xs="12"
              className="mt-1"
            >
              {this.renderForm()}
              <Button
                color="primary"
                className="custom-btn mr-4 mb-4"
                onClick={() =>
                  this.setState(
                    {
                      isShowModal: true,
                      selectedUser: {},
                      deleteUser: false
                    },
                    () => {
                      this.props.getInfo();
                    }
                  )
                }
              >
                {t("Button.AddNew")}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col
              lg={{ size: "auto", offset: 1 }}
              md={{ size: "auto", offset: 1 }}
              sm={{ size: "auto", offset: 1 }}
              xs="12"
              className="mt-1"
            >
              <div className="content-panel">
                <div className="header">
                  <div className="mainHeader">
                    {t("AdministratorSetting.Role")}
                  </div>
                </div>
                <div className="content-body">{this.renderTable()}</div>
              </div>
            </Col>
          </Row>
        </Container>
        {[
          ACTIONS.CREATE_USER_FAILED,
          ACTIONS.DELETE_USER_FAILED,
          ACTIONS.GET_USER_FAILED,
          ACTIONS.UPDATE_USER_FAILED
        ].indexOf(this.props.member) >= 0 &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Error")}
              content={this.props.memberError.code}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.setState({ alertAck: true });
              }}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    member: store.member.status,
    memberError: store.member.memberError,
    users: store.member.users,
    info: store.member.info
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUsers: () => {
      dispatch(getUsers());
    },
    getInfo: () => {
      dispatch(getInfo());
    },
    handleDeleteMember: data => {
      dispatch(deleteMember(data));
    },
    handleCreateMember: data => {
      dispatch(createUser(data));
    },
    handleUpdateMember: data => {
      dispatch(editMember(data));
    }
  };
};
export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AdministratorSetting)
);
