import { callApi } from "../05-utils/commonUtils";
import queryString from 'query-string'

const ACTIONS = {
    HUV_SEARCH_HISTORIES_BALANCE: "HUV_SEARCH_HISTORIES_BALANCE",
    HUV_GET_HISTORIES_BALANCE_SUCCESS: "HUV_GET_HISTORIES_BALANCE_SUCCESS",
    HUV_GET_HISTORIES_BALANCE_FAIL: "HUV_GET_HISTORIES_BALANCE_FAIL",
    HUV_GET_USER_MANIGO_SUCCESS: "HUV_GET_USER_MANIGO_SUCCESS",
    HUV_GET_USER_MANIGO_FAIL: "HUV_GET_USER_MANIGO_FAIL",
    HUV_GET_SEARCH_USER_MANIGO_SUCCESS: "HUV_GET_SEARCH_USER_MANIGO_SUCCESS",
    HUV_GET_SEARCH_USER_MANIGO_FAIL: "HUV_GET_SEARCH_USER_MANIGO_FAIL",
};


const updateStatus = (status, statusInfo) => {
    return {
        type: status,
        data: statusInfo
    }
};

const historiesBalance = (data, limit = 100) => {
    let config = {
        method: "get",
        isAuthorization: true,
    };

    const params =  queryString.stringify({
        userCode: data.id,
        startTime: data.startTime || "",
        endTime: data.endTime || "",
        page: data.page,
        limit: data.limit || limit,
    })
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.HUV_SEARCH_HISTORIES_BALANCE));
        callApi(`/api/user_histories?${params}`,
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.HUV_GET_HISTORIES_BALANCE_SUCCESS, { histories: result.data, pageHistories: result.pages }))
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.HUV_GET_HISTORIES_BALANCE_FAIL, { error: err }));
            }
        )
    }
};


const huvGetUsersManigo = (data) => {
    let config = {
        method: 'get',
        isAuthorization: true,
    };
    const params = queryString.stringify({
        page: data.page,
        limit: data.limit,
        search: data.search,
        isFilter: data.isFilter || 0,
        filter: JSON.stringify({
            name: data.name || '',
            option: data.option || "desc"
        })
})
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.HUV_SEARCH_HISTORIES_BALANCE));
         callApi(`/api/users?${params}`,
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.HUV_GET_USER_MANIGO_SUCCESS, { users: result.data, pages: result.pages }));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.HUV_GET_USER_MANIGO_FAIL, { error: err }));
            }
        );
    };
};

const searchUsersManigo = (data, limit = 100) => {

    let config = {
        method: "get",
        isAuthorization: true
    };

    const params = queryString.stringify({
            page: data.page,
            limit: data.limit || limit,
            search: data.search,
            isFilter: data.isFilter || 0,
            filter: JSON.stringify({
                name: data.name || '',
                option: data.option || "desc"
            })
    })

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.HUV_SEARCH_HISTORIES_BALANCE));
        callApi(`/api/users?${params}`,
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.HUV_GET_SEARCH_USER_MANIGO_SUCCESS, { users: result.data, pages: result.pages }))
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.HUV_GET_SEARCH_USER_MANIGO_FAIL, { error: err }));
            }
        )
    }
};

export {
    ACTIONS,
    historiesBalance,
    huvGetUsersManigo,
    searchUsersManigo
};
