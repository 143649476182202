import { callApi } from "../05-utils/commonUtils";

const ACTIONS = {
  MMP_GET_RATE_SETTING_PROGRESS: "MMP_GET_RATE_SETTING_PROGRESS",
  MMP_GET_RATE_SETTING_SUCCESS: "MMP_GET_RATE_SETTING_SUCCESS",
  MMP_GET_RATE_SETTING_FAILED: "MMP_GET_RATE_SETTING_FAILED",
  MMP_GET_RATE_LIMIT_SETTING_PROGRESS: "MMP_GET_RATE_LIMIT_SETTING_PROGRESS",
  MMP_GET_RATE_LIMIT_SETTING_SUCCESS: "MMP_GET_RATE_LIMIT_SETTING_SUCCESS",
  MMP_GET_RATE_LIMIT_SETTING_FAILED: "MMP_GET_RATE_LIMIT_SETTING_FAILED",
  MMP_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS:
    "MMP_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS",
  MMP_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS:
    "MMP_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS",
  MMP_UPDATE_EXCEL_FINANCE_SETTING_FAILED:
    "MMP_UPDATE_EXCEL_FINANCE_SETTING_FAILED",
  MMP_UPDATE_EZP_SETTING_PROGRESS: "MMP_UPDATE_EZP_SETTING_PROGRESS",
  MMP_UPDATE_EZP_SETTING_SUCCESS: "MMP_UPDATE_EZP_SETTING_SUCCESS",
  MMP_UPDATE_EZP_SETTING_FAILED: "MMP_UPDATE_EZP_SETTING_FAILED",
  MMP_UPDATE_SETTING_RATE_PROGRESS: "MMP_UPDATE_SETTING_RATE_PROGRESS",
  MMP_UPDATE_SETTING_RATE_SUCCESS: "MMP_UPDATE_SETTING_RATE_SUCCESS",
  MMP_UPDATE_SETTING_RATE_FAILED: "MMP_UPDATE_SETTING_RATE_FAILED",
  MMP_UPDATE_RATE_LIMIT_SETTING_PROGRESS:
    "MMP_UPDATE_RATE_LIMIT_SETTING_PROGRESS",
  MMP_UPDATE_RATE_LIMIT_SETTING_SUCCESS:
    "MMP_UPDATE_RATE_LIMIT_SETTING_SUCCESS",
  MMP_UPDATE_RATE_LIMIT_SETTING_FAILED:
    "MMP_UPDATE_RATE_LIMIT_SETTING_FAILED",
  MMP_REFUND_CCT_PROGRESS: "MMP_REFUND_CCT_PROGRESS",
  MMP_REFUND_CCT_SUCCESS: "MMP_REFUND_CCT_SUCCESS",
  MMP_REFUND_CCT_FAILED: "MMP_REFUND_CCT_FAILED",
  MMP_RECEIVING_CCT_PROGRESS: "MMP_RECEIVING_CCT_PROGRESS",
  MMP_RECEIVING_CCT_SUCCESS: "MMP_RECEIVING_CCT_SUCCESS",
  MMP_RECEIVING_CCT_FAILED: "MMP_RECEIVING_CCT_FAILED",
  MMP_AUTHENTICATION_PROGRESS: "MMP_AUTHENTICATION_PROGRESS",
  MMP_AUTHENTICATION_SUCCESS: "MMP_AUTHENTICATION_SUCCESS",
  MMP_AUTHENTICATION_FAILED: "MMP_AUTHENTICATION_FAILED",
};

const updateStatus = (status, statusInfo) => {
  return {
    type: status,
    data: statusInfo,
  };
};

const MmpAuthenticationSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.MMP_AUTHENTICATION_PROGRESS));
    callApi(
      "/api/pomo-mmp/update_auth_pomo_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.MMP_AUTHENTICATION_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.MMP_AUTHENTICATION_FAILED, { error: err })
        );
      }
    );
  };
};

const MmpReceivingCCT = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.MMP_RECEIVING_CCT_PROGRESS));
    callApi(
      "/api/pomo-mmp/update_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.MMP_RECEIVING_CCT_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.MMP_RECEIVING_CCT_FAILED, { error: err })
        );
      }
    );
  };
};

const MmpRefundCCT = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.MMP_REFUND_CCT_PROGRESS));
    callApi(
      "/api/pomo-mmp/update_wallet_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.MMP_REFUND_CCT_SUCCESS));
      },
      (err) => {
        dispatch(updateStatus(ACTIONS.MMP_REFUND_CCT_FAILED, { error: err }));
      }
    );
  };
};

const MmpUpdateRateLimit = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_PROGRESS));
    callApi(
      `/api/pomo-mmp/update_limit_title`,
      config,
      null,
      (data) => {
        dispatch(updateStatus(ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_FAILED, {
            error: err,
          })
        );
      }
    );
  };
};

const MmpUpdateRateSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.MMP_UPDATE_SETTING_RATE_PROGRESS));
    callApi(
      `/api/pomo-mmp/update_setting`,
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.MMP_UPDATE_SETTING_RATE_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.MMP_UPDATE_SETTING_RATE_FAILED, { error: err })
        );
      }
    );
  };
};

const MmpUpdateEzpSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.MMP_UPDATE_EZP_SETTING_PROGRESS));
    callApi(
      `/api/pomo-mmp/update_setting`,
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.MMP_UPDATE_EZP_SETTING_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.MMP_UPDATE_EZP_SETTING_FAILED, { error: err })
        );
      }
    );
  };
};

const MmpUpdateExcelFinanceSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS));
    callApi(
      `/api/pomo-mmp/update_setting`,
      config,
      null,
      () => {
        dispatch(
          updateStatus(ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS)
        );
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_FAILED, {
            error: err,
          })
        );
      }
    );
  };
};

const MmpGetRateLimitSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.MMP_GET_RATE_LIMIT_SETTING_PROGRESS));
    callApi(
      `/api/pomo-mmp/get_limit_titles`,
      config,
      null,
      (res) => {
        dispatch(
          updateStatus(ACTIONS.MMP_GET_RATE_LIMIT_SETTING_SUCCESS, {
            limitSettings: res,
          })
        );
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.MMP_GET_RATE_LIMIT_SETTING_FAILED, {
            error: err,
          })
        );
      }
    );
  };
};

const MmpGetRateSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.MMP_GET_RATE_SETTING_PROGRESS));
    callApi(
      `/api/pomo-mmp/get_setting`,
      config,
      null,
      (res) => {
        dispatch(
          updateStatus(ACTIONS.MMP_GET_RATE_SETTING_SUCCESS, { settings: res })
        );
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.MMP_GET_RATE_SETTING_FAILED, { error: err })
        );
      }
    );
  };
};

export {
  ACTIONS,
  MmpGetRateSetting,
  MmpGetRateLimitSetting,
  MmpUpdateExcelFinanceSetting,
  MmpUpdateEzpSetting,
  MmpUpdateRateSetting,
  MmpUpdateRateLimit,
  MmpRefundCCT,
  MmpReceivingCCT,
  MmpAuthenticationSetting
};
