import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Hidden
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

import Header from "../Home/Header";
import MenuComponent from "../Home/Menu";
import PermissionSetting from "./PermissionSetting";
import AdministratorSetting from "./AdministratorSetting";
import ApprovalHistory from "./ApprovalHistory";
import LogIp from "./LogIp";
import Statistic from "./Statistic";
import RateSetting from "./RateSetting";
import ReBalance from "./ReBalance";
import ChargeRequestList from "./ChargeRequestList";
import HubStyleChargeRequestList from "./HuvStyle/ChargeRequestList";
import HubStyleRateSetting from "./HuvStyle/RateSetting";
import ListConfirming from "./ListConfirming";
import SecretKey from "./SecretKey";
import LogAction from "./LogAction";
import TFASetting from "./TFASetting";
import "./style.css";
import { huvStyleRoutes, pomoRoutes, pomRoutes, mmpRoutes } from "../../../routes";
import DepositToSystem from "./HuvStyle/DepositToSystem";
import { SYSTEMS } from "../../../05-utils/commonData";
import { Input } from "reactstrap";
import PomChargeRequestList from "./Pom/ChargeRequestList";
import PomRateSetting  from "./Pom/RateSetting";
import PomoChargeRequestList from "./Pomo/ChargeRequestList/PomoChargeRequestList";
import PomoRateSettings from "./Pomo/RateSetting/PomoRateSettings";
import MMPChargeRequestList from "./MMP/ChargeRequestList/MMPChargeRequestList";
import MMPRateSettings from "./MMP/RateSetting/MMPRateSettings";

const drawerWidth = 315;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    // width: `calc(100% - ${drawerWidth}px)`,
    // marginLeft: drawerWidth,
    // position: `fixed`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    // display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    // marginTop: 64,
    // height: `auto !important`,
    position: `relative !important`,
  },
  drawerPaperMobile: {
    width: drawerWidth,
    marginTop: 64,
    position: `relative !important`,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  content: {
    display: `flex`,
    padding: 0,
    flexGrow: 1,
    // padding: theme.spacing(5),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth
  },
  contentMobile: {
    display: `flex`,
    padding: 0,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  grow: {
    flexGrow: 1
  },
  conteinerContent: {
    // marginLeft: 275,
    // marginTop: -40,
    // marginRight: -40,
    display: `flex`,
    flexGrow: 1,
    flexDirection: `column`,
    width: `34%`
  }
}));

export const checkHuvStylePage = (pathname) => (
  Object.values(huvStyleRoutes).some((item) => pathname.includes(item.link))
)

function HomePage(props) {
  const { t, history: { location: { pathname } } } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const toggleDrawer = () => {setOpen(open => !open);}
  const handleDrawerToggle = () => {setMobileOpen(mobileOpen=>!mobileOpen);};
  const isHuvStyle = checkHuvStylePage(pathname)

  const handleChangeSystem = event => {
    const { value } = event.target;
    let nextPage = '/';
    if (value === SYSTEMS.HUVSTYLE.value) {
      nextPage = huvStyleRoutes.topUpPage.link;
    }
    props.history.push(nextPage);
  };

  // render() {
  return (
    <div className={classes.root}>
      {!props.user && <Redirect to="/auth/login" />}
      <CssBaseline />
      {/* <AppBar
        position="static"
        // className={clsx(classes.appBar, { [classes.appBarShift]: open })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            edge="start"
            // className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {t("Header.Logo")}
          </Typography>

          <div className={classes.grow} />
          <Header />
        </Toolbar>
      </AppBar> */}

      <Hidden smUp>
        <AppBar
          position="static"
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6" noWrap>
              {t("Header.Logo")}
            </Typography>

            <div className={classes.grow} />
            <Input
              type="select"
              name="system"
              className="system-select"
              value={isHuvStyle ? SYSTEMS.HUVSTYLE.value : SYSTEMS.GREENBOX_TRIPLE.value}
              onChange={handleChangeSystem}
            >
              {Object.values(SYSTEMS).map((item) => (
                <option value={item.value} key={item.value}>
                  {t(item.name)}
                </option>
              ))}
            </Input>
            <Header />
          </Toolbar>
        </AppBar>
        <main className={clsx(classes.contentMobile, { [classes.contentShift]: open })}>
          <Drawer
            className={classes.drawer}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaperMobile
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <MenuComponent />
          </Drawer>
          <div className={clsx(classes.conteinerContent)}>
            <Switch>
              <Route path="/permission-setting" component={PermissionSetting} />
              <Route
                path="/administrator-setting"
                component={AdministratorSetting}
              />
              <Route path="/approval-history" component={ApprovalHistory} />
              <Route path="/statistic" component={Statistic} />
              <Route path="/rate-setting" component={RateSetting} />
              <Route path="/re-balance" component={ReBalance} />
              <Route path="/charged-request-list" component={ChargeRequestList} />
              <Route path="/list-reject" component={ListConfirming} />
              <Route path="/secret-key" component={SecretKey} />
              <Route path="/login-history" component={LogIp} />
              <Route path="/log-history" component={LogAction} />
              <Route path="/tfa-setting" component={TFASetting} />
              <Route path="/deposit-to-system" component={DepositToSystem} />
              <Route path={huvStyleRoutes.topUpPage.link} component={HubStyleChargeRequestList} />
              <Route path={huvStyleRoutes.rateSetting.link} component={HubStyleRateSetting} />
              {/* <Route path={huvStyleRoutes.userManagement.link} component={HuvStyleUserManagement} /> */}
              <Route path={pomRoutes.topUpPage.link} component={PomChargeRequestList} />
              <Route path={pomRoutes.rateSetting.link} component={PomRateSetting} />
              <Route path={pomoRoutes.topUpPage.link} component={PomoChargeRequestList} />
              <Route path={pomoRoutes.rateSetting.link} component={PomoRateSettings} />

              <Route path={mmpRoutes.topUpPage.link} component={MMPChargeRequestList} />
              <Route path={mmpRoutes.rateSetting.link} component={MMPRateSettings} />
            </Switch>
          </div>
        </main>
      </Hidden>

      <Hidden xsDown>
        <AppBar
            position="static"
            // className={clsx(classes.appBar, { [classes.appBarShift]: open })}
          >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              // className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Typography className={classes.title} variant="h6" noWrap>
              {t("Header.Logo")}
            </Typography>

            <div className={classes.grow} />
            <Hidden mdUp><Input
              type="select"
              name="system"
              className="system-select"
              value={isHuvStyle ? SYSTEMS.HUVSTYLE.value : SYSTEMS.GREENBOX_TRIPLE.value}
              onChange={handleChangeSystem}
            >
              {Object.values(SYSTEMS).map((item) => (
                <option value={item.value} key={item.value}>
                  {t(item.name)}
                </option>
              ))}
              </Input></Hidden>
            <Header />
          </Toolbar>
        </AppBar>
        <main className={clsx(classes.content, { [classes.contentShift]: open })}>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <MenuComponent />
          </Drawer>
          <div className={clsx(classes.conteinerContent)}>
            <Switch>
              <Route path="/permission-setting" component={PermissionSetting} />
              <Route
                path="/administrator-setting"
                component={AdministratorSetting}
              />
              <Route path="/approval-history" component={ApprovalHistory} />
              <Route path="/statistic" component={Statistic} />
              <Route path="/rate-setting" component={RateSetting} />
              <Route path="/re-balance" component={ReBalance} />
              <Route path="/charged-request-list" component={ChargeRequestList} />
              <Route path="/list-reject" component={ListConfirming} />
              <Route path="/secret-key" component={SecretKey} />
              <Route path="/login-history" component={LogIp} />
              <Route path="/log-history" component={LogAction} />
              <Route path="/tfa-setting" component={TFASetting} />
              <Route path={huvStyleRoutes.topUpPage.link} component={HubStyleChargeRequestList} />
              <Route path={huvStyleRoutes.rateSetting.link} component={HubStyleRateSetting} />
              {/* <Route path={huvStyleRoutes.userManagement.link} component={HuvStyleUserManagement} /> */}
              <Route path="/deposit-to-system" component={DepositToSystem} />
              {/* <Route path={huvStyleRoutes.depositToSystem.link} component={DepositToSystem}/> */}
              <Route path={pomRoutes.topUpPage.link} component={PomChargeRequestList} />
              <Route path={pomRoutes.rateSetting.link} component={PomRateSetting} />
              <Route path={pomoRoutes.topUpPage.link} component={PomoChargeRequestList} />
              <Route path={pomoRoutes.rateSetting.link} component={PomoRateSettings} />
              <Route path={mmpRoutes.topUpPage.link} component={MMPChargeRequestList} />
              <Route path={mmpRoutes.rateSetting.link} component={MMPRateSettings} />
            </Switch>
          </div>
        </main>

        </Hidden>

      {/* <main className={clsx(classes.content, { [classes.contentShift]: open })}>
        <Hidden smUp>
          <Drawer
            // container={container}
            className={classes.drawer}
            variant="persistent"
            anchor='left'
            open={mobileOpen}
            // onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <MenuComponent />
          </Drawer>
        </Hidden>
        <Hidden xsDown>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper
            }}
          >
            <MenuComponent />
          </Drawer>
        </Hidden>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <MenuComponent />
        </Drawer>
        <div className={clsx(classes.conteinerContent)}>
          <Switch>
            <Route path="/permission-setting" component={PermissionSetting} />
            <Route
              path="/administrator-setting"
              component={AdministratorSetting}
            />
            <Route path="/approval-history" component={ApprovalHistory} />
            <Route path="/statistic" component={Statistic} />
            <Route path="/rate-setting" component={RateSetting} />
            <Route path="/re-balance" component={ReBalance} />
            <Route path="/charged-request-list" component={ChargeRequestList} />
            <Route path="/list-reject" component={ListConfirming} />
            <Route path="/secret-key" component={SecretKey} />
            <Route path="/login-history" component={LogIp} />
            <Route path="/log-history" component={LogAction} />
          </Switch>
        </div>
      </main> */}
    </div>
  );

  // return (
  //     <div className="container-home-page">
  //         {!this.props.user &&
  //         <Redirect to="/auth/login"/>
  //         }
  //         <Header/>
  //         <div className="section d-flex">
  //             {/* <Menu/> */}
  //             <div className="content">
  //                 <Switch>
  //                     <Route path="/permission-setting" component={PermissionSetting}/>
  //                     <Route path="/administrator-setting" component={AdministratorSetting}/>
  //                     <Route path="/approval-history" component={ApprovalHistory}/>
  //                     <Route path="/statistic" component={Statistic}/>
  //                     <Route path="/rate-setting" component={RateSetting}/>
  //                     <Route path="/re-balance" component={ReBalance}/>
  //                     <Route path="/charged-request-list" component={ChargeRequestList}/>
  //                     <Route path="/list-reject" component={ListConfirming}/>
  //                     <Route path="/secret-key" component={SecretKey}/>
  //                     <Route path="/login-history" component={LogIp}/>
  //                     <Route path="/log-history" component={LogAction}/>
  //                 </Switch>
  //             </div>
  //         </div>
  //     </div>
  // );
  // }
}

function mapStateToProps(store) {
  return {
    user: store.auth.user
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    null
  )(HomePage)
);
