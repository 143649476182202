import { ACTIONS } from "../01-actions/pom";

const initialState = {
  status: null,
  pomStatus: null,
  pomCharges: [],
  pages: null,
  totalTCT: 0,
  totalUSD: 0,
  error: null,
  pomStatisticChargeByStatus: {},
  // note: null,
  totalGCCT: 0,
  totalCCT: 0,
  totalROY: 0,
  totalGROY: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    // PROGRESS
    case ACTIONS.POM_GET_CHARGE_PROGRESS:
    case ACTIONS.POM_SEARCH_CHARGE_PROGRESS:
    case ACTIONS.POM_GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS:
    case ACTIONS.POM_CHANGE_STATUS_TOP_UP_PROCESS:
      // case ACTIONS.POM_CHANGE_NOTE_PROCESS:
      return {
        ...state,
        status: action.type,
      };
    // SUCCESS
    case ACTIONS.POM_GET_CHARGE_SUCCESS:
    case ACTIONS.POM_SEARCH_CHARGE_SUCCESS:
      return {
        ...state,
        pomStatus: action.type,
        pomCharges: action.data.charges,
        pages: action.data.pages,
        totalTCT: action.data.totalTCT,
        totalUSD: action.data.totalUSD,
        totalGCCT: action.data.totalGCCT || 0,
        totalCCT: action.data.totalCCT || 0,
        totalROY: action.data.totalROY || 0,
        totalGROY: action.data.totalGROY || 0,
      };
    case ACTIONS.POM_GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS:
      return {
        ...state,
        status: action.type,
        pomStatisticChargeByStatus: action.data.statisticChargeByStatus,
      };
    // case ACTIONS.POM_CHANGE_NOTE_SUCCESS:
    // case ACTIONS.POM_GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS:
    //   return {
    //     ...state,
    //     note: action.data.note,
    //   };
    // FAILED
    case ACTIONS.POM_GET_CHARGE_FAIL:
    case ACTIONS.POM_SEARCH_CHARGE_FAIL:
      return {
        ...state,
        pomStatus: action.type,
        error: action.data.error,
        pomCharges: [],
      };
    case ACTIONS.POM_GET_STATISTIC_CHARGE_BY_STATUS_FAIL:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
      };
    case ACTIONS.POM_CLEAR_STATUS:
      return {
        ...state,
        status: null,
        pomStatus: null,
      };
    default:
      return state;
  }
};
