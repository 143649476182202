import React, { Component } from 'react'
import Confirm from '../../../../03-components/Confirm'
import {
    Button,
    Input,
    FormGroup
} from "reactstrap";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {ACTIONS, login} from '../../../../01-actions/auth';
import ggAuthentication from "../../../../06-assets/images/ga-guide.png";

class ModalTFALogin extends Component {
    constructor(props) {
        super(props)
        this.state = {
            code: ''
        }
    }

    onSubmit = () => {
        if (!this.state.code) return
        this.props.login({
            username: this.props.userName,
            password: this.props.password,
            code: this.state.code
        })
    }

    onchangeTfaCode = (filed) => {
        return(e) => {
            this.setState({
              [filed]: e.target.value
            })
        }
    }

    onClose = () => {
        const {close} = this.props
        this.setState({
            code: ''
        })
        close()
    }

  render() {
    return (
      <div>
        <Confirm
            isOpen={this.props.isOpen}
            toggle={this.onClose}
            destroyOnClose
            centered
            maskClosable={false}
            title='二段階認証'
            className='modal-confirm-tfa-login'
        >
            <FormGroup>
              <div className='text-center'>
                <img
                  className='w-100'
                  src={ggAuthentication}
                  alt=""
                />
              </div>
            </FormGroup>
            <FormGroup
              className='text-center'
              name='code'
            >
              <div>
                <Input
                  type="text"
                  className='text-center tfa-code'
                  value={this.state.code}
                  // disabled={submitting}
                  placeholder='二段階認証'
                  onChange={this.onchangeTfaCode('code')}
                />
              </div>
            </FormGroup>
            <FormGroup className='mb-0 text-center box-action'>
              <Button
                  className={this.props.authStatus === ACTIONS.LOGIN_PROGRESS ? "progress-button btn-tfa-login" : "btn-tfa-login"}
                type='primary'
                onClick={this.onSubmit}
                disabled={!this.state.code}
              >
                提出
              </Button>
            </FormGroup>
        </Confirm>
      </div>
    )
  }
}

const mapStateToProps = (store) => {
    return {
        authStatus: store.auth.status,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: (data) => {
            dispatch(login(data))
        }
    }
}

export default withNamespaces() (
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ModalTFALogin)
)
