import {ACTIONS} from '../01-actions/auth';

const initialState = {
    status: null,
    logIp: null,
    pages: null,
    user: localStorage.getItem("ACCESS_TOKEN"),
    authError: null,
    loginTfaEnable: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.LOGIN_PROGRESS:
        case ACTIONS.FORGOT_PASSWORD_PROGRESS:
        case ACTIONS.GET_LOG_LOCATION_PROGRESS:
        case ACTIONS.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                status: action.type
            };
        case ACTIONS.GET_LOG_LOCATION_SUCCESS:
            return {
                ...state,
                status: action.data.status,
                logIp: action.data.logIp,
                pages: action.data.pages
            };
        case ACTIONS.LOGIN_SUCCESS:
            localStorage.setItem("ACCESS_TOKEN", action.data.user.token);
            localStorage.setItem("ID", action.data.user.id);
            return {
                ...state,
                status: action.type,
                user: {token: action.data.user.token}
            };
        case ACTIONS.LOGIN_FAILED:
            return {
                ...state,
                status: action.type,
                authError: action.data.error,
                loginTfaEnable: action.data.error.data ? action.data.error.data.loginTfaEnable : false
            }
        case ACTIONS.FORGOT_PASSWORD_FAILED:
        case ACTIONS.GET_LOG_LOCATION_FAIL:
            return {
                ...state,
                status: action.type,
                authError: action.data.error
            };
        case ACTIONS.LOGOUT:
            localStorage.removeItem("ACCESS_TOKEN");
            localStorage.removeItem("ID");
            return {
                ...state,
                status: action.type,
                user: null
            };
        default:
            return state;
    }
}
