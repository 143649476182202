import React, { PureComponent } from "react";
import {
  // Form,
  // FormGroup,
  Label,
  Button,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import {
  ACTIONS,
  getSecretKet,
  addSecretKey
} from "../../../../01-actions/wallet";
import { connect } from "react-redux";
import "./style.css";
import Alert from "../../../../03-components/Alert";
import { CircularProgress } from "@material-ui/core";

class SecretKey extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      secretKey: "",
      alertAck: true,
      isSetSecretKey: false
    };
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  componentDidMount() {
    this.alertAck();
    this.props.getSecretKet();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.wallet === ACTIONS.GET_SECRET_KEY_SUCCESS) {
      this.setState({
        secretKey: nextProps.secretKey
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="secret-key">
        <div className="title">
          <div className="title-content">{t("SecretKey.AddSecretKey")}</div>
        </div>
        {/* <div className="title">{t("SecretKey.AddSecretKey")}</div> */}
        <Container>
          <Row className="mt-3">
            <Col lg={{ size: 11, offset: 1 }} md={{ size: 11, offset: 1 }} sm={{ size: 11, offset: 1 }} xs="12" className="mt-1">
              <Label>{t("SecretKey.SecretKey")}</Label>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 1 }} sm={{ size: 3, offset: 1 }} xs="12" className="mt-1">
              <Input
                className="input-label-style"
                disabled={true}
                value={"Secret Key"}
              />
            </Col>
            <Col lg="6" md="6" sm="6" xs="12" className="mt-1">
              <Input
                className="input-style"
                type="password"
                value={this.state.secretKey}
                disabled={
                  this.props.wallet === ACTIONS.UPDATE_RATE_SETTING_PROGRESS
                }
                onChange={e => {
                  this.setState({ secretKey: e.target.value });
                }}
                maxLength={56}
              />
            </Col>
            <Col lg="1" md="1" sm="1" xs="12" className="mt-1">
              <Button
                variant="contained"
                disabled={this.props.wallet === ACTIONS.ADD_SECRET_KEY_PROGRESS}
                className="submit-btn brs"
                onClick={() => {
                  this.setState({ isSetSecretKey: true });
                }}
              >
                {this.props.wallet === ACTIONS.ADD_SECRET_KEY_PROGRESS && (
                  <CircularProgress className="mr-2" size={20} />
                )}
                <span>{t("Button.Save")}</span>
              </Button>
            </Col>
          </Row>
        </Container>
        {[ACTIONS.GET_SECRET_KEY_FAILED, ACTIONS.ADD_SECRET_KEY_FAILED].indexOf(
          this.props.wallet
        ) >= 0 &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Error")}
              content={this.props.walletError.code}
              okTitle="OK"
              onOK={() => {
                this.setState({ alertAck: true });
              }}
            />
          )}
        {[ACTIONS.ADD_SECRET_KEY_SUCCESS].indexOf(this.props.wallet) >= 0 &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Success")}
              content={t("SecretKey.AddSecretSuccess")}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.props.getSecretKet();
                this.setState({ alertAck: true });
              }}
            />
          )}
        {this.state.isSetSecretKey && (
          <Alert
            title={t("Alert.ConfirmMessage")}
            content={t("SecretKey.SetSecretKey")}
            okTitle={t("Alert.OK")}
            cancelTitle={t("Alert.Cancel")}
            onOK={() => {
              this.setState({ isSetSecretKey: false });
              this.alertAck();
              this.props.addSecretKey({
                secretKey: this.state.secretKey
              });
            }}
            onCancel={() => {
              this.setState({ isSetSecretKey: false });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    wallet: store.wallet.status,
    secretKey: store.wallet.secretKey,
    walletError: store.wallet.walletError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSecretKet: () => {
      dispatch(getSecretKet());
    },
    addSecretKey: data => {
      dispatch(addSecretKey(data));
    }
  };
};
export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SecretKey)
);
