import i18n from 'i18next';
import xhr from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './06-assets/translations/en.json';
import jp from './06-assets/translations/jp.json';

i18n
  .use(xhr)
  .use(LanguageDetector)
  .init({
    // we init with resources
    resources: {
      en: {translations: en}, // translations: là namespace
      jp: {translations: jp},
    },
    fallbackLng: 'en',
    // Set default language
    lng: "en",
    debug: true,
    // debug: process.env.NODE_ENV !== 'production',

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    // detection: {
    //   lookupLocalStorage: 'language', // key name to save Localstorage
    // },
    // preload: true,

    // react: {
    //   wait: true,
    // }
  });

export default i18n;
