import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  ACTIONS,
  PomClearStatus,
  PomGetChargeRequest,
  PomChargeSearch,
  PomGetStatisticChargeByStatus,
  pomChangeStatus,
  pomChangeNote,
  pomPushNotification,
} from "../../../../../01-actions/pom";
import { getChargeAdminAmount } from "../../../../../01-actions/charge";
import {
  ACTIONS as ACTIONMB,
  getInfoUser,
} from "../../../../../01-actions/member";
import { POM_UPDATE_STATUS_TOP_UP } from "../../../../../05-utils/commonData";
import { useModal } from "../../../../../05-utils/hook/use-modal";
import Alert from "./Alert";
import AlertComponent from "../../../../../03-components/Alert";
import Search from "./Search";
import "./style.css";
import ChargeRequestListTabled from "./Table";
import moment from "moment";
import _ from "lodash";

const PomChargeRequestList = ({
  charges,
  handleGetChargeRequest,
  chargeSearch,
  status,
  pages,
  t,
  statisticOfChargesByStatus,
  statisticChargeByStatus,
  pomChangeStatus,
  onChangeNote,
  pushNotification,
  totalUSD,
  totalTCT,
  adminBalance,
  getAdminAmount,
  pomStatus,
  clearSts,
  getInfoUser,
  userInfo,
  statusGetUserInfo,
  error,
  totalGCCT,
  totalCCT,
  totalROY,
  totalGROY
}) => {
  const [init, setInit] = useState(false);
  const [selected, setSelected] = useState([]);
  const [alertAck, closeAlert, openAlert] = useModal(false);
  const [itemEditMemo, closeEditMemo, openEditMemo] = useModal(null);
  const [isConfirm, closeConfirm, openConfirm] = useModal(false);
  const [isRejection, closeRejection, openRejection] = useModal(null);
  const [params, setParams] = useState({
    limit: 1000,
    page: 1,
    startTime: null,
    endTime: null,
    filterBy: "",
    keyWord: "",
    tab: "",
  });
  const [statusConfirm, setStatusConfirm] = useState(null);

  const [isPushNotificationSuccess, setIsPushNotificationSuccess] =
    useState(false);
  const [statusTopUp, setStatusTopUp] = useState(false);
  const [rejectingSuccess, setRejectingSuccess] = useState(false);
  const [alertPermission, setAlertPermision] = useState(false);
  const [balanceNotEnough, setBalanceNotEnough] = useState(false);
  const [rejectingFailed, setRejectingFailed] = useState(false);

  useEffect(() => {
    if (
      pomStatus === "POM_GET_CHARGE_FAIL" ||
      pomStatus === "POM_SEARCH_CHARGE_FAIL"
    ) {
      setAlertPermision(false);
    }
  }, [pomStatus]);

  useEffect(() => {
    if (params && params?.startTime > params?.endTime) {
      setParams({ ...params, endTime: null });
    }
  }, [params?.startTime, params?.endTime]);

  const handleConfirm = () => {
    try {
      let payload = {};
      switch (statusConfirm) {
        case POM_UPDATE_STATUS_TOP_UP.CHECKED:
        case POM_UPDATE_STATUS_TOP_UP.RECHECKED:
          payload = {
            approveIds: selected,
            rejectIds: [],
            status: params.tab,
            systemId: 1,
          };
          break;
        case POM_UPDATE_STATUS_TOP_UP.REJECTED:
          payload = {
            approveIds: [],
            rejectIds: selected,
            status: params.tab,
            systemId: 1,
          };
          break;
      }
      pomChangeStatus(payload, (action, result) => {
        if (action === ACTIONS.POM_CHANGE_STATUS_TOP_UP_SUCCESS) {
          closeConfirm();
          handleSearch();
        
          if (result?.rejectSuccess.length < 0 ){
            setRejectingFailed(true)
          }else{
            statusConfirm === POM_UPDATE_STATUS_TOP_UP.REJECTED
            ? setRejectingSuccess(true)
            : setStatusTopUp(true);
          }

          const timeout = setTimeout(() => {
            handleAdminAmount();
            statisticOfChargesByStatus();
            clearTimeout(timeout);
          }, 2000);
        }
        if (action === ACTIONS.POM_CHANGE_STATUS_TOP_UP_FAIL) {
          closeConfirm();
          statusConfirm === POM_UPDATE_STATUS_TOP_UP.REJECTED
          ? setRejectingFailed(true)
          :  setBalanceNotEnough(true);
         
        }
      });
    } catch (error) {}
  };
  const handlePushNotification = (values) => {
    pushNotification({
      title: values.title,
      content: values.content,
    });
    closeConfirm();
    setIsPushNotificationSuccess(true);
  };
  const handleConfirmEditMemo = (values) => {
    try {
      onChangeNote(
        {
          note: values?.note,
          id: itemEditMemo?._id,
        },
        (action) => {
          if (action === ACTIONS.POM_CHANGE_NOTE_SUCCESS) {
            closeEditMemo();
            handleSearch();
          }
        }
      );
    } catch (error) {}
  };

  const handleSubmitNote = (value) => {
    try {
      let payload = {};

      payload = {
        approveIds: [],
        rejectIds: selected,
        status: params.tab,
        note: value?.note,
        systemId: 1,
      };

      pomChangeStatus(payload, (action, result) => {
        if (action === ACTIONS.POM_CHANGE_STATUS_TOP_UP_SUCCESS) {
          closeRejection();
          handleSearch();
         if (result?.rejectSuccess.length <= 0 ){
          setRejectingFailed(true)
          }else{
            setRejectingSuccess(true)
          }
        }
        if (action === ACTIONS.POM_CHANGE_STATUS_TOP_UP_FAIL) {
          closeRejection();
          setRejectingFailed(true)
        }
      });
    } catch (error) {}
  };

  const handleGetList = (payload) => {
    openAlert();

    handleGetChargeRequest(
      payload || {
        limit: params.limit,
        page: params.page,
        status: params.tab,
        search: params.keyWord,
        startTime: params.startTime,
        endTime: params.endTime,
        // businessId: null,
      }
    );
    setInit(true);
  };

  const handleSearch = () => {
    let startDate = params.startTime ? params.startTime : "";
    let endDate = params.endTime ? moment(params.endTime).endOf("date") : "";
    if (status === ACTIONS.POM_SEARCH_CHARGE_PROGRESS || !init) {
      return;
    }
    openAlert();
    chargeSearch({
      startTime: startDate,
      endTime: endDate,
      search: params.keyWord,
      page: params.page,
      limit: params.limit,
      status: params.tab,
    });
  };

  useEffect(() => {
    getInfoUser();
    statisticOfChargesByStatus();
    handleAdminAmount();
  }, []);

  useEffect(() => {
    if (statusGetUserInfo === ACTIONMB.GET_INFO_USER_SUCCESS) {
      const permissionArr = userInfo && userInfo.roleId?.permission;
      const startedRoute = _.chain(permissionArr)
        .reduce((rs, item) => {
          if (_.startsWith(item, "pom-")) {
            rs = _.concat(rs, item);
          }
          return rs;
        }, [])
        .head()
        .replace("pom-", "")
        .value();

      if (startedRoute) {
        setParams({
          limit: 1000,
          page: 1,
          startTime: null,
          endTime: null,
          filterBy: "",
          keyWord: "",
          tab: startedRoute,
        });
        handleGetList({
          limit: 1000,
          page: 1,
          startTime: null,
          endTime: null,
          filterBy: "",
          keyWord: "",
          status: startedRoute,
        });
      }
    }
  }, [statusGetUserInfo]);



  useEffect(() => {
    handleSearch();
  }, [
    params?.startTime,
    params?.endTime,
    params?.tab,
    params?.page,
    params?.limit,
  ]);

  const handleAdminAmount = () => {
    getAdminAmount({
      systemId: 12,
    });
  };

  const handleChange = (e) => {
    setParams({ ...params, [e.target.name]: e.target.value });
  };

  const handleClearStatus = () => {
    clearSts();
  };

  const messageError = (error) => {
    switch (error) {
      case "Source wallet balance is not enough token!":
        return t("not_enough_token");
      case "System error":
        return t("not_enough_token");
      case "REFUND_FAILED":
        return t("REFUND_FAILED");
      default:
        return t("DepositToSystem.MaxBalances");
    }
  };

  return (
    <div className="huv-styled-charge-request-list">
      <div className="title">
        <div className="title-content">
          {t("TopUpPage.ChargeApplicationList")}
        </div>
      </div>
      <div className="charge-request-list-content">
        {params && (
          <Search
            setStatusConfirm={setStatusConfirm}
            statisticChargeByStatus={statisticChargeByStatus}
            params={params}
            handleChange={handleChange}
            handleSearch={handleSearch}
            pages={pages}
            charges={charges}
            setParams={setParams}
            openConfirm={openConfirm}
            selected={selected}
            setSelected={setSelected}
            totalUSD={totalUSD}
            totalTCT={totalTCT}
            adminBalance={adminBalance}
            openRejection={openRejection}
            userInfo={userInfo}
            totalGCCT={totalGCCT}
            totalCCT={totalCCT}
            totalROY={totalROY}
            totalGROY={totalGROY}
          />
        )}
        <ChargeRequestListTabled
          charges={charges}
          selected={selected}
          setSelected={setSelected}
          tab={params?.tab}
          openEditMemo={openEditMemo}
        />
        <Alert
          selected={selected}
          charges={charges}
          isConfirm={isConfirm}
          closeConfirm={() => {
            setStatusConfirm(null);
            closeConfirm();
          }}
          handleConfirm={handleConfirm}
          tab={params?.tab}
          alertAck={alertAck}
          closeAlert={closeAlert}
          itemEditMemo={itemEditMemo}
          closeEditMemo={closeEditMemo}
          handleConfirmEditMemo={handleConfirmEditMemo}
          handlePushNotification={handlePushNotification}
          isRejection={isRejection}
          closeRejection={() => {
            setStatusConfirm(null);
            closeRejection();
          }}
          handleSubmitNote={handleSubmitNote}
        />
        {isPushNotificationSuccess && (
          <AlertComponent
            title={t("Alert.Success")}
            content={t("pushNotificationSuccess")}
            okTitle={t("Alert.OK")}
            onOK={() => setIsPushNotificationSuccess(false)}
          />
        )}
        {statusTopUp && (
          <AlertComponent
            title={t("Alert.Success")}
            content={t("changeStatusTopUpSuccess")}
            okTitle={t("Alert.OK")}
            onOK={() => setStatusTopUp(false)}
          />
        )}
        {rejectingSuccess && (
          <AlertComponent
            title={t("Alert.Success")}
            content={t("rejectingSuccess")}
            okTitle={t("Alert.OK")}
            onOK={() => setRejectingSuccess(false)}
          />
        )}
         {rejectingFailed && (
          <AlertComponent
            title={t("Alert.failed")}
            content={t("rejectingFail")}
            okTitle={t("Alert.OK")}
            onOK={() => setRejectingFailed(false)}
          />
        )}
        {balanceNotEnough && (
          <AlertComponent
            title={t("Alert.Error")}
            content={messageError(error.code)}
            okTitle={t("Alert.OK")}
            onOK={() => setBalanceNotEnough(false)}
          />
        )}
        {[ACTIONS.POM_GET_CHARGE_FAIL, ACTIONS.POM_SEARCH_CHARGE_FAIL].indexOf(
          status
        ) >= 0 &&
          !alertPermission && (
            <AlertComponent
              title={t("Alert.Error")}
              content={t("Code.PERMISSION_DENIED")}
              okTitle={t("Alert.OK")}
              onOK={() => {
                handleClearStatus();
                setAlertPermision(true);
              }}
            />
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    status: state.pom.pomStatus,
    error: state.pom.error,
    charges: state.pom.pomCharges,
    pages: state.pom.pages,
    statisticChargeByStatus: state.pom.pomStatisticChargeByStatus,
    totalTCT: state.pom.totalTCT,
    totalUSD: state.pom.totalUSD,
    pomStatus: state.pom.pomStatus,
    adminBalance: state.charge.adminBalance,
    userInfo: state.member.infoUser,
    statusGetUserInfo: state.member.status,
    totalGCCT: state.pom.totalGCCT,
    totalCCT: state.pom.totalCCT,
    totalROY: state.pom.totalROY,
    totalGROY: state.pom.totalGROY
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetChargeRequest: (data) => {
      dispatch(PomGetChargeRequest(data));
    },
    handleRejectCharge: (data) => {
      // dispatch(rejectCharge(data));
    },
    handleReBalanceCharge: (data) => {
      // dispatch(approvalCharge(data));
    },
    chargeSearch: (data) => {
      dispatch(PomChargeSearch(data));
    },
    statisticOfChargesByStatus: () => {
      dispatch(PomGetStatisticChargeByStatus());
    },
    pomChangeStatus: (data, callback) => {
      dispatch(pomChangeStatus(data, callback));
    },
    onChangeNote: (data, callback) => {
      dispatch(pomChangeNote(data, callback));
    },
    pushNotification: (data) => {
      dispatch(pomPushNotification(data));
    },
    getAdminAmount: (data) => {
      dispatch(getChargeAdminAmount(data));
    },
    clearSts: () => {
      dispatch(PomClearStatus());
    },
    getInfoUser: () => {
      dispatch(getInfoUser());
    },
  };
};

export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(PomChargeRequestList)
);
