import React, { Component } from "react";
import {
    Button,
    Input,
    Label,
    FormGroup
} from "reactstrap";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import "./style.css";
import { ACTIONS } from "../../../../01-actions/member";
import Alert from "../../../../03-components/Alert";
import SettingSecurity from "./SettingSecurity";

import "react-datepicker/dist/react-datepicker.css";
import {changePassword, getUsers} from "../../../../01-actions/member";

class TFASetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            newPassword: '',
            confirmPassword: '',
            alertAck: true,
            isLengthPassword: false,
            isMatchPassword: false,
            isPassword: false
        };
    }

    componentDidMount() {
        this.alertAck();
    }

    alertAck = () => {
        this.setState({ alertAck: false });
    };

    // handleValueChange(field) {
    //     return (e) => {
    //         if (e.target.value.length >= 100) return;
    //         this.setState({[field]: e.target.value});
    //     }
    // }
 

    onChangeValue = (event) =>{
        let value = event.target.value
        let name = event.target.name
        
        if(value.length > 100){
          
            return (this.setState({
                isLengthPassword: true
            }));
        }else{
            this.setState({
                [name]:value
            })
        }
    }

    handleChangePassword = () => {
        const {confirmPassword, newPassword, password} = this.state
        let regularExpression = /^([A-z0-9!@#$%^&*().,<>{}[\]<>?_=+\-|;:\'\"\/])*[^\s]\1*$/;
        let validatePassword = regularExpression.test(newPassword)

        if(!validatePassword){
            return (
                this.setState({
                    isPassword: true
                })
                   
            )
        }

        if (confirmPassword !== newPassword) {
            return (
                this.setState({
                    isMatchPassword: true
                })
            )
        }

        this.props.changePassword({
            password: password,
            newPassword: newPassword,
            confirmPassword: confirmPassword
        });
        this.alertAck();
    }

    render() {
        const { t } = this.props;
        const {isLengthPassword, isMatchPassword, isPassword} = this.state
        return (
            <div className="pages-auth-change-password">
                <div className='title-header'>
                <span>
                    {t('TFASetting.ChangePassword')}
                </span>
                </div>
                <fieldset>
                    <FormGroup>
                        <Label>{t('TFASetting.OldPassword')}</Label>
                        <Input className="input-style"
                            value={this.state.password}
                            name="password"
                            onChange={this.onChangeValue}
                            onPaste={(e) => {
                                e.preventDefault();
                                return false;
                            }}
                            type="password"/>
                    </FormGroup>
                    <FormGroup>
                        <Label>{t('TFASetting.NewPassword')}</Label>
                        <Input className="input-style"
                            value={this.state.newPassword}
                            name="newPassword"
                            onChange={this.onChangeValue}
                            onPaste={(e) => {
                                e.preventDefault();
                                return false;
                            }}
                        //    onChange={this.handleValueChange('newPassword')}
                            type="password"/>
                    </FormGroup>
                    <FormGroup>
                        <Label>{t('TFASetting.NewPasswordRepaid')}</Label>
                        <Input className="input-style"
                            name="confirmPassword"
                            value={this.state.confirmPassword}
                            onChange={this.onChangeValue}
                            onPaste={(e) => {
                                e.preventDefault();
                                return false;
                            }}
                            // onChange={this.handleValueChange('confirmPassword')}
                            type="password"
                            maxlength={100}
                        />
                    </FormGroup>

                    <div className="text-center">
                        <Button
                            className={this.props.status === ACTIONS.CHANGE_PASSWORD_PROGRESS ? "progress-button button-submit" : "button-submit"}
                            type='primary'
                            onClick={this.handleChangePassword}
                            disabled={this.props.status === ACTIONS.CHANGE_PASSWORD_PROGRESS || !this.state.password || !this.state.newPassword || !this.state.confirmPassword}
                        >{t('TFASetting.Submit')}</Button>
                    </div>
                </fieldset>
                {[
                    ACTIONS.CHANGE_PASSWORD_SUCCESS
                ].indexOf(this.props.status) >= 0 &&
                !this.state.alertAck && (
                    <Alert
                        title={t("Alert.Success")}
                        content={t("TFASetting.ChangePasswordSuccess")}
                        okTitle={t("Alert.OK")}
                        onOK={() => {
                            this.setState({
                                alertAck: true,
                                password: '',
                                newPassword: '',
                                confirmPassword: '',
                            });
                        }}
                    />
                )}
                {[
                    ACTIONS.CHANGE_PASSWORD_FAILED
                ].indexOf(this.props.status) >= 0 &&
                !this.state.alertAck && (
                    <Alert
                        title={t("Alert.Error")}
                        content={t("TFASetting.INVALID_CREDENTIAL")}
                        okTitle={t("Alert.OK")}
                        onOK={() => {
                            this.setState({ alertAck: true });
                        }}
                    />
                )}
                {isLengthPassword && (
                    <Alert
                        title={t("Alert.Error")}
                        content={t("errors.PASSWORD_MAX_LENGTH")}
                        okTitle={t("Alert.OK")}
                        onOK={() => {
                            this.setState({ isLengthPassword: false });
                        }}
                    />
                )}

                {isMatchPassword && (
                    <Alert
                        title={t("Alert.Error")}
                        content={t("errors.PASSWORD_NOT_MATCH")}
                        okTitle={t("Alert.OK")}
                        onOK={() => {
                            this.setState({ isMatchPassword: false });
                        }}
                    />
                )}

                {isPassword && (
                    <Alert
                        title={t("Alert.Error")}
                        content={t("errors.PASSWORD_NOT_TRUE")}
                        okTitle={t("Alert.OK")}
                        onOK={() => {
                            this.setState({ isPassword: false });
                        }}
                    />
                )}
                <SettingSecurity t={t} />
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        status: store.member.status,
        users: store.member.users,
        memberError: store.member.memberError
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => {
            dispatch(getUsers());
        },
        changePassword: (data) => {
            dispatch(changePassword(data));
        }
    };
};

export default withNamespaces()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TFASetting)
);
