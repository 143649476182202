import React, { Component } from "react";
import Confirm from "../../../../03-components/Confirm";
import {
    Button,
    Input,
    FormGroup
} from "reactstrap";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {ACTIONS, verifyCodeQR} from "../../../../01-actions/member";

class VerifyAuthenticator extends Component {
    constructor (props) {
        super(props)
        this.state = {
            code: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.status === ACTIONS.VERIFY_CODE_QR_SUCCESS) {
            this.setState({code: ''});
        }
    }

    onChangeValue = (code) => {
        return (e) => {
            this.setState({[code]: e.target.value});
        }
    }

    onClickVerifyAuthenticator = () => {
        if (!this.state.code) return;
        this.props.verifyCodeQR({
            code: this.state.code
        })
    }

    onClose = () => {
        const {close} = this.props
        this.setState({code: ''});
        close()
    }

    copyToClipboard = () => {
        let value = this.props.secretInfo ? this.props.secretInfo.gaSecret : '';
        navigator.clipboard.writeText(value);
    }

    render() {
        const {t, isOpenVerify, secretInfo} = this.props
        return (
            <div>
                <Confirm
                    isOpen={isOpenVerify}
                    destroyOnClose
                    maskClosable={false}
                    className="tfa-setting"
                    title={t('TFASetting.TfaSetting')}
                >
                    <div className='box-ga-secret mb-3'>
                        <label>
                            {t('TFASetting.GaSecret')}
                        </label>
                        <div className='ga-secret'>
                            <Input value={secretInfo && secretInfo.gaSecret}
                                   disabled
                            />
                            <div className="input-group-append">
                                <span onClick={this.copyToClipboard}>
                                    <i className="fas fa-copy"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                        <div className='text-center'>
                            <p>
                                {t('TFASetting.QrContent')}
                            </p>
                            <img src={secretInfo && secretInfo.qrData} alt=""
                                 className='qr-code'/>
                            <p>
                                {t('TFASetting.ContentCode')}
                            </p>
                        </div>
                        <FormGroup
                            name="code"
                        >
                            <div>
                                <Input
                                    className='text-center tfa-code'
                                    value={this.state.code}
                                    onChange={this.onChangeValue('code')}
                                    placeholder={t('TFASetting.TfaCode')}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup className='mb-0 text-right'>
                            <Button className='mr-3 btn-cancel-popup' color='danger'
                                    onClick={this.onClose}
                                    disabled={this.props.status === ACTIONS.VERIFY_CODE_QR_PROGRESS}
                            >
                                {t('TFASetting.Cancel')}
                            </Button>
                            <Button
                                type='primary'
                                className={this.props.status === ACTIONS.VERIFY_CODE_QR_PROGRESS ? "progress-button btn-submit-popup" : "btn-submit-popup"}
                                disabled={!this.state.code || this.props.status === ACTIONS.VERIFY_CODE_QR_PROGRESS}
                                onClick={this.onClickVerifyAuthenticator}
                            >
                                {t('TFASetting.Submit')}
                            </Button>
                        </FormGroup>
                </Confirm>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        status: store.member.status,
        secretInfo: store.member.secretInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        verifyCodeQR: (data) => {
            dispatch(verifyCodeQR(data));
        },
    };
};

export default withNamespaces() (
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(VerifyAuthenticator)
);
