import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import {
    Label,
    FormGroup,
    Input,
    Button,
} from 'reactstrap';
import Alert from '../../../../03-components/Alert';
import {ACTIONS, forgotPassword} from '../../../../01-actions/auth';
import './style.css';

class ForgotPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: '',
            email: '',
            alertAck: false
        };
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleForgotPassword = this.handleForgotPassword.bind(this);
        this.alertAck = this.alertAck.bind(this);
    }

    handleValueChange(field) {
        return (e) => {
            this.setState({[field]: e.target.value});
        }
    }

    handleForgotPassword() {
        this.setState({alertAck: false});
        this.props.forgotPassword({id: this.state.id, email: this.state.email});
    }

    alertAck() {
        this.setState({alertAck: true});
    }

    render() {
        return (
            <div className="pages-auth-forgot-password">
                {this.props.authStatus === ACTIONS.FORGOT_PASSWORD_SUCCESS && (
                    <Redirect to="/auth/login"/>
                )}
                <fieldset disabled={this.props.authStatus === ACTIONS.FORGOT_PASSWORD_PROGRESS}>
                    <FormGroup>
                        <Label>Administrator ID</Label>
                        <Input className="input-style" value={this.state.id}
                               onChange={this.handleValueChange('id')}
                               type="text"/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Email</Label>
                        <Input className="input-style" value={this.state.email}
                               onChange={this.handleValueChange('email')}
                               type="text"/>
                    </FormGroup>
                    <div>
                        <Link to={'/auth/login'} className="text-small">Login to Administrator</Link>
                    </div>
                    <div className="text-center">
                        <Button
                            className={this.props.authStatus === ACTIONS.FORGOT_PASSWORD_PROGRESS ? "progress-button custom-btn" : "custom-btn"}
                            onClick={this.handleForgotPassword}
                            disabled={!this.state.id || !this.state.email}
                            color="secondary"
                            outline
                        >Send</Button>
                    </div>
                </fieldset>
                {(this.props.authStatus === ACTIONS.FORGOT_PASSWORD_FAILED) && !this.state.alertAck && (
                    <Alert title="Error"
                           content={this.props.authError.code}
                           okTitle="OK"
                           onOK={() => {
                               this.setState({alertAck: true});
                           }}/>
                )}
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        authStatus: store.auth.status,
        authError: store.auth.authError,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        forgotPassword: (data) => {
            dispatch(forgotPassword(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);