import {callApi} from "../05-utils/commonUtils";

const ACTIONS = {
    GET_USER_PROGRESS: "GET_USER_PROGRESS",
    GET_USER_SUCCESS: "GET_USER_SUCCESS",
    GET_USER_FAILED: "GET_USER_FAILED",
    DELETE_USER_PROGRESS: "DELETE_USER_PROGRESS",
    DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
    DELETE_USER_FAILED: "DELETE_USER_FAILED",
    CREATE_USER_PROGRESS: "CREATE_USER_PROGRESS",
    CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
    CREATE_USER_FAILED: "CREATE_USER_FAILED",
    UPDATE_USER_PROGRESS: "UPDATE_USER_PROGRESS",
    UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
    UPDATE_USER_FAILED: "UPDATE_USER_FAILED",
    GET_INFO_PROGRESS: "GET_INFO_PROGRESS",
    GET_INFO_SUCCESS: "GET_INFO_SUCCESS",
    GET_INFO_FAILED: "GET_INFO_FAILED",
    GET_INFO_USER_PROGRESS: "GET_INFO_USER_PROGRESS",
    GET_INFO_USER_SUCCESS: "GET_INFO_USER_SUCCESS",
    GET_INFO_USER_FAILED: "GET_INFO_USER_FAILED",
    SETTING_GA_PROGRESS: "SETTING_GA_PROGRESS",
    SETTING_GA_SUCCESS: "SETTING_GA_SUCCESS",
    SETTING_GA_FAILED: "SETTING_GA_FAILED",
    RESET_GA_PROGRESS: "RESET_GA_PROGRESS",
    RESET_GA_SUCCESS: "RESET_GA_SUCCESS",
    RESET_GA_FAILED: "RESET_GA_FAILED",
    VERIFY_CODE_QR_PROGRESS: "VERIFY_CODE_QR_PROGRESS",
    VERIFY_CODE_QR_SUCCESS: "VERIFY_CODE_QR_SUCCESS",
    VERIFY_CODE_QR_FAILED: "VERIFY_CODE_QR_FAILED",
    CHANGE_PASSWORD_PROGRESS: "CHANGE_PASSWORD_PROGRESS",
    CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
    CHANGE_TFA_LOGIN_PROGRESS: "CHANGE_TFA_LOGIN_PROGRESS",
    CHANGE_TFA_LOGIN_SUCCESS: "CHANGE_TFA_LOGIN_SUCCESS",
    CHANGE_TFA_LOGIN_FAILED: "CHANGE_TFA_LOGIN_FAILED"
};

const updateStatus = (status, statusInfo) => {
    return {
        type: status,
        data: statusInfo
    }
};
const getUsers = () => {
    let config = {
        method: 'get',
        isAuthorization: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_USER_PROGRESS));
        callApi('/api/get_all_users',
            config,
            null,
            (data) => {
                dispatch(updateStatus(ACTIONS.GET_USER_SUCCESS, {users: data}));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.GET_USER_FAILED, {error: err}));
            }
        );
    };
};
const getInfo = () => {
    let config = {
        method: 'get',
        isAuthorization: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_INFO_PROGRESS));
        callApi('/api/get_info_sign_up',
            config,
            null,
            (data) => {
                dispatch(updateStatus(ACTIONS.GET_INFO_SUCCESS, {info: data}));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.GET_INFO_FAILED, {error: err}));
            }
        );
    };
};

const deleteMember = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.DELETE_USER_PROGRESS));
        callApi('/api/delete_member',
            config,
            null,
            () => {
                dispatch(updateStatus(ACTIONS.DELETE_USER_SUCCESS, {data}));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.DELETE_USER_FAILED, {error: err}));
            }
        );
    };
};

const createUser = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.CREATE_USER_PROGRESS));
        callApi('/api/add_member',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.CREATE_USER_SUCCESS, {user: result}));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.CREATE_USER_FAILED, {error: err}));
            }
        );
    };
};

const editMember = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.UPDATE_USER_PROGRESS));
        callApi('/api/edit_member',
            config,
            null,
            () => {
                dispatch(updateStatus(ACTIONS.UPDATE_USER_SUCCESS, {data}));
            },
            (err) => {

                dispatch(updateStatus(ACTIONS.UPDATE_USER_FAILED, {error: err}));
            }
        );
    };
};

const getInfoUser = () => {
    let config = {
        method: 'get',
        isAuthorization: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_INFO_USER_PROGRESS));
        callApi('/api/get_info',
            config,
            null,
            (data) => {
                dispatch(updateStatus(ACTIONS.GET_INFO_USER_SUCCESS, {infoUser: data}));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.GET_INFO_USER_FAILED, {error: err}));
            }
        );
    };
};

const settingGa = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.SETTING_GA_PROGRESS));
        callApi('/api/setting_ga',
            config,
            null,
            (data) => {
                dispatch(updateStatus(ACTIONS.SETTING_GA_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.SETTING_GA_FAILED, {error: err}))
            }
        )
    }
}

const resetGa = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.RESET_GA_PROGRESS));
        callApi('/api/reset_ga',
            config,
            null,
            (data) => {
                dispatch(updateStatus(ACTIONS.RESET_GA_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.RESET_GA_FAILED, {error: err}))
            }
        )
    }
}

const verifyCodeQR = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: data
    }

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.VERIFY_CODE_QR_PROGRESS));
        callApi('/api/verify_ga',
            config,
            null,
            (data) => {
                dispatch(updateStatus(ACTIONS.VERIFY_CODE_QR_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.VERIFY_CODE_QR_FAILED, {error: err}))
            }
        )
    }
}

const changePassword = (data) => {
    let config = {
        method: 'put',
        isAuthorization: true,
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.CHANGE_PASSWORD_PROGRESS));
        callApi('/api/change_password',
            config,
            null,
            (data) => {
                dispatch(updateStatus(ACTIONS.CHANGE_PASSWORD_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.CHANGE_PASSWORD_FAILED, {error: err}))
            }
        )
    }
}

const changeTfaLogin = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.CHANGE_TFA_LOGIN_PROGRESS));
        callApi('/api/tfa_login_status',
            config,
            null,
            (data) => {
                dispatch(updateStatus(ACTIONS.CHANGE_TFA_LOGIN_SUCCESS, {data}))
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.CHANGE_TFA_LOGIN_FAILED, {error: err}))
            }
        )
    }
}

export {ACTIONS, getUsers, deleteMember, createUser, editMember, getInfo, getInfoUser, settingGa, changePassword, resetGa, verifyCodeQR, changeTfaLogin};