import { callApi } from "../05-utils/commonUtils";

const ACTIONS = {
  GET_RATE_SETTING_PROGRESS: "GET_RATE_SETTING_PROGRESS",
  GET_RATE_SETTING_SUCCESS: "GET_RATE_SETTING_SUCCESS",
  GET_RATE_SETTING_FAILED: "GET_RATE_SETTING_FAILED",
  HUV_GET_RATE_SETTING_PROGRESS: "HUV_GET_RATE_SETTING_PROGRESS",
  HUV_GET_RATE_SETTING_SUCCESS: "HUV_GET_RATE_SETTING_SUCCESS",
  HUV_GET_RATE_SETTING_FAILED: "HUV_GET_RATE_SETTING_FAILED",
  GET_RATE_LIMIT_SETTING_PROGRESS: "GET_RATE_LIMIT_SETTING_PROGRESS",
  GET_RATE_LIMIT_SETTING_SUCCESS: "GET_RATE_LIMIT_SETTING_SUCCESS",
  GET_RATE_LIMIT_SETTING_FAILED: "GET_RATE_LIMIT_SETTING_FAILED",
  HUV_GET_RATE_LIMIT_SETTING_PROGRESS: "HUV_GET_RATE_LIMIT_SETTING_PROGRESS",
  HUV_GET_RATE_LIMIT_SETTING_SUCCESS: "HUV_GET_RATE_LIMIT_SETTING_SUCCESS",
  HUV_GET_RATE_LIMIT_SETTING_FAILED: "HUV_GET_RATE_LIMIT_SETTING_FAILED",
  UPDATE_RATE_SETTING_PROGRESS: "UPDATE_RATE_SETTING_PROGRESS",
  UPDATE_RATE_SETTING_SUCCESS: "UPDATE_RATE_SETTING_SUCCESS",
  UPDATE_RATE_SETTING_FAILED: "UPDATE_RATE_SETTING_FAILED",
  UPDATE_EXCEL_FINANCE_SETTING_PROGRESS:
    "UPDATE_EXCEL_FINANCE_SETTING_PROGRESS",
  UPDATE_EXCEL_FINANCE_SETTING_SUCCESS: "UPDATE_EXCEL_FINANCE_SETTING_SUCCESS",
  UPDATE_EXCEL_FINANCE_SETTING_FAILED:"UPDATE_EXCEL_FINANCE_SETTING_FAILED",
  HUV_UPDATE_EXCEL_FINANCE_SETTING_FAILED:
    "HUV_UPDATE_EXCEL_FINANCE_SETTING_FAILED",
  HUV_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS:
    "HUV_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS",
  HUV_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS:
    "HUV_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS",
  UPDATE_EZP_SETTING_PROGRESS: "UPDATE_EZP_SETTING_PROGRESS",
  UPDATE_EZP_SETTING_SUCCESS: "UPDATE_EZP_SETTING_SUCCESS",
  UPDATE_EZP_SETTING_FAILED: "UPDATE_EZP_SETTING_FAILED",
  HUV_UPDATE_EZP_SETTING_PROGRESS: "HUV_UPDATE_EZP_SETTING_PROGRESS",
  HUV_UPDATE_EZP_SETTING_SUCCESS: "HUV_UPDATE_EZP_SETTING_SUCCESS",
  HUV_UPDATE_EZP_SETTING_FAILED: "HUV_UPDATE_EZP_SETTING_FAILED",
  GET_LOG_SETTING_PROGRESS: "GET_LOG_SETTING_PROGRESS",
  GET_LOG_SETTING_SUCCESS: "GET_LOG_SETTING_SUCCESS",
  GET_LOG_SETTING_FAILED: "GET_LOG_SETTING_FAILED",
  UPDATE_MAX_TOP_UP_PROGRESS: "UPDATE_MAX_TOP_UP_PROGRESS",
  UPDATE_MAX_TOP_UP_SUCCESS: "UPDATE_MAX_TOP_UP_SUCCESS",
  UPDATE_MAX_TOP_UP_FAILED: "UPDATE_MAX_TOP_UP_FAILED",
  UPDATE_RATE_LIMIT_SETTING_PROGRESS: "UPDATE_RATE_LIMIT_SETTING_PROGRESS",
  UPDATE_RATE_LIMIT_SETTING_SUCCESS: "UPDATE_RATE_LIMIT_SETTING_SUCCESS",
  UPDATE_RATE_LIMIT_SETTING_FAILED: "UPDATE_RATE_LIMIT_SETTING_FAILED",
  HUV_UPDATE_RATE_LIMIT_SETTING_PROGRESS:
    "HUV_UPDATE_RATE_LIMIT_SETTING_PROGRESS",
  HUV_UPDATE_RATE_LIMIT_SETTING_SUCCESS:
    "HUV_UPDATE_RATE_LIMIT_SETTING_SUCCESS",
  HUV_UPDATE_RATE_LIMIT_SETTING_FAILED: "HUV_UPDATE_RATE_LIMIT_SETTING_FAILED",
  REFUND_AGT_PROGRESS: "REFUND_AGT_PROGRESS",
  REFUND_AGT_FAILED: "REFUND_AGT_FAILED",
  REFUND_AGT_SUCCESS: "REFUND_AGT_SUCCESS",
  RECEIVING_AGT_PROGRESS: "RECEIVING_AGT_PROGRESS",
  RECEIVING_AGT_FAILED: "RECEIVING_AGT_FAILED",
  RECEIVING_AGT_SUCCESS: "RECEIVING_AGT_SUCCESS",
  HUV_UPDATE_SETTING_RATE_PROGRESS: "HUV_UPDATE_SETTING_RATE_PROGRESS",
  HUV_UPDATE_SETTING_RATE_SUCCESS: "HUV_UPDATE_SETTING_RATE_SUCCESS",
  HUV_UPDATE_SETTING_RATE_FAILED: "HUV_UPDATE_SETTING_RATE_FAILED",
  POM_GET_RATE_LIMIT_SETTING_PROGRESS: "POM_GET_RATE_LIMIT_SETTING_PROGRESS",
  POM_GET_RATE_LIMIT_SETTING_SUCCESS: "POM_GET_RATE_LIMIT_SETTING_SUCCESS",
  POM_GET_RATE_LIMIT_SETTING_FAILED: "POM_GET_RATE_LIMIT_SETTING_FAILED",
  POM_GET_RATE_SETTING_PROGRESS: "POM_GET_RATE_SETTING_PROGRESS",
  POM_GET_RATE_SETTING_SUCCESS: "POM_GET_RATE_SETTING_SUCCESS",
  POM_GET_RATE_SETTING_FAILED: "POM_GET_RATE_SETTING_FAILED",
  POM_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS:
    "POM_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS",
  POM_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS:
    "POM_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS",
  POM_UPDATE_EXCEL_FINANCE_SETTING_FAILED:
    "POM_UPDATE_EXCEL_FINANCE_SETTING_FAILED",
  POM_UPDATE_EZP_SETTING_PROGRESS: "POM_UPDATE_EZP_SETTING_PROGRESS",
  POM_UPDATE_EZP_SETTING_SUCCESS: "POM_UPDATE_EZP_SETTING_SUCCESS",
  POM_UPDATE_EZP_SETTING_FAILED: "POM_UPDATE_EZP_SETTING_FAILED",
  POM_UPDATE_SETTING_RATE_PROGRESS: "POM_UPDATE_SETTING_RATE_PROGRESS",
  POM_UPDATE_SETTING_RATE_SUCCESS: "POM_UPDATE_SETTING_RATE_SUCCESS",
  POM_UPDATE_SETTING_RATE_FAILED: "POM_UPDATE_SETTING_RATE_FAILED",
  POM_UPDATE_RATE_LIMIT_SETTING_PROGRESS:
    "POM_UPDATE_RATE_LIMIT_SETTING_PROGRESS",
  POM_UPDATE_RATE_LIMIT_SETTING_SUCCESS:
    "POM_UPDATE_RATE_LIMIT_SETTING_SUCCESS",
  POM_UPDATE_RATE_LIMIT_SETTING_FAILED: "POM_UPDATE_RATE_LIMIT_SETTING_FAILED",
  POM_RECEIVING_AGT_PROGRESS: "POM_RECEIVING_AGT_PROGRESS",
  POM_RECEIVING_AGT_SUCCESS: "POM_RECEIVING_AGT_SUCCESS",
  POM_RECEIVING_AGT_FAILED: "POM_RECEIVING_AGT_FAILED",
  POM_REFUND_AGT_PROGRESS: "POM_REFUND_AGT_PROGRESS",
  POM_REFUND_AGT_SUCCESS: "POM_REFUND_AGT_SUCCESS",
  POM_REFUND_AGT_FAILED: "POM_REFUND_AGT_FAILED",
};

const updateStatus = (status, statusInfo) => {
  return {
    type: status,
    data: statusInfo,
  };
};

const getRateSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.GET_RATE_SETTING_PROGRESS));
    callApi(
      "/api/get_setting",
      config,
      null,
      (data) => {
        dispatch(
          updateStatus(ACTIONS.GET_RATE_SETTING_SUCCESS, { settings: data })
        );
      },
      (err) => {
        dispatch(updateStatus(ACTIONS.GET_RATE_SETTING_FAILED, { error: err }));
      }
    );
  };
};

const huvGetRateSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.HUV_GET_RATE_SETTING_PROGRESS));
    callApi(
      "/api/huv/get_setting",
      config,
      null,
      (res) => {
        dispatch(
          updateStatus(ACTIONS.HUV_GET_RATE_SETTING_SUCCESS, { settings: res })
        );
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.HUV_GET_RATE_SETTING_FAILED, { error: err })
        );
      }
    );
  };
};

const pomGetRateSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POM_GET_RATE_SETTING_PROGRESS));
    callApi(
      "/api/pom/get_setting",
      config,
      null,
      (res) => {
        dispatch(
          updateStatus(ACTIONS.POM_GET_RATE_SETTING_SUCCESS, { settings: res })
        );
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.POM_GET_RATE_SETTING_FAILED, { error: err })
        );
      }
    );
  };
};

const getRateLimitSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.GET_RATE_LIMIT_SETTING_PROGRESS));
    callApi(
      "/api/get_limit_titles",
      config,
      null,
      (data) => {
        dispatch(
          updateStatus(ACTIONS.GET_RATE_LIMIT_SETTING_SUCCESS, {
            limitSettings: data,
          })
        );
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.GET_RATE_LIMIT_SETTING_FAILED, { error: err })
        );
      }
    );
  };
};

const huvGetRateLimitSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.HUV_GET_RATE_LIMIT_SETTING_PROGRESS));
    callApi(
      "/api/huv/get_limit_titles",
      config,
      null,
      (res) => {
        dispatch(
          updateStatus(ACTIONS.HUV_GET_RATE_LIMIT_SETTING_SUCCESS, {
            limitSettings: res,
          })
        );
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.HUV_GET_RATE_LIMIT_SETTING_FAILED, {
            error: err,
          })
        );
      }
    );
  };
};

const pomGetRateLimitSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POM_GET_RATE_LIMIT_SETTING_PROGRESS));
    callApi(
      "/api/pom/get_limit_titles",
      config,
      null,
      (res) => {
        dispatch(
          updateStatus(ACTIONS.POM_GET_RATE_LIMIT_SETTING_SUCCESS, {
            limitSettings: res,
          })
        );
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.POM_GET_RATE_LIMIT_SETTING_FAILED, {
            error: err,
          })
        );
      }
    );
  };
};

const getLogSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.GET_LOG_SETTING_PROGRESS));
    callApi(
      "/api/get_log_setting",
      config,
      null,
      (data) => {
        dispatch(
          updateStatus(ACTIONS.GET_LOG_SETTING_SUCCESS, { logSettings: data })
        );
      },
      (err) => {
        dispatch(updateStatus(ACTIONS.GET_LOG_SETTING_FAILED, { error: err }));
      }
    );
  };
};
const updateMaxGbt = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.UPDATE_MAX_TOP_UP_PROGRESS));
    callApi(
      "/api/update_gbt_max",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.UPDATE_MAX_TOP_UP_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.UPDATE_MAX_TOP_UP_FAILED, { error: err })
        );
      }
    );
  };
};

const updateRateLimit = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.UPDATE_RATE_LIMIT_SETTING_PROGRESS));
    callApi(
      "/api/update_limit_title",
      config,
      null,
      (data) => {
        dispatch(updateStatus(ACTIONS.UPDATE_RATE_LIMIT_SETTING_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.UPDATE_RATE_LIMIT_SETTING_FAILED, { error: err })
        );
      }
    );
  };
};

const huvUpdateRateLimit = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.HUV_UPDATE_RATE_LIMIT_SETTING_PROGRESS));
    callApi(
      "/api/huv/update_limit_title",
      config,
      null,
      (data) => {
        dispatch(updateStatus(ACTIONS.HUV_UPDATE_RATE_LIMIT_SETTING_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.HUV_UPDATE_RATE_LIMIT_SETTING_FAILED, {
            error: err,
          })
        );
      }
    );
  };
};
const pomUpdateRateLimit = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POM_UPDATE_RATE_LIMIT_SETTING_PROGRESS));
    callApi(
      "/api/pom/update_limit_title",
      config,
      null,
      (data) => {
        dispatch(updateStatus(ACTIONS.POM_UPDATE_RATE_LIMIT_SETTING_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.POM_UPDATE_RATE_LIMIT_SETTING_FAILED, {
            error: err,
          })
        );
      }
    );
  };
};

const updateExcelFinanceSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.UPDATE_EXCEL_FINANCE_SETTING_PROGRESS));
    callApi(
      "/api/update_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.UPDATE_EXCEL_FINANCE_SETTING_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.UPDATE_EXCEL_FINANCE_SETTING_FAILED, {
            error: err,
          })
        );
      }
    );
  };
};

const huvUpdateExcelFinanceSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.HUV_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS));
    callApi(
      "/api/huv/update_setting",
      config,
      null,
      () => {
        dispatch(
          updateStatus(ACTIONS.HUV_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS)
        );
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.HUV_UPDATE_EXCEL_FINANCE_SETTING_FAILED, {
            error: err,
          })
        );
      }
    );
  };
};

const pomUpdateExcelFinanceSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POM_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS));
    callApi(
      "/api/pom/update_setting",
      config,
      null,
      () => {
        dispatch(
          updateStatus(ACTIONS.POM_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS)
        );
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.POM_UPDATE_EXCEL_FINANCE_SETTING_FAILED, {
            error: err,
          })
        );
      }
    );
  };
};

const updateEzpSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.UPDATE_EZP_SETTING_PROGRESS));
    callApi(
      "/api/update_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.UPDATE_EZP_SETTING_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.UPDATE_EZP_SETTING_FAILED, { error: err })
        );
      }
    );
  };
};

const huvUpdateEzpSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.HUV_UPDATE_EZP_SETTING_PROGRESS));
    callApi(
      "/api/huv/update_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.HUV_UPDATE_EZP_SETTING_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.HUV_UPDATE_EZP_SETTING_FAILED, { error: err })
        );
      }
    );
  };
};

const pomUpdateEzpSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POM_UPDATE_EZP_SETTING_PROGRESS));
    callApi(
      "/api/pom/update_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.POM_UPDATE_EZP_SETTING_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.POM_UPDATE_EZP_SETTING_FAILED, { error: err })
        );
      }
    );
  };
};

const updateRateSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.UPDATE_EZP_SETTING_PROGRESS));
    callApi(
      "/api/update_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.UPDATE_EZP_SETTING_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.UPDATE_EZP_SETTING_FAILED, { error: err })
        );
      }
    );
  };
};

const huvUpdateRateSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.HUV_UPDATE_SETTING_RATE_PROGRESS));
    callApi(
      "/api/huv/update_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.HUV_UPDATE_SETTING_RATE_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.HUV_UPDATE_SETTING_RATE_FAILED, { error: err })
        );
      }
    );
  };
};
const pomUpdateRateSetting = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POM_UPDATE_SETTING_RATE_PROGRESS));
    callApi(
      "/api/pom/update_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.POM_UPDATE_SETTING_RATE_SUCCESS));
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.POM_UPDATE_SETTING_RATE_FAILED, { error: err })
        );
      }
    );
  };
};

const refundAGT = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.REFUND_AGT_PROGRESS));
    callApi(
      "/api/huv/update_agt_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.REFUND_AGT_SUCCESS));
      },
      (err) => {
        dispatch(updateStatus(ACTIONS.REFUND_AGT_FAILED, { error: err }));
      }
    );
  };
};

const pomRefundAGT = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POM_REFUND_AGT_PROGRESS));
    callApi(
      "/api/pom/update_wallet_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.POM_REFUND_AGT_SUCCESS));
      },
      (err) => {
        dispatch(updateStatus(ACTIONS.POM_REFUND_AGT_FAILED, { error: err }));
      }
    );
  };
};

const receivingAGT = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.RECEIVING_AGT_PROGRESS));
    callApi(
      "/api/huv/update_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.RECEIVING_AGT_SUCCESS));
      },
      (err) => {
        dispatch(updateStatus(ACTIONS.RECEIVING_AGT_FAILED, { error: err }));
      }
    );
  };
};

const pomReceivingAGT = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.POM_RECEIVING_AGT_PROGRESS));
    callApi(
      "/api/pom/update_setting",
      config,
      null,
      () => {
        dispatch(updateStatus(ACTIONS.POM_RECEIVING_AGT_SUCCESS));
      },
      (err) => {
        dispatch(updateStatus(ACTIONS.POM_RECEIVING_AGT_FAILED, { error: err }));
      }
    );
  };
};

export {
  ACTIONS,
  huvUpdateEzpSetting,
  huvUpdateRateSetting,
  huvGetRateSetting,
  huvGetRateLimitSetting,
  huvUpdateRateLimit,
  huvUpdateExcelFinanceSetting,
  getRateSetting,
  getRateLimitSetting,
  updateMaxGbt,
  updateExcelFinanceSetting,
  updateEzpSetting,
  getLogSetting,
  updateRateSetting,
  updateRateLimit,
  refundAGT,
  receivingAGT,
  pomGetRateLimitSetting,
  pomGetRateSetting,
  pomUpdateExcelFinanceSetting,
  pomUpdateEzpSetting,
  pomUpdateRateSetting,
  pomUpdateRateLimit,
  pomReceivingAGT,
  pomRefundAGT,
};
