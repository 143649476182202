import {callApi} from "../05-utils/commonUtils";

const ACTIONS = {
    LOGIN_PROGRESS: "LOGIN_PROGRESS",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILED: "LOGIN_FAILED",
    FORGOT_PASSWORD_PROGRESS: "FORGOT_PASSWORD_PROGRESS",
    FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
    FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",
    GET_LOG_LOCATION_PROGRESS: "GET_LOG_LOCATION_PROGRESS",
    GET_LOG_LOCATION_SUCCESS: "GET_LOG_LOCATION_SUCCESS",
    GET_LOG_LOCATION_FAIL: "GET_LOG_LOCATION_FAIL",
    LOGOUT: "LOGOUT",
};

const updateStatus = (status, statusInfo) => {
    return {
        type: status,
        data: statusInfo
    }
};
const login = (data) => {
    let config = {
        method: 'post',
        body: {
            id: data.username,
            password: data.password,
            code: data.code
        }
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.LOGIN_PROGRESS));
        callApi('/api/login',
            config,
            null,
            (data) => {
                dispatch(updateStatus(ACTIONS.LOGIN_SUCCESS, {user: data}));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.LOGIN_FAILED, {error: err}));
            }
        );
    };
};
const forgotPassword = (data) => {
    let config = {
        method: 'post',
        body: data
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.FORGOT_PASSWORD_PROGRESS));
        callApi('/api/forgot_password',
            config,
            null,
            () => {
                dispatch(updateStatus(ACTIONS.FORGOT_PASSWORD_SUCCESS));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.FORGOT_PASSWORD_FAILED, {error: err}));
            }
        );
    };
};

const getAllLocation = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: {
            page: data.page,
            limit: 50,
        }
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_LOG_LOCATION_PROGRESS));
        callApi('/api/get_all_location',
            config,
            null,
            (data) => {
                dispatch(updateStatus(ACTIONS.GET_LOG_LOCATION_SUCCESS, {logIp: data.list, pages: data.pages}))
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.GET_LOG_LOCATION_FAIL, {error: err}));
            }
        )
    }
};


const logout = () => {
    return {
        type: ACTIONS.LOGOUT
    }
};

export {ACTIONS, login, forgotPassword, logout, getAllLocation};
