import React, { Fragment, useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { ACTIONS } from "../../../../../01-actions/pomo";
import AlertBase from "../../../../../03-components/Alert";
import Confirm from "../../../../../03-components/Confirm";
import { POMO_TOP_UP_TAB } from "../../../../../05-utils/commonData";
import { Col, Form, FormGroup, Label, Input } from "reactstrap";

const Alert = ({
  alertAck,
  status,
  error,
  closeAlert,
  isConfirm,
  closeConfirm,
  handleConfirm,
  tab,
  itemEditMemo,
  closeEditMemo,
  handleConfirmEditMemo,
  selected,
  charges,
  handlePushNotification,
  t,
  isRejection,
  closeRejection,
  handleSubmitNote,
  isLoading,
}) => {
  const [checkingParams, setCheckingParams] = useState({
    title: "",
    content: "",
    sure: false,
  });
  const [rejectingParams, setRejectingParams] = useState({
    note: "",
  });
  const [rejectionParams, setRejectionParams] = useState({
    note: "",
  });

  const handleChangeRejection = (event) => {
    const { name, value } = event.target;
    if (value.length > 100) return;
    setRejectionParams({ ...rejectionParams, [name]: value });
  };

  const handleChangeRejecting = (e) => {
    const { name, value } = e.target;
    if (value.length > 100) return;
    setRejectingParams({ ...rejectingParams, [name]: value });
  };
  const handleChangeChecking = (e) => {
    const { name, value } = e.target;
    setCheckingParams({ ...checkingParams, [name]: value });
  };
  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    handleChangeChecking({ target: { name, value: checked } });
  };

  useEffect(() => {
    if (isRejection && tab === POMO_TOP_UP_TAB.NotYetVerified.value) {
      setRejectionParams({ ...rejectionParams, note: isRejection.note || "" });
    }
  }, [isRejection]);

  useEffect(() => {
    if (isConfirm && tab === POMO_TOP_UP_TAB.Checking.value) {
      setCheckingParams({
        ...checkingParams,
        title: "",
        content: "",
        sure: false,
      });
    }
  }, [isConfirm]);

  useEffect(() => {
    if (itemEditMemo) {
      setRejectingParams({ note: itemEditMemo.note || "" });
    }
    return () => {
      setRejectingParams({ note: "" });
    };
  }, [itemEditMemo]);

  return (
    <Fragment>
      {[ACTIONS.POMO_GET_CHARGE_FAIL, ACTIONS.POMO_SEARCH_CHARGE_FAIL].indexOf(
        status
      ) >= 0 &&
        alertAck && (
          <AlertBase
            title={t("Alert.Error")}
            content={error.code}
            okTitle={t("Alert.OK")}
            onOK={closeAlert}
          />
        )}

      <Confirm
        isOpen={
          isConfirm &&
          [
            POMO_TOP_UP_TAB.NotYetVerified.value,
            POMO_TOP_UP_TAB.Rejecting.value,
          ].includes(tab)
        }
        toggle={closeConfirm}
        title={
          <Fragment>
            {`${t("HuvStyleTopUpPage.Confirm.NumberOfRequests")}: ${
              selected.length
            }`}
            <br />
            {`${t("HuvStyleTopUpPage.Confirm.USDTotal_2")}: ${
              selected.length !== 0 &&
              charges
                .filter((c) => selected.find((s) => s === c._id))
                .reduce(
                  (accumulator, currentValue) =>
                    accumulator + (currentValue.topUpAmount ?? 0),
                  0
                )
            }`}
          </Fragment>
        }
        labelCancel={t("Alert.Cancel")}
        labelSubmit={t("HuvStyleTopUpPage.Confirm.Execution")}
        onCancel={closeConfirm}
        onSubmit={() => handleConfirm(rejectingParams)}
        className="top-up-huv-not-verify-modal"
        disabledSubmit={isLoading}
      >
        {t("HuvStyleTopUpPage.Confirm.AreYouReallySure")}
      </Confirm>
      <Confirm
        isOpen={
          isRejection && [POMO_TOP_UP_TAB.NotYetVerified.value].includes(tab)
        }
        toggle={closeRejection}
        title={
          <Fragment>
            {`${t("HuvStyleTopUpPage.Confirm.NumberOfRequests")}: ${
              selected.length
            }`}
            <br />
            {`${t("HuvStyleTopUpPage.Confirm.USDTotal_2")}: ${
              selected.length !== 0 &&
              charges
                .filter((c) => selected.find((s) => s === c._id))
                .reduce(
                  (accumulator, currentValue) =>
                    accumulator + (currentValue.topUpAmount ?? 0),
                  0
                )
            }`}
          </Fragment>
        }
        labelCancel={t("Alert.Cancel")}
        labelSubmit={t("HuvStyleTopUpPage.Confirm.Execution")}
        onCancel={closeRejection}
        onSubmit={() => handleSubmitNote(rejectionParams)}
        className="top-up-huv-not-verify-modal"
        disabledSubmit={isLoading}
      >
        {t("HuvStyleTopUpPage.Confirm.AreYouReallySure")}
        <Form>
          <FormGroup row>
            <Label for="content" sm={4}>
              {t("HuvStyleTopUpPage.Table.Memo")}
            </Label>
            <Col sm={8}>
              <Input
                type="textarea"
                name="note"
                id="note"
                className="memo-input"
                onChange={handleChangeRejection}
                value={rejectionParams.note}
              />
            </Col>
          </FormGroup>
        </Form>
      </Confirm>
      <Confirm
        isOpen={isConfirm && tab === POMO_TOP_UP_TAB.Checking.value}
        toggle={closeConfirm}
        title=""
        labelCancel={t("Alert.Cancel")}
        labelSubmit={t("HuvStyleTopUpPage.Confirm.NotificationExecution")}
        onCancel={closeConfirm}
        onSubmit={() => handlePushNotification(checkingParams)}
        className="top-up-huv-checking-modal"
        disabledSubmit={
          !checkingParams.title ||
          !checkingParams.content ||
          !checkingParams.sure
        }
      >
        <Form>
          <FormGroup row>
            <Label for="title" sm={4}>
              {t("HuvStyleTopUpPage.Confirm.Title")}
            </Label>
            <Col sm={8}>
              <Input
                type="text"
                name="title"
                id="title"
                className="title-input"
                onChange={handleChangeChecking}
                value={checkingParams.title}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="content" sm={4}>
              {t("HuvStyleTopUpPage.Confirm.Content")}
            </Label>
            <Col sm={8}>
              <Input
                type="textarea"
                name="content"
                id="Content"
                className="content-input"
                onChange={handleChangeChecking}
                value={checkingParams.content}
              />
            </Col>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                name="sure"
                onChange={handleChangeCheckbox}
              />{" "}
              {t("HuvStyleTopUpPage.Confirm.AreYouReallySure")}
            </Label>
          </FormGroup>
        </Form>
      </Confirm>
      <Confirm
        isOpen={
          itemEditMemo &&
          [
            POMO_TOP_UP_TAB.Rejecting.value,
            POMO_TOP_UP_TAB.Rejected.value,
            POMO_TOP_UP_TAB.UncheckedToRejected.value,
          ].includes(tab)
        }
        toggle={closeEditMemo}
        title=""
        labelCancel={t("Alert.Cancel")}
        labelSubmit={t("Alert.OK")}
        onCancel={closeEditMemo}
        onSubmit={() => handleConfirmEditMemo(rejectingParams)}
        className="top-up-huv-rejecting-modal"
        disabledSubmit={!rejectingParams.note || isLoading}
      >
        <Form>
          <FormGroup row>
            <Label for="content" sm={4}>
              {t("HuvStyleTopUpPage.Table.Memo")}
            </Label>
            <Col sm={8}>
              <Input
                type="textarea"
                name="note"
                id="memo"
                className="memo-input"
                onChange={handleChangeRejecting}
                value={rejectingParams.note}
              />
            </Col>
          </FormGroup>
        </Form>
      </Confirm>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    status: state.pomo.status,
    error: state.pomo.error,
  };
};

export default withNamespaces()(connect(mapStateToProps)(Alert));
