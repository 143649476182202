import React, { Component } from "react";
import {
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  // FormGroup,
  Input,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { chargeSearchByDay, exportExcel } from "../../../../01-actions/charge";
import { getLogSetting } from "../../../../01-actions/settingRate";
import "./style.css";
import ReactPaginate from "react-paginate";
import { ACTIONS } from "../../../../01-actions/charge";
import { ACTIONS as ACTION_SETTING } from "../../../../01-actions/settingRate";
import { CircularProgress } from "@material-ui/core";
import Alert from "../../../../03-components/Alert";
import moment from "moment";
import { formatTimeFull } from "../../../../05-utils/commonUtils";
import Workbook from "react-excel-workbook";
import LogSetting from "../../../../03-components/LogSetting";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class ListConfirming extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 5,
      activePage: 1,
      chargesDay: [],
      logSettings: [],
      statisticCharge: null,
      startTime: null,
      endTime: null,
      checkDate: false,
      isShowModal: false,
      detailDay: "",
      businessId: "01",
      groupId: props.location.search.split("=")[
        props.location.search.split("=").length - 1
      ]
    };
    this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
    this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  componentDidMount() {
    this.props.exportExcel();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.chargesDay !== this.state.chargesDay) {
      this.setState({ chargesDay: nextProps.chargesDay });
    }
    if (nextProps.statisticCharge !== this.state.statisticCharge) {
      this.setState({ statisticCharge: nextProps.statisticCharge });
    }
    if (nextProps.logSettings !== this.state.logSettings) {
      this.setState({ logSettings: nextProps.logSettings });
    }
  }

  handlePageClick = data => {
    const page = data.selected + 1;
    this.setState({ activePage: page }, () => {
      this.props.chargeSearchByDay({
        page: this.state.activePage,
        startTime: this.state.startTime,
        endTime: this.state.endTime
      });
    });
  };

  validateDate() {
    let checkData = moment(this.state.startTime) > moment(this.state.endTime);
    this.setState({ checkDate: checkData });
  }

  handleSearchData = () => {
    const { startTime, endTime, businessId } = this.state;
    this.alertAck();
    this.props.chargeSearchByDay({
      startTime: moment(startTime).format("YYYY-MM-DD"),
      endTime: moment(endTime).format("YYYY-MM-DD"),
      businessId,
      page: 1
    });
  };

  handleChangeStartTime(date) {
    this.setState(
      {
        startTime: date
      },
      () => {
        this.validateDate();
      }
    );
  }

  handleChangeEndTime(date) {
    this.setState(
      {
        endTime: date
      },
      () => {
        this.validateDate();
      }
    );
  }

  handleDetail(e, data) {
    const { businessId } = this.state;
    e.preventDefault();
    this.alertAck();
    this.props.getLogSetting({
      date: data,
      businessId
    });
    this.setState({ detailDay: data, isShowModal: true });
  }

  closeModal = () => {
    this.setState({ isShowModal: !this.state.isShowModal });
  };

  renderUserFormDialog() {
    const { t } = this.props;
    return (
      <Modal
        isOpen={this.state.isShowModal}
        toggle={this.closeModal}
        className="confirming-list modal-style"
      >
        <ModalHeader toggle={this.closeModal}>
          Detail for {this.state.detailDay}
        </ModalHeader>
        <ModalBody>
          <Table bordered>
            <thead className="thead-light">
              <tr className="tb-header-detail">
                <th className="custom-item">No.</th>
                <th>Time</th>
                <th style={{ width: "340px" }}>Log</th>
              </tr>
            </thead>
            {this.renContentDetailTable()}
          </Table>
          {Array.isArray(this.state.logSettings) &&
            this.state.logSettings.length > 0 && (
              <div className="d-flex justify-content-end mt-2">
                <Workbook
                  filename="LogSetting.xlsx"
                  element={
                    <Button
                      outline
                      className="custom-btn mr-4 brs"
                      disabled={
                        Array.isArray(this.state.logSettings) &&
                        this.state.logSettings.length === 0
                      }
                    >
                      {t("Button.CSVExport")}
                    </Button>
                  }
                >
                  <Workbook.Sheet
                    data={this.state.logSettings}
                    name={this.state.detailDay}
                  >
                    <Workbook.Column label="No." value="no" />
                    <Workbook.Column label="Timestamps" value="createdAt" />
                    <Workbook.Column label="Key Setting" value="key" />
                    <Workbook.Column label="Old Value" value="oldValue" />
                    <Workbook.Column label="New Value" value="newValue" />
                  </Workbook.Sheet>
                </Workbook>
              </div>
            )}
        </ModalBody>
      </Modal>
    );
  }

  handleSelectGroupChange = value => {
    if (value !== "00") {
      this.props.history.push({
        pathname: "/statistic",
        search: `?group=${value}`
      });

      this.setState({
        businessId: value
      });
    } else {
      this.props.history.push(`/statistic`);
      this.setState({
        businessId: null
      });
    }
  };

  renderHeaderTable = () => {
    const { t } = this.props;
    return (
      <tr className="tb-header">
        <th className="custom-item">{t("Statistic.Table.No")}</th>
        <th>{t("Statistic.Table.Date")}</th>
        <th>{t("Statistic.Table.GBTAmount")}</th>
        <th>{t("Statistic.Table.TopUpAmount")}</th>
        <th>{t("Statistic.Table.Rate")}</th>
        <th>{t("Statistic.Table.EZPGBT")}</th>
        <th>{t("Statistic.Table.ExcelFinanceGBT")}</th>
        <th />
      </tr>
    );
  };

  renContentTable = () => {
    const { t } = this.props;
    if (
      Array.isArray(this.state.chargesDay) &&
      this.state.chargesDay.length > 0
    ) {
      return (
        <tbody>
          {this.state.chargesDay.map((item, index) => {
            return (
              <tr key={index} className="tb-body">
                <td>{index + 1}</td>
                <td>{item.day}</td>
                <td>{item.GBTAmount}</td>
                <td>{item.topUpAmount}</td>
                <td>{item.rate + "%"}</td>
                <td>{item.ezpGBT + "(" + item.ezpFee + "%)"}</td>
                <td>{item.excelGBT + "(" + item.excelFinanceFee + "%)"}</td>
                <td>
                  <Button
                    onClick={e => this.handleDetail(e, item.day)}
                    className="brs"
                  >
                    {t("Button.Detail")}
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={8}>
              <div className="d-flex justify-content-center">
                <span>{t("NoDataToShow")}</span>
              </div>
            </td>
          </tr>
        </tbody>
      );
    }
  };

  renContentDetailTable() {
    if (
      Array.isArray(this.state.logSettings) &&
      this.state.logSettings.length > 0
    ) {
      return (
        <tbody>
          {this.state.logSettings.map((item, index) => {
            return (
              <tr key={index} className="tb-body-detail">
                <td style={{ textAlign: "center" }}>{index + 1}</td>
                <td style={{ textAlign: "center" }}>
                  {formatTimeFull(item.createdAt)}
                </td>
                <td style={{ width: "340px" }}>
                  <LogSetting
                    keySetting={item.key}
                    oldValue={item.oldValue}
                    newValue={item.newValue}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={3}>
              <div className="d-flex justify-content-center">
                <span>No data to show</span>
              </div>
            </td>
          </tr>
        </tbody>
      );
    }
  }

  renderTable() {
    if (
      Array.isArray(this.state.chargesDay) &&
      this.state.chargesDay.length > 9
    ) {
      return (
        <div className="table-container">
          <Table bordered striped>
            <thead className="thead-light">{this.renderHeaderTable()}</thead>
            {this.renContentTable()}
          </Table>
        </div>
      );
    } else {
      return (
        <Table bordered responsive striped>
          <thead className="thead-light">{this.renderHeaderTable()}</thead>
          {this.renContentTable()}
        </Table>
      );
    }
  }

  render() {
    const { t } = this.props;
    const { chargesDay, statisticCharge, groupId } = this.state;
    return (
      <div className="statistic">
        <div className="title">
          <div className="title-content">{t("Statistic.StatisticCharge")}</div>
        </div>
        {/* <div className="bread-crumb d-flex">
          <div className="title">{t("Statistic.StatisticCharge")}</div>
        </div> */}
        {this.props.settingRateStatus ===
          ACTION_SETTING.GET_LOG_SETTING_SUCCESS && this.renderUserFormDialog()}
        <div className="statistic-content">
          <Row className="mt-3">
            <Col lg="3" md="3" sm="3" xs="12" className="mt-1">
              <DatePicker
                className="form-control brs"
                placeholderText="YYYY-MM-DD"
                dateFormat="yyyy/MM/dd"
                selected={this.state.startTime}
                onChange={this.handleChangeStartTime}
              />
            </Col>
            <Col lg="3" md="3" sm="3" xs="12" className="mt-1">
              <DatePicker
                className="form-control brs"
                placeholderText="YYYY-MM-DD"
                dateFormat="yyyy/MM/dd"
                selected={this.state.endTime}
                onChange={this.handleChangeEndTime}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg="3" md="3" sm="3" xs="12" className="mt-1">
              <Input
                className="selectGroup"
                type="select"
                name="selectGroup"
                id="selectGroup"
                defaultValue={groupId}
                onChange={event => {
                  this.handleSelectGroupChange(event.target.value);
                }}
              >
                {/* <option value="00">{t("Statistic.GroupSelection")}</option> */}
                <option value="01">Greenbox</option>
                <option value="02">Triple</option>
              </Input>
            </Col>
            <Col lg="3" md="3" sm="3" xs="12" className="mt-1">
              <Button
                outline
                color="secondary"
                className="custom-btn mr-4"
                disabled={
                  this.props.status === ACTIONS.SEARCH_CHARGE_BY_DAY_PROGRESS ||
                  !this.state.startTime ||
                  !this.state.endTime ||
                  this.state.checkDate
                }
                onClick={this.handleSearchData}
              >
                {this.props.status ===
                  ACTIONS.SEARCH_CHARGE_BY_DAY_PROGRESS && (
                  <CircularProgress className="mr-2" size={20} />
                )}
                {t("Button.Search")}
              </Button>
            </Col>
          </Row>
          {this.renderTable()}
        </div>

        {ACTIONS.SEARCH_CHARGE_BY_DAY_FAIL === this.props.status &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Error")}
              content={this.props.error.code}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.setState({ alertAck: true });
              }}
            />
          )}
        {this.props.settingRateStatus ===
          ACTION_SETTING.GET_LOG_SETTING_FAILED &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Error")}
              content={this.props.settingRateError.code}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.setState({ alertAck: true });
              }}
            />
          )}
        {Array.isArray(this.state.chargesDay) &&
          this.state.chargesDay.length > 0 && (
            <React.Fragment>
              <div className="statistic-content">
                <div className="d-flex justify-content-between align-content-center align-items-center">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={<a href="">...</a>}
                    breakClassName={"break-me"}
                    pageCount={this.props.pages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                  />
                  {this.props.exportButton && (
                    <Workbook
                      filename="statisticChargeByDay.xlsx"
                      element={
                        <button className="btn btn-secondary brs">
                          {t("Button.CSVExport")}
                        </button>
                      }
                    >
                      <Workbook.Sheet data={chargesDay} name="Sheet A">
                        <Workbook.Column label="No." value="no" />
                        <Workbook.Column label="Date" value="day" />
                        <Workbook.Column label="GBT amount" value="GBTAmount" />
                        <Workbook.Column
                          label="Top Up amount"
                          value="topUpAmount"
                        />
                        <Workbook.Column label="Rate" value="rate" />
                        <Workbook.Column label="EZP GBT" value="ezpGBT" />
                        <Workbook.Column
                          label="Excel finance GBT"
                          value="excelGBT"
                        />
                        <Workbook.Column label="EZP Fee" value="ezpFee" />
                        <Workbook.Column
                          label="Excel Finance Fee"
                          value="excelFinanceFee"
                        />
                      </Workbook.Sheet>
                      <Workbook.Sheet data={[statisticCharge]} name="Sheet B">
                        <Workbook.Column label="Time" value="time" />
                        <Workbook.Column
                          label="Sum GBT amount"
                          value="sumGBT"
                        />
                        <Workbook.Column
                          label="Sum top up amount"
                          value="sumTopUp"
                        />
                        <Workbook.Column
                          label="Sum Ezp Fee GBT"
                          value="sumEzpGBT"
                        />
                        <Workbook.Column
                          label="Sum Excel Finance Fee GBT"
                          value="sumExcelFinanceGBT"
                        />
                        <Workbook.Column
                          label="Sum Charges"
                          value="sumCharge"
                        />
                        <Workbook.Column label="Avg GBT" value="avgGBT" />
                      </Workbook.Sheet>
                    </Workbook>
                  )}
                </div>
                <div className="bread-crumb d-flex mb-2">
                  <div className="title-this-time">
                    {t("Statistic.StatisticInThisTime")}
                  </div>
                </div>
                <div className="table-this-time">
                  <Table bordered striped>
                    <thead className="thead-light">
                      <tr className="tb-header-detail">
                        <th className="custom-item-time">
                          {t("Statistic.Table.Time")}
                        </th>
                        <th>{t("Statistic.Table.SumGBT")}</th>
                        <th>{t("Statistic.Table.SumTopUp")}</th>
                        <th>{t("Statistic.Table.SumEzpGBT")}</th>
                        <th>{t("Statistic.Table.SumExcelFinanceGBT")}</th>
                        <th>{t("Statistic.Table.SumCharge")}</th>
                        <th>{t("Statistic.Table.AvgGBT")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="tb-body-detail">
                        {/* <td>{moment(this.state.startTime).format('YYYY-MM-DD') + "->" + moment(this.state.endTime).format('YYYY-MM-DD')}</td> */}
                        <td>{statisticCharge.time}</td>
                        <td>{statisticCharge.sumGBT}</td>
                        <td>{statisticCharge.sumTopUp}</td>
                        <td>{statisticCharge.sumEzpGBT}</td>
                        <td>{statisticCharge.sumExcelFinanceGBT}</td>
                        <td>{statisticCharge.sumCharge}</td>
                        <td>{statisticCharge.avgGBT}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </React.Fragment>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.charge.status,
    error: state.charge.error,
    chargesDay: state.charge.chargesDay,
    exportButton: state.charge.exportButton,
    statisticCharge: state.charge.statisticCharge,
    pages: state.charge.pages,
    logSettings: state.settingRate.logSettings,
    settingRateStatus: state.settingRate.status,
    settingRateError: state.settingRate.settingRateError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    chargeSearchByDay: data => {
      dispatch(chargeSearchByDay(data));
    },
    getLogSetting: data => {
      dispatch(getLogSetting(data));
    },
    exportExcel: () => {
      dispatch(exportExcel());
    }
  };
};

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ListConfirming)
);
