import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { PureComponent } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import Alert from "../../../../../03-components/Alert";
import {
  TYPE_SETTING,
  ENCRYPT_KEY,
  IV_ENCRYPT,
  COIN_TYPE,
} from "../../../../../05-utils/commonData";
import ModalSettingRate from "../RateSetting/ModalSettingRate";
import { getInfoUser } from "../../../../../01-actions/member";
import crypto from "crypto";
import "./style.css";
import {
  ACTIONS,
  MmpGetRateLimitSetting,
  MmpGetRateSetting,
} from "../../../../../01-actions/settingRateMMP";

class MMPRateSettings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      settingRate: "",
      settingExcelFinance: "",
      settingEzp: "",
      alertAck: true,
      setRate: false,
      isLoop: true,
      timeStart: "",
      timeEnd: "",
      idRateLimit: null,
      titleRateLimit: "",
      changeOneTimeLimit: "",
      changeDailyLimit: "",
      changeMonthlyLimit: "",
      changeYearlyLimit: "",
      limitSettings: [],
      checkData: false,
      businessId: "12",
      isOpenModal: false,
      isRefundCCT: false,
      isReceivingCCT: false,
      valueRefundCCT: "",
      valueReceivingCCT: "",
      settingRateCCT: "",
      settingTFA: false,
      isUpdateSetting: false,
      isUpdateSettingCCT: false,
      valueRateSetting: "",
      valueRateSettingCCT: "",
      isFeeExcel: false,
      isFeeGlover: false,
      isSettingRateLimit: false,
      templeSecretKey: "",
      templeSecretKeyFIL: "",
      templeSecretKeyCCT: "",
      secretValueCCT: "",
      isShowValidate: false,
      mmpEmail: "",
      mmpToken: "",
      mmpPassword: "",
      templePassword: "",
      emailWrongFormat: false,
      isAuthenticationSetting: false,
      isRefundROY: false,
      isReceivingROY: false,
      valueRefundROY: "",
      valueReceivingROY: "",
      settingRateROY: "",
      isUpdateSettingROY: false,
      valueRateSettingROY: "",
      templeSecretKeyROY: "",
      secretValueROY: "",
    };
    this.handleChangeStartTime = this.handleChangeStartTime.bind(this);
    this.handleChangeEndTime = this.handleChangeEndTime.bind(this);
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  clearState = () => {
    this.setState({
      idRateLimit: null,
      titleRateLimit: "",
      changeOneTimeLimit: "",
      changeDailyLimit: "",
      changeMonthlyLimit: "",
      changeYearlyLimit: "",
      valueReceivingCCT: "",
      valueRefundCCT: "",
      valueRateSetting: "",
      valueRateSettingCCT: "",
      mmpEmail: "",
      mmpToken: "",
      mmpPassword: "",
      emailWrongFormat: false,
      valueReceivingROY: "",
      valueRefundROY: "",
      valueRateSettingROY: "",
    });
  };

  validateDate() {
    const checkData =
      moment(this.state.timeStart).format("YYYY-MM-DD") >
      moment(this.state.timeEnd).format("YYYY-MM-DD");
    this.setState({ checkDate: checkData });
  }

  componentDidMount() {
    this.alertAck();
    this.props.getRateSetting({
      businessId: this.state.businessId,
    });
    this.props.getRateLimitSetting({ businessId: this.state.businessId });
    this.props.getInfoUser();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.settings !== this.props.settings) {
      this.setState({
        settingRate: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.MMP_RATE
        )?.value,
        settingExcelFinance: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.MMP_EXCEL_FINANCE_FEE
        )?.value,
        settingEzp: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.MMP_POMO_CARD_FEE
        )?.value,
        timeStart: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.TIME_START
        )?.value,
        timeEnd: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.TIME_END
        )?.value,
        valueRefundCCT: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.WALLET_ADDRESS_CCT
        )?.value,
        valueReceivingCCT: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.ADDRESS_USER_CCT
        )?.value,
        settingRateCCT: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.CCT_RATE
        )?.value,
        secretValueCCT: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.SECRET_KEY_CCT
        )?.value,
        mmpEmail: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.EMAIL
        )?.value,
        mmpToken: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.TOKEN
        )?.value,
        mmpPassword: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.PASSWORD
        )?.value,
        valueRefundROY: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.WALLET_ADDRESS_ROY
        )?.value,
        valueReceivingROY: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.ADDRESS_USER_ROY
        )?.value,
        settingRateROY: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.ROY_RATE
        )?.value,
        secretValueROY: nextProps.settings?.find(
          (val) => val.key === TYPE_SETTING.SECRET_KEY_ROY
        )?.value,
        isLoop:
          nextProps.settings?.find(
            (val) => val.key === TYPE_SETTING.TIME_SET_LOOP
          )?.value !== "0",
      });
    }

    if (nextProps.limitSettings !== this.props.limitSettings) {
      this.setState({
        limitSettings: nextProps.limitSettings,
      });
    }

    if (
      nextProps.settingRate === ACTIONS.MMP_UPDATE_EZP_SETTING_SUCCESS ||
      nextProps.settingRate === ACTIONS.MMP_UPDATE_EZP_SETTING_FAILED ||
      nextProps.settingRate ===
        ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS ||
      nextProps.settingRate ===
        ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_FAILED ||
      nextProps.settingRate ===
        ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_SUCCESS ||
      nextProps.settingRate === ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_FAILED ||
      nextProps.settingRate === ACTIONS.MMP_UPDATE_SETTING_RATE_SUCCESS ||
      nextProps.settingRate === ACTIONS.MMP_UPDATE_SETTING_RATE_FAILED ||
      nextProps.settingRate === ACTIONS.MMP_GET_RATE_SETTING_FAILED ||
      nextProps.settingRate === ACTIONS.MMP_GET_RATE_LIMIT_SETTING_FAILED ||
      nextProps.settingRate ===
        ACTIONS.MMP_RECEIVING_CCT_FAILED ||
      nextProps.settingRate ===
        ACTIONS.MMP_REFUND_CCT_FAILED ||
      nextProps.settingRate ===
        ACTIONS.MMP_AUTHENTICATION_FAILED ||
      nextProps.settingRate === ACTIONS.MMP_RECEIVING_CCT_SUCCESS ||
      nextProps.settingRate === ACTIONS.MMP_AUTHENTICATION_SUCCESS ||
      nextProps.settingRate === ACTIONS.MMP_REFUND_CCT_SUCCESS 
    ) {
      if (
        nextProps.settingRate === ACTIONS.MMP_UPDATE_EZP_SETTING_SUCCESS ||
        nextProps.settingRate ===
          ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS ||
        nextProps.settingRate ===
          ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_SUCCESS ||
        nextProps.settingRate === ACTIONS.MMP_UPDATE_SETTING_RATE_SUCCESS || 
        nextProps.settingRate === ACTIONS.MMP_RECEIVING_CCT_SUCCESS ||
        nextProps.settingRate === ACTIONS.MMP_AUTHENTICATION_SUCCESS ||
        nextProps.settingRate === ACTIONS.MMP_REFUND_CCT_SUCCESS 
      ) {
        this.setState({
          isOpenModal: false,
          isUpdateSetting: false,
          isFeeExcel: false,
          isFeeGlover: false,
          isSettingRateLimit: false,
          isAuthenticationSetting: false,
        });
      }
      this.setState({
        alertAck: false,
      });
    }
  }

  handleChangeStartTime(date) {
    this.setState(
      {
        timeStart: date,
      },
      () => {
        this.validateDate();
      }
    );
  }

  handleChangeEndTime(date) {
    this.setState(
      {
        timeEnd: date,
      },
      () => {
        this.validateDate();
      }
    );
  }

  onAuthenticationSetting = () => {
    if (this.props.infoUser.tfaEnable) {
      const regCheck = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
      const emailValue = this.state.mmpEmail;
      const check = regCheck.test(emailValue);

      if (check) {
        this.setState({
          isOpenModal: true,
          isRefundCCT: false,
          isReceivingCCT: false,
          alertAck: true,
          isUpdateSetting: false,
          isFeeExcel: false,
          isFeeGlover: false,
          isSettingRateLimit: false,
          isAuthenticationSetting: true,
        });
      } else {
        this.setState({
          emailWrongFormat: true,
        });
      }
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  onRefundCCT = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        isRefundCCT: true,
        isReceivingCCT: false,
        alertAck: true,
        isUpdateSetting: false,
        isFeeExcel: false,
        isFeeGlover: false,
        isSettingRateLimit: false,
        isAuthenticationSetting: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  onRefundROY = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        isRefundROY: true,
        isReceivingROY: false,
        alertAck: true,
        isUpdateSetting: false,
        isFeeExcel: false,
        isFeeGlover: false,
        isSettingRateLimit: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  onReceivingCCT = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        isRefundCCT: false,
        isReceivingCCT: true,
        alertAck: true,
        isUpdateSetting: false,
        isFeeExcel: false,
        isFeeGlover: false,
        isSettingRateLimit: false,
        isAuthenticationSetting: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  onReceivingROY = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        isRefundROY: false,
        isReceivingROY: true,
        alertAck: true,
        isUpdateSetting: false,
        isFeeExcel: false,
        isFeeGlover: false,
        isSettingRateLimit: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  onCCTRateSetting = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        alertAck: true,
        isUpdateSettingCCT: true,
        isRefundCCT: false,
        isReceivingCCT: false,
        isFeeExcel: false,
        isFeeGlover: false,
        isSettingRateLimit: false,
        isAuthenticationSetting: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  onROYRateSetting = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        alertAck: true,
        isUpdateSettingROY: true,
        isRefundROY: false,
        isReceivingROY: false,
        isFeeExcel: false,
        isFeeGlover: false,
        isSettingRateLimit: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  onMMPFeeExcel = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        alertAck: true,
        isUpdateSetting: false,
        isFeeExcel: true,
        isFeeGlover: false,
        isSettingRateLimit: false,
        isAuthenticationSetting: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  onMMPFeePomo = () => {
    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        alertAck: true,
        isUpdateSetting: false,
        isFeeExcel: false,
        isFeeGlover: true,
        isSettingRateLimit: false,
        isAuthenticationSetting: false,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  messageError = (errorCode) => {
    const { t } = this.props;
    switch (errorCode) {
      case "USER_TFA_CODE_INVALID":
        return t("Code.USER_TFA_CODE_INVALID");
      case "MISSING_REQUIRED_VALUE":
        return t("Code.MISSING_REQUIRED_VALUE");
      case "VALUE_EQUAL":
        return t("Code.VALUE_EQUAL");
      case "PERMISSION_DENIED":
        return t("Code.PERMISSION_DENIED");
      default:
        return errorCode;
    }
  };

  closeModal = () => {
    this.setState({
      isOpenModal: false,
      isRefundCCT: false,
      isReceivingCCT: false,
      alertAck: true,
      isUpdateSetting: false,
      isAuthenticationSetting: false,
      isUpdateSettingCCT: false,
      isFeeExcel: false,
      isFeeGlover: false,
      isSettingRateLimit: false,
      isRefundROY: false,
      isReceivingROY: false,
      isUpdateSettingROY: false,
    });
  };

  decryptWithIv = (data, key) => {
    const inputEncoding = "base64" || "hex";

    let decipher = crypto.createCipheriv(
      "aes-256-ctr",
      ENCRYPT_KEY,
      IV_ENCRYPT
    );

    let decrypted = decipher.update(data, inputEncoding, "utf8");
    decrypted += decipher.final("utf8");
    return decrypted;
  };

  onChangeSecretValueCCT = (e) => {
    const value = e?.target?.value;

    this.setState({
      templeSecretKeyCCT: value,
      secretValueCCT: value,
    });
  };

  onChangeSecretValueROY = (e) => {
    const value = e?.target?.value;

    this.setState({
      templeSecretKeyROY: value,
      secretValueROY: value,
    });
  };

  formatSecretKey = (key) => {
    return key
      ? key.substring(0, 4) +
          "********" +
          key?.substring(key?.length - 4, key?.length)
      : "";
  };

  formatPassword = (key) => {
    return key ? "*************" : "";
  };

  // RENDER UI
  renderSettingCCTRate(settingRateCCT) {
    const { t } = this.props;

    return (
      <>
        <Row className="mt-5">
          <Label className="label-title">
            {t("RateSetting.SettingRate")}
            {` (1 USD ~ ${
              (+this.props.settings?.find(
                (val) => val.key === TYPE_SETTING.CCT_RATE
              )?.value || 0) / 100
            } ${COIN_TYPE.CCT})`}
          </Label>
        </Row>
        <Row>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <Input
              className="input-label-style"
              disabled={true}
              value={t("RateSetting.SettingRate")}
            />
          </Col>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <InputGroup>
              <Input
                className="input-style"
                type="number"
                value={settingRateCCT}
                disabled={
                  this.props.settingRate ===
                  ACTIONS.MMP_UPDATE_SETTING_RATE_PROGRESS
                }
                onChange={(e) => {
                  this.setState({ settingRateCCT: parseInt(e.target.value) });
                }}
                min="1"
                // max="120"
              />
              <InputGroupAddon addonType="append">
                <InputGroupText className="brs">%</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>

          <Col lg="2" md="2" sm="2" xs="12" className="mt-1">
            <Button
              variant="contained"
              disabled={
                this.props.settingRate ===
                ACTIONS.MMP_UPDATE_SETTING_RATE_PROGRESS
              }
              className="submit-btn brs"
              onClick={this.onCCTRateSetting}
            >
              {this.props.settingRate ===
                ACTIONS.MMP_UPDATE_SETTING_RATE_PROGRESS && (
                <CircularProgress className="mr-2" size={20} />
              )}
              <span>{t("Button.Save")}</span>
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  renderSettingROYRate(settingRateROY) {
    const { t } = this.props;

    return (
      <>
        <Row className="mt-5">
          <Label className="label-title">
            {t("RateSetting.SettingRate")}
            {` (1 EUR ~ ${
              (+this.props.settings?.find(
                (val) => val.key === TYPE_SETTING.ROY_RATE
              )?.value || 0) / 100
            } ${COIN_TYPE.ROY})`}
            {` ${t("adil_network")}`}
          </Label>
        </Row>
        <Row>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <Input
              className="input-label-style"
              disabled={true}
              value={t("RateSetting.SettingRate")}
            />
          </Col>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <InputGroup>
              <Input
                className="input-style"
                type="number"
                value={settingRateROY}
                disabled={
                  this.props.settingRate ===
                  ACTIONS.UPDATE_RATE_SETTING_PROGRESS
                }
                onChange={(e) => {
                  this.setState({ settingRateROY: parseInt(e.target.value) });
                }}
                min="1"
                // max="120"
              />
              <InputGroupAddon addonType="append">
                <InputGroupText className="brs">%</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col lg="2" md="2" sm="2" xs="12" className="mt-1">
            <Button
              variant="contained"
              disabled={
                this.props.settingRate === ACTIONS.UPDATE_RATE_SETTING_PROGRESS
              }
              className="submit-btn brs"
              // onClick={() => {
              //     this.setState({ setRate: true });
              // }}
              onClick={this.onROYRateSetting}
            >
              {this.props.settingRate ===
                ACTIONS.UPDATE_RATE_SETTING_PROGRESS && (
                <CircularProgress className="mr-2" size={20} />
              )}
              <span>{t("Button.Save")}</span>
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  renderSettingFee(settingExcelFinance, settingEzp) {
    const { t } = this.props;
    return (
      <>
        <Row className="mt-5">
          <Label className="label-title">
            {t("RateSetting.SettingFee")}
            {` (~ ${
              ((+this.props.settings?.find(
                (val) => val.key === TYPE_SETTING.MMP_EXCEL_FINANCE_FEE
              )?.value || 0) +
                (+this.props.settings?.find(
                  (val) => val.key === TYPE_SETTING.MMP_POMO_CARD_FEE
                )?.value || 0)) /
              100
            } USD)`}
          </Label>
        </Row>
        <Row>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <Input
              className="input-label-style"
              disabled={true}
              value={t("SettingRateHeader.settingFeeExcel")}
            />
          </Col>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <InputGroup>
              <Input
                className="input-style"
                type="number"
                value={settingExcelFinance}
                disabled={
                  this.props.settingRate ===
                  ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS
                }
                onChange={(e) => {
                  this.setState({ settingExcelFinance: e.target.value });
                }}
                min="0"
                // max="100"
              />
              <InputGroupAddon addonType="append">
                <InputGroupText className="brs">%</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col lg="2" md="2" sm="2" xs="12" className="mt-1">
            <Button
              variant="contained"
              disabled={
                this.props.settingRate ===
                ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS
              }
              className="submit-btn brs"
              onClick={this.onMMPFeeExcel}
            >
              {this.props.settingRate ===
                ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_PROGRESS && (
                <CircularProgress className="mr-2" size={20} />
              )}
              <span>{t("Button.Save")}</span>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <Input
              className="input-label-style"
              disabled={true}
              value={t("SettingRateHeader.settingFeeManagement")}
            />
          </Col>
          <Col lg="5" md="5" sm="5" xs="12" className="mt-1">
            <InputGroup>
              <Input
                className="input-style"
                type="number"
                value={settingEzp}
                disabled={
                  this.props.settingRate ===
                  ACTIONS.MMP_UPDATE_EZP_SETTING_PROGRESS
                }
                onChange={(e) => {
                  this.setState({ settingEzp: e.target.value });
                }}
                min="0"
                // max="100"
              />
              <InputGroupAddon addonType="append">
                <InputGroupText className="brs">%</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col lg="2" md="2" sm="2" xs="12" className="mt-1">
            <Button
              variant="contained"
              disabled={
                this.props.settingRate ===
                ACTIONS.MMP_UPDATE_EZP_SETTING_PROGRESS
              }
              className="submit-btn brs"
              onClick={this.onMMPFeePomo}
            >
              {this.props.settingRate ===
                ACTIONS.MMP_UPDATE_EZP_SETTING_PROGRESS && (
                <CircularProgress className="mr-2" size={20} />
              )}
              <span>{t("Button.Save")}</span>
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  renderHeaderSettingLimit() {
    const { t } = this.props;
    return (
      <>
        <Row className="mt-5">
          <Label className="label-title">
            {t("RateSetting.SettingRateLimit")}
          </Label>
        </Row>
        <Row>
          <Col lg="2" md="2" sm="12" xs="12" className="mt-1">
            <Input
              className="input-label-style"
              disabled={true}
              value={t("SettingRateHeader.type")}
            />
          </Col>
          <Col lg="2" md="2" sm="12" xs="12" className="mt-1 pd-right-left-10">
            <Input
              className="input-label-style"
              disabled={true}
              value={t("SettingRateHeader.oneTime")}
            />
          </Col>
          <Col lg="2" md="2" sm="12" xs="12" className="mt-1 pd-right-left-10">
            <Input
              className="input-label-style"
              disabled={true}
              value={t("SettingRateHeader.daily")}
            />
          </Col>
          <Col lg="2" md="2" sm="12" xs="12" className="mt-1 pd-right-left-10">
            <Input
              className="input-label-style"
              disabled={true}
              value={t("SettingRateHeader.month")}
            />
          </Col>
          <Col lg="2" md="2" sm="12" xs="12" className="mt-1 pd-right-left-10">
            <Input
              className="input-label-style"
              disabled={true}
              value={t("SettingRateHeader.year")}
            />
          </Col>
          <Col lg="1" md="1" sm="12" xs="12" className="mt-1"></Col>
        </Row>
      </>
    );
  }

  renderSettingRateLimit() {
    const {
      idRateLimit,
      changeOneTimeLimit,
      changeDailyLimit,
      changeMonthlyLimit,
      changeYearlyLimit,
      titleRateLimit,
      limitSettings,
    } = this.state;
    const { t } = this.props;

    return (
      limitSettings &&
      limitSettings.map((s, index) => {
        return (
          <Row key={index}>
            <Col lg="2" md="2" sm="12" xs="12" className="mt-1">
              <Input
                className="input-label-style"
                disabled={true}
                value={s.name}
              />
            </Col>

            <Col
              lg="2"
              md="2"
              sm="12"
              xs="12"
              className="mt-1 pd-right-left-10"
            >
              <InputGroup>
                <Input
                  className="input-style"
                  type="number"
                  value={
                    changeOneTimeLimit && s._id === idRateLimit
                      ? changeOneTimeLimit
                      : s.oneTimeLimit
                  }
                  disabled={
                    this.props.settingRate ===
                    ACTIONS.MMP_UPDATE_SETTING_RATE_PROGRESS
                  }
                  onChange={(e) => {
                    if (s._id === idRateLimit) {
                      this.setState({
                        idRateLimit: s._id,
                        titleRateLimit: s.title,
                        changeOneTimeLimit: parseInt(e.target.value),
                      });
                    } else {
                      this.setState({
                        idRateLimit: s._id,
                        titleRateLimit: s.title,
                        changeOneTimeLimit: parseInt(e.target.value),
                        changeDailyLimit: "",
                        changeMonthlyLimit: "",
                        changeYearlyLimit: "",
                      });
                    }
                  }}
                  min="0"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText className="brs">USD</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>

            <Col
              lg="2"
              md="2"
              sm="12"
              xs="12"
              className="mt-1 pd-right-left-10"
            >
              <InputGroup>
                <Input
                  className="input-style"
                  type="number"
                  value={
                    changeDailyLimit && s._id === idRateLimit
                      ? changeDailyLimit
                      : s.dailyLimit
                  }
                  disabled={
                    this.props.settingRate ===
                    ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_PROGRESS
                  }
                  onChange={(e) => {
                    if (s._id === idRateLimit) {
                      this.setState({
                        idRateLimit: s._id,
                        titleRateLimit: s.title,
                        changeDailyLimit: parseInt(e.target.value),
                      });
                    } else {
                      this.setState({
                        idRateLimit: s._id,
                        titleRateLimit: s.title,
                        changeOneTimeLimit: "",
                        changeDailyLimit: parseInt(e.target.value),
                        changeMonthlyLimit: "",
                        changeYearlyLimit: "",
                      });
                    }
                  }}
                  min="0"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText className="brs">USD</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>

            <Col
              lg="2"
              md="2"
              sm="12"
              xs="12"
              className="mt-1 pd-right-left-10"
            >
              <InputGroup>
                <Input
                  className="input-style"
                  type="number"
                  value={
                    changeMonthlyLimit && s._id === idRateLimit
                      ? changeMonthlyLimit
                      : s.monthlyLimit
                  }
                  disabled={
                    this.props.settingRate ===
                    ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_PROGRESS
                  }
                  onChange={(e) => {
                    if (s._id === idRateLimit) {
                      this.setState({
                        idRateLimit: s._id,
                        titleRateLimit: s.title,
                        changeMonthlyLimit: parseInt(e.target.value),
                      });
                    } else {
                      this.setState({
                        idRateLimit: s._id,
                        titleRateLimit: s.title,
                        changeOneTimeLimit: "",
                        changeDailyLimit: "",
                        changeMonthlyLimit: parseInt(e.target.value),
                        changeYearlyLimit: "",
                      });
                    }
                  }}
                  min="0"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText className="brs">USD</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>

            <Col
              lg="2"
              md="2"
              sm="12"
              xs="12"
              className="mt-1 pd-right-left-10"
            >
              <InputGroup>
                <Input
                  className="input-style"
                  type="number"
                  value={
                    changeYearlyLimit && s._id === idRateLimit
                      ? changeYearlyLimit
                      : s.yearlyLimit
                  }
                  disabled={
                    this.props.settingRate ===
                    ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_PROGRESS
                  }
                  onChange={(e) => {
                    if (s._id === idRateLimit) {
                      this.setState({
                        idRateLimit: s._id,
                        titleRateLimit: s.title,
                        changeYearlyLimit: parseInt(e.target.value),
                      });
                    } else {
                      this.setState({
                        idRateLimit: s._id,
                        titleRateLimit: s.title,
                        changeOneTimeLimit: "",
                        changeDailyLimit: "",
                        changeMonthlyLimit: "",
                        changeYearlyLimit: parseInt(e.target.value),
                      });
                    }
                  }}
                  min="0"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText className="brs">USD</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>

            <Col lg="1" md="1" sm="12" xs="12" className="mt-1">
              <Button
                variant="contained"
                disabled={
                  this.props.settingRate ===
                  ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_PROGRESS
                }
                className="submit-btn brs"
                onClick={() => {
                  !changeOneTimeLimit &&
                    this.setState({ changeOneTimeLimit: s.oneTimeLimit });
                  !changeDailyLimit &&
                    this.setState({ changeDailyLimit: s.dailyLimit });
                  !changeMonthlyLimit &&
                    this.setState({ changeMonthlyLimit: s.monthlyLimit });
                  !changeYearlyLimit &&
                    this.setState({ changeYearlyLimit: s.yearlyLimit });
                  !titleRateLimit && this.setState({ titleRateLimit: s.title });

                  if (this.props.infoUser.tfaEnable) {
                    this.setState({
                      isOpenModal: true,
                      alertAck: true,
                      isUpdateSetting: false,
                      sUpdateSettingFIL: false,

                      isFeeExcel: false,
                      isFeeGlover: false,
                      isSettingRateLimit: true,
                    });
                  } else {
                    this.setState({
                      settingTFA: true,
                    });
                  }
                }}
              >
                {this.props.settingRate ===
                  ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_PROGRESS && (
                  <CircularProgress className="mr-2" size={20} />
                )}
                <span>{t("Button.Save")}</span>
              </Button>
            </Col>
          </Row>
        );
      })
    );
  }

  renderWalletCCT(
    valueRefundCCT,
    templeSecretKeyCCT,
    decryptSecretKeyCCT,
    secretValueCCT,
    valueReceivingCCT
  ) {
    const { t } = this.props;
    return (
      <div className="border-div col-11 mt-5">
        <div className="border-div-overlay-text">
          <span className="font-weight-bold">{COIN_TYPE.CCT}</span>
        </div>
        <div className="content-setting-address">
          <Label className="label-title">{t("RateSetting.RefundFIL")}</Label>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Input
                value={valueRefundCCT || ""}
                onChange={(e) => {
                  this.setState({ valueRefundCCT: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Label className="label-title mt-3">
            {t("RateSetting.SecretKey")}
          </Label>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Input
                value={
                  templeSecretKeyCCT ? templeSecretKeyCCT : decryptSecretKeyCCT
                }
                onChange={this.onChangeSecretValueCCT}
              />
            </Col>
          </Row>
          <Row className="margin-top-10px">
            <Col lg="12" md="12" sm="12" xs="12">
              <Button
                className="btn-confirm"
                onClick={this.onRefundCCT}
                disabled={!secretValueCCT || !valueRefundCCT}
              >
                {t("Button.Confirm")}
              </Button>
            </Col>
          </Row>
          <Label className="label-title">{t("RateSetting.ReceivingFIL")}</Label>
          <Row>
            <Col lg="10" md="10" sm="10" xs="10">
              <Input
                value={valueReceivingCCT || ""}
                onChange={(e) => {
                  this.setState({ valueReceivingCCT: e.target.value });
                }}
              />
            </Col>
            <Col lg="2" md="2" sm="2" xs="2">
              <Button
                className="btn-confirm"
                onClick={this.onReceivingCCT}
                disabled={!valueReceivingCCT}
              >
                {t("Button.Confirm")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderWalletROY(
    valueRefundROY,
    templeSecretKeyROY,
    decryptSecretKeyROY,
    secretValueROY,
    valueReceivingROY
  ) {
    const { t } = this.props;
    return (
      <div className="border-div col-11 mt-5">
        <div className="border-div-overlay-text">
          <span className="font-weight-bold">
            {COIN_TYPE.ROY} {` ${t("adil_network")}`}
          </span>
        </div>
        <div className="content-setting-address">
          <Label className="label-title">{t("RateSetting.RefundFIL")}</Label>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Input
                value={valueRefundROY}
                onChange={(e) => {
                  this.setState({ valueRefundROY: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Label className="label-title mt-3">
            {t("RateSetting.SecretKey")}
          </Label>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Input
                value={
                  templeSecretKeyROY ? templeSecretKeyROY : decryptSecretKeyROY
                }
                onChange={this.onChangeSecretValueROY}
              />
            </Col>
          </Row>
          <Row className="margin-top-10px">
            <Col lg="12" md="12" sm="12" xs="12">
              <Button
                className="btn-confirm"
                onClick={this.onRefundROY}
                disabled={!secretValueROY || !valueRefundROY}
              >
                {t("Button.Confirm")}
              </Button>
            </Col>
          </Row>
          <Label className="label-title">{t("RateSetting.ReceivingFIL")}</Label>
          <Row>
            <Col lg="10" md="10" sm="10" xs="10">
              <Input
                value={valueReceivingROY}
                onChange={(e) => {
                  this.setState({ valueReceivingROY: e.target.value });
                }}
              />
            </Col>
            <Col lg="2" md="2" sm="2" xs="2">
              <Button
                className="btn-confirm"
                onClick={this.onReceivingROY}
                disabled={!valueReceivingROY}
              >
                {t("Button.Confirm")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  renderEmailAndPassword(
    mmpEmail,
    mmpPassword,
    mmpToken,
    decryptPassword,
    templePassword
  ) {
    const { t } = this.props;
    return (
      <div className="border-div col-11 mt-5">
        <div className="border-div-overlay-text">
          <span className="font-weight-bold">
            {t("AdministratorSetting.Table.ApiAuthenSetting")}
          </span>
        </div>
        <div className="content-setting-address">
          <Label className="label-title">
            {t("AdministratorSetting.Table.ApiToken")}
          </Label>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Input
                value={mmpToken || ""}
                onChange={(e) => {
                  this.setState({ mmpToken: e.target.value });
                }}
              />
            </Col>
          </Row>

          <Label className="label-title  mt-3">
            {t("AdministratorSetting.Table.MasterEmail")}
          </Label>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Input
                value={mmpEmail || ""}
                onChange={(e) => {
                  this.setState({ mmpEmail: e.target.value });
                }}
              />
            </Col>
          </Row>
          <Label className="label-title  mt-3">
            {t("AdministratorSetting.Table.MasterEmailPassword")}
          </Label>
          <Row>
            <Col lg="12" md="12" sm="12" xs="12">
              <Input
                type="password"
                value={mmpPassword || ""}
                onChange={(e) => {
                  this.setState({
                    mmpPassword: e.target.value,
                    // templePassword: e.target.value
                  });
                }}
              />
            </Col>
          </Row>

          <Row className="margin-top-10px">
            <Col lg="12" md="12" sm="12" xs="12">
              <Button
                className="btn-confirm"
                onClick={this.onAuthenticationSetting}
                disabled={!mmpToken || !mmpEmail || !mmpPassword}
              >
                {t("Button.Confirm")}
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }

  render() {
    const { t } = this.props;

    const {
      settingRate,
      settingExcelFinance,
      settingEzp,
      businessId,
      alertAck,
      titleRateLimit,
      changeOneTimeLimit,
      changeDailyLimit,
      changeMonthlyLimit,
      changeYearlyLimit,
      isOpenModal,
      settingTFA,
      isRefundCCT,
      isReceivingCCT,
      valueRefundCCT,
      isUpdateSettingCCT,
      valueReceivingCCT,
      templeSecretKeyCCT,
      secretValueCCT,
      settingRateCCT,
      isUpdateSetting,
      isFeeExcel,
      isFeeGlover,
      isSettingRateLimit,
      isShowValidate,
      mmpEmail,
      mmpPassword,
      mmpToken,
      emailWrongFormat,
      isAuthenticationSetting,
      templePassword,
      isRefundROY,
      isReceivingROY,
      valueRefundROY,
      isUpdateSettingROY,
      valueReceivingROY,
      templeSecretKeyROY,
      secretValueROY,
      settingRateROY,
    } = this.state;
     
    const decryptSecretKeyCCT = secretValueCCT
      ? this.formatSecretKey(this.decryptWithIv(secretValueCCT))
      : "";

    const decryptPassword = mmpPassword ? this.formatPassword(mmpPassword) : "";
    const decryptSecretKeyROY = secretValueROY ? this.formatSecretKey(this.decryptWithIv(secretValueROY)) : '';
    return (
      <div className="huv-style-setting-rate">
        <div className="title">
          <div className="title-content">{t("RateSetting.SettingRate")}</div>
        </div>
        <Container className="mb-5">
          {/* {this.renderSettingCCTRate(settingRateCCT)} */}
          {this.renderSettingROYRate(settingRateROY)}
          {this.renderSettingFee(settingExcelFinance, settingEzp)}
          {this.renderHeaderSettingLimit()}
          {this.renderSettingRateLimit()}
          {this.renderWalletROY(valueRefundROY, templeSecretKeyROY, decryptSecretKeyROY, secretValueROY, valueReceivingROY)}
          {/* {this.renderWalletCCT(
            valueRefundCCT,
            templeSecretKeyCCT,
            decryptSecretKeyCCT,
            secretValueCCT,
            valueReceivingCCT
          )} */}
          {this.renderEmailAndPassword(
            mmpEmail,
            mmpPassword,
            mmpToken,
            decryptPassword,
            templePassword
          )}
        </Container>
        <ModalSettingRate
          t={t}
          isOpen={isOpenModal}
          isUpdateSetting={isUpdateSetting}
          isUpdateSettingCCT={isUpdateSettingCCT}
          isRefundCCT={isRefundCCT}
          isReceivingCCT={isReceivingCCT}
          valueRefundCCT={valueRefundCCT}
          valueReceivingCCT={valueReceivingCCT}
          secretValueCCT={secretValueCCT}
          close={this.closeModal}
          valueRateSetting={settingRate}
          valueRateSettingCCT={settingRateCCT}
          isFeeExcel={isFeeExcel}
          settingExcelFinance={settingExcelFinance}
          isFeeGlover={isFeeGlover}
          settingEzp={settingEzp}
          businessId={businessId}
          isSettingRateLimit={isSettingRateLimit}
          title={titleRateLimit}
          oneTimeLimit={Number(changeOneTimeLimit)}
          dailyLimit={Number(changeDailyLimit)}
          monthlyLimit={Number(changeMonthlyLimit)}
          yearlyLimit={Number(changeYearlyLimit)}
          isAuthenticationSetting={isAuthenticationSetting}
          mmpEmail={mmpEmail}
          mmpPassword={mmpPassword}
          mmpToken={mmpToken}
          isRefundROY={isRefundROY}
          isReceivingROY={isReceivingROY}
          isUpdateSettingROY={isUpdateSettingROY}
          valueRefundROY={valueRefundROY}
          valueReceivingROY={valueReceivingROY}
          secretValueROY={secretValueROY}
          valueRateSettingROY={settingRateROY}
        />
        {isShowValidate && (
          <Alert
            title={t("Alert.ConfirmMessage")}
            okTitle={t("Alert.OK")}
            cancelTitle={t("Alert.Cancel")}
            onOK={() => {
              this.setState({ isShowValidate: false });
            }}
            onCancel={() => {
              this.setState({ isShowValidate: false });
            }}
          />
        )}
        {[
          ACTIONS.MMP_GET_RATE_SETTING_FAILED,
          ACTIONS.MMP_GET_RATE_LIMIT_SETTING_FAILED,
          ACTIONS.MMP_RECEIVING_CCT_FAILED,
          ACTIONS.MMP_REFUND_CCT_FAILED,
          ACTIONS.MMP_AUTHENTICATION_FAILED,
          ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_FAILED,
          ACTIONS.MMP_UPDATE_EZP_SETTING_FAILED,
          ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_FAILED,
          ACTIONS.MMP_UPDATE_SETTING_RATE_FAILED,
        ].indexOf(this.props.settingRate) >= 0 &&
          !alertAck && (
            <Alert
              title={t("Alert.Error")}
              content={this.messageError(this.props.settingRateError.code)}
              okTitle={t("Alert.OK")}
              onOK={() => {
                // this.props.getRateSetting({ businessId });
                // this.props.getRateLimitSetting({ businessId });
                this.setState({ alertAck: true });
              }}
            />
          )}
        {[
          ACTIONS.MMP_RECEIVING_CCT_SUCCESS,
          ACTIONS.MMP_AUTHENTICATION_SUCCESS,
          ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS,
          ACTIONS.MMP_UPDATE_EZP_SETTING_SUCCESS,
          ACTIONS.MMP_REFUND_CCT_SUCCESS,
          ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_SUCCESS,
          ACTIONS.MMP_UPDATE_SETTING_RATE_SUCCESS,
        ].indexOf(this.props.settingRate) >= 0 &&
          !alertAck && (
            <Alert
              title={t("Alert.Success")}
              content={t("RateSetting.SetSettingSuccess")}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.props.getRateSetting({ businessId });
                this.props.getRateLimitSetting({ businessId });
                this.setState({ alertAck: true });
              }}
            />
          )}
        {settingTFA && (
          <Alert
            title={t("Alert.Error")}
            content={t("Code.SETTING_TFA_PLEASE")}
            okTitle={t("Alert.OK")}
            onOK={() => {
              this.setState({ settingTFA: false });
            }}
          />
        )}
        {emailWrongFormat && (
          <Alert
            title={t("Alert.Error")}
            content={t("Code.EMAIL_WRONG_FORMAT")}
            okTitle={t("Alert.OK")}
            onOK={() => {
              this.setState({ emailWrongFormat: false });
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    settingRate: store.settingRateMMP.mmpStatus,
    settings: store.settingRateMMP.mmpSettings,
    limitSettings: store.settingRateMMP.mmpLimitSettings,
    settingRateError: store.settingRateMMP.settingRateError,
    infoUser: store.member.infoUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRateSetting: (data) => {
      dispatch(MmpGetRateSetting(data));
    },
    getRateLimitSetting: (data) => {
      dispatch(MmpGetRateLimitSetting(data));
    },
    getInfoUser: () => {
      dispatch(getInfoUser());
    },
  };
};
export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(MMPRateSettings)
);
