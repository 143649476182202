import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Label, FormGroup, Input, Button} from 'reactstrap';
import {Redirect, Link} from 'react-router-dom';
import Alert from '../../../../03-components/Alert';
import {ACTIONS, login} from '../../../../01-actions/auth';
import ModalTFALogin from './ModalTFALogin'
import './style.css';
import { withNamespaces } from "react-i18next";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            alertAck: false,
            isOpen: false,
        };
        this.handleValueChange = this.handleValueChange.bind(this);
        this.handleLogin = this.handleLogin.bind(this);
        this.alertAck = this.alertAck.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.authStatus === ACTIONS.LOGIN_FAILED && nextProps.loginTfaEnable) {
            this.setState({
                isOpen: true
            })
        } else {
            this.setState({alertAck: false});
        }

        if (nextProps.authStatus === ACTIONS.LOGIN_SUCCESS) {
            this.showModalTFA(false)
        }
    }

    handleValueChange(field) {
        return (e) => {
            this.setState({[field]: e.target.value});
        }
    }

    handleLogin() {
        this.setState({alertAck: false});
        this.props.login({username: this.state.username, password: this.state.password});
    }

    alertAck() {
        this.setState({alertAck: true});
    }

    onKeyPress = (e) => {
        if (e.which === 13) {
            this.handleLogin();
        }
    };

    showModalTFA = (isOpen) => {
        this.setState({
            isOpen: isOpen
        })
    }
   

    messageError = (errorCode) => {
        const {t} = this.props
        switch (errorCode) {
            case 'INVALID_CREDENTIAL':
                return t("TFASetting.INVALID_CREDENTIAL");
            case 'LOGIN_IS_BLOCK':
                return t("TFASetting.LOGIN_IS_BLOCK");
            case 'USER_TFA_CODE_INVALID':
                return  t("Code.USER_TFA_CODE_INVALID");
            default:
                return errorCode;
        }
    }

    render() {

        let isCheckLogin = window.localStorage !== undefined ? window.localStorage.getItem('ACCESS_TOKEN') : ''
        return (
            <div className="pages-auth-login">
                {this.props.authStatus === ACTIONS.LOGIN_SUCCESS && (
                    <Redirect to="/"/>
                )}
                {isCheckLogin && (
                    <Redirect to="/"/>
                )}
               
                <fieldset disabled={this.props.authStatus === ACTIONS.LOGIN_PROGRESS}>
                    <div className="login-form">
                        <FormGroup>
                            <Label>管理者 ID</Label>
                            <Input className="input-style"
                                   value={this.state.username}
                                   onKeyPress={this.onKeyPress}
                                   onChange={this.handleValueChange('username')}
                                   type="text"/>
                        </FormGroup>
                        <FormGroup>
                            <Label>パスワード</Label>
                            <Input className="input-style"
                                   value={this.state.password}
                                   onKeyPress={this.onKeyPress}
                                   onChange={this.handleValueChange('password')}
                                   type="password"/>
                        </FormGroup>
                        <div className="d-none">
                            <Link to={'/auth/forgot-password'} className="text-small">Forgot Password</Link>
                        </div>
                        <div className="text-center">
                            <Button
                                className={this.props.authStatus === ACTIONS.LOGIN_PROGRESS ? "progress-button custom-btn" : "custom-btn"}
                                onClick={this.handleLogin}
                                disabled={!this.state.username || !this.state.password}
                                color="secondary"
                                outline
                            >Login</Button>
                        </div>
                    </div>
                </fieldset>
                <ModalTFALogin
                    isOpen={this.state.isOpen}
                    close={() => this.showModalTFA(false)}
                    userName={this.state.username}
                    password={this.state.password}
                />
                {!this.props.loginTfaEnable ?
                    (this.props.authStatus === ACTIONS.LOGIN_FAILED) && !this.state.alertAck && (
                        <Alert title="Error"
                           content={this.messageError(this.props.authError.code)}
                           okTitle="OK"
                           onOK={() => {
                               this.setState({alertAck: true});
                           }}/>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        authStatus: store.auth.status,
        authError: store.auth.authError,
        loginTfaEnable: store.auth.loginTfaEnable
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (data) => {
            dispatch(login(data));
        }
    };
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
