import React, { Component } from "react";
import {
  Table,
  Modal,
  Form,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Button,
  Input,
  Container,
  Row
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import {
  ACTIONS,
  getRoles,
  deleteRole,
  createRole,
  updatePermission
} from "../../../../01-actions/permission";
import { connect } from "react-redux";
import _ from "lodash";
import Alert from "../../../../03-components/Alert";
import CcCheckbox from "../../../../03-components/ccCheckbox";
import { PERMISSION, ROLE_SUPPER_ADMIN } from "../../../../05-utils/commonData";
import PermissionItem from "../../../../03-components/PermissionItem";
import "./style.css";

class PermissionSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: [],
      selectedRole: {},
      name: "",
      alertAck: true,
      edit_permission: true,
      isShowModal: false
    };
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  componentDidMount() {
    this.alertAck();
    this.props.getRoles();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.role !== this.props.role) {
      this.setState({
        role: nextProps.role
      });
    }
  }

  renderHeaderTable = () => {
    const { t } = this.props;
    return (
      <tr>
        <th className="no-border">{t("PermissionSetting.Title")}</th>
        <th className="no-border" />
        <th className="no-border" />
      </tr>
    );
  };

  renderContentTable() {
    const { role, t } = this.props;
    if (Array.isArray(role) && role.length > 0) {
      return role.map((data, index) => {
        return (
          <tr key={index}>
            <td className="no-border">
              <Input
                className="input-label-style"
                disabled={true}
                value={data.name}
              />
            </td>
            <td className="no-border">
              {data.name !== ROLE_SUPPER_ADMIN && (
                <Button
                  color="primary"
                  className="button-style"
                  onClick={() =>
                    this.setState({
                      isShowModal: true,
                      name: data.name,
                      selectedRole: { ...data },
                      edit_permission: false
                    })
                  }
                >
                  {t("Button.Edit")}
                </Button>
              )}
            </td>
            <td className="no-border">
              {data.name !== ROLE_SUPPER_ADMIN && (
                <Button
                  color="danger"
                  className="button-style"
                  onClick={() =>
                    this.setState({
                      isShowModal: true,
                      edit_permission: true,
                      selectedRole: data
                    })
                  }
                >
                  {t("Button.Delete")}
                </Button>
              )}
            </td>
          </tr>
        );
      });
    } else {
      return (
        <tr>
          <td colSpan={5}>
            <div className="d-flex justify-content-center">
              <span>{t("NoDataToShow")}</span>
            </div>
          </td>
        </tr>
      );
    }
  }

  handleCheckboxChange(e, value) {
    let dataPermission = [...this.state.selectedRole.permission];
    dataPermission.includes(value)
      ? dataPermission.splice(dataPermission.indexOf(value), 1)
      : dataPermission.push(value);
    this.setState({
      selectedRole: {
        ...this.state.selectedRole,
        permission: dataPermission
      }
    });
  }

  handleSubmitBtn = (e, actionName) => {
    e.preventDefault();
    this.alertAck();
    this.setState({ isShowModal: false });
    const { selectedRole, name } = this.state;
    switch (actionName) {
      case "Delete":
        this.props.handleDeleteRole({ _id: selectedRole && selectedRole._id });
        break;
      case "Create":
        this.props.handleCreateRole({ name: name.trim(), permission: [] });
        break;
      default:
        break;
    }
  };
  handleUpdatePermission = () => {
    this.alertAck();
    const { selectedRole, name } = this.state;
    this.props.updatePermission({
      _id: selectedRole._id,
      permission: selectedRole.permission,
      name: name.trim()
    });
    this.setState({ isShowModal: false });
  };

  checkAllBox(data) {
    let check = true;
    let dataPermission = [...this.state.selectedRole.permission];
    data.forEach(val => {
      check = check && dataPermission.includes(val.name);
    });
    return check;
  }

  handleCheckbox(e, data) {
    let dataCheckBox = [];
    data.forEach(val => dataCheckBox.push(val.name));
    let dataPermission = [...this.state.selectedRole.permission];
    if (_.difference(dataCheckBox, dataPermission).length === 0) {
      dataPermission = _.pullAll(dataPermission, dataCheckBox);
    } else
      dataCheckBox.forEach(val => {
        if (!dataPermission.includes(val)) dataPermission.push(val);
      });
    this.setState({
      selectedRole: {
        ...this.state.selectedRole,
        permission: dataPermission
      }
    });
  }

  renderTable() {
    return (
      <Table>
        <thead>{this.renderHeaderTable()}</thead>
        <tbody>{this.renderContentTable()}</tbody>
      </Table>
    );
  }

  toggleDialog = () => {
    // this.setState({isShowModal: false, selectedRole: {}});
    this.setState({ isShowModal: false });
  };

  renderForm() {
    const { t } = this.props;
    const { selectedRole, edit_permission, isShowModal } = this.state;
    const permission = selectedRole && selectedRole.permission;
    const permissionData = {
      "Permission.Key.SettingPermission": [
        { name: PERMISSION.PERMISSION, text: t("Permission.Permission") },
        { name: PERMISSION.MEMBER, text: t("Permission.Member") }
      ],
      "Permission.Key.TopUpPage": [
        { name: PERMISSION.VIEW_TOP_UP, text: t("Permission.ViewTopUp") },
        {
          name: PERMISSION.EXPORT_FILE_REQUEST,
          text: t("Permission.ExportFileRequest")
        },
        {
          name: PERMISSION.REJECT_REQUEST,
          text: t("Permission.RejectRequest")
        },
        { name: PERMISSION.UPDATE_STATUS, text: t("Permission.UpdateStatus") }
      ],
      "Permission.Key.RebalancePage": [
        {
          name: PERMISSION.VIEW_RE_BALANCE,
          text: t("Permission.ViewReBalance")
        },
        { name: PERMISSION.SEND_TO_EZP, text: t("Permission.SendToEZP") },
        { name: PERMISSION.IMPORT_CSV, text: t("Permission.ImportCSV") }
      ],
      "Permission.Key.HistoryPage": [
        { name: PERMISSION.VIEW_HISTORY, text: t("Permission.ViewHistory") },
        {
          name: PERMISSION.EXPORT_FILE_HISTORY,
          text: t("Permission.ExportFileHistory")
        }
      ],
      "Permission.Key.UnconfirmedPage": [
        {
          name: PERMISSION.VIEW_UNCONFIRMED,
          text: t("Permission.ViewUnconfirmed")
        },
        {
          name: PERMISSION.EXPORT_FILE_UNCONFIRMED,
          text: t("Permission.ExportFileUnconfirmed")
        },
        { name: PERMISSION.RE_REQUEST, text: t("Permission.ReRequest") },
        { name: PERMISSION.REFUND_REQUEST, text: t("Permission.RefundRequest") }
      ],
      "Permission.Key.SettingRateFeepage": [
        { name: PERMISSION.VIEW_SETTING, text: t("Permission.ViewSetting") },
        { name: PERMISSION.SET_RATE, text: t("Permission.SetRate") },
        {
          name: PERMISSION.SET_EXCEL_FINANCE_FEE,
          text: t("Permission.SetExcelFinanceFee")
        },
        { name: PERMISSION.SET_EZP_FEE, text: t("Permission.SetEZPFee") },
        { name: PERMISSION.UPDATE_MAX_GBT, text: t("Permission.UpdateMaxGBT") }
      ],
      "Permission.Key.StatisticPage": [
        {
          name: PERMISSION.VIEW_STATISTIC,
          text: t("Permission.ViewStatistic")
        },
        {
          name: PERMISSION.EXPORT_FILE_STATISTIC,
          text: t("Permission.ExportFileStatistic")
        },
        {
          name: PERMISSION.VIEW_DETAIL_STATISTIC,
          text: t("Permission.ViewDetailStatistic")
        }
      ],
      "Permission.Key.HistoryLogin": [
        { name: PERMISSION.LOGIN_HISTORY, text: t("Permission.LoginHistory") }
      ],
      "Permission.Key.ActionLog": [
        { name: PERMISSION.LOG_ACTION, text: t("Permission.LogAction") }
      ],
      "HuvStyle": [
        {
          name: PERMISSION.HUV_NOT_YET_VERIFIED,
          text: t("Permission.HuvNotYetVerified")
        },
        {
          name: PERMISSION.HUV_CHECKING,
          text: t("Permission.HuvChecking")
        },
        {
          name: PERMISSION.HUV_APPROVED,
          text: t("Permission.HuvApproved")
        },
        {
          name: PERMISSION.HUV_REJECTING,
          text: t("Permission.HuvRejecting")
        },
        {
          name: PERMISSION.HUV_REJECTED,
          text: t("Permission.HuvRejected")
        },
        {
          name: PERMISSION.HUV_SETTING,
          text: t("Permission.HuvSetting")
        },
        {
          name: PERMISSION.HUV_ADMIN_REJECTED,
          text: t("Permission.HuvAdminRejected")
        },
        {
          name: PERMISSION.HUV_APPROVED_APP,
          text: t("Permission.HuvApprovedApp")
        },
        {
          name: PERMISSION.VIEW_LIST_USER,
          text: t("Permission.ViewListUser")
        }
      ],
      "Triple": [
        {
          name: PERMISSION.TRIPLE_NOT_YET_VERIFIED,
          text: t("Permission.TripleNotYetVerified")
        },
        {
          name: PERMISSION.TRIPLE_CHECKING,
          text: t("Permission.TripleChecking")
        },
        {
          name: PERMISSION.TRIPLE_APPROVED,
          text: t("Permission.TripleApproved")
        },
        {
          name: PERMISSION.TRIPLE_REJECTING,
          text: t("Permission.TripleRejecting")
        },
        {
          name: PERMISSION.TRIPLE_REJECTED,
          text: t("Permission.TripleRejected")
        },
        {
          name: PERMISSION.TRIPLE_SETTING,
          text: t("Permission.TripleSetting")
        },
        {
          name: PERMISSION.TRIPLE_ADMIN_REJECTED,
          text: t("Permission.TripleAdminRejected")
        },
        {
          name: PERMISSION.TRIPLE_APPROVED_APP,
          text: t("Permission.TripleApprovedApp")
        }
      ],
      "Pom": [
        {
          name: PERMISSION.POM_NOT_YET_VERIFIED,
          text: t("Permission.PomNotYetVerified")
        },
        {
          name: PERMISSION.POM_CHECKING,
          text: t("Permission.PomChecking")
        },
        {
          name: PERMISSION.POM_APPROVED,
          text: t("Permission.PomApproved")
        },
        {
          name: PERMISSION.POM_REJECTING,
          text: t("Permission.PomRejecting")
        },
        {
          name: PERMISSION.POM_REJECTED,
          text: t("Permission.PomRejected")
        },
        {
          name: PERMISSION.POM_SETTING,
          text: t("Permission.PomSetting")
        },
        {
          name: PERMISSION.POM_ADMIN_REJECTED,
          text: t("Permission.PomAdminRejected")
        },
        {
          name: PERMISSION.POM_APPROVED_APP,
          text: t("Permission.PomApprovedApp")
        },
        {
          name: PERMISSION.POM_VIEW_LIST_USER,
          text: t("Permission.PomViewListUser")
        }
      ],
      "Pomo": [
        {
          name: PERMISSION.POMO_NOT_YET_VERIFIED,
          text: t("Permission.PomoNotYetVerified")
        },
        {
          name: PERMISSION.POMO_CHECKING,
          text: t("Permission.PomoChecking")
        },
        {
          name: PERMISSION.POMO_APPROVED,
          text: t("Permission.PomoApproved")
        },
        {
          name: PERMISSION.POMO_REJECTING,
          text: t("Permission.PomoRejecting")
        },
        {
          name: PERMISSION.POMO_REJECTED,
          text: t("Permission.PomoRejected")
        },
        {
          name: PERMISSION.POMO_SETTING,
          text: t("Permission.PomoSetting")
        },
        {
          name: PERMISSION.POMO_ADMIN_REJECTED,
          text: t("Permission.PomoAdminRejected")
        },
        {
          name: PERMISSION.POMO_APPROVED_APP,
          text: t("Permission.PomoApprovedApp")
        },
        {
          name: PERMISSION.POMO_VIEW_LIST_USER,
          text: t("Permission.PomoViewListUser")
        }
      ],
      "MMP": [
        {
          name: PERMISSION.MMP_NOT_YET_VERIFIED,
          text: t("Permission.MMPNotYetVerified")
        },
        {
          name: PERMISSION.MMP_CHECKING,
          text: t("Permission.MMPChecking")
        },
        {
          name: PERMISSION.MMP_APPROVED,
          text: t("Permission.MMPApproved")
        },
        {
          name: PERMISSION.MMP_REJECTING,
          text: t("Permission.MMPRejecting")
        },
        {
          name: PERMISSION.MMP_REJECTED,
          text: t("Permission.MMPRejected")
        },
        {
          name: PERMISSION.MMP_SETTING,
          text: t("Permission.MMPSetting")
        },
        {
          name: PERMISSION.MMP_ADMIN_REJECTED,
          text: t("Permission.MMPAdminRejected")
        },
        {
          name: PERMISSION.MMP_APPROVED_APP,
          text: t("Permission.MMPApprovedApp")
        },
        {
          name: PERMISSION.MMP_VIEW_LIST_USER,
          text: t("Permission.MMPViewListUser")
        }
      ],
      "Menu.DepositToSystem":[
        {
          name:PERMISSION.DEPOSIT_TO_HUV,
          text:  t("Permission.DepositToHuv")
        },
        {
          name:PERMISSION.DEPOSIT_TO_TRIPLE,
          text: t("Permission.DepositToTriple")
        },
        {
          name:PERMISSION.DEPOSIT_TO_POM,
          text: t("Permission.DepositToPom")
        },
        {
          name:PERMISSION.DEPOSIT_TO_POMO,
          text: t("Permission.DepositToPomo")
        },
        {
          name:PERMISSION.DEPOSIT_TO_MMP,
          text: t("Permission.DepositToMMP")
        }
      ],
    };

    return (
      <Modal
        isOpen={isShowModal}
        toggle={this.toggleDialog}
        className={this.props.className}
      >
        <ModalHeader toggle={this.toggleDialog}>
          {edit_permission
            ? selectedRole && selectedRole._id
              ? t("PermissionSetting.DeleteRole")
              : t("PermissionSetting.NewRole")
            : t("PermissionSetting.PermissionList")}
        </ModalHeader>
        <ModalBody className="permission-setting">
          <Form>
            {edit_permission ? (
              <FormGroup
                row
                className="d-flex justify-content-between align-items-center"
              >
                <div className="ml-4" sm={4}>
                  {t("PermissionSetting.NameOfRole")}
                </div>
                <Col sm={8}>
                  <Input
                    type="input"
                    name="name"
                    id="name"
                    value={(selectedRole && selectedRole.name) || undefined}
                    onChange={e => this.setState({ name: e.target.value })}
                    placeholder="Name"
                    disabled={selectedRole && selectedRole._id}
                  />
                </Col>
              </FormGroup>
            ) : (
              t("PermissionSetting.Permission") && (
                <div>
                  <div className="d-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>{t("PermissionSetting.Title")}</div>
                      <Input
                        className="input-label-style ml-4 w-auto"
                        type="input"
                        name="title"
                        id="title"
                        placeholder="Title"
                        value={this.state.name}
                        onChange={e => this.setState({ name: e.target.value })}
                      />
                    </div>
                    <Button
                      color="primary"
                      disabled={!this.state.name.trim()}
                      className="button-update"
                      onClick={this.handleUpdatePermission}
                    >
                      {t("Button.Update")}
                    </Button>
                  </div>
                  <div className="permission-view">
                    {Object.keys(permissionData).map((item, index) => (
                      <React.Fragment key={index}>
                        <div className="permission-page d-flex align-items-center">
                          <CcCheckbox
                            checked={
                              this.checkAllBox(permissionData[item]) || false
                            }
                            onChange={e =>
                              this.handleCheckbox(e, permissionData[item])
                            }
                          />
                          {t(item)}
                        </div>
                        {permissionData[item].map((value, key) => (
                          <PermissionItem
                            key={key}
                            checked={permission.includes(value.name) || false}
                            onChange={e =>
                              this.handleCheckboxChange(e, value.name)
                            }
                            text={value.text}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              )
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" className="brs" onClick={this.toggleDialog}>
            {t("Button.Close")}
          </Button>{" "}
          {edit_permission ? (
            selectedRole && selectedRole._id ? (
              <Button
                color="primary"
                className="brs"
                onClick={e => this.handleSubmitBtn(e, "Delete")}
              >
                {t("Button.Delete")}
              </Button>
            ) : (
              <Button
                color="primary"
                className="brs"
                disabled={!this.state.name.trim()}
                onClick={e => this.handleSubmitBtn(e, "Create")}
              >
                {t("Button.Create")}
              </Button>
            )
          ) : null}
        </ModalFooter>
      </Modal>
    );
  }

  render() {
    const { t } = this.props;
    return (
      <div className="permission-setting">
        <div className="title">
          <div className="title-content">
            {t("PermissionSetting.PermissionTitle")}
          </div>
        </div>
        {/* <div className="title">{t("PermissionSetting.PermissionTitle")}</div> */}

        <Container>
          <Row className="mt-3">
            <Col lg={{ size: 'auto', offset: 3 }} md={{ size: 'auto', offset: 3 }} sm={{ size: 'auto', offset: 3 }} xs="12" className="mt-1">
              {this.renderForm()}
              <Button
                color="primary"
                className="custom-btn mr-4 mb-4"
                onClick={() =>
                  this.setState({
                    isShowModal: true,
                    edit_permission: true,
                    selectedRole: {}
                  })
                }
              >
                {t("Button.AddNew")}
              </Button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col lg={{ size: 'auto', offset: 3 }} md={{ size: 'auto', offset: 3 }} sm={{ size: 'auto', offset: 3 }} xs="12" className="mt-1">
              <div className="content-panel">
                <div className="header">
                  <div className="mainHeader">{t("PermissionSetting.Role")}</div>
                </div>
                <div className="content-body">{this.renderTable()}</div>
              </div>
            </Col>
          </Row>
        </Container>

        {/* <Form className="mt-5 ml-5 setting-cover">
          {this.renderForm()}
          <Button
            color="primary"
            className="custom-btn mr-4 mb-4"
            onClick={() =>
              this.setState({
                isShowModal: true,
                edit_permission: true,
                selectedRole: {}
              })
            }
          >
            {t("Button.AddNew")}
          </Button>
          <div className="content-panel">
            <div className="header">
              <div className="mainHeader">{t("PermissionSetting.Role")}</div>
            </div>
            <div className="content-body">{this.renderTable()}</div>
          </div>
        </Form> */}
        {[
          ACTIONS.CREATE_ROLE_FAILED,
          ACTIONS.DELETE_ROLE_FAILED,
          ACTIONS.GET_ROLE_FAILED,
          ACTIONS.UPDATE_ROLE_FAILED
        ].indexOf(this.props.permission) >= 0 &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Error")}
              content={this.props.permissionError.code}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.setState({ alertAck: true });
              }}
            />
          )}
        {this.props.permission === ACTIONS.CREATE_ROLE_SUCCESS &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Success")}
              content={t("PermissionSetting.CreateRoleSuccess")}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.setState({ alertAck: true, name: "" });
              }}
            />
          )}
        {this.props.permission === ACTIONS.UPDATE_ROLE_SUCCESS &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Success")}
              content={t("PermissionSetting.UpdateRoleSuccess")}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.setState({ alertAck: true });
              }}
            />
          )}
        {this.props.permission === ACTIONS.DELETE_ROLE_SUCCESS &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Success")}
              content={t("PermissionSetting.DeleteRoleSuccess")}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.setState({ alertAck: true });
              }}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    permission: store.permission.status,
    permissionError: store.permission.permissionError,
    role: store.permission.role
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRoles: () => {
      dispatch(getRoles());
    },
    handleDeleteRole: data => {
      dispatch(deleteRole(data));
    },
    handleCreateRole: data => {
      dispatch(createRole(data));
    },
    updatePermission: data => {
      dispatch(updatePermission(data));
    }
  };
};
export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PermissionSetting)
);
