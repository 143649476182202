import React, { Component } from "react";
import Confirm from "../../../../../03-components/Confirm";
import { Button, Input, FormGroup } from "reactstrap";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import ggAuthentication from "../../../../../06-assets/images/ga-guide.png";
import { TYPE_SETTING } from "../../../../../05-utils/commonData";
import {
  ACTIONS,
  MmpAuthenticationSetting,
  MmpReceivingCCT,
  MmpRefundCCT,
  MmpUpdateExcelFinanceSetting,
  MmpUpdateEzpSetting,
  MmpUpdateRateLimit,
  MmpUpdateRateSetting,
} from "../../../../../01-actions/settingRateMMP";

class ModalSettingRate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.status === ACTIONS.MMP_RECEIVING_CCT_SUCCESS ||
      nextProps.status === ACTIONS.MMP_REFUND_CCT_SUCCESS ||
      nextProps.status === ACTIONS.MMP_UPDATE_EXCEL_FINANCE_SETTING_SUCCESS ||
      nextProps.status === ACTIONS.MMP_GET_RATE_SETTING_SUCCESS ||
      nextProps.status === ACTIONS.MMP_UPDATE_EZP_SETTING_SUCCESS ||
      nextProps.status === ACTIONS.MMP_UPDATE_RATE_LIMIT_SETTING_SUCCESS ||
      nextProps.status === ACTIONS.MMP_UPDATE_SETTING_RATE_SUCCESS ||
      nextProps.status === ACTIONS.MMP_AUTHENTICATION_SUCCESS
    ) {
      this.setState({
        code: "",
      });
    }
  }

  onSubmit = () => {
    if (!this.state.code) return;
    const {
      isRefundCCT,
      isReceivingCCT,
      valueRefundCCT,
      valueReceivingCCT,
      secretValueCCT,
      isUpdateSettingCCT,
      valueRateSettingCCT,
      isUpdateSetting,
      valueRateSetting,
      isFeeExcel,
      settingExcelFinance,
      isFeeGlover,
      settingEzp,
      businessId,
      isSettingRateLimit,
      title,
      oneTimeLimit,
      dailyLimit,
      monthlyLimit,
      yearlyLimit,
      isAuthenticationSetting,
      mmpEmail,
      mmpPassword,
      mmpToken,
      isRefundROY,
      isReceivingROY,
      isUpdateSettingROY,
      valueRefundROY,
      valueReceivingROY,
      secretValueROY,
      valueRateSettingROY,
    } = this.props;

    if (isAuthenticationSetting) {
      this.props.authenticationSetting({
        TOKEN: mmpToken,
        EMAIL: mmpEmail,
        PASSWORD: mmpPassword,
        code: this.state.code,
      });
    }

    if (isRefundCCT) {
      this.props.refundCCT({
        walletAddress: {
          key: TYPE_SETTING.WALLET_ADDRESS_CCT,
          value: valueRefundCCT,
        },
        secretKey: {
          key: TYPE_SETTING.SECRET_KEY_CCT,
          value: secretValueCCT,
        },
        code: this.state.code,
      });
    }

    if (isRefundROY) {
      this.props.refundCCT({
        walletAddress: {
          key: TYPE_SETTING.WALLET_ADDRESS_ROY,
          value: valueRefundROY
        },
        secretKey: {
          key: TYPE_SETTING.SECRET_KEY_ROY,
          value: secretValueROY
        },
        code: this.state.code
      });
    } 

    if (isReceivingCCT) {
      this.props.receivingCCT({
        key: TYPE_SETTING.ADDRESS_USER_CCT,
        value: valueReceivingCCT,
        code: this.state.code,
      });
    }

    if (isReceivingROY) {
      this.props.receivingCCT({
        key: TYPE_SETTING.ADDRESS_USER_ROY,
        value: valueReceivingROY,
        code: this.state.code
      });
    }

    if (isUpdateSetting) {
      this.props.updateRateSetting({
        key: TYPE_SETTING.MMP_RATE,
        value: valueRateSetting,
        code: this.state.code,
      });
    }

    if (isUpdateSettingCCT) {
      this.props.updateRateSetting({
        key: TYPE_SETTING.CCT_RATE,
        value: valueRateSettingCCT,
        code: this.state.code,
      });
    }

    if (isUpdateSettingROY) {
      this.props.updateRateSetting({
        key: TYPE_SETTING.ROY_RATE,
        value: valueRateSettingROY,
        code: this.state.code
      });
    }

    if (isFeeExcel) {
      this.props.updateExcelFinanceSetting({
        key: TYPE_SETTING.MMP_EXCEL_FINANCE_FEE,
        value: settingExcelFinance,
        code: this.state.code,
      });
    }

    if (isFeeGlover) {
      this.props.updateEzpSetting({
        key: TYPE_SETTING.MMP_POMO_CARD_FEE,
        value: settingEzp,
        businessId: businessId,
        code: this.state.code,
      });
    }

    if (isSettingRateLimit) {
      this.props.updateRateLimit({
        title: title,
        oneTimeLimit: oneTimeLimit,
        dailyLimit: dailyLimit,
        monthlyLimit: monthlyLimit,
        yearlyLimit: yearlyLimit,
        code: this.state.code,
      });
    }
  };

  onchangeTfaCode = (filed) => {
    return (e) => {
      this.setState({
        [filed]: e.target.value,
      });
    };
  };

  onClose = () => {
    const { close } = this.props;
    this.setState({
      code: "",
    });
    close();
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Confirm
          isOpen={this.props.isOpen}
          toggle={this.onClose}
          destroyOnClose
          centered
          maskClosable={false}
          title={t("Two-step_verification")}
          className="modal-confirm-tfa-login"
        >
          <FormGroup>
            <div className="text-center">
              <img className="w-100" src={ggAuthentication} alt="" />
            </div>
          </FormGroup>
          <FormGroup className="text-center" name="code">
            <div>
              <Input
                type="text"
                className="text-center tfa-code"
                value={this.state.code}
                // disabled={submitting}
                placeholder={t("Two-step_verification")}
                onChange={this.onchangeTfaCode("code")}
              />
            </div>
          </FormGroup>
          <FormGroup className="mb-0 text-center box-action">
            <Button
              className="btn-tfa-login"
              type="primary"
              onClick={this.onSubmit}
              disabled={!this.state.code}
            >
              {t("TFASetting.Submit")}
            </Button>
          </FormGroup>
        </Confirm>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    status: store.settingRateMMP.mmpStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    refundCCT: (data) => {
      dispatch(MmpRefundCCT(data));
    },
    receivingCCT: (data) => {
      dispatch(MmpReceivingCCT(data));
    },
    updateRateSetting: (data) => {
      dispatch(MmpUpdateRateSetting(data));
    },
    updateExcelFinanceSetting: (data) => {
      dispatch(MmpUpdateExcelFinanceSetting(data));
    },
    updateEzpSetting: (data) => {
      dispatch(MmpUpdateEzpSetting(data));
    },
    updateRateLimit: (data) => {
      dispatch(MmpUpdateRateLimit(data));
    },
    authenticationSetting: (data) => {
      dispatch(MmpAuthenticationSetting(data))
    },
  };
};

export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(ModalSettingRate)
);
