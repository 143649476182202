import {getEnv} from "../env";

const TYPE_SETTING = {
  EZP_FEE: "EZP_FEE",
  EXCEL_FEE: "EXCEL_FEE",
  RATE: "RATE",
  HUV_RATE: "HUV_RATE",
  CURSOR: "CURSOR",
  MAX_GBT_CHARGE: "MAX_GBT_CHARGE",
  TIME_START: "TIME_START",
  TIME_END: "TIME_END",
  TIME_SET_LOOP: "TIME_SET_LOOP",
  EXCEL_FINANCE_FEE: "EXCEL_FINANCE_FEE",
  HUV_EXCEL_FINANCE_FEE: "HUV_EXCEL_FINANCE_FEE",
  HUV_GLOVER_CARD_FEE: "HUV_GLOVER_CARD_FEE",
  WALLET_ADDRESS_AGT: "WALLET_ADDRESS_AGT",
  SECRET_KEY_AGT: "SECRET_KEY_AGT",
  ADDRESS_USER_AGT: "ADDRESS_USER_AGT",
  WALLET_ADDRESS_GCCT: "WALLET_ADDRESS_GCCT",
  SECRET_KEY_GCCT: "SECRET_KEY_GCCT",
  ADDRESS_USER_GCCT: "ADDRESS_USER_GCCT",
  WALLET_ADDRESS_CCT: "WALLET_ADDRESS_CCT",
  SECRET_KEY_CCT: "SECRET_KEY_CCT",
  ADDRESS_USER_CCT: "ADDRESS_USER_CCT",
  FIL_RATE: "FIL_RATE",
  CCT_RATE: "CCT_RATE",
  POM_RATE: "POM_RATE",
  POM_EXCEL_FINANCE_FEE: "POM_EXCEL_FINANCE_FEE",
  POM_GLOVER_CARD_FEE: "POM_GLOVER_CARD_FEE",
  POMO_RATE: "POMO_RATE",
  POMO_EXCEL_FINANCE_FEE: "POMO_EXCEL_FINANCE_FEE",
  POMO_POMO_CARD_FEE: "POMO_POMO_CARD_FEE",
  TOKEN: "TOKEN",
  EMAIL:"EMAIL",
  PASSWORD:"PASSWORD",
  MMP_RATE: "MMP_RATE",
  MMP_EXCEL_FINANCE_FEE: "MMP_EXCEL_FINANCE_FEE",
  MMP_POMO_CARD_FEE: "MMP_POMO_CARD_FEE",

  ROY_RATE: "ROY_RATE",
  WALLET_ADDRESS_ROY: "WALLET_ADDRESS_ROY",
  SECRET_KEY_ROY: "SECRET_KEY_ROY",
  ADDRESS_USER_ROY: "ADDRESS_USER_ROY",

  GROY_RATE: "GROY_RATE",
  WALLET_ADDRESS_GROY: "WALLET_ADDRESS_GROY",
  SECRET_KEY_GROY: "SECRET_KEY_GROY",
  ADDRESS_USER_GROY: "ADDRESS_USER_GROY",
};
const STATUS_CHARGE = {
  REFUNDED: "refunded",
  REJECT: "reject",
  INIT: "init",
  APPROVE: "approve",
};

const COIN_TYPE = {
  AGT: "AGT",
  GCCT: "GCCT",
  CCT: "CCT",
  FIL: "GCCT",
  ROY: "ROY",
  GROY: "GROY"
};

const STATUS_USER = {
  ACTIVE: "Active",
  DISABLED: "Disabled",
};
const ROLE_SUPPER_ADMIN = "super-admin";

const GE_TOP_UP_STATUS = {
  0: "ADMIN REVIEW",
  1: "SUPER ADMIN REVIEW",
};

const PERMISSION = {
  VIEW_TOP_UP: "get_charge_by_status_" + STATUS_CHARGE.INIT,
  REJECT_REQUEST: "change_status_charge_" + STATUS_CHARGE.REJECT,
  UPDATE_STATUS: "change_status_charge_" + STATUS_CHARGE.APPROVE,
  EXPORT_FILE_REQUEST: "search_charge_" + STATUS_CHARGE.INIT,
  VIEW_RE_BALANCE: "get_balance_account",
  SEND_TO_EZP: "send_to_ezp",
  IMPORT_CSV: "update_balance_account",
  VIEW_HISTORY: "get_charge_by_status_" + STATUS_CHARGE.APPROVE,
  EXPORT_FILE_HISTORY: "search_charge_" + STATUS_CHARGE.APPROVE,
  VIEW_UNCONFIRMED: "get_charge_by_status_" + STATUS_CHARGE.REJECT,
  EXPORT_FILE_UNCONFIRMED: "search_charge_" + STATUS_CHARGE.REJECT,
  RE_REQUEST: "change_status_charge_" + STATUS_CHARGE.INIT,
  REFUND_REQUEST: "change_status_charge_" + STATUS_CHARGE.REFUNDED,
  VIEW_SETTING: "view_setting",
  SET_RATE: "update_setting_" + TYPE_SETTING.RATE,
  SET_EXCEL_FINANCE_FEE: "update_setting_" + TYPE_SETTING.EXCEL_FEE,
  SET_EZP_FEE: "update_setting_" + TYPE_SETTING.EZP_FEE,
  VIEW_STATISTIC: "view_statistic",
  EXPORT_FILE_STATISTIC: "export_file_statistic",
  VIEW_DETAIL_STATISTIC: "view_detail_statistic",
  PERMISSION: "permission",
  MEMBER: "member",
  UPDATE_MAX_GBT: "update_gbt_max",
  LOGIN_HISTORY: "get_all_location",
  LOG_ACTION: "get_log",
  HUV_NOT_YET_VERIFIED: "huv-not-yet-verified",
  HUV_CHECKING: "huv-checking",
  HUV_APPROVED: "huv-approved",
  HUV_REJECTING: "huv-rejecting",
  HUV_REJECTED: "huv-rejected",
  HUV_SETTING: "huv-setting",
  HUV_ADMIN_REJECTED: "huv-rejected-step-2",
  HUV_APPROVED_APP: "huv-approved-app",
  TRIPLE_NOT_YET_VERIFIED: "triple-not-yet-verified",
  TRIPLE_CHECKING: "triple-checking",
  TRIPLE_ADMIN_REJECTED: "triple-admin-rejected",
  TRIPLE_APPROVED: "triple-approved",
  TRIPLE_REJECTING: "triple-rejecting",
  TRIPLE_REJECTED: "triple-rejected",
  TRIPLE_SETTING: "triple-setting",
  TRIPLE_APPROVED_APP: "triple-approved-app",
  DEPOSIT_TO_HUV: "deposit-to-huv",
  DEPOSIT_TO_TRIPLE: "deposit-to-triple",
  VIEW_LIST_USER: "view-list-user",
  POM_NOT_YET_VERIFIED: "pom-not-yet-verified",
  POM_CHECKING: "pom-checking",
  POM_APPROVED: "pom-approved",
  POM_REJECTING: "pom-rejecting",
  POM_REJECTED: "pom-rejected",
  POM_SETTING: "pom-setting",
  POM_ADMIN_REJECTED: "pom-rejected-step-2",
  POM_APPROVED_APP: "pom-approved-app",
  POM_VIEW_LIST_USER: "pom-view-list-user",
  DEPOSIT_TO_POM: "deposit-to-pom",
  POMO_NOT_YET_VERIFIED: "pomo-not-yet-verified",
  POMO_CHECKING: "pomo-checking",
  POMO_APPROVED: "pomo-approved",
  POMO_REJECTING: "pomo-rejecting",
  POMO_REJECTED: "pomo-rejected",
  POMO_SETTING: "pomo-setting",
  POMO_ADMIN_REJECTED: "pomo-rejected-step-2",
  POMO_APPROVED_APP: "pomo-approved-app",
  POMO_VIEW_LIST_USER: "pomo-view-list-user",
  DEPOSIT_TO_POMO: "deposit-to-pomo",
  MMP_NOT_YET_VERIFIED: "mmp-not-yet-verified",
  MMP_CHECKING: "mmp-checking",
  MMP_APPROVED: "mmp-approved",
  MMP_REJECTING: "mmp-rejecting",
  MMP_REJECTED: "mmp-rejected",
  MMP_SETTING: "mmp-setting",
  MMP_ADMIN_REJECTED: "mmp-rejected-step-2",
  MMP_APPROVED_APP: "mmp-approved-app",
  MMP_VIEW_LIST_USER: "mmp-view-list-user",
  DEPOSIT_TO_MMP: "deposit-to-mmp",
};

const SYSTEMS = {
  GREENBOX_TRIPLE: {
    name: "Header.GreenboxTriple",
    value: "GREENBOX_TRIPLE",
  },
  HUVSTYLE: {
    name: "Header.Huvstyle",
    value: "HUVSTYLE",
  },
  POM: {
    name: "Header.Pom",
    value: "POM",
  },
  POMO:{
    name: "Header.Pomo",
    value: "POMO",
  },
  MMP:{
    name: "Header.MMP",
    value: "MMP",
  }
};

const HUV_UPDATE_STATUS_TOP_UP = {
  CHECKED: "CHECKED",
  REJECTED: "REJECTED",
  RECHECKED: "RECHECKED",
  REJECTION: "REJECTION",
};

const HUV_STYLE_TOP_UP_TAB = {
  NotYetVerified: {
    name: "HuvStyleTopUpPage.Tabs.NotYetVerified",
    value: "not-yet-verified",
    permission: "huv-not-yet-verified",
  },
  UncheckedToRejected: {
    name: "HuvStyleTopUpPage.Tabs.UncheckedToRejected",
    value: "rejected-step-2",
    permission: "huv-rejected-step-2",
  },
  Checking: {
    name: "HuvStyleTopUpPage.Tabs.Checking",
    value: "checking",
    permission: "huv-checking",
  },
  Approved: {
    name: "HuvStyleTopUpPage.Tabs.Approved",
    value: "approved",
    permission: "huv-approved",
  },
  Rejecting: {
    name: "HuvStyleTopUpPage.Tabs.Rejecting",
    value: "rejecting",
    permission: "huv-rejecting",
  },
  Rejected: {
    name: "HuvStyleTopUpPage.Tabs.Rejected",
    value: "rejected",
    permission: "huv-rejected",
  },
};

const POM_UPDATE_STATUS_TOP_UP = {
  CHECKED: "CHECKED",
  REJECTED: "REJECTED",
  RECHECKED: "RECHECKED",
  REJECTION: "REJECTION",
};

const POM_TOP_UP_TAB = {
  NotYetVerified: {
    name: "HuvStyleTopUpPage.Tabs.NotYetVerified",
    value: "not-yet-verified",
    permission: "pom-not-yet-verified",
  },
  UncheckedToRejected: {
    name: "HuvStyleTopUpPage.Tabs.UncheckedToRejected",
    value: "rejected-step-2",
    permission: "pom-rejected-step-2",
  },
  Checking: {
    name: "HuvStyleTopUpPage.Tabs.Checking",
    value: "checking",
    permission: "pom-checking",
  },
  Approved: {
    name: "HuvStyleTopUpPage.Tabs.Approved",
    value: "approved",
    permission: "pom-approved",
  },
  Rejecting: {
    name: "HuvStyleTopUpPage.Tabs.Rejecting",
    value: "rejecting",
    permission: "pom-rejecting",
  },
  Rejected: {
    name: "HuvStyleTopUpPage.Tabs.Rejected",
    value: "rejected",
    permission: "pom-rejected",
  },
};

const POMO_TOP_UP_TAB = {
  NotYetVerified: {
    name: "HuvStyleTopUpPage.Tabs.NotYetVerified",
    value: "not-yet-verified",
    permission: "pomo-not-yet-verified",
  },
  UncheckedToRejected: {
    name: "HuvStyleTopUpPage.Tabs.UncheckedToRejected",
    value: "rejected-step-2",
    permission: "pomo-rejected-step-2",
  },
  Checking: {
    name: "HuvStyleTopUpPage.Tabs.Checking",
    value: "checking",
    permission: "pomo-checking",
  },
  Approved: {
    name: "HuvStyleTopUpPage.Tabs.Approved",
    value: "approved",
    permission: "pomo-approved",
  },
  Rejecting: {
    name: "HuvStyleTopUpPage.Tabs.Rejecting",
    value: "rejecting",
    permission: "pomo-rejecting",
  },
  Rejected: {
    name: "HuvStyleTopUpPage.Tabs.Rejected",
    value: "rejected",
    permission: "pomo-rejected",
  },
};

const MMP_TOP_UP_TAB = {
  NotYetVerified: {
    name: "HuvStyleTopUpPage.Tabs.NotYetVerified",
    value: "not-yet-verified",
    permission: "mmp-not-yet-verified",
  },
  UncheckedToRejected: {
    name: "HuvStyleTopUpPage.Tabs.UncheckedToRejected",
    value: "rejected-step-2",
    permission: "mmp-rejected-step-2",
  },
  Checking: {
    name: "HuvStyleTopUpPage.Tabs.Checking",
    value: "checking",
    permission: "mmp-checking",
  },
  Approved: {
    name: "HuvStyleTopUpPage.Tabs.Approved",
    value: "approved",
    permission: "mmp-approved",
  },
  Rejecting: {
    name: "HuvStyleTopUpPage.Tabs.Rejecting",
    value: "rejecting",
    permission: "mmp-rejecting",
  },
  Rejected: {
    name: "HuvStyleTopUpPage.Tabs.Rejected",
    value: "rejected",
    permission: "mmp-rejected",
  },
};

const MANIGO_STATUS = {
  INIT: "init",
  SUCCESS: "success",
  FAIL: "fail",
};

const ENCRYPT_KEY = getEnv('ENCRYPT_KEY');
const IV_ENCRYPT = getEnv('IV_ENCRYPT');

// const ENCRYPT_KEY = "7c8b5J341Gy8Ds4AJQR7kEFZH3WmcslR";
// const IV_ENCRYPT = "aKSKKSZsB7I9sTxr";

export {
  MANIGO_STATUS,
  HUV_UPDATE_STATUS_TOP_UP,
  TYPE_SETTING,
  ROLE_SUPPER_ADMIN,
  STATUS_CHARGE,
  PERMISSION,
  STATUS_USER,
  GE_TOP_UP_STATUS,
  SYSTEMS,
  HUV_STYLE_TOP_UP_TAB,
  ENCRYPT_KEY,
  IV_ENCRYPT,
  COIN_TYPE,
  POM_TOP_UP_TAB,
  POM_UPDATE_STATUS_TOP_UP,
  POMO_TOP_UP_TAB,
  MMP_TOP_UP_TAB
};
