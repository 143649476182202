import React from "react";
import { connect } from "react-redux";
import { List, Divider } from "@material-ui/core";
import {
  routes,
  huvStyleRoutes,
  pomRoutes,
  pomoRoutes,
  mmpRoutes
} from "../../../../routes";
import ChildMenu from "./ChildMenu";
import ezpLogo from "../../../../06-assets/images/eZpay_logo.png";
import { withRouter } from "react-router-dom";
import "./style.css";
// import { SYSTEMS, PERMISSION } from "../../../../05-utils/commonData";
import { getStatusPage } from "../Header";
import { SYSTEMS } from "../../../../05-utils/commonData";

const Menu = ({
  history: {
    location: { pathname },
  },
  ...props
}) => {
  const statusPage = getStatusPage(pathname);
  const permissionArr = props.infoUser && props.infoUser.roleId?.permission;
  const checkPermission = (permissions) => {
    let isPermission = false;
    if (permissions && permissions.length > 0) {
      permissions.map((item) => {
        if (
          permissionArr &&
          permissionArr.findIndex((a) => a === item) !== -1
        ) {
          isPermission = true;
        }
      });
    }
    return isPermission;
  };

  return (
    <div>
      <List>
        <div className="logo">
          <img src={ezpLogo} alt="Ezp logo" />
        </div>
        <Divider />
        {statusPage === SYSTEMS.GREENBOX_TRIPLE.value &&
          routes.map((route, index) =>
            !route.redirect ? (
              <ChildMenu route={route} key={index} {...props} />
            ) : null
          )}
        {statusPage === SYSTEMS.HUVSTYLE.value &&
          Object.values(huvStyleRoutes).map((route, index) =>
            !route.redirect && checkPermission(route.permissions) ? (
              <ChildMenu route={route} key={index} {...props} />
            ) : null
          )}
        {statusPage === SYSTEMS.POM.value &&
          Object.values(pomRoutes).map(
            (route, index) =>
              !route.redirect && checkPermission(route.permissions) ? (
                <ChildMenu route={route} key={index} {...props} />
              ) : null
          )}
        {statusPage === SYSTEMS.POMO.value &&
          Object.values(pomoRoutes).map((route, index) =>
            !route.redirect && checkPermission(route.permissions) ? (
              <ChildMenu route={route} key={index} {...props} />
            ) : null
          )}
        {statusPage === SYSTEMS.MMP.value &&
          Object.values(mmpRoutes).map((route, index) =>
            !route.redirect && checkPermission(route.permissions) ? (
              <ChildMenu route={route} key={index} {...props} />
            ) : null
          )}
      </List>
    </div>
    // <div id="sidebar" className="sidebar">
    //     <div className="logo">
    //         <img src={ezpLogo} alt="Ezp logo"/>
    //     </div>
    //     <div className="sidebar-wrapper">
    //         <ul className="nav">
    //             {routes.map((route, index) => (!route.redirect ?
    //                 <ChildMenu route={route} key={index} {...this.props} /> : null)
    //             )}
    //         </ul>
    //     </div>
    // </div>
  );
};

function mapStateToProps(state) {
  const { auth, member } = state;
  return {
    user: auth.user,
    infoUser: member.infoUser,
  };
}

export default withRouter(connect(mapStateToProps)(Menu));
