import { callApi } from "../05-utils/commonUtils";
import queryString from "query-string";

const ACTIONS = {
  MMP_CLEAR_STATUS: "MMP_CLEAR_STATUS",
  MMP_GET_CHARGE_PROGRESS: "MMP_GET_CHARGE_PROGRESS",
  MMP_GET_CHARGE_SUCCESS: "MMP_GET_CHARGE_SUCCESS",
  MMP_GET_CHARGE_FAIL: "MMP_GET_CHARGE_FAIL",
  MMP_SEARCH_CHARGE_PROGRESS: "MMP_SEARCH_CHARGE_PROGRESS",
  MMP_SEARCH_CHARGE_SUCCESS: "MMP_SEARCH_CHARGE_SUCCESS",
  MMP_SEARCH_CHARGE_FAIL: "MMP_SEARCH_CHARGE_FAIL",
  MMP_GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS:
    "MMP_GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS",
  MMP_GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS:
    "MMP_GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS",
  MMP_GET_STATISTIC_CHARGE_BY_STATUS_FAIL:
    "MMP_GET_STATISTIC_CHARGE_BY_STATUS_FAIL",
  MMP_CHANGE_STATUS_TOP_UP_PROCESS: "MMP_CHANGE_STATUS_TOP_UP_PROCESS",
  MMP_CHANGE_STATUS_TOP_UP_SUCCESS: "MMP_CHANGE_STATUS_TOP_UP_SUCCESS",
  MMP_CHANGE_STATUS_TOP_UP_FAIL: "MMP_CHANGE_STATUS_TOP_UP_FAIL",
  MMP_CHANGE_NOTE_PROCESS: "MMP_CHANGE_NOTE_PROCESS",
  MMP_CHANGE_NOTE_SUCCESS: "MMP_CHANGE_NOTE_SUCCESS",
  MMP_CHANGE_NOTE_FAIL: "MMP_CHANGE_NOTE_FAIL",
  MMP_PUSH_NOTIFICATION_PROCESS: "MMP_PUSH_NOTIFICATION_PROCESS",
  MMP_PUSH_NOTIFICATION_SUCCESS: "MMP_PUSH_NOTIFICATION_SUCCESS",
  MMP_PUSH_NOTIFICATION_FAIL: "MMP_PUSH_NOTIFICATION_FAIL",
  MMP_GET_CURRENCY_BALANCE_PROGRESS: "MMP_GET_CURRENCY_BALANCE_PROGRESS",
  MMP_GET_CURRENCY_BALANCE_SUCCESS: "MMP_GET_CURRENCY_BALANCE_SUCCESS",
  MMP_GET_CURRENCY_BALANCE_FAIL: "MMP_GET_CURRENCY_BALANCE_FAIL",
};

const MmpUpdateStatus = (status, statusInfo) => {
  return {
    type: status,
    data: statusInfo,
  };
};

const MmpCurrencyBalance = () => {
  let config = {
    method: "get",
    isAuthorization: true,
  };
  return (dispatch) => {
    dispatch(MmpUpdateStatus(ACTIONS.MMP_GET_CURRENCY_BALANCE_PROGRESS));
    return callApi(
      `/api/pomo-mmp/balance_admin`,
      config,
      null,
      (result) => {
        dispatch(
          MmpUpdateStatus(ACTIONS.MMP_GET_CURRENCY_BALANCE_SUCCESS, {
            currencyBalance: result,
          })
        );
      },
      (err) => {
        dispatch(
          MmpUpdateStatus(ACTIONS.MMP_GET_CURRENCY_BALANCE_FAIL, {
            error: err,
          })
        );
      }
    );
  };
};

const MmpPushNotification = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(MmpUpdateStatus(ACTIONS.MMP_PUSH_NOTIFICATION_PROCESS));
    return callApi(
      `/api/pomo-mmp/push_notification`,
      config,
      null,
      (result) => {
        dispatch(
          MmpUpdateStatus(ACTIONS.MMP_PUSH_NOTIFICATION_SUCCESS, {
            note: result.data,
          })
        );
      },
      (err) => {
        dispatch(
          MmpUpdateStatus(ACTIONS.MMP_PUSH_NOTIFICATION_FAIL, { error: err })
        );
      }
    );
  };
};

const MmpChangeNote = (data, callback) => {
  let config = {
    method: "put",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(MmpUpdateStatus(ACTIONS.MMP_CHANGE_NOTE_PROCESS));
    return callApi(
      `/api/pomo-mmp/note_charge`,
      config,
      null,
      (result) => {
        dispatch(
          MmpUpdateStatus(ACTIONS.MMP_CHANGE_NOTE_SUCCESS, {
            note: result.data,
          })
        );
        callback(ACTIONS.MMP_CHANGE_NOTE_SUCCESS, result);
      },
      (err) => {
        dispatch(
          MmpUpdateStatus(ACTIONS.MMP_CHANGE_NOTE_FAIL, { error: err })
        );
        callback(ACTIONS.MMP_CHANGE_NOTE_FAIL, err);
      }
    );
  };
};

const MmpChangeStatus = (data, callback) => {
  let config = {
    method: "put",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(MmpUpdateStatus(ACTIONS.MMP_CHANGE_STATUS_TOP_UP_PROCESS));
    return callApi(
      `/api/pomo-mmp/change_status_top_up`,
      config,
      null,
      (result) => {
        dispatch(
          MmpUpdateStatus(ACTIONS.MMP_CHANGE_STATUS_TOP_UP_SUCCESS, {
            note: result.data,
          })
        );
        callback(ACTIONS.MMP_CHANGE_STATUS_TOP_UP_SUCCESS, result);
      },
      (err) => {
        dispatch(
          MmpUpdateStatus(ACTIONS.MMP_CHANGE_STATUS_TOP_UP_FAIL, {
            error: err,
          })
        );
        callback(ACTIONS.MMP_CHANGE_STATUS_TOP_UP_FAIL, err);
      }
    );
  };
};

const MmpGetStatisticChargeByStatus = (data) => {
  let config = {
    method: "get",
    isAuthorization: true,
  };
  return (dispatch) => {
    dispatch(
      MmpUpdateStatus(ACTIONS.MMP_GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS)
    );
    return callApi(
      `/api/pomo-mmp/statistic_charge_status`,
      config,
      null,
      (result) => {
        dispatch(
          MmpUpdateStatus(
            ACTIONS.MMP_GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS,
            {
              statisticChargeByStatus: result.data,
            }
          )
        );
      },
      (err) => {
        dispatch(
          MmpUpdateStatus(ACTIONS.MMP_GET_STATISTIC_CHARGE_BY_STATUS_FAIL, {
            error: err,
          })
        );
      }
    );
  };
};

const MmpChargeSearch = (data, limit = 100) => {
  let config = {
    method: "get",
    isAuthorization: true,
  };

  const params = queryString.stringify({
    page: data.page,
    limit: data.limit || limit,
    startTime: data.startTime,
    endTime: data.endTime,
    search: data.search,
    status: data.status,
  });

  return (dispatch) => {
    dispatch(MmpUpdateStatus(ACTIONS.MMP_SEARCH_CHARGE_PROGRESS));
    callApi(
      `/api/pomo-mmp/charges?${params}`,
      config,
      null,
      (result) => {
        dispatch(
          MmpUpdateStatus(ACTIONS.MMP_SEARCH_CHARGE_SUCCESS, {
            charges: result.charges,
            pages: result.pages,
            totalTCT: result.totalTCT,
            totalUSD: result.totalUSD,
            totalCCT: result.totalCCT,
            totalROY: result.totalROY,
          })
        );
      },
      (err) => {
        dispatch(
          MmpUpdateStatus(ACTIONS.MMP_SEARCH_CHARGE_FAIL, { error: err })
        );
      }
    );
  };
};

const MmpGetChargeRequest = (data) => {
  let config = {
    method: "get",
    isAuthorization: true,
  };

  return (dispatch) => {
    dispatch(MmpUpdateStatus(ACTIONS.MMP_GET_CHARGE_PROGRESS));
    callApi(
      `/api/pomo-mmp/charges?${queryString.stringify(data)}`,
      config,
      null,
      (result) => {
        dispatch(
          MmpUpdateStatus(ACTIONS.MMP_GET_CHARGE_SUCCESS, {
            charges: result.charges,
            pages: result.pages,
            totalTCT: result.totalTCT,
            totalUSD: result.totalUSD,
            totalCCT: result.totalCCT,
            totalROY: result.totalROY,
          })
        );
      },
      (err) => {
        dispatch(
          MmpUpdateStatus(ACTIONS.MMP_GET_CHARGE_FAIL, { error: err })
        );
      }
    );
  };
};

const MmpClearStatus = () => {
  return (dispatch) => {
    dispatch(MmpUpdateStatus(ACTIONS.MMP_CLEAR_STATUS));
  };
};

export {
  ACTIONS,
  MmpPushNotification,
  MmpChangeNote,
  MmpChangeStatus,
  MmpGetStatisticChargeByStatus,
  MmpChargeSearch,
  MmpGetChargeRequest,
  MmpClearStatus,
  MmpCurrencyBalance
};
