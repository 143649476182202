import queryString from 'querystring';
import 'isomorphic-fetch';
import { getEnv } from '../env';
import moment from 'moment';

const parseUrlQuery = (query) => {
    if (query.indexOf('?') >= 0) query = query.substr(query.indexOf('?') + 1);
    return queryString.parse(query);
};

const constructRequest = (config) => {
    if (config && config.isAuthorization)
        config = {
            ...config,
            headers: {
                ...config.headers,
                "Authorization": getToken()
            }
        };
    if (config && config.isFormData) {
        config = {
            ...config,
            body: config.body
        };
    } else {
        if ((config.method && config.method.toLowerCase() === 'post') || (config.method.toLowerCase() === 'put'))
            config = {
                ...config,
                headers: {
                    ...config.headers,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(config.body)
            };
    }
    return config;
};

const callApi = (url,
    config,
    onRequestFinish,
    onRequestSuccess,
    onRequestFailure) => {
    return fetch(getEnv('API_SERVER') + url, constructRequest(config))
        .then(response => {
            if (config.isFileDownload) {
                onRequestSuccess && onRequestSuccess(response);
            } else {
                return response.json();
            }
        })
        .then((data) => {
            onRequestFinish && onRequestFinish();
            data && data.success && !config.isFileDownload && onRequestSuccess && onRequestSuccess(data.result);
            (!data || (data && !data.success)) && onRequestFailure && onRequestFailure(data ? data.error : { code: 'UNKNOWN_ERROR' });
        })
        .catch((err) => {
            console.log(err);
            onRequestFinish && onRequestFinish();
            onRequestFailure && onRequestFailure({ code: 'NETWORK_ERROR' });
        });
};

const getToken = () => {
    return localStorage.getItem("ACCESS_TOKEN");
};
const formatDateTimeFull = dateTime => moment(dateTime).format('YYYY-MM-DD HH:mm:ss Z');

const formatTimeFull = dateTime => moment(dateTime).format('HH:mm:ss Z');

const Validation = {
    validateEmail(value) {
        if (Array.isArray(value)) return true;
        let re = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
        let result = value.match(re);
        return result && result[0] === value;
    }
};
export { callApi, Validation, parseUrlQuery, formatDateTimeFull, formatTimeFull };
