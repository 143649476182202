import { callApi } from "../05-utils/commonUtils";
import queryString from 'query-string'

const ACTIONS = {
    GET_CHARGE_PROGRESS: "GET_CHARGE_PROGRESS",
    GET_CHARGE_SUCCESS: "GET_CHARGE_SUCCESS",
    GET_CHARGE_FAIL: "GET_CHARGE_FAIL",
    REJECT_CHARGE_PROGRESS: "REJECT_CHARGE_PROGRESS",
    REJECT_CHARGE_SUCCESS: "REJECT_CHARGE_SUCCESS",
    REJECT_CHARGE_FAIL: "REJECT_CHARGE_FAIL",
    APPROVAL_CHARGE_PROGRESS: "APPROVAL_CHARGE_PROGRESS",
    APPROVAL_CHARGE_SUCCESS: "APPROVAL_CHARGE_SUCCESS",
    APPROVAL_CHARGE_FAIL: "APPROVAL_CHARGE_FAIL",
    RE_REJECT_CHARGE_PROGRESS: "RE_REJECT_CHARGE_PROGRESS",
    RE_REJECT_CHARGE_SUCCESS: "RE_REJECT_CHARGE_SUCCESS",
    RE_REJECT_CHARGE_FAIL: "RE_REJECT_CHARGE_FAIL",
    CSV_EXPORTING: "CSV_EXPORTING",
    CSV_EXPORTED: "CSV_EXPORTED",
    CSV_EXPORT_FAILED: "CSV_EXPORT_FAILED",
    SEARCH_CHARGE_PROGRESS: "SEARCH_CHARGE_PROGRESS",
    SEARCH_CHARGE_SUCCESS: "SEARCH_CHARGE_SUCCESS",
    SEARCH_CHARGE_FAIL: "SEARCH_CHARGE_FAIL",
    SEARCH_CHARGE_BY_DAY_PROGRESS: "SEARCH_CHARGE_BY_DAY_PROGRESS",
    SEARCH_CHARGE_BY_DAY_SUCCESS: "SEARCH_CHARGE_BY_DAY_SUCCESS",
    SEARCH_CHARGE_BY_DAY_FAIL: "SEARCH_CHARGE_BY_DAY_FAIL",
    UPDATE_BALANCE_ACCOUNT_PROGRESS: "UPDATE_BALANCE_ACCOUNT_PROGRESS",
    UPDATE_BALANCE_ACCOUNT_SUCCESS: "UPDATE_BALANCE_ACCOUNT_SUCCESS",
    UPDATE_BALANCE_ACCOUNT_FAIL: "UPDATE_BALANCE_ACCOUNT_FAIL",
    GET_BALANCE_ACCOUNT_PROGRESS: "GET_BALANCE_ACCOUNT_PROGRESS",
    GET_BALANCE_ACCOUNT_SUCCESS: "GET_BALANCE_ACCOUNT_SUCCESS",
    GET_BALANCE_ACCOUNT_FAIL: "GET_BALANCE_ACCOUNT_FAIL",
    SEND_TO_EZP_PROGRESS: "SEND_TO_EZP_PROGRESS",
    SEND_TO_EZP_SUCCESS: "SEND_TO_EZP_SUCCESS",
    SEND_TO_EZP_FAIL: "SEND_TO_EZP_FAIL",
    GET_LOG_PROGRESS: "GET_LOG_PROGRESS",
    GET_LOG_SUCCESS: "GET_LOG_SUCCESS",
    GET_LOG_FAIL: "GET_LOG_FAIL",
    SELECT_GROUP: "SELECT_GROUP",
    GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS: "GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS",
    GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS: "GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS",
    GET_STATISTIC_CHARGE_BY_STATUS_FAIL: "GET_STATISTIC_CHARGE_BY_STATUS_FAIL",
    HUV_GET_CHARGE_PROGRESS: "HUV_GET_CHARGE_PROGRESS",
    HUV_GET_CHARGE_SUCCESS: "HUV_GET_CHARGE_SUCCESS",
    HUV_GET_CHARGE_FAIL: "HUV_GET_CHARGE_FAIL",
    HUV_SEARCH_CHARGE_PROGRESS: "HUV_SEARCH_CHARGE_PROGRESS",
    HUV_SEARCH_CHARGE_SUCCESS: "HUV_SEARCH_CHARGE_SUCCESS",
    HUV_SEARCH_CHARGE_FAIL: "HUV_SEARCH_CHARGE_FAIL",
    CHANGE_NOTE_PROCESS: "CHANGE_NOTE_PROCESS",
    CHANGE_NOTE_SUCCESS: "CHANGE_NOTE_SUCCESS",
    CHANGE_NOTE_FAIL: "CHANGE_NOTE_FAIL",
    HUV_CHANGE_STATUS_TOP_UP_PROCESS: "HUV_CHANGE_STATUS_TOP_UP_PROCESS",
    HUV_CHANGE_STATUS_TOP_UP_SUCCESS: "HUV_CHANGE_STATUS_TOP_UP_SUCCESS",
    HUV_CHANGE_STATUS_TOP_UP_FAIL: "HUV_CHANGE_STATUS_TOP_UP_FAIL",
    PUSH_NOTIFICATION_PROCESS: "PUSH_NOTIFICATION_PROCESS",
    PUSH_NOTIFICATION_SUCCESS: "PUSH_NOTIFICATION_SUCCESS",
    PUSH_NOTIFICATION_FAIL: "PUSH_NOTIFICATION_FAIL",
    HUV_GET_CHARGE_ADMIN_AMOUNT_PROCESS: "HUV_GET_CHARGE_ADMIN_AMOUNT_PROCESS",
    HUV_GET_CHARGE_ADMIN_AMOUNT_SUCCESS: "HUV_GET_CHARGE_ADMIN_AMOUNT_SUCCESS",
    HUV_GET_CHARGE_ADMIN_AMOUNT_FAIL: "HUV_GET_CHARGE_ADMIN_AMOUNT_FAIL",
    CLEAR_STATUS:"CLEAR_STATUS"
};

const updateStatus = (status, statusInfo) => {
    return {
        type: status,
        data: statusInfo
    }
};

const getChargeRequest = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_CHARGE_PROGRESS));
        callApi('/api/get_charge_by_status',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.GET_CHARGE_SUCCESS, { charges: result.charges, pages: result.pages }));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.GET_CHARGE_FAIL, { error: err }));
            }
        );
    };
}

const huvGetChargeRequest = (data) => {
    let config = {
        method: 'get',
        isAuthorization: true,
    };

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.HUV_GET_CHARGE_PROGRESS));
        callApi(`/api/huv/charges?${queryString.stringify(data)}`,
            config,
            null,
            (result) => {
                dispatch(
                  updateStatus(ACTIONS.HUV_GET_CHARGE_SUCCESS, {
                    charges: result.charges,
                    pages: result.pages,
                    totalTCT: result.totalTCT,
                    totalUSD: result.totalUSD,
                    totalAGT: result.totalAGT,
                    totalGCCT: result.totalGCCT,
                    totalCCT: result.totalCCT,
                    totalROY: result.totalROY,
                    totalGROY: result.totalGROY,
                  })
                );
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.HUV_GET_CHARGE_FAIL, { error: err }));
            }
        );
    };
}

const getBalanceAccount = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_BALANCE_ACCOUNT_PROGRESS));
        callApi('/api/get_balance_account',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.GET_BALANCE_ACCOUNT_SUCCESS, { users: result.list, pages: result.pages }));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.GET_BALANCE_ACCOUNT_FAIL, { error: err }));
            }
        );
    };
};

const getAllLog = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_LOG_PROGRESS));
        callApi('/api/get_log',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.GET_LOG_SUCCESS, {
                    logs: result.list,
                    actions: result.actions,
                    pages: result.pages
                }));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.GET_LOG_FAIL, { error: err }));
            }
        );
    };
};

const chargeSearch = (data, limit = 100) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: {
            page: data.page,
            limit,
            startTime: data.startTime,
            endTime: data.endTime,
            query: data.query,
            status: data.status,
            businessId: data.businessId
        }
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.SEARCH_CHARGE_PROGRESS));
        callApi('/api/search_charge',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.SEARCH_CHARGE_SUCCESS, { charges: result.charges, pages: result.pages }))
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.SEARCH_CHARGE_FAIL, { error: err }));
            }
        )
    }
};

const huvChargeSearch = (data, limit = 100) => {
    let config = {
        method: "get",
        isAuthorization: true
    };

    const params = queryString.stringify({
            page: data.page,
            limit: data.limit || limit,
            startTime: data.startTime,
            endTime: data.endTime,
            search: data.search,
            status: data.status,
    })

    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.HUV_SEARCH_CHARGE_PROGRESS));
        callApi(`/api/huv/charges?${params}`,
            config,
            null,
            (result) => {
                dispatch(
                    updateStatus(ACTIONS.HUV_SEARCH_CHARGE_SUCCESS, { 
                        charges: result.charges, 
                        pages: result.pages, 
                        totalTCT: result.totalTCT, 
                        totalUSD: result.totalUSD, 
                        totalAGT: result.totalAGT, 
                        totalGCCT: result.totalGCCT, 
                        totalCCT: result.totalCCT,  
                        totalROY: result.totalROY,
                        totalGROY: result.totalGROY, 
                    })
                )
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.HUV_SEARCH_CHARGE_FAIL, { error: err }));
            }
        )
    }
};

const getChargeAdminAmount = (data) => {

    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.HUV_GET_CHARGE_ADMIN_AMOUNT_PROCESS));
        // return callApi('/api/huv/balance_current_by_system',
        return callApi('/api/balance_current_by_system',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.HUV_GET_CHARGE_ADMIN_AMOUNT_SUCCESS, result.balance ))
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.HUV_GET_CHARGE_ADMIN_AMOUNT_FAIL, { error: err }));
            }
        )
    }
}

const chargeSearchByDay = (data) => {
    let config = {
        method: "post",
        isAuthorization: true,
        body: {
            page: data.page,
            limit: 100,
            startTime: data.startTime,
            endTime: data.endTime,
            businessId: data.businessId
        }
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.SEARCH_CHARGE_BY_DAY_PROGRESS));
        callApi('/api/get_charge_by_day',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.SEARCH_CHARGE_BY_DAY_SUCCESS, {
                    chargesDay: result.chargesDay,
                    pages: result.pages,
                    statisticCharge: result.statistic
                }))
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.SEARCH_CHARGE_BY_DAY_FAIL, { error: err }));
            }
        )
    }
};
const exportExcel = () => {
    let config = {
        method: "get",
        isAuthorization: true,
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.CSV_EXPORTING));
        callApi('/api/export_statistic_charge',
            config,
            null,
            () => {
                dispatch(updateStatus(ACTIONS.CSV_EXPORTED))
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.CSV_EXPORT_FAILED, { error: err }));
            }
        )
    }
};

const sendToEzp = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.SEND_TO_EZP_PROGRESS));
        callApi('/api/send_to_ezp',
            config,
            null,
            () => {
                dispatch(updateStatus(ACTIONS.SEND_TO_EZP_SUCCESS));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.SEND_TO_EZP_FAIL, { error: err }));
            }
        );
    };
};

const rejectCharge = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.REJECT_CHARGE_PROGRESS));
        callApi('/api/change_status_charge',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.REJECT_CHARGE_SUCCESS, handleRemoveData(data.data, result.chargeUpdateFails)));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.REJECT_CHARGE_FAIL, { error: err }));
            }
        );
    };
};

const huvChangeStatus = (data, callback) => {
    let config = {
        method: 'put',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.HUV_CHANGE_STATUS_TOP_UP_PROCESS));
        return callApi('/api/huv/change_status_top_up',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.HUV_CHANGE_STATUS_TOP_UP_SUCCESS, { note: result.data }))
                callback(ACTIONS.HUV_CHANGE_STATUS_TOP_UP_SUCCESS, result)
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.HUV_CHANGE_STATUS_TOP_UP_FAIL, { error: err }));
                callback(ACTIONS.HUV_CHANGE_STATUS_TOP_UP_FAIL, err)
            }
        )
    }
}

const approvalCharge = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.APPROVAL_CHARGE_PROGRESS));
        callApi('/api/change_status_charge',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.APPROVAL_CHARGE_SUCCESS, handleRemoveData(data.data, result.chargeUpdateFails)));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.APPROVAL_CHARGE_FAIL, { error: err }));
            }
        );
    };
};
const handleReRejectCharge = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.RE_REJECT_CHARGE_PROGRESS));
        callApi('/api/change_status_charge',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.RE_REJECT_CHARGE_SUCCESS, handleRemoveData(data.data, result.chargeUpdateFails)));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.RE_REJECT_CHARGE_FAIL, { error: err }));
            }
        );
    };
};
const updateBalanceAccount = (data) => {
    let formData = new FormData();
    formData.append("balanceAccounts", data);

    let config = {
        method: 'post',
        isAuthorization: true,
        body: formData,
        isFormData: true
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.UPDATE_BALANCE_ACCOUNT_PROGRESS));
        callApi('/api/update_balance_account',
            config,
            null,
            () => {
                dispatch(updateStatus(ACTIONS.UPDATE_BALANCE_ACCOUNT_SUCCESS));
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.UPDATE_BALANCE_ACCOUNT_FAIL, { error: err }));
            }
        );
    };
};

const handleRemoveData = (data, chargeUpdateFails) => {
    let listData = data;
    if (Array.isArray(chargeUpdateFails) && chargeUpdateFails.length > 0) {
        for (let i = 0; i < chargeUpdateFails.length; i++) {
            listData = listData.filter(item => item !== chargeUpdateFails[i]);
        }
    }
    return listData;
};

const onGetStatisticChargeByStatus = () => {
    let config = {
        method: "get",
        isAuthorization: true,
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS));
        callApi('/api/huv/statistic_charge_status',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS, { statisticChargeByStatus: result.data }))
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.GET_STATISTIC_CHARGE_BY_STATUS_FAIL, { error: err }));
            }
        )
    }
}

const onChangeNote = (data, callback) => {
    let config = {
        method: 'put',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.CHANGE_NOTE_PROCESS));
        return callApi('/api/huv/note_charge',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.CHANGE_NOTE_SUCCESS, { note: result.data }))
                callback(ACTIONS.CHANGE_NOTE_SUCCESS, result)
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.CHANGE_NOTE_FAIL, { error: err }));
                callback(ACTIONS.CHANGE_NOTE_FAIL, err)
            }
        )
    }
}

const pushNotification = (data) => {
    let config = {
        method: 'post',
        isAuthorization: true,
        body: data
    };
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.PUSH_NOTIFICATION_PROCESS));
        return callApi('/api/huv/push_notification',
            config,
            null,
            (result) => {
                dispatch(updateStatus(ACTIONS.PUSH_NOTIFICATION_SUCCESS, { note: result.data }))
            },
            (err) => {
                dispatch(updateStatus(ACTIONS.PUSH_NOTIFICATION_FAIL, { error: err }));
            }
        )
    }
}

const clearStatus = () => {
    return (dispatch) => {
        dispatch(updateStatus(ACTIONS.CLEAR_STATUS));
    }
}

export {
    ACTIONS,
    onChangeNote,
    huvChangeStatus,
    huvGetChargeRequest,
    huvChargeSearch,
    onGetStatisticChargeByStatus,
    getChargeRequest,
    rejectCharge,
    approvalCharge,
    handleReRejectCharge,
    chargeSearch,
    exportExcel,
    chargeSearchByDay,
    updateBalanceAccount,
    getBalanceAccount,
    sendToEzp,
    getAllLog,
    pushNotification,
    getChargeAdminAmount,
    clearStatus
};
