import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {LocalizeProvider} from 'react-localize-redux';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import './05-utils/commonStyles.css';

import App from './04-containers/App/App';
import { unregister } from './registerServiceWorker';
import configStore from './store';

let store = configStore();

ReactDOM.render(
	<LocalizeProvider>
		<I18nextProvider i18n={ i18n }>
			<Provider store={store}>
				<BrowserRouter>
					<App/>
				</BrowserRouter>
			</Provider>
		</I18nextProvider>
	</LocalizeProvider>,
	document.getElementById('root')
);

unregister();

