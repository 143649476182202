import React, { Component } from "react";
import Confirm from "../../../../03-components/Confirm";
import {
    Button,
    Input,
    Label,
    FormGroup
} from "reactstrap";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {ACTIONS, settingGa} from "../../../../01-actions/member";

class SetupTFA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            showPassword: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.status === ACTIONS.SETTING_GA_SUCCESS) {
            this.setState({password: ''});
        }
    }

    onClose = () => {
        const {close} = this.props;
        this.setState({
            password: ''
        });
        close()
    }

    onChangeValue = (password) => {
        return (e) => {
            if (e.target.value.length === 0) return;
            this.setState({[password]: e.target.value.trim()});
        }
    }

    onSubmit = () => {
        if (!this.state.password) return
        this.props.settingGa({password: this.state.password});
    }

    showPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    render() {
        const {t, isOpenSetup} = this.props
        let { password } = this.state;
        return (
            <div>
                <Confirm
                    isOpen={isOpenSetup}
                    destroyOnClose
                    maskClosable={false}
                    className="tfa-setting"
                    title={t('TFASetting.TfaSetting')}
                >
                    <FormGroup
                        label={t('AdministratorSetting.Table.Password')}
                        name="password"
                    >
                        <Label>
                            <p className="text-required">*</p>
                            {t('AdministratorSetting.Table.Password')}
                        </Label>
                        <div className="ga-secret">
                            <Input className="input-style"
                                value={password}
                                onChange={this.onChangeValue('password')}
                                type={this.state.showPassword ? "text" : "password"}
                            />
                            <div className="input-group-append">
                                <span onClick={this.showPassword}>
                                    <i className="far fa-eye-slash"></i>
                                </span>
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup className='mb-0 text-right'>
                        <Button className='mr-3 btn-cancel-popup'
                                color='danger'
                                onClick={this.onClose}
                                disabled={this.props.status === ACTIONS.SETTING_GA_PROGRESS}
                        >
                            {t("TFASetting.Cancel")}
                        </Button>
                        <Button
                            className={this.props.status === ACTIONS.SETTING_GA_PROGRESS ? "progress-button btn-submit-popup" : "btn-submit-popup"}
                            type='primary'
                            disabled={!password || this.props.status === ACTIONS.SETTING_GA_PROGRESS}
                            onClick={this.onSubmit}
                        >
                            {t("TFASetting.Submit")}
                        </Button>
                    </FormGroup>
                </Confirm>
            </div>
        )
    }
}

const mapStateToProps = store => {
    return {
        status: store.member.status,
        isSettingGa: store.member.isSettingGa
    };
};

const mapDispatchToProps = dispatch => {
    return {
        settingGa: (data) => {
            dispatch(settingGa(data));
        },
    };
};

export default withNamespaces() (
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SetupTFA)
);