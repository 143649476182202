import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import ForgotPasswordPage from "./ForgotPasswordPage";
import LoginPage from "./Login";

import './style.css';

export default class AuthPage extends Component {
	render() {
		return (
			<div className="pages-auth">
				<Switch>
					<Route exact path="/auth">
						<Redirect to="auth/login"/>
					</Route>
					<Route path="/auth/login" component={LoginPage}/>
					<Route path="/auth/forgot-password" component={ForgotPasswordPage}/>
					<Redirect to="/error/404"/>
				</Switch>
			</div>
		);
	}
}