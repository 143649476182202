import React, { Component } from "react";
import Confirm from "../../../../03-components/Confirm";
import {
    Button,
    Input,
    Label,
    FormGroup
} from "reactstrap";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {ACTIONS, resetGa} from "../../../../01-actions/member";

class ResetTFA extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            showPassword: false
        }
    }

    onClose = () => {
        const {close} = this.props
        this.setState({
            password: ''
        })
        close()
    }

    handleValueChange = (password) => {
        return (e) => {
            this.setState({[password]: e.target.value.trim()})
        }
    }

    onSubmit = () => {
        if (!this.state.password) return;
        this.props.resetGa({password: this.state.password});
    }

    showPassword = () => {
        this.setState({
            showPassword: !this.state.showPassword
        })
    }

    render() {
        const {t, isOpenReset} = this.props
        return (
            <>
                <Confirm
                    isOpen={isOpenReset}
                    destroyOnClose
                    maskClosable={false}
                    className="reset-tfa"
                    title={t('TFASetting.ResetTfa')}
                >
                    <p className='content-reset-tfa text-center'>
                        {t('TFASetting.ContentResetTfa')}
                    </p>
                    <FormGroup
                        label={t('AdministratorSetting.Table.Password')}
                        name="password"
                    >
                        <Label>
                            <p className="text-required">*</p>
                            {t('AdministratorSetting.Table.Password')}
                        </Label>
                        <div className="ga-secret">
                            <Input className="input-style"
                                   value={this.state.password}
                                   onChange={this.handleValueChange('password')}
                                   type={this.state.showPassword ? "text" : "password"}
                            />
                            <div className="input-group-append">
                                <span onClick={this.showPassword}>
                                    <i className="far fa-eye-slash"></i>
                                </span>
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup className='mb-0 text-right'>
                        <Button className="mr-3 btn-cancel-popup"
                                color='danger'
                                onClick={this.onClose}
                                disabled={this.props.status === ACTIONS.RESET_GA_PROGRESS}
                        >
                            {t("TFASetting.Cancel")}
                        </Button>
                        <Button
                            className={this.props.status === ACTIONS.RESET_GA_PROGRESS ? "progress-button btn-submit-popup" : "btn-submit-popup"}
                            type='primary'
                            disabled={!this.state.password || this.props.status === ACTIONS.RESET_GA_PROGRESS}
                            onClick={this.onSubmit}
                        >
                            {t("TFASetting.Submit")}
                        </Button>
                    </FormGroup>
                </Confirm>
            </>
        )
    }
}

const mapStateToProps = store => {
    return {
        status: store.member.status,
        isSettingGa: store.member.isSettingGa
    };
};

const mapDispatchToProps = dispatch => {
    return {
        resetGa: (data) => {
            dispatch(resetGa(data));
        },
    };
};

export default withNamespaces()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ResetTFA)
);