import React, { Component } from "react";
import {
  Button,
  Table,
  Input,
  Label,
  Row,
  Col
} from "reactstrap";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import {
  getBalanceAccount,
  sendToEzp,
  updateBalanceAccount
} from "../../../../01-actions/charge";
import "./style.css";
import ReactPaginate from "react-paginate";
import { ACTIONS } from "../../../../01-actions/charge";
import { CircularProgress } from "@material-ui/core";
import Alert from "../../../../03-components/Alert";

import "react-datepicker/dist/react-datepicker.css";
// import CSVReader from "react-csv-reader";

class ReBalance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 100,
      activePage: 1,
      charges: props.charges,
      tickCharges: [],
      dataUpdate: [],
      startTime: null,
      endTime: null,
      filterBy: "",
      businessId: null,
      keyWord: "",
      checkDate: false,
      isShowMessage: false,
      isReject: false,
      groupId: props.location.search.split("=")[
        props.location.search.split("=").length - 1
      ],
      textPlaceholder: "ReBalancePage.ChooseFileExcel"
    };
  }

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  componentDidMount() {
    this.getUser();
  }

  getUser() {
    const { groupId } = this.state;
    this.props.handleGetBalanceAccount({
      limit: this.state.limit,
      page: 1,
      businessId: groupId ? groupId : null
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.users !== this.state.users) {
      this.setState({ users: nextProps.users });
    }
  }

  handlePageClick = data => {
    const page = data.selected + 1;
    this.setState({ activePage: page }, () => {
      this.props.handleGetBalanceAccount({
        limit: this.state.limit,
        page: this.state.activePage
      });
    });
  };

  handleSelectGroupChange = value => {
    if (value !== "00") {
      this.props.history.push({
        pathname: "/re-balance",
        search: `?group=${value}`
      });
      this.setState({
        businessId: value,
        groupId: value
      });
      this.props.handleGetBalanceAccount({
        limit: this.state.limit,
        page: this.state.activePage,
        businessId: value
      });
    } else {
      this.props.history.push(`/re-balance`);
      this.setState({
        businessId: null,
        groupId: null
      });
      this.props.handleGetBalanceAccount({
        limit: this.state.limit,
        page: this.state.activePage
      });
    }
  };

  renderHeaderTable() {
    const { t } = this.props;
    return (
      <tr className="tb-header">
        <th className="custom-item">{t("ReBalancePage.Table.No")}</th>
        <th>{t("ReBalancePage.Table.PassportNumber")}</th>
        <th>{t("ReBalancePage.Table.BusinessMemberID")}</th>
        <th>{t("ReBalancePage.Table.BalanceAmount")}</th>
        <th>{t("ReBalancePage.Table.UpdatedBalance")}</th>
      </tr>
    );
  }

  renContentTable() {
    const { t } = this.props;
    const { users } = this.state;
    if (Array.isArray(users) && users.length > 0) {
      return (
        <tbody>
          {users.map((item, index) => {
            return (
              <tr key={index} className="tb-body">
                <td>{index + 1}</td>
                <td>{item.passportNumber}</td>
                <td>{item.businessMemberId}</td>
                <td>{item.balance}</td>
                <td className="text-center">
                  {item.updatedBalance ? (
                    <i className="far fa-check-circle text-success" />
                  ) : (
                    <i className="far fa-times-circle text-danger" />
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={11}>
              <div className="d-flex justify-content-center">
                <span>{t("NoDataToShow")}</span>
              </div>
            </td>
          </tr>
        </tbody>
      );
    }
  }

  handleImportCsv = async data => {
    let dataUpdate = [];
    for (let [index, account] of data.entries()) {
      if (index === 0) continue;
      if (account[0] && account[1] && account[2])
        dataUpdate.push({
          passportNumber: account[0],
          robinId: account[1],
          balance: account[2]
        });
    }
    this.setState({ dataUpdate });
  };

  renderTable() {
    return (
      <Table bordered responsive>
        <thead className="thead-light">{this.renderHeaderTable()}</thead>
        {this.renContentTable()}
      </Table>
    );
  }

  onChangeHandler = event => {
    let dataUpdate = event.target.files[0];
    this.setState({ dataUpdate });
    let textPlaceholder = this.state.textPlaceholder.toString();
    if (dataUpdate !== "") {
      dataUpdate && this.setState({ textPlaceholder: dataUpdate.name });
    } else {
      this.setState({ textPlaceholder });
    }
  };

  render() {
    const { t } = this.props;
    let { dataUpdate, users, groupId, textPlaceholder } = this.state;
    const usersUpdated =
      (users && users.length && users.filter(user => user.updatedBalance)) ||
      [];
    return (
      <div className="re-balance-list">
        <div className="title">
          <div className="title-content">
            {t("ReBalancePage.ChargeApplicationList")}
          </div>
        </div>
        <div className="re-balance-list-content">
          <Row className="mt-3">
            <Col lg="2" md="3" sm="12" xs="12" className="mt-1">
              <Input
                className="selectGroup"
                type="select"
                name="selectGroup"
                id="selectGroup"
                // defaultValue={groupId}
                value={groupId || ""}
                onChange={event => {
                  this.handleSelectGroupChange(event.target.value);
                }}
              >
                <option value="00">{t("ReBalancePage.GroupSelection")}</option>
                <option value="01">Greenbox</option>
                <option value="02">Triple</option>
                <option value="03">Huv Style</option>
              </Input>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg="2" md="3" sm="12" xs="12" className="mt-1">
              <Input
                type="file"
                id="file"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                onChange={this.onChangeHandler}
                className="form-control input-choose-file"
                placeholder={t(textPlaceholder)}
              />
              <Label for="file" className="btn-choose-file">
                <span>{t(textPlaceholder)}</span>
              </Label>
            </Col>
            <Col lg="4" md="4" sm="12" xs="12" className="mt-1">
              <Button
                outline
                color="secondary"
                className="custom-btn mr-4"
                disabled={
                  this.props.status ===
                    ACTIONS.UPDATE_BALANCE_ACCOUNT_PROGRESS ||
                  (dataUpdate && dataUpdate.length === 0)
                }
                onClick={() => {
                  this.alertAck();
                  this.props.handleUpdateBalanceAccount(dataUpdate);
                  this.setState({
                    businessId: null,
                    groupId: ""
                  });
                }}
              >
                {this.props.status ===
                  ACTIONS.UPDATE_BALANCE_ACCOUNT_PROGRESS && (
                  <CircularProgress className="mr-2" size={20} />
                )}
                {t("ReBalancePage.ImportXLSX")}
              </Button>
            </Col>
          </Row>

          {this.renderTable()}
        </div>
        {[
          ACTIONS.UPDATE_BALANCE_ACCOUNT_FAIL,
          ACTIONS.SEND_TO_EZP_FAIL
        ].indexOf(this.props.status) >= 0 &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Error")}
              content={
                this.props.error
                  ? this.props.error.code
                  : t("ReBalancePage.AlertExcelExists")
              }
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.setState({ alertAck: true });
              }}
            />
          )}
        {[ACTIONS.SEND_TO_EZP_SUCCESS].indexOf(this.props.status) >= 0 &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Success")}
              content={t("ReBalancePage.AlertSendEZPSuccess")}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.setState({ alertAck: true });
                this.getUser();
              }}
            />
          )}
        {[ACTIONS.UPDATE_BALANCE_ACCOUNT_SUCCESS].indexOf(this.props.status) >=
          0 &&
          !this.state.alertAck && (
            <Alert
              title={t("Alert.Success")}
              content={t("ReBalancePage.AlertUpdateBalanceSuccess")}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.setState({ alertAck: true });
                this.getUser();
                this.props.history.push(`/re-balance`);
              }}
            />
          )}
        {this.state.isShowMessage && (
          <Alert
            title={t("Alert.ConfirmMessage")}
            content={t("ReBalancePage.AlertApproveRequest")}
            okTitle={t("Alert.OK")}
            cancelTitle={t("Alert.Cancel")}
            onOK={() => {
              this.setState({ isShowMessage: false });
              this.alertAck();
              //    this.props.sendToEzp({data: {}});
              this.props.sendToEzp({ businessId: this.state.businessId });
            }}
            onCancel={() => {
              this.setState({ isShowMessage: false });
            }}
          />
        )}
        {Array.isArray(users) && users.length > 0 && (
          <div className="d-flex justify-content-between align-content-center align-items-center re-balance-list-content">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={<a href="">...</a>}
              breakClassName={"break-me"}
              pageCount={this.props.pages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
            <div className="">
              <Button
                variant="contained"
                disabled={
                  this.props.status === ACTIONS.SEND_TO_EZP_PROGRESS ||
                  !users.length
                }
                className="submit-btn"
                onClick={() => {
                  this.setState({ isShowMessage: true });
                }}
              >
                {this.props.status === ACTIONS.SEND_TO_EZP_PROGRESS && (
                  <CircularProgress className="mr-2" size={20} />
                )}
                <span>
                  {t("ReBalancePage.ButtonApproveRequest")} (
                  {usersUpdated.length} {t("ReBalancePage.Records")})
                </span>
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    status: state.charge.status,
    error: state.charge.error,
    users: state.charge.users,
    pages: state.charge.pages
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleGetBalanceAccount: data => {
      dispatch(getBalanceAccount(data));
    },
    sendToEzp: data => {
      dispatch(sendToEzp(data));
    },
    handleUpdateBalanceAccount: data => {
      dispatch(updateBalanceAccount(data));
    }
  };
};

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReBalance)
);
