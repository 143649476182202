import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  ACTIONS,
  PomoClearStatus,
  PomoGetChargeRequest,
  PomoChargeSearch,
  PomoGetStatisticChargeByStatus,
  PomoChangeStatus,
  PomoChangeNote,
  PomoPushNotification,
  PomoCurrencyBalance,
} from "../../../../../01-actions/pomo";
import { getChargeAdminAmount } from "../../../../../01-actions/charge";
import {
  ACTIONS as ACTION_USER,
  getInfoUser,
} from "../../../../../01-actions/member";
import {
  PERMISSION,
  POM_UPDATE_STATUS_TOP_UP,
} from "../../../../../05-utils/commonData";
import { useModal } from "../../../../../05-utils/hook/use-modal";
import Alert from "./Alert";
import AlertComponent from "../../../../../03-components/Alert";
import Search from "./Search";
import "./style.css";
import ChargeRequestListTabled from "./Table";
import moment from "moment";
import _ from "lodash";
import LoadingSpinner from "../../../../../03-components/LoadingSpinner/LoadingSpinner";

const PomoChargeRequestList = ({
  charges,
  handleGetChargeRequest,
  chargeSearch,
  status,
  pages,
  t,
  statisticOfChargesByStatus,
  currencyBalance,
  statisticChargeByStatus,
  pomoChangeStatus,
  onChangeNote,
  pushNotification,
  totalUSD,
  totalTCT,
  adminBalance,
  getAdminAmount,
  pomoStatus,
  clearSts,
  getInfoUser,
  userInfo,
  statusGetUserInfo,
  error,
  totalCCT,
  totalROY,
  pomoCurrencyBalance,
}) => {
  const [init, setInit] = useState(false);
  const [selected, setSelected] = useState([]);
  const [alertAck, closeAlert, openAlert] = useModal(false);
  const [itemEditMemo, closeEditMemo, openEditMemo] = useModal(null);
  const [isConfirm, closeConfirm, openConfirm] = useModal(false);
  const [isRejection, closeRejection, openRejection] = useModal(null);
  const [params, setParams] = useState({
    limit: 1000,
    page: 1,
    startTime: null,
    endTime: null,
    filterBy: "",
    keyWord: "",
    tab: "",
  });

  const [statusConfirm, setStatusConfirm] = useState(null);

  const [isPushNotificationSuccess, setIsPushNotificationSuccess] =
    useState(false);
  const [statusTopUp, setStatusTopUp] = useState(false);
  const [rejectingSuccess, setRejectingSuccess] = useState(false);
  const [alertPermission, setAlertPermision] = useState(false);
  const [balanceNotEnough, setBalanceNotEnough] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rejectingFailed, setRejectingFailed] = useState(false);

  useEffect(() => {
    if (
      pomoStatus === "POMO_GET_CHARGE_FAIL" ||
      pomoStatus === "POMO_SEARCH_CHARGE_FAIL"
    ) {
      setAlertPermision(false);
    }
  }, [pomoStatus]);

  useEffect(() => {
    if (params && params?.startTime > params?.endTime) {
      setParams({ ...params, endTime: null });
    }
  }, [params?.startTime, params?.endTime]);

  const handleConfirm = async (value) => {
    try {
      let payload = {};
      setIsLoading(true);
      switch (statusConfirm) {
        case POM_UPDATE_STATUS_TOP_UP.CHECKED:
        case POM_UPDATE_STATUS_TOP_UP.RECHECKED:
          payload = {
            rejectIds: [],
            approveIds: selected,
            status: params.tab,
            systemId: 13,
            note: value?.note,
          };
          break;
        case POM_UPDATE_STATUS_TOP_UP.REJECTED:
          payload = {
            approveIds: [],
            rejectIds: selected,
            status: params.tab,
            systemId: 13,
            note: value?.note,
          };
          break;
      }
      pomoChangeStatus(payload, (action, result) => {
        console.log("result", result)
        if (action === ACTIONS.POMO_CHANGE_STATUS_TOP_UP_SUCCESS) {
          setIsLoading(false);
          setSelected([]);
          closeConfirm();
          handleSearch();
          if (result?.rejectSuccess.length < 0 ){
            setRejectingFailed(true)
          }else{
            statusConfirm === POM_UPDATE_STATUS_TOP_UP.REJECTED
            ? setRejectingSuccess(true)
            : setStatusTopUp(true);
          }
          const timeout = setTimeout(() => {
            handleAdminAmount();
            statisticOfChargesByStatus();
            currencyBalance();
            clearTimeout(timeout);
          }, 2000);
        }
        if (action === ACTIONS.POMO_CHANGE_STATUS_TOP_UP_FAIL) {
          setIsLoading(false);
          setSelected([]);
          closeConfirm();
          statusConfirm === POM_UPDATE_STATUS_TOP_UP.REJECTED
          ?  setRejectingFailed(true)
          :  setBalanceNotEnough(true);
         
        }
      });
    } catch (error) {}
  };
  const handlePushNotification = (values) => {
    pushNotification({
      title: values.title,
      content: values.content,
    });
    closeConfirm();
    setIsPushNotificationSuccess(true);
  };
  const handleConfirmEditMemo = async (values) => {
    try {
      setIsLoading(true);
      onChangeNote(
        {
          note: values?.note,
          id: itemEditMemo?._id,
        },
        (action) => {
          if (action === ACTIONS.POMO_CHANGE_NOTE_SUCCESS) {
            setIsLoading(false);
            closeEditMemo();
            handleSearch();
          }
        }
      );
    } catch (error) {}
  };

  const handleSubmitNote = async (value) => {
    try {
      let payload = {};
      setIsLoading(true);
      payload = {
        approveIds: [],
        rejectIds: selected,
        status: params.tab,
        note: value?.note,
      };

      pomoChangeStatus(payload, (action, result) => {
        if (action === ACTIONS.POMO_CHANGE_STATUS_TOP_UP_SUCCESS) {
          setIsLoading(false);
          setSelected([]);
          closeRejection();
          handleSearch();
          if (result?.rejectSuccess.length <= 0 ){
            setRejectingFailed(true)
          }else{
            setRejectingSuccess(true)
          }
        }
        if (action === ACTIONS.POMO_CHANGE_STATUS_TOP_UP_FAIL) {
          setIsLoading(false);
          setSelected([]);
          closeRejection();
          setRejectingFailed(true)
        }
      });
    } catch (error) {}
  };

  const handleGetList = (payload) => {
    openAlert();

    handleGetChargeRequest(
      payload || {
        limit: params.limit,
        page: params.page,
        status: params.tab,
        search: params.keyWord,
        startTime: params.startTime,
        endTime: params.endTime,
      }
    );
    setInit(true);
  };

  const handleSearch = () => {
    let startDate = params.startTime ? params.startTime : "";
    let endDate = params.endTime ? moment(params.endTime).endOf("date") : "";
    if (status === ACTIONS.POMO_SEARCH_CHARGE_PROGRESS || !init) {
      return;
    }
    openAlert();
    chargeSearch({
      startTime: startDate,
      endTime: endDate,
      search: params.keyWord,
      page: params.page,
      limit: params.limit,
      status: params.tab,
    });
  };

  const checkPermission = () => {
    const permissionArr = userInfo && userInfo.roleId?.permission;

    const routes = _.chain(permissionArr)
      .reduce((rs, item) => {
        if (_.startsWith(item, "pomo-") && item !== "pomo-setting") {
          rs = _.concat(rs, item);
        }
        return rs;
      }, [])
      .value();

    if (routes.includes(PERMISSION.POMO_NOT_YET_VERIFIED)) {
      return PERMISSION.POMO_NOT_YET_VERIFIED;
    } else if (routes.includes(PERMISSION.POMO_ADMIN_REJECTED)) {
      return PERMISSION.POMO_ADMIN_REJECTED;
    } else if (routes.includes(PERMISSION.POMO_CHECKING)) {
      return PERMISSION.POMO_CHECKING;
    } else if (routes.includes(PERMISSION.POMO_APPROVED)) {
      return PERMISSION.POMO_APPROVED;
    } else if (routes.includes(PERMISSION.POMO_REJECTING)) {
      return PERMISSION.POMO_REJECTING;
    } else if (routes.includes(PERMISSION.POMO_REJECTED)) {
      return PERMISSION.POMO_REJECTED;
    }
    return;
  };

  useEffect(() => {
    getInfoUser();
    statisticOfChargesByStatus();
    currencyBalance();
    handleAdminAmount();
  }, []);

  useEffect(() => {
    if (statusGetUserInfo === ACTION_USER.GET_INFO_USER_SUCCESS) {
      const startedRoute = checkPermission()
        ? checkPermission().replace("pomo-", "")
        : "";

      if (startedRoute) {
        setParams({
          limit: 1000,
          page: 1,
          startTime: null,
          endTime: null,
          filterBy: "",
          keyWord: "",
          tab: startedRoute,
        });
        handleGetList({
          limit: 1000,
          page: 1,
          startTime: null,
          endTime: null,
          filterBy: "",
          keyWord: "",
          status: startedRoute,
        });
      }
    }
  }, [statusGetUserInfo]);

  useEffect(() => {
    handleSearch();
  }, [
    params?.startTime,
    params?.endTime,
    params?.tab,
    params?.page,
    params?.limit,
  ]);

  const handleAdminAmount = () => {
    getAdminAmount({
      systemId: 13,
    });
  };

  const handleChange = (e) => {
    setParams({ ...params, [e.target.name]: e.target.value });
  };

  const handleClearStatus = () => {
    clearSts();
  };

  const messageError = (error) => {
    switch (error) {
      case "Source wallet balance is not enough token!":
        return t("not_enough_token");
      case "System error":
        return t("not_enough_token");
      case "REFUND_FAILED":
        return t("REFUND_FAILED");
      case "MISSING_REQUIRED_VALUE":
        return t("Code.MISSING_REQUIRED_VALUE");
      case "CHANGE_STATUS_TOPUP_FAIL":
        return t("Code.CHANGE_STATUS_TOPUP_FAIL");
      default:
        return t("DepositToSystem.MaxBalances");
    }
  };

  return (
    <div className="huv-styled-charge-request-list">
      {isLoading && <LoadingSpinner />}
      <div className="title">
        <div className="title-content">
          {t("TopUpPage.ChargeApplicationList")}
        </div>
      </div>
      <div className="charge-request-list-content">
        {params && (
          <Search
            setStatusConfirm={setStatusConfirm}
            statisticChargeByStatus={statisticChargeByStatus}
            pomoCurrencyBalance={pomoCurrencyBalance}
            params={params}
            handleChange={handleChange}
            handleSearch={handleSearch}
            pages={pages}
            charges={charges}
            setParams={setParams}
            openConfirm={openConfirm}
            selected={selected}
            setSelected={setSelected}
            totalUSD={totalUSD}
            totalTCT={totalTCT}
            adminBalance={adminBalance}
            openRejection={openRejection}
            userInfo={userInfo}
            totalCCT={totalCCT}
            totalROY={totalROY}
          />
        )}
        <ChargeRequestListTabled
          charges={charges}
          selected={selected}
          setSelected={setSelected}
          tab={params?.tab}
          openEditMemo={openEditMemo}
        />
        <Alert
          selected={selected}
          charges={charges}
          isConfirm={isConfirm}
          closeConfirm={() => {
            setStatusConfirm(null);
            closeConfirm();
          }}
          handleConfirm={handleConfirm}
          tab={params?.tab}
          alertAck={alertAck}
          closeAlert={closeAlert}
          itemEditMemo={itemEditMemo}
          closeEditMemo={closeEditMemo}
          handleConfirmEditMemo={handleConfirmEditMemo}
          handlePushNotification={handlePushNotification}
          isRejection={isRejection}
          closeRejection={() => {
            setStatusConfirm(null);
            closeRejection();
          }}
          handleSubmitNote={handleSubmitNote}
          isLoading={isLoading}
        />
        {isPushNotificationSuccess && (
          <AlertComponent
            title={t("Alert.Success")}
            content={t("pushNotificationSuccess")}
            okTitle={t("Alert.OK")}
            onOK={() => setIsPushNotificationSuccess(false)}
          />
        )}
        {statusTopUp && (
          <AlertComponent
            title={t("Alert.Success")}
            content={t("changeStatusTopUpSuccess")}
            okTitle={t("Alert.OK")}
            onOK={() => setStatusTopUp(false)}
          />
        )}
        {rejectingSuccess && (
          <AlertComponent
            title={t("Alert.Success")}
            content={t("rejectingSuccess")}
            okTitle={t("Alert.OK")}
            onOK={() => setRejectingSuccess(false)}
          />
        )}
         {rejectingFailed && (
          <AlertComponent
            title={t("Alert.failed")}
            content={t("rejectingFail")}
            okTitle={t("Alert.OK")}
            onOK={() => setRejectingFailed(false)}
          />
        )}
        {balanceNotEnough && (
          <AlertComponent
            title={t("Alert.Error")}
            content={messageError(error?.code)}
            okTitle={t("Alert.OK")}
            onOK={() => setBalanceNotEnough(false)}
          />
        )}
        {[
          ACTIONS.POMO_GET_CHARGE_FAIL,
          ACTIONS.POMO_SEARCH_CHARGE_FAIL,
        ].indexOf(status) >= 0 &&
          !alertPermission && (
            <AlertComponent
              title={t("Alert.Error")}
              content={t("Code.PERMISSION_DENIED")}
              okTitle={t("Alert.OK")}
              onOK={() => {
                handleClearStatus();
                setAlertPermision(true);
              }}
            />
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    status: state.pomo.pomoStatus,
    error: state.pomo.error,
    charges: state.pomo.pomoCharges,
    pages: state.pomo.pages,
    statisticChargeByStatus: state.pomo.pomoStatisticChargeByStatus,
    totalTCT: state.pomo.totalTCT,
    totalUSD: state.pomo.totalUSD,
    pomoStatus: state.pomo.pomoStatus,
    adminBalance: state.charge.adminBalance,
    userInfo: state.member.infoUser,
    statusGetUserInfo: state.member.status,
    totalCCT: state.pomo.totalCCT,
    totalROY: state.pomo.totalROY,
    pomoCurrencyBalance: state.pomo.pomoCurrencyBalance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleGetChargeRequest: (data) => {
      dispatch(PomoGetChargeRequest(data));
    },
    chargeSearch: (data) => {
      dispatch(PomoChargeSearch(data));
    },
    statisticOfChargesByStatus: () => {
      dispatch(PomoGetStatisticChargeByStatus());
    },
    pomoChangeStatus: (data, callback) => {
      dispatch(PomoChangeStatus(data, callback));
    },
    onChangeNote: (data, callback) => {
      dispatch(PomoChangeNote(data, callback));
    },
    pushNotification: (data) => {
      dispatch(PomoPushNotification(data));
    },
    getAdminAmount: (data) => {
      dispatch(getChargeAdminAmount(data));
    },
    clearSts: () => {
      dispatch(PomoClearStatus());
    },
    getInfoUser: () => {
      dispatch(getInfoUser());
    },
    currencyBalance: () => {
      dispatch(PomoCurrencyBalance());
    },
  };
};

export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(PomoChargeRequestList)
);
