import React, { Component } from 'react'
import Confirm from '../../../../../03-components/Confirm'
import {
  Button,
  Input,
  FormGroup
} from "reactstrap";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import ggAuthentication from "../../../../../06-assets/images/ga-guide.png";
import {
  ACTIONS,
  huvTransferSystem
} from "../../../../../01-actions/deposit";

class ModalPopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      code: ''
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status === ACTIONS.GET_TRANSFER_SUCCESS) {
      this.setState({
        code: ''
      })
    }

  }

  onchangeTfaCode = (filed) => {
    return (e) => {
      this.setState({
        [filed]: e.target.value
      })
    }
  }

  onClose = () => {
    const { close } = this.props
    this.setState({
      code: ''
    })
    close()
  }

  onSubmit = () => {

    const { isTransferAmount, inputAmount, systemId } = this.props
    const { code } = this.state
    if (!code) return
    if (isTransferAmount) {
      this.props.insertTransferAmount({
        systemId: Number(systemId),
        point: Number(inputAmount),
        code: this.state.code
      })

    }

  }

  // this.props

  render() {
    const {t} = this.props
    return (
      <div>
        <Confirm
          isOpen={this.props.isOpen}
          toggle={this.onClose}
          destroyOnClose
          centered
          maskClosable={false}
          title={t("Two-step_verification")}
          className='modal-confirm-tfa-login'
        >
          <FormGroup>
            <div className='text-center'>
              <img
                className='w-100'
                src={ggAuthentication}
                alt=""
              />
            </div>
          </FormGroup>
          <FormGroup
            className='text-center'
            name='code'
          >
            <div>
              <Input
                type="text"
                className='text-center tfa-code'
                value={this.state.code}
                // disabled={submitting}
                placeholder={t("Two-step_verification")}
                onChange={this.onchangeTfaCode('code')}
              />
            </div>
          </FormGroup>
          <FormGroup className='mb-0 text-center box-action'>
            <Button
              className='btn-tfa-login'
              type='primary'
              onClick={this.onSubmit}
              disabled={!this.state.code}
            >
            {t("TFASetting.Submit")}
            </Button>
          </FormGroup>
        </Confirm>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.deposit.status,
    error: state.deposit.error,

  };
};

const mapDispatchToProps = dispatch => {
  return {
    insertTransferAmount: (data) => {
      dispatch(huvTransferSystem(data))
    },
  }
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ModalPopup)
)
