import { callApi } from "../05-utils/commonUtils";
import queryString from "query-string";

const ACTIONS = {
  GET_DEPOSIT_TABLE_PROGRESS: "GET_DEPOSIT_TABLE_PROGRESS",
  GET_DEPOSIT_TABLE_SUCCESS: "GET_DEPOSIT_TABLE_SUCCESS",
  GET_DEPOSIT_TABLE_FAIL: "GET_DEPOSIT_TABLE_FAIL",
  GET_TRANSFER_PROGRESS: "GET_TRANSFER_PROGRESS",
  GET_TRANSFER_SUCCESS: "GET_TRANSFER_SUCCESS",
  GET_TRANSFER_FAIL: "GET_TRANSFER_FAIL",
  GET_SYSTEM_TOPUP_PROGRESS: "GET_SYSTEM_TOPUP_PROGRESS",
  GET_SYSTEM_TOPUP_SUCCESS: "GET_SYSTEM_TOPUP_SUCCESS",
  GET_SYSTEM_TOPUP_FAIL: "GET_SYSTEM_TOPUP_FAIL",
  GET_ALL_DEPOSIT_DATA_PROGRESS: "GET_ALL_DEPOSIT_DATA_PROGRESS",
  GET_ALL_DEPOSIT_DATA_SUCCESS: "GET_ALL_DEPOSIT_DATA_SUCCESS",
  GET_ALL_DEPOSIT_DATA_FAILD: "GET_ALL_DEPOSIT_DATA_FAILD",
};

const updateStatus = (status, statusInfo) => {
  return {
    type: status,
    data: statusInfo,
  };
};

const huvDepositGetData = (data) => {
  let config = {
    method: "get",
    isAuthorization: true,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.GET_DEPOSIT_TABLE_PROGRESS));
    // callApi(`/api/huv/transfers?${queryString.stringify(data)}`,
    callApi(
      `/api/transfers?${queryString.stringify(data)}`,
      config,
      null,
      (result) => {
        dispatch(updateStatus(ACTIONS.GET_DEPOSIT_TABLE_SUCCESS, result.data));
        return result;
      },
      (err) => {
        dispatch(updateStatus(ACTIONS.GET_DEPOSIT_TABLE_FAIL, { error: err }));
      }
    );
  };
};

const huvGetAllDepositData = (data) => {
  let config = {
    method: "get",
    isAuthorization: true,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.GET_ALL_DEPOSIT_DATA_PROGRESS));
    // callApi(`/api/huv/transfers?${queryString.stringify(data)}`,
    callApi(
      `/api/transfers?${queryString.stringify(data)}`,
      config,
      null,
      (result) => {
        dispatch(
          updateStatus(ACTIONS.GET_ALL_DEPOSIT_DATA_SUCCESS, result.data)
        );
        return result;
      },
      (err) => {
        dispatch(
          updateStatus(ACTIONS.GET_ALL_DEPOSIT_DATA_FAILD, { error: err })
        );
      }
    );
  };
};

const huvTransferSystem = (data) => {
  let config = {
    method: "post",
    isAuthorization: true,
    body: data,
  };
  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.GET_TRANSFER_PROGRESS));
    // callApi('/api/huv/transfer_system',
    callApi(
      "/api/transfer_system",
      config,
      null,
      (result) => {
        dispatch(updateStatus(ACTIONS.GET_TRANSFER_SUCCESS));
      },
      (err) => {
        dispatch(updateStatus(ACTIONS.GET_TRANSFER_FAIL, { error: err }));
      }
    );
  };
};

const huvStaticBalance = (data) => {
  let config = {
    method: "get",
    isAuthorization: true,
  };

  return (dispatch) => {
    dispatch(updateStatus(ACTIONS.GET_SYSTEM_TOPUP_PROGRESS));
    // callApi(`/api/huv/statistics_balance?${queryString.stringify(data)}`,
    callApi(
      `/api/statistics_balance?${queryString.stringify(data)}`,
      config,
      null,
      (result) => {
        dispatch(updateStatus(ACTIONS.GET_SYSTEM_TOPUP_SUCCESS, result));
      },
      (err) => {
        dispatch(updateStatus(ACTIONS.GET_SYSTEM_TOPUP_FAIL, { error: err }));
      }
    );
  };
};

export {
  ACTIONS,
  huvDepositGetData,
  huvTransferSystem,
  huvStaticBalance,
  huvGetAllDepositData,
};
