import React, { Component } from "react";
import {
    Button,
} from "reactstrap";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import "./style.css";
import { ACTIONS } from "../../../../01-actions/member";
import Alert from "../../../../03-components/Alert";
import CcCheckbox from "../../../../03-components/ccCheckbox";
import SetupTFA from "./SetupTFA";
import ResetTFA from "./ResetTFA";
import VerifyAuthenticator from "./VerifyAuthenticator";

import "react-datepicker/dist/react-datepicker.css";
import {getInfoUser, changeTfaLogin} from "../../../../01-actions/member";

class SettingSecurity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpenSetup: false,
            isOpenVerify: false,
            isOpenReset: false,
            alertAck: true

        };
    }

    alertAck = () => {
        this.setState({ alertAck: false });
    };

    componentDidMount() {
        this.props.getInfoUser();
        this.alertAck();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.status === ACTIONS.VERIFY_CODE_QR_SUCCESS) {
            this.props.getInfoUser();
            this.showVerifyAuthenticator(false);
        }

        if (nextProps.status === ACTIONS.CHANGE_TFA_LOGIN_SUCCESS
            || nextProps.status === ACTIONS.SETTING_GA_FAILED
            || nextProps.status === ACTIONS.RESET_GA_FAILED
            || nextProps.status === ACTIONS.VERIFY_CODE_QR_FAILED) {
            this.alertAck();
        }

        if ( nextProps.status === ACTIONS.SETTING_GA_SUCCESS ) {
            this.showSetupTfa(false)
            this.setState({isOpenVerify: true})
        }

        if (nextProps.status === ACTIONS.RESET_GA_SUCCESS) {
            this.props.getInfoUser();
            this.showResetTfa(false)
        }
    }

    showResetTfa(isShow) {
        this.setState({
            isOpenReset: isShow
        })
    }

    showSetupTfa(isShow) {
        this.setState({
            isOpenSetup: isShow
        })
    }

    showVerifyAuthenticator() {
        this.setState({
            isOpenVerify: false
        })
    }

    tfaLogin = () => {
        this.props.changeTfaLogin({
            loginTfaEnable: this.props.infoUser && !this.props.infoUser.loginTfaEnable
        })
    }

    render() {
        const { t, infoUser } = this.props;
        let { isOpenSetup, isOpenVerify, isOpenReset } = this.state;

        return (
            <div className='setting-security'>
                <div>
                <span className='title'>
                    {t('TFASetting.SettingTfa')}
                </span>
                    <span>
                    {t('TFASetting.Content')}
                </span>
                </div>
                <div className='d-flex mt-4'>
                <span className='mr-5 font-weight-bold'>
                    {t('TFASetting.Status')}
                </span>
                    <div className='format-svg'>
                        {
                            infoUser && infoUser.tfaEnable
                            ?
                            <i className="far fa-check-circle">
                                <p>{t('TFASetting.Enabled')}</p>
                            </i>
                            :
                            <i className="far fa-times-circle">
                                <p>{t('TFASetting.Disabled')}</p>
                            </i>
                        }
                    </div>
                </div>
                <div className='mt-4'>
                    <div className='text-center mt-4'>
                        {
                            infoUser && infoUser.tfaEnable ?
                            <div>
                                <div className='text-left'>
                                    <CcCheckbox
                                        checked={infoUser.loginTfaEnable}
                                        text={t('TFASetting.TFALogin')}
                                        onChange={this.tfaLogin}
                                    />
                                </div>
                                <Button
                                    className='mt-3 button-submit'
                                    type='primary'
                                    onClick={() => this.showResetTfa(true)}
                                >
                                    {t('TFASetting.Reset')}
                                </Button>
                            </div>
                            :
                            <div>
                                <p className='content-setup'>
                                    {t('TFASetting.ContentSetup')}
                                </p>
                                <Button
                                    className='button-submit'
                                    type='primary'
                                    onClick={() => this.showSetupTfa(true)}
                                >
                                    {t('TFASetting.Setting')}
                                </Button>
                            </div>
                        }
                    </div>
                </div>
                <SetupTFA
                    t={t}
                    isOpenSetup={isOpenSetup}
                    close={() => this.showSetupTfa(false)}
                />
                <VerifyAuthenticator
                    t={t}
                    isOpenVerify={this.props.isSettingGa && isOpenVerify}
                    close={() => this.showVerifyAuthenticator(false)}
                    onSubmit={()=> this.setState({alertAck: false})}
                />
                <ResetTFA
                    t={t}
                    isOpenReset={isOpenReset}
                    close={() => this.showResetTfa(false)}
                />
                {[
                    ACTIONS.CHANGE_TFA_LOGIN_SUCCESS
                ].indexOf(this.props.status) >= 0 &&
                !this.state.alertAck && (
                    <Alert
                        title={t("Alert.Success")}
                        content={t("TFASetting.ChangeTfaLoginSuccess")}
                        okTitle={t("Alert.OK")}
                        onOK={() => {
                            this.setState({
                                alertAck: true
                            });
                            this.props.getInfoUser();
                        }}
                    />
                )}
                {[
                    ACTIONS.SETTING_GA_FAILED,
                    ACTIONS.VERIFY_CODE_QR_FAILED,
                    ACTIONS.RESET_GA_FAILED,
                ].indexOf(this.props.status) >= 0 &&
                !this.state.alertAck && (
                    <Alert
                        title={t("Alert.Error")}
                        content={t("TFASetting.INVALID_CREDENTIAL")}
                        okTitle={t("Alert.OK")}
                        onOK={() => {
                            this.setState({ alertAck: true });
                        }}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        status: store.member.status,
        infoUser: store.member.infoUser,
        isSettingGa: store.member.isSettingGa,
        memberError: store.member.memberError
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getInfoUser: () => {
            dispatch(getInfoUser());
        },
        changeTfaLogin: (data) => {
            dispatch(changeTfaLogin(data));
        }
    };
};

export default withNamespaces()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(SettingSecurity)
);
