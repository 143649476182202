import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";

import auth from "./02-reducers/auth";
import charge from "./02-reducers/charge";
import settingRate from "./02-reducers/settingRate";
import permission from "./02-reducers/permission";
import member from "./02-reducers/member";
import wallet from "./02-reducers/wallet";
import deposit from "./02-reducers/deposit";
import userMaingo from "./02-reducers/user-manigo";
import pom from "./02-reducers/pom";
import pomo from "./02-reducers/pomo";
import settingRatePomo from "./02-reducers/settingRatePomo";
import mmp from "./02-reducers/mmp";
import settingRateMMP from "./02-reducers/settingRateMMP";
const initialState = {};

export default () => {
  return createStore(
    combineReducers({
      auth,
      charge,
      settingRate,
      permission,
      member,
      wallet,
      deposit,
      userMaingo,
      pom,
      pomo,
      settingRatePomo,
      mmp,
      settingRateMMP,
    }),
    initialState,
    applyMiddleware(thunkMiddleware)
  );
};
