import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import './style.scss';

const Confirm = ({
  isOpen,
  toggle,
  className,
  title,
  children,
  labelCancel = 'Cancel',
  labelSubmit = 'Submit',
  onSubmit,
  onCancel,
  disabledSubmit
}) => (
  <Modal
    isOpen={isOpen}
    toggle={toggle}
    className={`confirm-modal ${className ? className + ' ' : ''}`}
    centered
  >
    <ModalHeader toggle={toggle}>{title}</ModalHeader>
    <ModalBody>{children}</ModalBody>
    <ModalFooter>
      <Button outline color="secondary" onClick={onCancel} className="cancel-btn">
        {labelCancel}
      </Button>{' '}
      <Button color="primary" onClick={onSubmit} className="submit-btn" disabled={!!disabledSubmit}>
        {labelSubmit}
      </Button>
    </ModalFooter>
  </Modal>
);

export default Confirm;
