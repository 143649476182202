import { ACTIONS } from '../01-actions/charge';

const initialState = {
  status: null,
  huvStatus: null,
  charges: [],
  huvCharges: [],
  users: [],
  logs: [],
  actions: [],
  chargesDay: [],
  error: null,
  pages: null,
  exportButton: true,
  statisticCharge: null,
  statisticChargeByStatus: {},
  totalTCT: 0,
  totalUSD: 0,
  adminBalance: 0,
  totalAGT:0,
  totalGCCT: 0,
  totalCCT: 0,
  totalROY: 0,
  totalGROY: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_CHARGE_PROGRESS:
        case ACTIONS.HUV_GET_CHARGE_PROGRESS:
        case ACTIONS.SELECT_GROUP:
        case ACTIONS.REJECT_CHARGE_PROGRESS:
        case ACTIONS.APPROVAL_CHARGE_PROGRESS:
        case ACTIONS.RE_REJECT_CHARGE_PROGRESS:
        case ACTIONS.SEARCH_CHARGE_PROGRESS:
        case ACTIONS.HUV_SEARCH_CHARGE_PROGRESS:
        case ACTIONS.SEARCH_CHARGE_BY_DAY_PROGRESS:
        case ACTIONS.CSV_EXPORTING:
        case ACTIONS.CSV_EXPORTED:
        case ACTIONS.UPDATE_BALANCE_ACCOUNT_PROGRESS:
        case ACTIONS.UPDATE_BALANCE_ACCOUNT_SUCCESS:
        case ACTIONS.GET_BALANCE_ACCOUNT_PROGRESS:
        case ACTIONS.SEND_TO_EZP_PROGRESS:
        case ACTIONS.SEND_TO_EZP_SUCCESS:
        case ACTIONS.GET_LOG_PROGRESS:
        case ACTIONS.GET_STATISTIC_CHARGE_BY_STATUS_PROGRESS:
        case ACTIONS.HUV_GET_CHARGE_ADMIN_AMOUNT_PROCESS:
        case ACTIONS.HUV_CHANGE_STATUS_TOP_UP_PROCESS:
            return {
                ...state,
                status: action.type
            };
        case ACTIONS.HUV_GET_CHARGE_ADMIN_AMOUNT_SUCCESS:
            return {
                ...state,
                adminBalance: action.data
            }
        case ACTIONS.REJECT_CHARGE_SUCCESS:
        case ACTIONS.APPROVAL_CHARGE_SUCCESS:
        case ACTIONS.RE_REJECT_CHARGE_SUCCESS:
            return {
                ...state,
                status: action.type,
                charges: handleRemoveItem([...state.charges], action.data, action.type)
            };
        case ACTIONS.GET_CHARGE_SUCCESS:
        case ACTIONS.SEARCH_CHARGE_SUCCESS:
            return {
                ...state,
                status: action.type,
                charges: action.data.charges,
                pages: action.data.pages
            };
        case ACTIONS.HUV_GET_CHARGE_SUCCESS:
        case ACTIONS.HUV_SEARCH_CHARGE_SUCCESS:
            return {
              ...state,
              huvStatus: action.type,
              huvCharges: action.data.charges,
              pages: action.data.pages,
              totalTCT: action.data.totalTCT || 0,
              totalUSD: action.data.totalUSD || 0,
              totalAGT: action.data.totalAGT || 0,
              totalGCCT: action.data.totalGCCT || 0,
              totalCCT: action.data.totalCCT || 0,
              totalROY: action.data.totalROY || 0,
              totalGROY: action.data.totalGROY || 0,
            };
        case ACTIONS.GET_LOG_SUCCESS:
            return {
                ...state,
                status: action.type,
                logs: action.data.logs,
                actions: action.data.actions,
                pages: action.data.pages
            };
        case ACTIONS.GET_BALANCE_ACCOUNT_SUCCESS:
            return {
                ...state,
                status: action.type,
                users: action.data.users,
                pages: action.data.pages
            };
        case ACTIONS.SEARCH_CHARGE_BY_DAY_SUCCESS:
            return {
                ...state,
                status: action.type,
                chargesDay: action.data.chargesDay,
                statisticCharge: action.data.statisticCharge,
                pages: action.data.pages
            };
        case ACTIONS.GET_STATISTIC_CHARGE_BY_STATUS_SUCCESS:
            return {
                ...state,
                status: action.type,
                statisticChargeByStatus: action.data.statisticChargeByStatus
            }
        case ACTIONS.GET_CHARGE_FAIL:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                charges: []
            };
        case ACTIONS.HUV_GET_CHARGE_FAIL:
        case ACTIONS.HUV_SEARCH_CHARGE_FAIL:
            return {
                ...state,
                huvStatus: action.type,
                error: action.data.error,
                huvCharges: []
            };
        case ACTIONS.REJECT_CHARGE_FAIL:
        case ACTIONS.APPROVAL_CHARGE_FAIL:
        case ACTIONS.RE_REJECT_CHARGE_FAIL:
        case ACTIONS.SEARCH_CHARGE_FAIL:
        case ACTIONS.SEARCH_CHARGE_BY_DAY_FAIL:
        case ACTIONS.UPDATE_BALANCE_ACCOUNT_FAIL:
        case ACTIONS.GET_BALANCE_ACCOUNT_FAIL:
        case ACTIONS.SEND_TO_EZP_FAIL:
        case ACTIONS.GET_LOG_FAIL:
        case ACTIONS.GET_STATISTIC_CHARGE_BY_STATUS_FAIL:
        case ACTIONS.HUV_GET_CHARGE_ADMIN_AMOUNT_FAIL:
        case ACTIONS.HUV_CHANGE_STATUS_TOP_UP_FAIL:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
            };
        case ACTIONS.CSV_EXPORT_FAILED:
            return {
                ...state,
                status: action.type,
                error: action.data.error,
                exportButton: false
            };
        case ACTIONS.CLEAR_STATUS:
            return{
                ...state,
                status: "",
                huvStatus: "",
            }
        default:
            return state;
    }
}
export const handleRemoveItem = (charges, listData, status) => {
    let listCharge = charges;
    if (Array.isArray(listData) && listData.length > 0) {
        for (let i = 0; i < listData.length; i++) {
            // listCharge = listCharge.filter(u => u._id !== listData[i]); // GE comment
            listCharge = listCharge.reduce((dt, u) => {
                if (u._id !== listData[i] || (u.businessId === '03' && status === ACTIONS.APPROVAL_CHARGE_SUCCESS)) {
                    u.businessId === '03' && u._id === listData[i] && (u.extra.topUpStatus = 1); // Change to ADMIN_GE_REVIEW: 1
                    dt.push(u);
                }
                return dt;
            }, []);
        }
    }
    return listCharge;
};
