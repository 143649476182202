import React, { Component } from "react";
import "./style.css";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Label, Button, Input, InputGroup, Row, Col, Table } from "reactstrap";
import {
  ACTIONS,
  huvDepositGetData,
  huvStaticBalance,
  huvGetAllDepositData,
} from "../../../../../01-actions/deposit";
import Alert from "../../../../../03-components/Alert";
import moment from "moment";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import { getInfoUser } from "../../../../../01-actions/member";
import ModalPopup from "../DepositToSystem/ModalPopup";
import Workbook from "react-excel-workbook";

class DepositToSystem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: 10,
      page: 1,
      totalPages: 1,
      systemId: 1,
      inputAmount: "",
      isOpenModal: false,
      isTransferAmount: false,
      alertAck: true,
      setMaxTransfer: false,
      valueTransfer: "",
      settingTFA: false,
      errorInsert: false,
      isInsertApiSuccess: true,
      statusAction: "",
      isPermission: false,
    };
  }

  componentDidMount() {
    this.getDepositTable();
    this.props.getInfoUser();
    this.getTopupStaticBalance();
    this.props.handleGetAllDepositData({ systemId: this.state.systemId, page: this.state.page });
    this.setState({
      alertAck: false,
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      statusAction: nextProps.status,
    });
    if (
      nextProps.status === ACTIONS.GET_TRANSFER_FAIL ||
      nextProps.status === ACTIONS.GET_TRANSFER_SUCCESS
    ) {
      if (nextProps.status === ACTIONS.GET_TRANSFER_SUCCESS) {
        this.closeModal();
        this.getDepositTable();
        this.getTopupStaticBalance();
        this.setState({
          inputAmount: "",
          systemId: this.state.systemId,
          isOpenModal: false,
          isTransferAmount: false,
        });
      }

      this.setState({
        alertAck: false,
      });
    }
  }

  getDepositTable = () => {
    this.props.handleGetDepositRequest({
      page: this.state.page,
      limit: this.state.limit,
      systemId: this.state.systemId,
    });
  };

  handleChangePage = ({ selected }) => {
    const value = parseInt(selected) + 1;
    this.setState({ page: value }, () => {
      this.getDepositTable();
    });
  };

  renderPagination = () => {
    const totalPages = _.get(this.props, "depositData.histories.totalPages", 1);
    return (
      <Row>
        <Col lg="12" md="12" sm="12" xs="12">
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            // breakLabel={<a href="">...</a>}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={this.handleChangePage}
            containerClassName="pagination m-0"
            subContainerClassName={"pages pagination"}
            activeClassName="active"
            forcePage={this.state.page - 1}
          />
        </Col>
      </Row>
    );
  };

  renderHeaderTable = () => {
    const { t } = this.props;
    return (
      <tr>
        <th>{t("DepositToSystem.Table.No")}</th>
        <th>{t("DepositToSystem.Table.System")}</th>
        <th>{t("DepositToSystem.Table.Quantity")}</th>
        <th>{t("DepositToSystem.Table.DepositTime")}</th>
        <th>{t("DepositToSystem.Table.Administrator")}</th>
      </tr>
    );
  };

  renContentTable = () => {
    const { t, depositData } = this.props;
    const managers = _.chain(depositData)
      .get(`managers[${this.state.systemId}]`, [])
      .join(", ")
      .value();
    if (
      Array.isArray(depositData.histories?.data) &&
      depositData.histories?.data.length > 0
    ) {
      return (
        <tbody>
          {depositData.histories?.data.map((item, index) => {
            return (
              <tr key={item._id}>
                <td>{index + 1}</td>
                <td>{this.onFormatSystem(item.systemId)}</td>
                <td>
                  {item.point} {item.currency}
                </td>
                <td>{moment(item.createdAt).format("YYYY/MM/DD HH:mm:ss")}</td>
                <td>{managers}</td>
              </tr>
            );
          })}
        </tbody>
      );
    } else {
      return (
        <tbody>
          <tr>
            <td colSpan={5}>
              <div className="d-flex justify-content-center">
                <span>{t("NoDataToShow")}</span>
              </div>
            </td>
          </tr>
        </tbody>
      );
    }
  };

  renderTable = () => {
    return (
      <div className="table-info-deposit mt-4">
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <Table hover bordered responsive>
              <thead className="thead-light">{this.renderHeaderTable()}</thead>
              {this.renContentTable()}
            </Table>
          </Col>
        </Row>
      </div>
    );
  };

  onChangeValue = (event) => {
    this.setState(
      {
        systemId: event.target.value,
        page: 1
      },
      () => {
        this.getDepositTable();
        this.getTopupStaticBalance();
        this.props.handleGetAllDepositData({ systemId: this.state.systemId });
      }
    );
  };

  getTopupStaticBalance = () => {
    this.props.getStaticBalance({
      systemId: this.state.systemId,
    });
  };

  onChangeEUR = (event) => {
    this.setState({
      inputAmount: event.target.value,
    });
  };

  alertAck = () => {
    this.setState({ alertAck: false });
  };

  closeModal = () => {
    this.setState({
      isOpenModal: false,
      isTransferAmount: false,
      alertAck: true,
    });
  };

  onSubmitTransfer = (event) => {
    const { inputAmount } = this.state;
    if (inputAmount > 1000000) {
      this.setState({
        setMaxTransfer: true,
      });
      return;
    }

    if (this.props.infoUser.tfaEnable) {
      this.setState({
        isOpenModal: true,
        isTransferAmount: true,
        alertAck: true,
      });
    } else {
      this.setState({
        settingTFA: true,
      });
    }
  };

  onFormatSystem = (systemId) => {
    if (!systemId) return;
    switch (Number(systemId)) {
      case 1:
        return "HUVSTYLE";
      case 2:
        return "TRIPLE";
      case 12:
        return "POM";
      case 13:
        return "POMO";
      case 14:
        return "MMP";
      default:
        return "";
    }
  };

  onFormatBalance = (systemId) => {
    if (!systemId) return;
    switch (Number(systemId)) {
      case 1:
      case 2:
      case 12:
        return "EUR";
      case 13:
      case 14:
        return "USD";
      default:
        return "";
    }
  };

  onFormatTitle = (systemId) => {
    if (!systemId) return;
    switch (Number(systemId)) {
      case 1:
      case 2:
      case 12:
        return "DepositToSystem.EUR_quantity";
      case 13:
      case 14:
        return "DepositToSystem.USD_quantity";
      default:
        return "";
    }
  };
  messageError = (errorCode) => {
    const { t } = this.props;
    switch (errorCode) {
      case "USER_TFA_CODE_INVALID":
        return t("Code.USER_TFA_CODE_INVALID");
      case "PERMISSION_DENIED":
        return t("Code.PERMISSION_DENIED");
      case "BALANCE_SUPER_ADMIN_NOT_ENOUGH":
        return t("DepositToSystem.MaxBalances");
      case "BALANCE_ADMIN_NOT_ENOUGH":
        return t("DepositToSystem.MaxBalances");
      default:
        return errorCode;
    }
  };

  render() {
    const { t, error, staticBalance, depositAllData } = this.props;

    const managers = _.chain(depositAllData)
      .get(`managers[${this.state.systemId}]`, [])
      .join(", ")
      .value();

    const dataExport = depositAllData
      ? depositAllData?.histories?.data.map((item, index) => {
          return {
            number: index + 1,
            system: this.onFormatSystem(item.systemId),
            quantity: item.point + " " + item.currency,
            date: moment(item.createdAt).format("YYYY/MM/DD HH:mm:ss"),
            admin: managers,
          };
        })
      : [];

    const {
      systemId,
      setMaxTransfer,
      isOpenModal,
      isTransferAmount,
      inputAmount,
      settingTFA,
      errorInsert,
      alertAck,
    } = this.state;

    return (
      <>
        <div className="deposit-to-system">
          <div className="top-action">
            <Row className="mt-4">
              <Col lg="4" md="4" sm="4" xs="12" className="left-info">
                <Label>
                  {this.onFormatSystem(systemId)} {t("DepositToSystem.Total")}:{" "}
                  {systemId === 0 ? 0 : staticBalance.totalRequest}{" "}
                  {this.onFormatBalance(systemId)}
                </Label>
                <Label>
                  {this.onFormatSystem(systemId)} {t("DepositToSystem.Rest")}:{" "}
                  {systemId === 0 ? 0 : staticBalance.currentBalance}{" "}
                  {this.onFormatBalance(systemId)}
                </Label>
                <Label>
                  {this.onFormatSystem(systemId)} {t("DepositToSystem.Settled")}
                  : {systemId === 0 ? 0 : staticBalance.totalRequestApproved}{" "}
                  {this.onFormatBalance(systemId)}
                </Label>
              </Col>
              <Col lg="8" md="8" sm="8" xs="12" className="left-info">
                <div className="deposit-form">
                  <InputGroup>
                    <Label>{t(this.onFormatTitle(systemId))}</Label>
                    <Input
                      type="number"
                      name="inputAmount"
                      value={inputAmount}
                      onChange={this.onChangeEUR}
                      pattern="[0-9]"
                    />
                    <small>※{t("DepositToSystem.MaxTransfer")}</small>
                  </InputGroup>
                  <InputGroup>
                    <Label>{t("DepositToSystem.ToSystem")}</Label>
                    <Input
                      type="select"
                      name="systemId"
                      value={_.toString(systemId)}
                      onChange={this.onChangeValue}
                    >
                      <option value={1}>HUVSTYLE</option>
                      <option value={12}>POM</option>
                      <option value={13}>POMO</option>
                      <option value={14}>MMP</option>
                    </Input>
                  </InputGroup>
                  <Button
                    className="btn-submit"
                    onClick={this.onSubmitTransfer}
                    disabled={
                      !this.state.inputAmount || this.state.isPermission
                    }
                  >
                    {t("Button.Confirm")}
                  </Button>
                </div>
                <div>
                  <Workbook
                    filename="システムに入金.xlsx"
                    element={
                      <button className="btn btn-export-csv mt-3">
                        {t("Button.CSVDownloadAll")}
                      </button>
                    }
                  >
                    <Workbook.Sheet
                      data={dataExport ? dataExport : []}
                      name="Sheet A"
                    >
                      <Workbook.Column label="No." value="number" />
                      <Workbook.Column label="システム" value="system" />
                      <Workbook.Column label="数量" value="quantity" />
                      <Workbook.Column label="入金時間" value="date" />
                      <Workbook.Column label="管理者" value="admin" />
                    </Workbook.Sheet>
                  </Workbook>
                </div>
              </Col>
            </Row>
          </div>
          {this.renderTable()}
          {this.renderPagination()}
        </div>

        <ModalPopup
          t={t}
          isOpen={isOpenModal}
          isTransferAmount={isTransferAmount}
          inputAmount={inputAmount}
          systemId={systemId}
          close={this.closeModal}
        />

        {setMaxTransfer && (
          <Alert
            title={t("Alert.Error")}
            content={t("DepositToSystem.MaxTransfer")}
            okTitle={t("Alert.OK")}
            onOK={() => {
              this.setState({ setMaxTransfer: false });
            }}
          />
        )}
        {settingTFA && (
          <Alert
            title={t("Alert.Error")}
            content={t("Code.SETTING_TFA_PLEASE")}
            okTitle={t("Alert.OK")}
            onOK={() => {
              this.setState({ settingTFA: false });
            }}
          />
        )}
        {errorInsert && (
          <Alert
            title={t("Alert.Error")}
            content={error?.code}
            okTitle={t("Alert.OK")}
            onOK={() => {
              this.setState({ errorInsert: false });
            }}
          />
        )}
        {[
          ACTIONS.GET_DEPOSIT_TABLE_FAIL,
          ACTIONS.GET_SYSTEM_TOPUP_FAIL,
          ACTIONS.GET_TRANSFER_FAIL,
        ].indexOf(this.props.status) >= 0 &&
          !alertAck && (
            <Alert
              title={t("Alert.Error")}
              content={this.messageError(this.props.error.code)}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.getDepositTable();
                this.setState({ alertAck: true });
              }}
            />
          )}
        {[ACTIONS.GET_ALL_DEPOSIT_DATA_FAILD].indexOf(this.props.status) >= 0 &&
          !alertAck && (
            <Alert
              title={t("Alert.Error")}
              content={this.messageError(this.props.error.code)}
              okTitle={t("Alert.OK")}
              onOK={() => {
                this.getDepositTable();
                this.setState({ alertAck: true, isPermission: true });
              }}
            />
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    depositData: state.deposit.depositData,
    status: state.deposit.status,
    infoUser: state.member.infoUser,
    error: state.deposit.error,
    staticBalance: state.deposit.staticBalance,
    depositAllData: state.deposit.depositAllData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleGetDepositRequest: (data) => {
      dispatch(huvDepositGetData(data));
    },
    getStaticBalance: (data) => {
      dispatch(huvStaticBalance(data));
    },
    getInfoUser: () => {
      dispatch(getInfoUser());
    },
    handleGetAllDepositData: (data) => {
      dispatch(huvGetAllDepositData(data));
    },
  };
};
export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(DepositToSystem)
);
